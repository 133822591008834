import React from 'react'
import { useNavigate } from 'react-router-dom'

import backgroundImage from '@/assets/images/signUp-background.svg'
import { DualScreenLayout } from '@/components/DualScreenLayout'
import signInImage from '@/assets/images/signIn-image.svg'
import { SignInForm } from '@/forms/SignIn'
import { useAuth } from '@/contexts/auth'
import { Loader } from '@/components/Loader'

export default function SignIn() {
  const { signIn, route, authorized, isLoading } = useAuth()
  const navigate = useNavigate()

  if (isLoading) {
    return <Loader />
  }

  authorized && navigate(route)

  return (
    <DualScreenLayout
      image={signInImage}
      imageWidth="73%"
      background={backgroundImage}
    >
      <SignInForm isLoading={isLoading} onSubmit={signIn} />
    </DualScreenLayout>
  )
}
