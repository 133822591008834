import React, { forwardRef } from 'react'
import ReactSelect from 'react-select'

import { Wrapper } from './styles'

const TeamSizeDropDown = forwardRef(
  (
    {
      label = '',
      placeholder = '',
      error = '',
      name,
      type,
      value,
      touched,
      children,
      options,
      form,
      setFieldValue,
      onBlur,
      onChange,
      icon,
      width,
      isSearchable,
      ...otherProps
    },
    ref
  ) => {
    return (
      <Wrapper {...otherProps}>
        <ReactSelect
          ref={ref}
          classNamePrefix="react-select"
          options={options}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          defaultValue={options[0]}
        />
      </Wrapper>
    )
  }
)

export default TeamSizeDropDown
