import React, { useCallback, useEffect, useState } from 'react'
import { Formik } from 'formik'
import axios from 'axios'

import { ADD_EMPLOYEES_ROUTE } from '@/constants/routes'
import { managerColumns } from '@/constants/table-columns'
import { GET_USERS_ENDPOINT } from '@/constants/endpoints'
import { fetchManagersConfig } from '@/constants/fetch-data-config'
import plusIcon from '@/assets/icons/plus.svg'
import emptyImage from '@/assets/images/add-managers.png'
import { Icon } from '@/components/Icon'
import { Title } from '@/components/Title'
import { Table } from '@/components/Table'
import { Button } from '@/components/Button'
import { Loader } from '@/components/Loader'
import { InfoCircle } from '@/components/InfoCircle'
import { HelpButton } from '@/components/HelpButton'
import { Pagination } from '@/components/Pagination'
import { ImageBlock } from '@/components/ImageBlock'
import { DualSection } from '@/components/DualSection'
import { ProgressBar } from '@/components/ProgressBar'
import { ExpertiseField } from '@/components/Expertise'
import { AddManagersModalForm } from '@/forms/AddManagersModal'
import { InputField } from '@/fields/Input'
import { SelectField } from '@/fields/Select'
import { CheckboxField } from '@/fields/Checkbox'
import { MaskedInputField } from '@/fields/MaskedInput'
import { SelectSearchField } from '@/fields/SelectSearch'
import { formatData, formatSearchUsers } from '@/helpers/formatData'
import { createToast } from '@/helpers/createToast'

import { validationSchema, DEFAULT_VALUES } from './config'
import { HelpButtonWrapper, CheckboxWrapper, TableWrapper } from './styles'

export function AddManagersForm({
  initialValues,
  fetchData,
  data,
  locations,
  onSubmit,
  onModalSubmit,
  isLoading,
  error,
  setError,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [rows, setRows] = useState([])
  const [changingUser, setUser] = useState()
  const [config, setConfig] = useState(fetchManagersConfig)
  const [pages, setPages] = useState(1)
  const [currentPage, setPage] = useState(1)
  const [isRequestLoading, setRequestLoading] = useState(false)

  let searchTimeout

  const openModal = useCallback(() => setModalIsOpen(true), [])
  const closeModal = useCallback(() => setModalIsOpen(false), [])

  const openEditModalHandler = useCallback((user) => {
    openModal()
    setUser(user)
  }, [])
  const closeEditModalHandler = useCallback(() => {
    closeModal()
    setUser(false)
  }, [])

  const submitForm = useCallback(
    (formData) => {
      const formattedData = { ...formData }
      onSubmit(formattedData)
      setRequestLoading(true)
      setTimeout(() => {
        fetchData(config)
        setRequestLoading(false)
      }, 1000)
    },
    [config]
  )

  const submitModalForm = useCallback((formData, users) => {
    const formattedData = { ...formData }
    onModalSubmit(formattedData)
    const res = users?.reduce(
      (arr, user) =>
        user.id !== formattedData.id
          ? [...arr, user]
          : [
              ...arr,
              {
                firstName: formattedData.name.split(' ')[0],
                lastName: formattedData.name.split(' ')[1] || '',
                manager: formattedData.manager,
                managerName: formattedData.managerName,
                ...formattedData,
              },
            ],
      []
    )
    setRows(formatData(res, null, openEditModalHandler))
  }, [])

  const handlePagination = useCallback((page) => {
    setPage(page)
    setConfig({
      skip: page * 10,
      limit: 10,
      search: '',
      role: 'manager',
      originManager: false,
    })
  }, [])

  const loadOptions = useCallback((inputValue, callback) => {
    clearTimeout(searchTimeout)
    searchTimeout = setTimeout(() => {
      axios
        .post(GET_USERS_ENDPOINT, {
          skip: 0,
          limit: 10,
          search: inputValue,
          role: 'manager',
          originManager: true,
        })
        .then(({ data: users }) => {
          const options = formatSearchUsers(users.tempUsers, true)
          callback(options)
        })
    }, 1000)
  }, [])

  useEffect(() => {
    fetchData(config)
  }, [config])

  useEffect(() => {
    if (error) {
      if (typeof error === 'object') createToast('error', error[0])
      else createToast('error', error)
      fetchData(config)
    }
    setError(false)
  }, [error, setError, config])

  useEffect(() => {
    setRows(
      (data && formatData(data.tempUsers, null, openEditModalHandler)) || []
    )
    setPages(data && data.total)
  }, [data])

  return (
    <>
      <HelpButtonWrapper>
        <HelpButton />
      </HelpButtonWrapper>
      <ProgressBar active={2} />
      <AddManagersModalForm
        isOpen={modalIsOpen}
        onClose={closeEditModalHandler}
        onSubmit={submitModalForm}
        locations={locations}
        user={changingUser}
        rows={rows}
      />
      <DualSection
        data={rows.length}
        title="Add Managers"
        subtitle="Start at the top of your org chart and work your way down."
        continuePath={ADD_EMPLOYEES_ROUTE}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitForm}
          >
            {({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <Title bold fontSize={7} marginBottom={2}>
                  Add Manager
                </Title>
                <InputField
                  name="name"
                  label="NAME"
                  placeholder="Full name"
                  margin={12}
                />
                <InputField
                  type="email"
                  name="email"
                  label="EMAIL"
                  placeholder="test@soar.com"
                  margin={12}
                />
                <MaskedInputField
                  type="text"
                  name="phone"
                  label="MOBILE PHONE NUMBER"
                  placeholder="123-456-7890"
                  margin={12}
                />
                <SelectField
                  name="location"
                  label="LOCATION"
                  placeholder="Location"
                  options={
                    locations
                      ? locations.map((item) => {
                          return { value: item, label: item }
                        })
                      : []
                  }
                  margin={12}
                />
                <SelectSearchField
                  name="manager"
                  label="MANAGER THEY REPORT TO"
                  placeholder="None (top-level manager)"
                  loadOptions={loadOptions}
                  hideIndicator={false}
                  margin={12}
                  menuPlacement="top"
                />
                <CheckboxWrapper>
                  <CheckboxField
                    name="certifiedCoach"
                    label="Certified coach"
                  />
                  <InfoCircle right marginLeft={3}>
                    You can designate any employee as a certified coach if you
                    want them to mentor other employees.
                  </InfoCircle>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <CheckboxField name="isAdmin" size={20} label="Admin role" />
                  <InfoCircle right marginLeft={3}>
                    You can designate any employee as an admin of this
                    organization if you want them to manage the admin console.
                  </InfoCircle>
                </CheckboxWrapper>
                {values.certifiedCoach && <ExpertiseField values={values} />}
                <Button type="submit" fontSize={5} margin={{ top: '16px' }}>
                  <Icon src={plusIcon} />
                  Add Manager
                </Button>
              </form>
            )}
          </Formik>
        )}
        <TableWrapper>
          {(isLoading || isRequestLoading) && <Loader />}
          {!isLoading &&
            !isRequestLoading &&
            (rows.length ? (
              <TableWrapper>
                <Table columns={managerColumns} data={rows} />
                <Pagination
                  currentPage={currentPage}
                  size={pages}
                  callback={handlePagination}
                />
              </TableWrapper>
            ) : (
              <ImageBlock
                image={emptyImage}
                subtitle="Managers will appear here once you add them"
                imageWidth={100}
              />
            ))}
        </TableWrapper>
      </DualSection>
    </>
  )
}

AddManagersForm.defaultProps = {
  initialValues: DEFAULT_VALUES,
}
