import React from 'react'

import '../../index.css'
import 'tw-elements'

import { Button } from '@/components/Button'
import { Input } from '@/components/Input'
import { numberWithCommas } from '@/utils/paymentPlanUtils'

export function PriceConfirmation({
  handleTotalUsers,
  handlePaymentFrequency,
  usersValue,
  planRate,
  highlightedPlan,
  planFrequency,
  totalPriceMonthly,
  totalPriceYearly,
  planPriceElite,
  onGoToCheckout,
  onGoBack,
}) {
  return (
    <div className="p-6 pl-0">
      <div className="text-base text-white uppercase mb-4">Sales accounts</div>
      <div className="text-base text-white uppercase mb-4">
        <Input
          name="userQuantity"
          type="text"
          onChange={handleTotalUsers}
          style={{ width: '20rem' }}
          defaultValue={usersValue}
        />
      </div>
      <div className="text-base text-white uppercase mb-4">Plan type</div>
      <div className="text-base text-white  mb-4 text-gray-400">
        {highlightedPlan}
      </div>
      <div className="text-base text-white uppercase mb-4">
        manager accounts
      </div>
      <div className="text-base text-white  mb-4 text-gray-400">No charge</div>
      <div className="text-base text-white uppercase mb-4">Coaching App</div>
      <div className="text-base text-white  mb-4 text-gray-400">No charge</div>
      <div className="text-base text-white uppercase mb-4">Plan Rate</div>
      <div className="text-base text-white  mb-4 text-gray-400">
        $
        {highlightedPlan === 'Pro'
          ? planRate.toFixed(2)
          : planPriceElite.toFixed(2)}{' '}
        / month
      </div>
      <div className="text-base text-white uppercase mb-4">
        Payment term & pricing
      </div>

      <div className="text-base text-white mb-4">
        <div className="flex">
          <div>
            <div
              className="form-check my-2"
              onClick={() => handlePaymentFrequency('monthly')}
            >
              <input
                className="form-check-input appearance-none rounded-full h-6 w-6 border-2 border-gray-500 bg-transparent checked:bg-green-500 checked:border-green-500 focus:outline-none transition duration-200 align-middle mr-2 cursor-pointer"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                defaultChecked={planFrequency === 'monthly' ? 'checked' : false}
              />
              <label
                className="form-check-label inline-block text-white"
                htmlFor="flexRadioDefault1"
              >
                Monthly ${numberWithCommas(totalPriceMonthly.toFixed(2))}
              </label>
            </div>

            <div
              className="form-check my-2"
              onClick={() => handlePaymentFrequency('yearly')}
            >
              <input
                className="form-check-input appearance-none rounded-full h-6 w-6 border-2 border-gray-500 bg-transparent checked:bg-green-500 checked:border-green-500 focus:outline-none transition duration-200 align-middle bg-no-repeat bg-center mr-2 cursor-pointer"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                defaultChecked={planFrequency === 'yearly' ? 'checked' : false}
              />
              <label
                className="form-check-label inline-block text-white"
                htmlFor="flexRadioDefault2"
              >
                Annually ${numberWithCommas(totalPriceYearly.toFixed(2))} -{' '}
                <span className="text-green-500 font-bold">
                  {' '}
                  Save $
                  {numberWithCommas(
                    (totalPriceMonthly * 12 - totalPriceYearly).toFixed(2)
                  )}{' '}
                  (15%){' '}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="text-base text-white uppercase mb-4 italic text-gray-400">
        You won't be charged during your free trial, which begins on the day of
        purchase. You can cancel anytime within the first 30 days to avoid being
        charged.
      </div>
      <div className="flex justify-between mt-10">
        <Button
          onClick={onGoBack}
          style={{
            color: '#FFF',
            fontWeight: 'normal',
            background: 'transparent',
            border: '1px solid',
            minWidth: '14.3rem',
          }}
        >
          Back
        </Button>
        <Button
          gray={usersValue <= 0 ? 'gray' : null}
          onClick={onGoToCheckout}
          style={{ color: '#FFF', fontWeight: 'normal', minWidth: '14.3rem' }}
        >
          Continue
        </Button>
      </div>
      <div className="text-white mt-8">
        Questions?{' '}
        <a className="text-green-500 font-bold" href="mailto:admin@soarapp.io">
          Email
        </a>{' '}
        or call{' '}
        <a className="text-green-500" href="tel:+18449963700">
          +1-844-996-3700
        </a>
      </div>
    </div>
  )
}

export default PriceConfirmation
