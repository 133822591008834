import styled from 'styled-components'

export const Wrapper = styled.div`
  cursor: ${({ pointer }) => pointer && 'pointer'};
  display: flex;
  filter: ${({iconColorSvg}) => iconColorSvg};
  margin-left: ${({ theme, marginLeft }) =>
    marginLeft !== undefined ? theme.spaces[marginLeft] : theme.spaces[0]}px;
  margin-right: ${({ theme, margin }) =>
    margin !== undefined ? theme.spaces[margin] : theme.spaces[3]}px;
`

export const Image = styled.img`
  display: block;
  height: ${({ size }) => size || 18}px;
  width: ${({ size }) => size || 18}px;
`
