import { Box } from 'grommet'
import styled, { css } from 'styled-components'

import searchIcon from '@/assets/icons/search.svg'

export const FieldWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  margin: ${({ theme, margin }) => theme.spaces[margin]}px 0;
  max-width: ${({ width }) => width || 'auto'};
  width: 100%;
`

export const Input = styled.input`
  background-color: ${({ theme }) => theme.colors.backgroundItems};
  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.colors.error : theme.colors.border};
  border-radius: ${({ theme }) => theme.spaces[1]}px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.primaryText};
  display: block;
  height: 40px;
  max-width: ${({ width }) => width};
  outline: 0;
  padding: 12px 12px 12px 48px;
  transition: 0.1s all;
  width: 100%;

  &:hover {
    border: 1px solid
      ${({ theme, hasError }) =>
        hasError ? theme.colors.errorActive : theme.colors.white};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }

  ${(props) =>
    props.disabled &&
    css`
      background: ${({ theme }) => theme.colors.disabled};
      border-color: ${({ theme }) => theme.colors.disabled};
      color: ${({ theme }) => theme.colors.primaryText};
    `}
`

export const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`

export const SearchIcon = styled.img.attrs({
  src: searchIcon,
})`
  height: 20px;
  left: 12px;
  min-width: 20px;
  position: absolute;
`
