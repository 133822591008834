import styled from 'styled-components'

export const DualScreenWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  height: 100vh;
  width: 100%;

  ${({ theme }) => theme.below.tablet`
    justify-content: center;
  `}
`

export const DualScreenContent = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 52%;

  ${({ theme }) => theme.below.tablet`
  align-items: start;
  width: 80%;
  `}
`

export const DualScreenDecor = styled.div`
  align-items: center;
  background-image: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 48%;

  ${({ theme }) => theme.below.tablet`
    display: none;
  `}
`

export const DualScreenDecorBackground = styled.img`
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
`

export const DualScreenDecorImage = styled.img`
  display: block;
  width: ${(props) => props.imageWidth};
`
