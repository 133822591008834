import axios from 'axios'
import { openCalendly } from 'utils/calendlyUtils'
import { ORGANIZATION_ENDPOINT, MANAGER_ENDPOINT } from '@/constants/endpoints'
import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'

export function searchAndFilterArrayOfObjects(array, searchKey) {
  return array.filter((obj) => {
    return Object.keys(obj).some((key) => {
      return obj[key]
        .toString()
        .toLowerCase()
        .includes(searchKey.toString().toLowerCase())
    })
  })
}

export function arrayFromObjectKeys(obj) {
  const arr = []
  for (const key in obj) {
    arr[key] = obj[key]
  }
  return arr
}

export const getLocations = async (orgId) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', localStorage.getItem(AUTH_TOKEN_STORAGE_KEY))
  const org = await (
    await fetch(`${ORGANIZATION_ENDPOINT}/${orgId}`, {
      method: 'GET',
      headers,
    })
  ).json()
  return (org && org.locations) || []
}

export const getUsrInfo = async (fbUid) => {
  return axios.get(`${MANAGER_ENDPOINT}/${fbUid}`)
}

export function searchAndFilterArrayOfObjectsViaKeyValue(array, keyString) {
  return array.filter((obj) => {
    return Object.keys(obj).some((key) => {
      if (keyString.toString().toLowerCase() === 'admin')
        return obj.isAdmin === true && obj.certifiedCoach === false
      if (keyString.toString().toLowerCase() === 'coach')
        return obj.certifiedCoach === true && obj.isAdmin === false
      if (keyString.toString().toLowerCase() === 'coach & admin')
        return obj.isAdmin === true && obj.certifiedCoach === true
    })
  })
}

export const formatMoney = (number) => {
  return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export const GoToCalendly = (e) => {
  e.preventDefault()
  if (
    // eslint-disable-next-line no-alert
    window.confirm(
      "You're being redirected to our scheduling website. \nPlease setup an appointment for a sales consultation."
    )
  ) {
    openCalendly()
  }
}
