import styled from 'styled-components'

import { Link as BaseLink } from '@/components/Link'

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[5]}px;
  justify-content: space-between;
  max-width: 400px;
  width: 100%;

  @media (max-width: 500px) {
    align-items: center;
    flex-direction: column;
  }
`

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const UploadButtonWrapper = styled.label`
  & > input {
    display: none;
  }
`

export const UploadButton = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundActive};
  border-radius: ${({ theme }) => theme.spaces[2]}px;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  height: 40px;
  justify-content: center;
  transition: 0.1s all;
  width: 192px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundActiveLight};
  }
`

export const Link = styled(BaseLink)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-decoration: underline;

  &:hover {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-decoration: underline;
  }
`
