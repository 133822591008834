import React from 'react'

import { PricingTable } from './styles'

export function PlanPricingDetails(props) {
  const { data, paymentFrequencyFlag } = props
  const pricingLabels = Object.keys(data.pricing.monthly)
  const pricingValues = Object.values(data.pricing.monthly)
  return (
    <PricingTable>
      <table>
        <th>
          <td colSpan="2">Monthly price per user</td>
        </th>
        {pricingLabels.map((key) => (
          <tr key={key}>
            <td>{key} users</td>
            <td>
              {paymentFrequencyFlag === 'yearly' ? (
                <>
                  <span className="subscription-box-price-before">
                    ${data.pricing.monthly[key].toFixed(2)}
                  </span>
                  <span className="subscription-box-price-after">
                    ${data.pricing.yearly[key].toFixed(2)}
                  </span>
                </>
              ) : (
                <span>${data.pricing.monthly[key].toFixed(2)}</span>
              )}
            </td>
          </tr>
        ))}
      </table>
    </PricingTable>
  )
}
