import styled from 'styled-components'
import 'rsuite/dist/rsuite.min.css'
import '../../forms/Dashboard/styles.css'

export const GraphFiltersBlock = styled.div`
  display: ${({ isDesktop }) => (isDesktop ? 'grid' : 'flex')};
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'initial' : 'column')};
  flex-grow: 1;
  gap: 22px;
  grid-template-columns: ${({ isDesktop }) =>
    isDesktop ? 'repeat(5, 1fr)' : 'none'};
  height: ${({ isDesktop }) => (isDesktop ? '40px' : 'auto')};
  justify-content: flex-end;
  margin-bottom: 50px;
  position: relative;

  ${({ theme }) => theme.below.laptop`
    align-items: center;
    flex-direction: column;
    min-height: 165px;
  `}
`

export const TopWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 38px;
  width: 100%;
`

export const CloseButton = styled.div`
  cursor: pointer;
  font-family: 'Inter';
`

export const SearchWrapper = styled.div`
  align-items: center;
  background-color: rgb(31, 41, 55);
  border: 1px solid rgb(107, 114, 128);
  border-radius: 4px;
  display: flex;
  justify-content: left;
  padding: 0px 8px 0px 15px;
  width: ${({ isDesktop }) => (isDesktop ? '315px' : '100%')};
  & input {
    background: transparent;
    border: none;
    color: #fff;
    outline: none;
    width: 100%;
    &::placeholder {
      color: #fff;
    }
  }
`

export const RangeTitle = styled.div`
  color: #ffffff;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`

export const CustomDateRange = styled.div`
  align-items: center;
  background: #1f2937;
  border: 1px solid #4b5563;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgb(255 255 255 / 4%);
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: start;
  left: 0;
  left: 50% !important;
  max-width: 575px;
  padding: 38px 0;
  position: fixed;
  top: 20%;
  transform: translateX(-50%);
  width: 90vw;
  z-index: 7;
`
