import axios from 'axios'
import { useMutation } from 'react-query'

import { ALL_USERS_DATA_ENDPOINT } from '@/constants/endpoints'

export const useAllUsersDataMutation = (options, orgId, mode = '') =>
  useMutation(
    (payload = { skip: 0, limit: 100, search: '', originManager: false, mode  }) =>
      axios.post(`${ALL_USERS_DATA_ENDPOINT}/${orgId}`, payload),
    options
  )
