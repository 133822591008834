import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'
import { PAYMENT_SUCCESS_ROUTE } from '@/constants/routes'
import '../../index.css'

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#87bbfd' },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
}

export default function PaymentForm() {
  const [success, setSuccess] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    })

    if (!error) {
      try {
        const { id } = paymentMethod
        const response = await axios({
          url: 'http://localhost:8080/api/payments',
          method: 'post',
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              AUTH_TOKEN_STORAGE_KEY
            )}`,
          },
          data: {
            amount: 1000,
            id,
          },
        })

        if (response && response.data && response.data.success) {
          setSuccess(true)
          navigate(PAYMENT_SUCCESS_ROUTE)
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      console.error(error)
    }
  }

  return (
    <>
      {!success ? (
        <form onSubmit={handleSubmit}>
          <fieldset className="FormGroup">
            <div className="FormRow border-slate-400 bg-neutral-200">
              <CardElement options={CARD_OPTIONS} />
            </div>
          </fieldset>
          <button className="bg-green-500">Pay</button>
        </form>
      ) : null}
    </>
  )
}
