import React, { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useForgotPasswordMutation } from '@/queries/forgot-password'
import backgroundImage from '@/assets/images/layout-background.png'
import { RecoveryLinkForm } from '@/forms/RecoveryLink'
import Layout from '@/components/Layout'
import { createToast } from '@/helpers/createToast'

export default function RecoveryLink() {
  const [searchParams] = useSearchParams()

  const { mutate: forgotPassword } = useForgotPasswordMutation({
    onSuccess: () => {
      createToast('success', 'Recovery link has been sent!')
    },
  })

  const handleResend = useCallback(
    () => forgotPassword({ email: searchParams.get('email') }),
    []
  )

  return (
    <Layout background={backgroundImage}>
      <RecoveryLinkForm resend={handleResend} />
    </Layout>
  )
}
