import React from 'react'

import { Icon } from '@/components/Icon'
import crossIcon from '@/assets/icons/crossWhite.svg'

import { Wrapper } from './styles'

export function CloseButton({ onClick, top = 30, right = 30 }) {
  return (
    <Wrapper onClick={onClick} top={top} right={right}>
      <Icon src={crossIcon} size={24} />
    </Wrapper>
  )
}
