import axios from 'axios'
import { useQuery } from 'react-query'

import { GET_INFO_ENDPOINT, ORGANIZATION_ENDPOINT } from '@/constants/endpoints'
import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'

export const useOrgDataQuery = (orgId = null, options = null) =>
  useQuery(
    `${ORGANIZATION_ENDPOINT}/locations`,
    async () => {
      const { data: userData } = await axios.get(GET_INFO_ENDPOINT, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            AUTH_TOKEN_STORAGE_KEY
          )}`,
        },
      })

      const organizationId = orgId || userData.organization
      const { data } = await axios.get(
        `${ORGANIZATION_ENDPOINT}/${organizationId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              AUTH_TOKEN_STORAGE_KEY
            )}`,
          },
        }
      )

      data.orgName = data.name
      data.name = `${userData.firstName} ${userData.lastName}`
      data.email = userData.email
      data.mobile = userData.mobile

      return data
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      ...options,
    }
  )
