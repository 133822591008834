export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '/api'
export const ADMIN_ENDPOINT = process.env.REACT_APP_ADMIN_ENDPOINT || '/admin'

export const API_PREFIX = 'api'
export const ORG_PREFIX = 'org'
export const AUTH_PREFIX = 'auth'
export const USER_PREFIX = 'user'
export const GOAL_PREFIX = 'goal'
export const VIEW_PREFIX = 'view'
export const ADMIN_PREFIX = 'admin'
export const SUPER_ADMIN_PREFIX = 'super-admin'
export const TABLE_PREFIX = 'table'
export const GRAPH_PREFIX = 'graph'
export const COACH_PREFIX = 'coach'
export const TRENDS_PREFIX = 'trends'
export const FUNNEL_PREFIX = 'funnel'
export const REPORT_PREFIX = 'report'
export const MANAGER_PREFIX = 'manager'
export const COACHING_PREFIX = 'coaching'
export const PROGRESS_PREFIX = 'progress'
export const MULTIREG_PREFIX = 'multireg'
export const GRAPH_RANGE_PREFIX = 'graphRange'
export const MULTI_COACHING_PREFIX = 'multicoach'
export const ORGANIZATIONS_PREFIX = 'organizations'
export const USERS_CROSS_ORG_PREFIX = 'all_users_cross_org'
export const ALL_ORGANIZATIONS_PREFIX = 'all_organizations'
export const PAYMENTS_PREFIX = 'payments'

export const DOWNLOAD_SPREADSHEET_LINK =
  'https://soarapp-bucket.fra1.digitaloceanspaces.com/template.xlsx'
export const APP_ANDROID_URI =
  'https://play.google.com/store/apps/details?id=com.dgs.soar'
export const APP_IOS_URI =
  'https://apps.apple.com/us/app/sales-with-soar/id1640065963'
export const UPLOAD_SPREADSHEET_ENDPOINT = `${ADMIN_PREFIX}/${MULTIREG_PREFIX}/xlsx`

export const SIGN_IN_ENDPOINT = `${ADMIN_PREFIX}/${AUTH_PREFIX}/signin`
export const SIGN_UP_ENDPOINT = `${ADMIN_PREFIX}/${AUTH_PREFIX}/signup`
export const GET_INFO_ENDPOINT = `${ADMIN_PREFIX}/${AUTH_PREFIX}/me`
export const UPDATE_INFO_ENDPOINT = `${ADMIN_PREFIX}/${AUTH_PREFIX}/edit`

export const UPDATE_PASSWORD_ENDPOINT = `${API_PREFIX}/${USER_PREFIX}/password`
export const CHANGE_PASSWORD_ENDPOINT = `${ADMIN_PREFIX}/${AUTH_PREFIX}/password`
export const FORGOT_PASSWORD_ENDPOINT = `${ADMIN_PREFIX}/${AUTH_PREFIX}/forgot-password`

export const ORGANIZATION_ENDPOINT = `${ADMIN_PREFIX}/org`
export const DELETE_MANY_ORGANIZATIONS_ENDPOINT = `${ADMIN_PREFIX}/${ORG_PREFIX}/deleteMany`
export const ENABLE_MANY_ORGANIZATIONS_ENDPOINT = `${ADMIN_PREFIX}/${ORG_PREFIX}/enableMany`
export const GET_BILLING_DATA = `${ADMIN_PREFIX}/${ORG_PREFIX}/billing`

export const MANAGER_ENDPOINT = `${ADMIN_PREFIX}/${MANAGER_PREFIX}`
export const RESET_PASSWORD_ENDPOINT = `${API_PREFIX}/${USER_PREFIX}/reset-password`
export const MANAGER_DATA_ENDPOINT = `${ADMIN_PREFIX}/${MANAGER_PREFIX}/managers`
export const EMPLOYEE_DATA_ENDPOINT = `${ADMIN_PREFIX}/${MANAGER_PREFIX}/employees`
export const ALL_USERS_DATA_ENDPOINT = `${ADMIN_PREFIX}/${MANAGER_PREFIX}/all_users`

export const VIEW_ENDPOINT = `${ADMIN_PREFIX}/${VIEW_PREFIX}`
export const GET_SAVED_VIEWS_ENDPOINT = `${ADMIN_PREFIX}/${VIEW_PREFIX}`

export const GET_COACH_MANAGER_ENDPOINT = `${ADMIN_PREFIX}/${MANAGER_PREFIX}/coach-users`
export const GET_USER_COACHES_ENDPOINT = `${API_PREFIX}/${USER_PREFIX}/${COACHING_PREFIX}/mylist`
export const SET_USER_COACH_ENDPOINT = `${API_PREFIX}/${USER_PREFIX}/${COACHING_PREFIX}`
export const SET_USER_MULTIPLE_COACH_ENDPOINT = `${API_PREFIX}/${USER_PREFIX}/${MULTI_COACHING_PREFIX}`

export const USER_ENDPOINT = `${ADMIN_PREFIX}/${MULTIREG_PREFIX}/user`
export const GET_USERS_ENDPOINT = `${ADMIN_PREFIX}/${MULTIREG_PREFIX}/users`
export const GET_COACH_ENDPOINT = `${ADMIN_PREFIX}/${MULTIREG_PREFIX}/coach-users`
export const SEND_INVITES_ENDPOINT = `${ADMIN_PREFIX}/${MULTIREG_PREFIX}/select-invite`

export const FUNNEL_ENDPOINT = `${ADMIN_PREFIX}/${FUNNEL_PREFIX}`

export const DASHBOARD_GRAPH_DATA_ENDPOINT = `${ADMIN_PREFIX}/${REPORT_PREFIX}/${GRAPH_PREFIX}`
export const DASHBOARD_GRAPH_DATA_RANGE_ENDPOINT = `${ADMIN_PREFIX}/${REPORT_PREFIX}/${GRAPH_RANGE_PREFIX}`
export const DASHBOARD_TABLE_DATA_ENDPOINT = `${ADMIN_PREFIX}/${REPORT_PREFIX}/${TABLE_PREFIX}/employee`
export const DASHBOARD_TABLE_DATA_RANGE_ENDPOINT = `${ADMIN_PREFIX}/${REPORT_PREFIX}/${TABLE_PREFIX}/employeeRange`
export const DASHBOARD_TABLE_LOCATION_DATA_ENDPOINT = `${ADMIN_PREFIX}/${REPORT_PREFIX}/${TABLE_PREFIX}/location`
export const DASHBOARD_TABLE_USER_DATA_ENDPOINT = `${API_PREFIX}/${GOAL_PREFIX}/${PROGRESS_PREFIX}`
export const DASHBOARD_GRAPH_TRENDS_ENDPOINT = `${ADMIN_PREFIX}/${REPORT_PREFIX}/${TRENDS_PREFIX}`

export const GET_COACHEE_DATA = `${ADMIN_PREFIX}/${COACH_PREFIX}`

export const SUPER_ADMIN_ORGANIZATIONS_ENDPOINT = `${SUPER_ADMIN_PREFIX}/${ORGANIZATIONS_PREFIX}`
export const SUPER_ADMIN_ALL_ORGANIZATIONS_ENDPOINT = `${SUPER_ADMIN_PREFIX}/${ALL_ORGANIZATIONS_PREFIX}`

export const GET_DASHBOARD_GRAPH_TRENDS_ENDPOINT = (orgId) =>
  orgId
    ? `${DASHBOARD_GRAPH_TRENDS_ENDPOINT}/${orgId}`
    : DASHBOARD_GRAPH_TRENDS_ENDPOINT
export const ALL_ORGANIZATIONS_ENDPOINT = `${ADMIN_PREFIX}/${ORG_PREFIX}/list`
export const ORGANIZATIONS_LOOKUP_ENDPOINT = `${ADMIN_PREFIX}/${ORG_PREFIX}/lookup`
export const USERS_CROSS_ORG_ENDPOINT = `${ADMIN_PREFIX}/${MANAGER_PREFIX}/${USERS_CROSS_ORG_PREFIX}`
export const UPDATE_USERS_ENDPOINT = `${SUPER_ADMIN_PREFIX}/update-users`
export const UPDATE_PAYMENT_STATUS = `${API_PREFIX}/${PAYMENTS_PREFIX}/order/status`
