import styled from 'styled-components'

export const PaginationWrapper = styled.div`
  align-items: center;
  display: ${({ size }) => (size > 10 ? 'flex' : 'none')};
  gap: ${({ theme }) => theme.spaces[6]}px;
  justify-content: center;
  margin-top: ${({ theme, mt }) => (mt ? `${theme.spaces[mt]}px` : 'auto')};
  width: 100%;
`

export const PaginationItem = styled.button`
  background: none;
  color: ${({ theme }) => theme.colors.backgroundActive};
  font-size: ${({ theme }) => theme.fontSizes[6]}px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  &:hover {
    color: ${({ theme }) => theme.colors.backgroundActiveDark};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.backgroundActiveDark};
  }
`
