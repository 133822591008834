import React from 'react'
import { GoToCalendly } from 'utils/utils'

export function Footer() {
  const d = new Date()
  const year = d.getFullYear()
  return (
    <div
      data-elementor-type="footer"
      data-elementor-id="358"
      className="elementor elementor-358 elementor-location-footer"
    >
      <div className="elementor-section-wrap">
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-1acaf3a1 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="1acaf3a1"
          data-element_type="section"
          id="footer-custom"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div className="elementor-row">
              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3d2f4195"
                data-id="3d2f4195"
                data-element_type="column"
              >
                <div className="elementor-column-wrap elementor-element-populated">
                  <div className="elementor-widget-wrap">
                    <div
                      className="elementor-element elementor-element-d4ca234 elementor-widget-tablet__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-image"
                      data-id="d4ca234"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-image">
                          <a href="/home">
                            <img
                              width="218"
                              height="74"
                              src={`${process.env.REACT_APP_DOMAIN}/landing/logo-w-tagline-white.png`}
                              className="attachment-full size-full"
                              alt=""
                              loading="lazy"
                            />{' '}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-5ce7b4c e-grid-align-left e-grid-align-mobile-center elementor-shape-circle e-grid-align-tablet-center elementor-widget-tablet__width-auto elementor-widget-mobile__width-inherit elementor-grid-0 elementor-widget elementor-widget-social-icons"
                      data-id="5ce7b4c"
                      data-element_type="widget"
                      data-widget_type="social-icons.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-social-icons-wrapper elementor-grid">
                          <span className="elementor-grid-item">
                            <a
                              className="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-grow elementor-repeater-item-0b6b92c"
                              target="_blank"
                            >
                              <span className="elementor-screen-only">
                                Facebook-f
                              </span>
                              <i className="fab fa-facebook-f" />{' '}
                            </a>
                          </span>
                          <span className="elementor-grid-item">
                            <a
                              className="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-animation-grow elementor-repeater-item-a9c715f"
                              target="_blank"
                            >
                              <span className="elementor-screen-only">
                                Twitter
                              </span>
                              <i className="fab fa-twitter" />{' '}
                            </a>
                          </span>
                          <span className="elementor-grid-item">
                            <a
                              className="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-animation-grow elementor-repeater-item-c3629ec"
                              target="_blank"
                            >
                              <span className="elementor-screen-only">
                                Youtube
                              </span>
                              <i className="fab fa-youtube" />{' '}
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-57222629"
                data-id="57222629"
                data-element_type="column"
              >
                <div className="elementor-column-wrap elementor-element-populated">
                  <div className="elementor-widget-wrap">
                    <div
                      className="elementor-element elementor-element-c12f30d elementor-widget elementor-widget-wp-widget-nav_menu"
                      data-id="c12f30d"
                      data-element_type="widget"
                      data-widget_type="wp-widget-nav_menu.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="menu-footer-menu-container">
                          <ul id="menu-footer-menu" className="menu">
                            <li
                              id="menu-item-1176"
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1176"
                            >
                              <a href="#features">Features</a>
                            </li>
                            <li
                              id="menu-item-1177"
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1177"
                            >
                              <a href="#overview">Overview</a>
                            </li>
                            <li
                              id="menu-item-1178"
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1178"
                            >
                              <a href="#coaching">Coaching</a>
                            </li>
                            <li
                              id="menu-item-1179"
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1179"
                            >
                              <a href="#pricing">Pricing</a>
                            </li>
                            <li
                              id="menu-item-1180"
                              className="contact menu-item menu-item-type-custom menu-item-object-custom menu-item-1180"
                            >
                              <a onClick={GoToCalendly} style={{ cursor: 'pointer' }}>Contact Sales</a>
                            </li>
                            <li
                              id="menu-item-1185"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1185"
                            >
                              <a href="/privacy_policy">Privacy Policy</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-6bbf24fc elementor-widget elementor-widget-text-editor"
                      data-id="6bbf24fc"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-text-editor elementor-clearfix">
                          <p>Copyright ©{year} • SOAR</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
