import styled from 'styled-components'

import { Link } from '@/components/Link'

export const SignInField = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
  gap: ${({ theme }) => theme.spaces[4]}px;
`

export const SignInLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
`

export const PasswordPrompt = styled.span`
  color: ${({ theme }) => theme.colors.promptText};
  font-size: 13px;
`
