import React from 'react'

import { Wrapper, Image } from './styles'

export function Icon({ src, size, ...props }) {
  return (
    <Wrapper {...props}>
      <Image src={src} size={size} />
    </Wrapper>
  )
}
