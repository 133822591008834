import { createMachine, assign } from 'xstate'

export const coachingMachine = createMachine({
  id: 'coaching',
  initial: 'initCoaching',
  context: {
    dateRange: {
      startDate: '',
      endDate: '',
    },
  },
  states: {
    initCoaching: {
      on: {
        SELECTED_DATE_RANGE: {
          actions: assign({
            dateRange: (context, event) => {
              return {
                ...event.dateRange,
              }
            },
          }),
        },
        CLEAN_DATE_RANGE: {
          actions: assign({
            dateRange: {
              startDate: '',
              endDate: '',
            },
          }),
        },
      },
    },
    end: {},
  },
})
