/* eslint-disable no-unused-vars */

import React, {
  useState,
  useCallback,
  useMemo,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react'
import { format, subMonths } from 'date-fns'

import { Icon } from '@/components/Icon'
import { Button } from '@/components/Button'
import { SelectSavedView } from '@/components/SelectSavedView'
import { ViewAddModalForm } from '@/forms/ViewAddModal'
import { useAddViewMutation } from '@/queries/add-view'
import { deleteViewService } from '@/queries/delete-view'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import newIcon from '@/assets/icons/new.svg'
import { Title } from '@/components/Title'

import { Wrapper, Grid } from './styles'

export const CustomReport = forwardRef(
  (
    {
      type,
      range,
      endDate,
      location,
      startDate,
      manager,
      employee,
      search,
      userList,
      savedViewData,
      selectedView,
      setFiltersArray,
      resetMainData,
      fetchCoacheeData,
      title,
    },
    ref
  ) => {
    const [error, setError] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const isDesktop = useMediaQuery('(min-width: 1023px)')
    const [currentView, setCurrentView] = useState(null)
    const openModal = useCallback(() => setModalIsOpen(true), [])
    const closeModal = useCallback(() => setModalIsOpen(false), [])

    const viewRef = useRef()

    useImperativeHandle(ref, () => ({
      clear: () => {
        viewRef.current.clear()
      },
    }))

    // replace with state management
    const { mutate: addView, isLoading: addViewLoad } = useAddViewMutation()

    const deleteSubmit = async () => {
      if (currentView && currentView.value) {
        // eslint-disable-next-line
        const confirmed = window.confirm(
          `Are you sure you want to delete the view ${currentView.label}`
        )

        if (confirmed) {
          const result = await deleteViewService(currentView.value)
          if (result?.status === 200) {
            window.location.reload()
          } else {
            setError(result.data.message)
          }
        }
      }
    }

    const handleChanged = useCallback((data) => {
      if (data != null) {
        const filteredData = savedViewData.filter(
          (item) => item.name === data.label
        )[0]
        setCurrentView(data)
        if (type === 'DASHBOARD') {
          const position = {
            key: filteredData.location,
            label: filteredData.location,
            value: filteredData.location,
          }
          const dateRange = filteredData?.startDate
            ? [
                {
                  startDate: filteredData.startDate,
                  endDate: filteredData.endDate,
                },
              ]
            : []
          const savedRange = filteredData?.date
          const selectedUser = userList?.filter(
            (item) => item.name === filteredData.employee
          )[0]
          selectedView(position, dateRange, selectedUser, savedRange)
        } else if (type === 'COACH') {
          const savedRange = filteredData?.date
          if (savedRange) {
            resetMainData()
            switch (savedRange) {
              case 'range':
                fetchCoacheeData({
                  startDate: filteredData.startDate,
                  endDate: filteredData.endDate,
                })
                break
              case 'thisMonth':
                fetchCoacheeData({ date: format(new Date(), 'yyyy-MM-dd') })
                break
              default: {
                const newDate = subMonths(new Date(), 1)
                fetchCoacheeData({ date: format(newDate, 'yyyy-MM-dd') })
              }
            }
          }
          setFiltersArray({
            searchValue: filteredData?.search,
            selectedLocation: filteredData?.location,
            selectedManagers: filteredData?.manager,
            selectedEmployees: filteredData?.employee,
            startDate: filteredData?.startDate,
            endDate: filteredData?.endDate,
          })
        }
      }
    })

    const savedViewList = useMemo(() => {
      return savedViewData
        ?.filter((item) => item.type === type)
        ?.map((item) => {
          return {
            // eslint-disable-next-line
            value: item._id,
            label: item.name,
          }
        })
    }, [savedViewData])

    return (
      <>
        <ViewAddModalForm
          isOpen={modalIsOpen}
          onSubmit={addView}
          closeModal={closeModal}
          isLoading={addViewLoad}
          error={error}
          setError={setError}
          type={type}
          range={range}
          endDate={endDate}
          location={location}
          startDate={startDate}
          manager={manager}
          employee={employee}
          search={search}
        />
        <Wrapper margin={10} isDesktop={isDesktop} type={type}>
          <Title position={'absolute'} left={0} bold fontSize={9}>{title}</Title>
          <Grid isDesktop={isDesktop} />

          <SelectSavedView
            ref={viewRef}
            width={type === 'DASHBOARD' ? '181px' : ''}
            isSearchable={1}
            placeholder="Saved Views"
            options={savedViewList}
            onChange={handleChanged}
          />
          <Button
            padding={4}
            width={type === 'DASHBOARD' ? '181px' : ''}
            onClick={openModal}
          >
            <Icon src={newIcon} size={24} /> Save This View
          </Button>
          <Button
            padding={4}
            width={type === 'DASHBOARD' ? '181px' : ''}
            onClick={deleteSubmit}
            style={{ backgroundColor: 'orange', borderRadius: '4px' }}
          >
            <Icon src={newIcon} size={24} /> Delete View
          </Button>
        </Wrapper>
      </>
    )
  }
)
