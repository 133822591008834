import React, { useCallback, useEffect, useRef, useState } from 'react'

import { CheckboxItemField } from '@/fields/CheckboxItem'
import { salesFunnelArray } from '@/constants/sales-funnel'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import selectArrow from '@/assets/icons/selectArrow.svg'

import { Wrapper, Field, Icon, Modal, CheckboxWrapper, Label } from './styles'

export function ExpertiseField({ values, isModal }) {
  const [modal, setModal] = useState(false)
  const modalRef = useRef()
  const isDesktop = useMediaQuery('(min-width: 1201px)')

  const handleModal = useCallback(() => setModal((prev) => !prev), [])

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleModal()
      }
    }
    if (modal) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [modal, modalRef])

  return (
    <Wrapper ref={modalRef}>
      <Label>EXPERTISE</Label>
      <Field value={values.expertise?.length} onClick={handleModal}>
        {values.expertise?.length
          ? `${values.expertise?.length > 1 ? 'Multi' : values.expertise[0]}`
          : 'Expertise'}
        <Icon src={selectArrow} margin={0} size={20} />
      </Field>
      <Modal isOpen={modal} isDesktop={isDesktop} modal={isModal}>
        <CheckboxWrapper key="Heading">Select one or more:</CheckboxWrapper>
        {salesFunnelArray.map((item) => (
          <CheckboxWrapper key={item.name}>
            <CheckboxItemField
              name="expertise"
              label={item.name}
              value={item.name}
            />
          </CheckboxWrapper>
        ))}
      </Modal>
    </Wrapper>
  )
}
