/* eslint-disable no-console */
import axios from 'axios'
import { useMutation } from 'react-query'

import { SIGN_UP_ENDPOINT } from '@/constants/endpoints'

export const useSignUpMutation = (options) => {
  const signUp = useMutation(async (payload) => {
    try {
      await axios
        .post(SIGN_UP_ENDPOINT, payload)
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          throw error
        })
    } catch {
      throw new Error('Something wrong')
    }
  }, options)
  return signUp
}
