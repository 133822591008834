import axios from 'axios'
import { useMutation } from 'react-query'

import {
  SET_USER_COACH_ENDPOINT,
  SET_USER_MULTIPLE_COACH_ENDPOINT,
} from '@/constants/endpoints'

export const useChangeCoachMutation = () =>
  useMutation(async ({ user, selectedCoach }) => {
    if (selectedCoach.value)
      await axios.post(
        `${SET_USER_COACH_ENDPOINT}/${selectedCoach.fbUid}/${user.fbUid}`
      )
  })

export const useChangeMultipleCoachMutation = () =>
  useMutation(async ({ user, fbUids }) => {
    if (Array.isArray(fbUids)) {
      await axios.post(`${SET_USER_MULTIPLE_COACH_ENDPOINT}/${user.fbUid}`, {
        coacherUids: fbUids,
      })
    }
  })
