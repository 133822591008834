import React, { useState } from 'react'
import { GoToCalendly } from 'utils/utils'

export function HeaderSection() {
  const [toggleMobileMenu, setToggleMobileMenu] = useState('false')
  const showHideMobileMenu = () => {
    setToggleMobileMenu(!toggleMobileMenu)
  }

  return (
    <div
      data-elementor-type="header"
      data-elementor-id="394"
      className="elementor elementor-394 elementor-location-header"
    >
      <div
        id="stickyHeader"
        className="elementor-section-wrap ee-sticky-parent"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-f4e9860 elementor-section-full_width elementor-hidden-desktop elementor-hidden-tablet elementor-section-height-default elementor-section-height-default"
          data-id="f4e9860"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div className="elementor-row">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fd9ff2c"
                data-id="fd9ff2c"
                data-element_type="column"
              >
                <div className="elementor-column-wrap elementor-element-populated">
                  <div className="elementor-widget-wrap">
                    <div
                      className="elementor-element elementor-element-7533741 elementor-mobile-align-justify elementor-align-justify elementor-widget elementor-widget-button"
                      data-id="7533741"
                      data-element_type="widget"
                      data-widget_type="button.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-button-wrapper">
                          <a
                            href="#pricing"
                            className="elementor-button-link elementor-button elementor-size-sm"
                          >
                            <span className="elementor-button-content-wrapper">
                              <span className="elementor-button-text">
                                Get Started
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-6b2700c2 elementor-section-content-middle elementor-section-full_width elementor-section-height-default ee-sticky"
          data-id="6b2700c2"
          data-element_type="section"
          id="header"
          style={{ position: 'relative', left: 'auto' }}
        >
          <div className="elementor-container elementor-column-gap-default">
            <div className="elementor-row">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1ec2c164"
                data-id="1ec2c164"
                data-element_type="column"
              >
                <div className="elementor-column-wrap elementor-element-populated">
                  <div className="elementor-widget-wrap">
                    <div
                      className="elementor-element elementor-element-445bc926 logo elementor-widget__width-auto elementor-widget elementor-widget-theme-site-logo elementor-widget-image"
                      data-id="445bc926"
                      data-element_type="widget"
                      data-widget_type="theme-site-logo.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-image">
                          <a href="/home">
                            <img
                              width="290"
                              height="144"
                              src={`${process.env.REACT_APP_DOMAIN}/landing/logo.png`}
                              className="attachment-full size-full"
                              alt=""
                              loading="lazy"
                            />{' '}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-6492a2d4 elementor-nav-menu__align-left elementor-nav-menu--stretch elementor-nav-menu__text-align-center elementor-widget__width-auto elementor-nav-menu--dropdown-mobile elementor-hidden-tablet elementor-hidden-mobile elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
                      data-id="6492a2d4"
                      data-element_type="widget"
                      data-widget_type="nav-menu.default"
                    >
                      <div className="elementor-widget-container">
                        <nav
                          migration_allowed="1"
                          migrated="0"
                          role="navigation"
                          className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-text e--animation-grow"
                        >
                          <ul
                            id="menu-1-6492a2d4"
                            className="elementor-nav-menu"
                            data-smartmenus-id="16627559453528892"
                          >
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1167">
                              <a
                                href="#overview"
                                className="elementor-item elementor-item-anchor"
                              >
                                Overview
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1168">
                              <a
                                href="#features"
                                className="elementor-item elementor-item-anchor"
                              >
                                Features
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1169">
                              <a
                                href="#coaching"
                                className="elementor-item elementor-item-anchor"
                              >
                                Coaching
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1170">
                              <a
                                href="#pricing"
                                className="elementor-item elementor-item-anchor"
                              >
                                Pricing
                              </a>
                            </li>
                          </ul>
                        </nav>
                        <div
                          className="elementor-menu-toggle"
                          role="button"
                          tabIndex="0"
                          aria-label="Menu Toggle"
                          aria-expanded="false"
                        >
                          <i
                            aria-hidden="true"
                            role="presentation"
                            className="elementor-menu-toggle__icon--open eicon-menu-bar"
                          />
                          <i
                            aria-hidden="true"
                            role="presentation"
                            className="elementor-menu-toggle__icon--close eicon-close"
                          />{' '}
                          <span className="elementor-screen-only">Menu</span>
                        </div>
                        <nav
                          className="elementor-nav-menu--dropdown elementor-nav-menu__container"
                          role="navigation"
                          aria-hidden="true"
                          style={{ top: '80px', width: '1440px', left: '0px' }}
                        >
                          <ul
                            id="menu-2-6492a2d4"
                            className="elementor-nav-menu"
                            data-smartmenus-id="16627559453531501"
                          >
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1167">
                              <a
                                href="/#overview"
                                className="elementor-item elementor-item-anchor"
                                tabIndex="-1"
                              >
                                Overview
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1168">
                              <a
                                href="#features"
                                className="elementor-item elementor-item-anchor"
                                tabIndex="-1"
                              >
                                Features
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1169">
                              <a
                                href="#coaching"
                                className="elementor-item elementor-item-anchor"
                                tabIndex="-1"
                              >
                                Coaching
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1170">
                              <a
                                href="#pricing"
                                className="elementor-item elementor-item-anchor"
                                tabIndex="-1"
                              >
                                Pricing
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-0d07c07 elementor-icon-list--layout-inline elementor-widget__width-auto action-nav elementor-hidden-tablet elementor-hidden-mobile elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                      data-id="0d07c07"
                      data-element_type="widget"
                      data-widget_type="icon-list.default"
                    >
                      <div className="elementor-widget-container">
                        <ul className="elementor-icon-list-items elementor-inline-items">
                          <li className="elementor-icon-list-item elementor-inline-item">
                            <a
                              id="contactSales"
                              style={{ cursor: 'pointer' }}
                              onClick={GoToCalendly}
                            >
                              <span className="elementor-icon-list-text">
                                Contact Sales
                              </span>
                            </a>
                          </li>
                          <li className="elementor-icon-list-item elementor-inline-item">
                            <a href="/sign-in">
                              <span className="elementor-icon-list-text">
                                Login
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-31b8fc9 elementor-nav-menu__align-left elementor-nav-menu--stretch elementor-nav-menu__text-align-center elementor-widget__width-auto elementor-widget-tablet__width-auto elementor-hidden-desktop elementor-nav-menu--dropdown-tablet elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
                      data-id="31b8fc9"
                      data-element_type="widget"
                      data-widget_type="nav-menu.default"
                    >
                      <div className="elementor-widget-container">
                        <nav
                          migration_allowed="1"
                          migrated="0"
                          role="navigation"
                          className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-text e--animation-grow"
                        >
                          <ul
                            id="menu-1-31b8fc9"
                            className="elementor-nav-menu"
                            data-smartmenus-id="16627559453608533"
                          >
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1167">
                              <a
                                href="/#overview"
                                className="elementor-item elementor-item-anchor"
                              >
                                Overview
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1168">
                              <a
                                href="#features"
                                className="elementor-item elementor-item-anchor"
                              >
                                Features
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1169">
                              <a
                                href="#coaching"
                                className="elementor-item elementor-item-anchor"
                              >
                                Coaching
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1170">
                              <a
                                href="#pricing"
                                className="elementor-item elementor-item-anchor"
                              >
                                Pricing
                              </a>
                            </li>
                          </ul>
                        </nav>
                        <div
                          className={
                            toggleMobileMenu
                              ? 'elementor-menu-toggle'
                              : 'elementor-menu-toggle elementor-active'
                          }
                          role="button"
                          tabIndex="0"
                          aria-label="Menu Toggle"
                          aria-expanded="false"
                          onClick={showHideMobileMenu}
                          id="mobileToggleMenu"
                        >
                          <i
                            aria-hidden="true"
                            role="presentation"
                            className="elementor-menu-toggle__icon--open eicon-menu-bar"
                          />
                          <i
                            aria-hidden="true"
                            role="presentation"
                            className="elementor-menu-toggle__icon--close eicon-close"
                          />{' '}
                          <span className="elementor-screen-only">Menu</span>
                        </div>
                        <nav
                          className="w-full elementor-nav-menu--dropdown elementor-nav-menu__container"
                          role="navigation"
                          aria-hidden="true"
                          style={{ top: '55px', width: '400px', left: '0px' }}
                        >
                          <ul
                            id="menu-2-31b8fc9"
                            className="elementor-nav-menu"
                            data-smartmenus-id="16627559453609795"
                          >
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1167">
                              <a
                                href="#overview"
                                className="elementor-item elementor-item-anchor"
                                tabIndex="-1"
                              >
                                Overview
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1168">
                              <a
                                href="#features"
                                className="elementor-item elementor-item-anchor"
                                tabIndex="-1"
                              >
                                Features
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1169">
                              <a
                                href="#coaching"
                                className="elementor-item elementor-item-anchor"
                                tabIndex="-1"
                              >
                                Coaching
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1170">
                              <a
                                href="#pricing"
                                className="elementor-item elementor-item-anchor"
                                tabIndex="-1"
                              >
                                Pricing
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-85ba51a elementor-widget__width-auto elementor-hidden-mobile elementor-widget elementor-widget-button"
                      data-id="85ba51a"
                      data-element_type="widget"
                      data-widget_type="button.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-button-wrapper">
                          <a
                            href="#pricing"
                            className="elementor-button-link elementor-button elementor-size-sm external"
                          >
                            <span className="elementor-button-content-wrapper">
                              <span className="elementor-button-text">
                                Get Started
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="ee-conditions--hidden elementor-section elementor-top-section elementor-element elementor-element-9857178 elementor-section-full_width hero-wrapper elementor-section-height-default elementor-section-height-default"
          data-id="9857178"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div className="elementor-row">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-cfa0c7e"
                data-id="cfa0c7e"
                data-element_type="column"
              >
                <div className="elementor-column-wrap elementor-element-populated">
                  <div className="elementor-widget-wrap">
                    <div
                      className="elementor-element elementor-element-9bf463d elementor-widget elementor-widget-template"
                      data-id="9bf463d"
                      data-element_type="widget"
                      data-widget_type="template.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-template">
                          <div
                            data-elementor-type="section"
                            data-elementor-id="773"
                            className="elementor elementor-773 elementor-location-header"
                          >
                            <div className="elementor-section-wrap">
                              <section
                                className="elementor-section elementor-top-section elementor-element elementor-element-4fbe7c4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="4fbe7c4"
                                data-element_type="section"
                              >
                                <div className="elementor-container elementor-column-gap-wide">
                                  <div className="elementor-row">
                                    <div
                                      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d381f09"
                                      data-id="d381f09"
                                      data-element_type="column"
                                    >
                                      <div className="elementor-column-wrap elementor-element-populated">
                                        <div className="elementor-widget-wrap">
                                          <div
                                            className="elementor-element elementor-element-32b1595 elementor-widget elementor-widget-heading"
                                            data-id="32b1595"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <h1 className="elementor-heading-title elementor-size-default">
                                                Home
                                              </h1>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-09eacfd elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                            data-id="09eacfd"
                                            data-element_type="widget"
                                            data-widget_type="divider.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="elementor-divider">
                                                <span className="elementor-divider-separator" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
