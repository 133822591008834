import {
  ADD_MANAGERS_ROUTE,
  CREATE_ORGANIZATION_ROUTE,
  DASHBOARD_ROUTE,
  LANDING_ROUTE,
} from '@/constants/routes'
import React, { useContext, useState } from 'react'
import { elitePlanData, proPlanData } from '../../constants/lang/plans'

import { calculatePriceBand } from '@/utils/paymentPlanUtils'
import Layout from '@/components/Layout'
import PlanBox from '@/components/PlanBox'
import PriceConfirmation from '@/components/PriceConfirmation/PriceConfirmation'
import { SoarStateContext } from '../../global/soar.context'
import { redirectToStripeCheckout } from '@/utils/stripeUtils'
import { useNavigate } from 'react-router-dom'
import { useMeQuery } from '@/queries/get-me'
import { useSelector } from '@xstate/react'

export function ConfirmYourPlan() {
  const soarContext = useContext(SoarStateContext)
  const navigate = useNavigate()
  const { name, users, price, frequency, band } = useSelector(
    soarContext.subscriptionService,
    (state) => state.context.planDetails
  )

  const selectedPlanFromStorage =
    localStorage.getItem('SELECTED_PLAN_DETAILS') || ''

  const selectedPlanObject =
    selectedPlanFromStorage.length > 0
      ? JSON.parse(selectedPlanFromStorage)
      : {}

  const [paymentFrequency, setPaymentFrequency] = useState(
    frequency || selectedPlanObject.frequency || 'yearly'
  )
  const [totalUsers, setTotalUsers] = useState(
    users || selectedPlanObject.users || 0
  )
  const [planPrice, setPlanPrice] = useState(price || selectedPlanObject.price || 0)
  const [planPriceElite, setPlanPriceElite] = useState(
    price || selectedPlanObject.price || 0
  )
  const [totalPriceMonthly, setTotalPriceMonthly] = useState(
    totalUsers *
      calculatePriceBand(
        name || selectedPlanObject.name,
        users || selectedPlanObject.users,
        'monthly'
      )['paymentPrice'] || 0
  )
  const [totalPriceYearly, setTotalPriceYearly] = useState(
    12 *
      totalUsers *
      calculatePriceBand(
        name || selectedPlanObject.name,
        users || selectedPlanObject.users,
        'yearly'
      )['paymentPrice'] || 0
  )
  const [paymentBand, setPaymentBand] = useState(
    band || selectedPlanObject.band || '3-15'
  )
  const [highlightedPlan, setHighlightedPlan] = useState(
    name || selectedPlanObject.name || 'Pro'
  )

  const { data, isLoading, refetch } = useMeQuery({
    onSuccess: (data) => {
      if (!data.isReg && data.isPaid && data.organization) {
        return navigate(ADD_MANAGERS_ROUTE)
      }
      if (!data.isReg && data.isPaid) {
        return navigate(CREATE_ORGANIZATION_ROUTE)
      }
      if (data.isReg && data.isPaid) {
        return navigate(DASHBOARD_ROUTE)
      }
    },
    onError: (err) => {
      console.log('error while fething admin info', err)
    },
  })

  const handleBillingPeriodOnChange = (value) => {
    setPaymentFrequency(value)

    let { paymentPrice } = calculatePriceBand(
      highlightedPlan,
      totalUsers,
      value
    )

    if (highlightedPlan === 'Elite') {
      setPlanPriceElite(paymentPrice)
    } else {
      setPlanPrice(paymentPrice)
    }
  }

  const handleIncreaseQuantity = (e) => {
    const totalUsers = parseInt(e.target.value) || 0

    setTotalUsers(totalUsers)
    if (totalUsers <= 50) {
      setHighlightedPlan('Pro')
      let { paymentPrice, paymentBand } = calculatePriceBand(
        'Pro',
        totalUsers,
        paymentFrequency
      )

      setPaymentBand(paymentBand)
      setPlanPrice(paymentPrice)

      setTotalPriceMonthly(
        totalUsers *
          calculatePriceBand('Pro', totalUsers, 'monthly')['paymentPrice']
      )

      setTotalPriceYearly(
        12 *
          totalUsers *
          calculatePriceBand('Pro', totalUsers, 'yearly')['paymentPrice']
      )
    } else {
      setHighlightedPlan('Elite')
      let { paymentPrice, paymentBand } = calculatePriceBand(
        'Elite',
        totalUsers,
        paymentFrequency
      )

      setPaymentBand(paymentBand)

      setTotalPriceMonthly(
        totalUsers *
          calculatePriceBand('Elite', totalUsers, 'monthly')['paymentPrice']
      )

      setTotalPriceYearly(
        12 *
          totalUsers *
          calculatePriceBand('Elite', totalUsers, 'yearly')['paymentPrice']
      )

      setPlanPriceElite(paymentPrice)
    }
  }

  const handleGoToCheckout = async () => {
    await soarContext.subscriptionService.send({
      type: 'SELECTED_PLAN_DETAILS',
      planDetails: {
        name: highlightedPlan,
        users: totalUsers,
        price: planPrice,
        frequency: paymentFrequency,
        band: paymentBand,
      },
      paymentConfirmationRedirect: true,
    })

    try {
      totalUsers &&
        (await redirectToStripeCheckout(
          paymentFrequency,
          highlightedPlan,
          totalUsers,
          paymentBand
        ))
    } catch (err) {
      console.log(err)
    }
  }

  const handleGotoLanding = () => navigate(LANDING_ROUTE)

  return (
    <Layout hideLogo>
      <div className="max-w-5xl mx-auto">
        <div className="text-gray-500 text-base mx-auto">Step 2 of 2</div>
        <div className="text-white text-4xl  mx-auto font-bold my-4">
          Confirm your plan
        </div>
        <div className="grid lg:grid-cols-2 sm:grid-cols-1 lg:gap-2 mx-auto w-full">
          <div className="lg:col-span-1 sm:col-span-2">
            <PlanBox
              planPrice={planPrice}
              planPriceElite={planPriceElite}
              data={highlightedPlan === 'Pro' ? proPlanData : elitePlanData}
              background="#1F2937"
              noButtonTheme
              onClick={handleGoToCheckout}
              paymentFrequencyFlag={paymentFrequency}
              highlightedPlan={highlightedPlan}
            />
          </div>
          <div className="lg:col-span-1 sm:col-span-2">
            <PriceConfirmation
              handlePaymentFrequency={handleBillingPeriodOnChange}
              handleTotalUsers={handleIncreaseQuantity}
              usersValue={totalUsers}
              planRate={planPrice}
              planPriceElite={planPriceElite}
              highlightedPlan={highlightedPlan}
              planFrequency={paymentFrequency}
              totalPriceMonthly={totalPriceMonthly}
              totalPriceYearly={totalPriceYearly}
              onGoToCheckout={handleGoToCheckout}
              onGoBack={handleGotoLanding}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
