import chevronIcon from '@/assets/icons/chevron.svg'
import { Icon } from '@/components/Icon'
import styled from 'styled-components'

export const formatColumns = (
  footerData,
  funnelData,
  conversionMeasurementOption
) => {
  const funnels = {}

  const funnelConversionLabel = {
    leads: ' ',
    appointments: '%Leads',
    shows:
      conversionMeasurementOption === 2
        ? '%Leads'
        : conversionMeasurementOption === 1
        ? '%Leads'
        : '%Appts.',
    instore:
      conversionMeasurementOption === 2
        ? '%Leads'
        : conversionMeasurementOption === 1
        ? '%Leads'
        : '%Appts.',
    presentations:
      conversionMeasurementOption === 2
        ? '%Tot. Visits'
        : conversionMeasurementOption === 1
        ? '%Leads'
        : '%Tot. Visits',
    demos:
      conversionMeasurementOption === 2
        ? '%Tot. Visits'
        : conversionMeasurementOption === 1
        ? '%Leads'
        : '%Presentations',
    writeUps:
      conversionMeasurementOption === 2
        ? '%Tot. Visits'
        : conversionMeasurementOption === 1
        ? '%Leads'
        : '%Demos',
    sales:
      conversionMeasurementOption === 2
        ? '%Tot. Visits'
        : conversionMeasurementOption === 1
        ? '%Leads'
        : '%Write-Ups',
  }

  funnelData.forEach((item) => {
    funnels[item.name] = true
  })

  return [
    {
      Header: 'average',
      Footer: 'Average',
      columns: [
        {
          Header: 'Salesperson',
          accessor: 'salesperson',
          Footer: 'Total',
        },
      ],
    },
    funnels['Leads & Opportunities'] && {
      Header: 'leads',
      Footer: footerData?.leads?.avg || '',
      columns: [
        {
          Header: `Leads\n${funnelConversionLabel.leads}`,
          accessor: 'Leads & Opportunities',
          Footer: footerData?.leads?.total || '',
        },
      ],
    },
    funnels.Appointments && {
      Header: 'appointments',
      Footer: footerData?.appointments?.avg || '',
      columns: [
        {
          Header: `Appts.\n${funnelConversionLabel.appointments}`,
          accessor: 'Appointments',
          Footer: footerData?.appointments?.total || '',
        },
      ],
    },
    {
      Header: 'Total Visits',
      Footer: footerData?.totVisits?.avg || '',
      columns: [
        {
          Header: `Appt. Shows\n${funnelConversionLabel.shows}`,
          accessor: 'Appointment Shows',
          Footer: footerData?.shows?.total || '',
        },
        {
          Header: `Other Visits\n${funnelConversionLabel.instore}`,
          accessor: 'In-Store Visits',
          Footer: footerData?.instore?.total || '',
        },
      ],
    },
    funnels.Presentations && {
      Header: 'presentations',
      Footer: footerData?.presentations?.avg || '',
      columns: [
        {
          Header: `Presentations\n${funnelConversionLabel.presentations}`,
          accessor: 'Presentations',
          Footer: footerData?.presentations?.total || '',
        },
      ],
    },
    funnels['Demo Drives'] && {
      Header: 'demos',
      Footer: footerData?.demos?.avg || '',
      columns: [
        {
          Header: `Demos\n${funnelConversionLabel.demos}`,
          accessor: 'Demo Drives',
          Footer: footerData?.demos?.total || '',
        },
      ],
    },
    funnels['Write-Ups'] && {
      Header: 'write-ups',
      Footer: footerData?.writeUps?.avg || '',
      columns: [
        {
          Header: `Write-ups\n${funnelConversionLabel.writeUps}`,
          accessor: 'Write-Ups',
          Footer: footerData?.writeUps?.total || '',
        },
      ],
    },
    funnels.Sales && {
      Header: 'sales',
      Footer: footerData?.sales?.avg || '',
      columns: [
        {
          Header: `Sales\n${funnelConversionLabel.sales}`,
          accessor: 'Sales',
          Footer: footerData?.sales?.total || '',
        },
      ],
    },
    {
      Header: 'usage',
      Footer: footerData?.usage?.avg ? `${footerData?.usage?.avg}%` : '',
      columns: [
        {
      Header: 'Usage',
      accessor: 'Usage',
          Footer: '',
        },
      ],
    },
    {
      Header: 'Coach',
      accessor: 'coach',
    },
    {
      Header: 'expertise',
      Footer: '',
      columns: [
        {
      Header: 'Expertise',
      accessor: 'expertise',
      Footer: '',
    },
      ],
    },
  ].filter((item) => !!item)
}

export const OpenIconWrapperOpen = styled.div`
  left: 25px;
  position: absolute;
  top: 25px;
  transition: 0.3s;
  transform: rotate(90deg);
`

export const OpenIconWrapperClosed = styled.div`
  left: 25px;
  position: absolute;
  top: 25px;
  transition: 0.3s;
`

export const formatManagerColumns = (
  footerData,
  funnelData,
  conversionMeasurementOption
) => {
  const funnels = {}

  const funnelConversionLabel = {
    leads: ' ',
    appointments: '%Leads',
    shows:
      conversionMeasurementOption === 2
        ? '%Leads'
        : conversionMeasurementOption === 1
        ? '%Leads'
        : '%Appts.',
    instore:
      conversionMeasurementOption === 2
        ? '%Leads'
        : conversionMeasurementOption === 1
        ? '%Leads'
        : '%Appts.',
    presentations:
      conversionMeasurementOption === 2
        ? '%Tot. Visits'
        : conversionMeasurementOption === 1
        ? '%Leads'
        : '%Tot. Visits',
    demos:
      conversionMeasurementOption === 2
        ? '%Tot. Visits'
        : conversionMeasurementOption === 1
        ? '%Leads'
        : '%Presentations',
    writeUps:
      conversionMeasurementOption === 2
        ? '%Tot. Visits'
        : conversionMeasurementOption === 1
        ? '%Leads'
        : '%Demos',
    sales:
      conversionMeasurementOption === 2
        ? '%Tot. Visits'
        : conversionMeasurementOption === 1
        ? '%Leads'
        : '%Write-Ups',
  }

  funnelData.forEach((item) => {
    funnels[item.name] = true
  })

  return [
    {
      Header: '',
      id: 'expander',
      width: 10,
      Cell: ({ row }) =>
        row.original.subordinates.length > 0 && (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <OpenIconWrapperOpen>
                <Icon src={chevronIcon} size={20} />
              </OpenIconWrapperOpen>
            ) : (
              <OpenIconWrapperClosed>
                <Icon src={chevronIcon} size={20} />
              </OpenIconWrapperClosed>
            )}
          </span>
        ),
    },
    {
      Header: 'average',
      Footer: 'Average',
      columns: [
        {
          Header: 'Managers',
          accessor: 'managerName',
          Footer: 'Total',
        },
      ],
    },
    funnels['Leads & Opportunities'] && {
      Header: 'leads',
      Footer: footerData?.leads?.avg || '',
      columns: [
        {
          Header: `Leads\n${funnelConversionLabel.leads}`,
          accessor: 'Leads & Opportunities',
          Footer: footerData?.leads?.total || '',
        },
      ],
    },
    funnels.Appointments && {
      Header: 'appointments',
      Footer: footerData?.appointments?.avg || '',
      columns: [
        {
          Header: `Appts.\n${funnelConversionLabel.appointments}`,
          accessor: 'Appointments',
          Footer: footerData?.appointments?.total || '',
        },
      ],
    },
    {
      Header: 'Total Visits',
      Footer: footerData?.totVisits?.avg || '',
      columns: [
        {
          Header: `Appt. Shows\n${funnelConversionLabel.shows}`,
          accessor: 'Appointment Shows',
          Footer: footerData?.shows?.total || '',
        },
        {
          Header: `Other Visits\n${funnelConversionLabel.instore}`,
          accessor: 'In-Store Visits',
          Footer: footerData?.instore?.total || '',
        },
      ],
    },
    funnels.Presentations && {
      Header: 'presentations',
      Footer: footerData?.presentations?.avg || '',
      columns: [
        {
          Header: `Presentations\n${funnelConversionLabel.presentations}`,
          accessor: 'Presentations',
          Footer: footerData?.presentations?.total || '',
        },
      ],
    },
    funnels['Demo Drives'] && {
      Header: 'demos',
      Footer: footerData?.demos?.avg || '',
      columns: [
        {
          Header: `Demos\n${funnelConversionLabel.demos}`,
          accessor: 'Demo Drives',
          Footer: footerData?.demos?.total || '',
        },
      ],
    },
    funnels['Write-Ups'] && {
      Header: 'write-ups',
      Footer: footerData?.writeUps?.avg || '',
      columns: [
        {
          Header: `Write-ups\n${funnelConversionLabel.writeUps}`,
          accessor: 'Write-Ups',
          Footer: footerData?.writeUps?.total || '',
        },
      ],
    },
    funnels.Sales && {
      Header: 'sales',
      Footer: footerData?.sales?.avg || '',
      columns: [
        {
          Header: `Sales\n${funnelConversionLabel.sales}`,
          accessor: 'Sales',
          Footer: footerData?.sales?.total || '',
        },
      ],
    },
    {
      Header: 'usage',
      Footer: footerData?.usage?.avg ? `${footerData?.usage?.avg}%` : '',
      columns: [
        {
      Header: 'Usage',
      accessor: 'Usage',
          Footer: '',
        },
      ],
    },
    {
      Header: 'Coach',
      accessor: 'coach',
    },
    {
      Header: 'expertise',
      Footer: '',
      columns: [
        {
      Header: 'Expertise',
      accessor: 'expertise',
      Footer: '',
        },
      ],
    },
  ].filter((item) => !!item)
}

export const formatUserColumns = (funnelData) => {
  const funnels = {}

  funnelData.forEach((item) => {
    funnels[item.name] = true
  })

  return [{
    Header: 'manager',
    columns: [
      {
        Header: 'Managers',
        accessor: 'managerName',
      },
    ],
  },
  {
    Header: 'average',
    columns: [
      {
        Header: 'Salesperson',
        accessor: 'salesperson',
      },
    ],
  },
  funnels['Leads & Opportunities'] && {
    Header: 'leads',
    columns: [
      {
        Header: `Leads`,
        accessor: 'Leads & Opportunities',
      },
    ],
  },
  funnels.Appointments && {
    Header: 'appointments',
    columns: [
      {
        Header: `Appts.`,
        accessor: 'Appointments',
      },
    ],
  },
  {
    Header: 'Total Visits',
    columns: [
      {
        Header: `Appt. Shows`,
        accessor: 'Appointment Shows',
      },
      {
        Header: `Other Visits`,
        accessor: 'In-Store Visits',
      },
    ],
  },
  funnels.Presentations && {
    Header: 'presentations',
    columns: [
      {
        Header: `Presentations`,
        accessor: 'Presentations',
      },
    ],
  },
  funnels['Demo Drives'] && {
    Header: 'demos',
    columns: [
      {
        Header: `Demos`,
        accessor: 'Demo Drives',
      },
    ],
  },
  funnels['Write-Ups'] && {
    Header: 'write-ups',
    columns: [
      {
        Header: `Write-ups`,
        accessor: 'Write-Ups',
      },
    ],
  },
  funnels.Sales && {
    Header: 'sales',
    columns: [
      {
        Header: `Sales`,
        accessor: 'Sales',
      },
    ],
  },
  {
    Header: 'usage',
    columns: [
      {
        Header: 'Usage',
        accessor: 'Usage',
      },
    ],
  },
  {
    Header: 'Coach',
    accessor: 'coach',
  },
  {
    Header: 'expertise',
    columns: [
      {
        Header: 'Expertise',
        accessor: 'expertise',
      },
    ],
  },
  ].filter((item) => !!item)
}

export const formatLocationColumns = (footerData, funnelData) => {
  const funnels = {}

  funnelData.forEach((item) => {
    funnels[item.name] = true
  })

  return [
    {
      Header: 'average',
      Footer: 'Average',
      columns: [
        {
          Header: 'Location',
          accessor: 'name',
          Footer: 'Total',
        },
      ],
    },
    {
      Header: 'employees',
      Footer: footerData?.employees?.avg || '',
      columns: [
        {
          Header: 'Employees',
          accessor: 'Employees',
          Footer: footerData?.employees?.total || '',
        },
      ],
    },
    funnels['Leads & Opportunities'] && {
      Header: 'leads',
      Footer: footerData?.leads?.avg || '',
      columns: [
        {
          Header: 'Leads',
          accessor: 'Leads & Opportunities',
          Footer: footerData?.leads?.total || '',
        },
      ],
    },
    funnels.Appointments && {
      Header: 'appointments',
      Footer: footerData?.appointments?.avg || '',
      columns: [
        {
          Header: 'Appts.',
          accessor: 'Appointments',
          Footer: footerData?.appointments?.total || '',
        },
      ],
    },
    funnels['Appointment Shows'] && {
      Header: 'shows',
      Footer: footerData?.shows?.avg || '',
      columns: [
        {
          Header: 'Appt. Shows',
          accessor: 'Appointment Shows',
          Footer: footerData?.shows?.total || '',
        },
      ],
    },
    funnels.Presentations && {
      Header: 'presentations',
      Footer: footerData?.presentations?.avg || '',
      columns: [
        {
          Header: 'Presentations',
          accessor: 'Presentations',
          Footer: footerData?.presentations?.total || '',
        },
      ],
    },
    funnels['Demo Drives'] && {
      Header: 'demos',
      Footer: footerData?.demos?.avg || '',
      columns: [
        {
          Header: 'Demos',
          accessor: 'Demo Drives',
          Footer: footerData?.demos?.total || '',
        },
      ],
    },
    funnels['Write-Ups'] && {
      Header: 'write-ups',
      Footer: footerData?.writeUps?.avg || '',
      columns: [
        {
          Header: 'Write-ups',
          accessor: 'Write-Ups',
          Footer: footerData?.writeUps?.total || '',
        },
      ],
    },
    funnels.Sales && {
      Header: 'sales',
      Footer: footerData?.sales?.avg || '',
      columns: [
        {
          Header: 'Sales',
          accessor: 'Sales',
          Footer: footerData?.sales?.total || '',
        },
      ],
    },
    {
      Header: 'usage',
      Footer: footerData?.usage?.avg ? `${footerData?.usage?.avg}%` : '',
      columns: [
        {
          Header: 'Usage',
          accessor: 'Usage',
          Footer: '',
        },
      ],
    },
    {
      Header: '',
      accessor: 'edit',
      disableSortBy: true,
    },
  ].filter((item) => !!item)
}
