import React from 'react'

import {
  CoachWrapper,
  CoacheeWrapper,
  ItemWrapper,
  RowWrapper,
} from '../styles'

export default function TotalRows({ mainData }) {
  const index = mainData.length
  let performance = 0
  let contactsFromCoach = 0
  let contactsFromCoachee = 0
  let sales = 0
  let overall = 0
  let coachee = 0

  for (let i = 0; i < mainData.length; i += 1) {
    performance += mainData[i].performance
    contactsFromCoach += mainData[i].contactsFromCoach
    contactsFromCoachee += mainData[i].contactsFromCoachee
    sales += mainData[i].sales
    overall += mainData[i].overall
    coachee += mainData[i].coachees.length
  }

  return (
    <>
      <RowWrapper
        className={`${index % 2 === 0 ? 'even' : 'odd'} bold divider`}
      >
        <CoachWrapper>Totals</CoachWrapper>
        <CoacheeWrapper>
          <ItemWrapper>
            <div>{coachee}</div>
            <div>{performance}</div>
            <div>{contactsFromCoach}</div>
            <div>{contactsFromCoachee}</div>
            <div>{sales}</div>
            <div />
          </ItemWrapper>
        </CoacheeWrapper>
      </RowWrapper>
      <RowWrapper
        className={`${(index + 1) % 2 === 0 ? 'even' : 'odd'} bold border`}
      >
        <CoachWrapper>Average</CoachWrapper>
        <CoacheeWrapper>
          <ItemWrapper>
            <div>{(coachee / mainData.length).toFixed(1)}</div>
            <div>{(performance / mainData.length).toFixed(1)}</div>
            <div>{(contactsFromCoach / mainData.length).toFixed(1)}</div>
            <div>{(contactsFromCoachee / mainData.length).toFixed(1)}</div>
            <div>{(sales / mainData.length).toFixed(1)}</div>
            <div>{(overall / mainData.length).toFixed(1)} %</div>
          </ItemWrapper>
        </CoacheeWrapper>
      </RowWrapper>
    </>
  )
}
