import styled from 'styled-components'

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spaces[4]}px;
`

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[4]}px;
`

export const Item = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.spaces[4]}px;
  display: flex;
  height: 50px;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spaces[5]}px;
  width: 100%;
`

export const ItemValue = styled.div`
  display: flex;
  flex-direction: column;
`

export const ItemButtonsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[5]}px;
`

export const ItemEditButton = styled.button`
  align-items: center;
  background: none;
  border: 1px solid
    ${({ theme, gray }) =>
      gray ? theme.colors.promptText : theme.colors.backgroundActive};
  border-radius: ${({ theme }) => theme.spaces[2]}px;
  color: ${({ theme, gray }) =>
    gray ? theme.colors.promptText : theme.colors.backgroundActive};
  display: flex;
  font-family: ${({ theme }) => theme.fontFamilies.Oswald};
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  gap: 6px;
  height: 24px;
  justify-content: center;
  width: ${({ gray }) => (gray ? 74 : 62)}px;

  &:hover {
    filter: brightness(1.1);
  }
`

export const ItemIcon = styled.img`
  height: 24px;
  width: 36px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 160px;
`
