import styled from 'styled-components'

export const Wrapper = styled.button`
  align-items: center;
  background-color: ${({ theme, outlined, gray }) => {
    if (outlined) return 'transparent'
    if (gray) return theme.colors.backgroundInactive
    return theme.colors.backgroundActive
  }};
  border: ${({ theme, outlined, border }) =>
    outlined
      ? `${border || 2}px solid ${theme.colors.backgroundActive}`
      : 'none'};
  border-radius: ${({ theme }) => theme.spaces[2]}px;
  color: ${({ theme, color, outlined, gray }) => {
    if (outlined) return theme.colors.backgroundActive
    if (gray) return theme.colors.inactiveText
    return theme.colors[color] || theme.colors.black
  }};
  display: flex;
  font-size: ${({ theme, fontSize }) =>
    theme.fontSizes[fontSize] || theme.fontSizes[6]}px;
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight ? theme.fontWeights[fontWeight] : theme.fontWeights.bold};
  height: ${({ height }) => height || '40px'};
  justify-content: center;
  margin: ${({ theme, margin }) => theme.spaces[margin]}px 0;
  padding: 0 ${({ theme, padding }) => theme.spaces[padding]}px;
  transition: all 0.1s;
  width: ${(props) => props.width || '100%'};
  &:hover {
    background-color: ${({ theme, outlined, gray }) => {
      if (!outlined) {
        if (gray) return theme.colors.backgroundInactiveDark
        return theme.colors.backgroundActiveLight
      }
      return null
    }};
    border-color: ${({ theme, outlined }) =>
      outlined && theme.colors.backgroundActiveLight};
    color: ${({ theme, outlined }) =>
      outlined && theme.colors.backgroundActiveLight};

    > img {
      filter: ${({ outlined }) => outlined && 'brightness(1.2)'};
      transition: all 0.1s;
    }
  }
`
