import styled from 'styled-components'

export const Wrapper = styled.button`
  background: none;
  height: 24px;
  position: absolute;
  right: ${({ theme, padding }) => padding || theme.spaces[6]}px;
  top: ${({ theme, padding }) => padding || theme.spaces[6]}px;
  width: 24px;
`
