export const salesFunnelArray = [
  { name: 'Leads & Opportunities', order: 0 },
  { name: 'Appointments', order: 1 },
  { name: 'Appointment Shows', order: 2 },
  { name: 'In-Store Visits', order: 2.1 },
  { name: 'Presentations', order: 3 },
  { name: 'Demo Drives', order: 4 },
  { name: 'Write-Ups', order: 5 },
  { name: 'Sales', order: 6 },
]

export const salesFunnelValues = [
  'Leads & Opportunities',
  'Appointments',
  'Appointment Shows',
  'In-Store Visits',
  'Presentations',
  'Demo Drives',
  'Write-Ups',
  'Sales',
]
