import Layout from '@/components/Layout'
import StripeContainer from '@/forms/StripeContainer'

export default function Payment() {
  return (
    <Layout hideLogo>
      <StripeContainer />
    </Layout>
  )
}
