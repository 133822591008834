import React, { useState } from 'react'

import backgroundImage from '@/assets/images/layout-background.png'
import Layout from '@/components/Layout'
import { SendInvitesForm } from '@/forms/SendInvites'
import { useUsersMutation } from '@/queries/users'
import { useEditUserMutation } from '@/queries/edit-user'
import { useInvitesMutation } from '@/queries/send-invites'
import { useOrgDataQuery } from '@/queries/get-org'
import { createToast } from '@/helpers/createToast'

export default function SendInvites() {
  const [error, setError] = useState(false)

  const { data: orgData, isLoading: orgDataLoad } = useOrgDataQuery()

  const { mutate: sendInvites, isLoading: invitesLoad } = useInvitesMutation()
  const { mutate: fetchData, data, isLoading: usersLoad } = useUsersMutation()
  const { mutate: editUser, isLoading: editUserLoad } = useEditUserMutation({
    onSuccess: () => {
      createToast('success', 'User data edited successfully!')
    },
    onError: (err) => {
      setError(err.response.data.message)
    },
  })

  return (
    <Layout logo justify="start" pt={28} background={backgroundImage}>
      <SendInvitesForm
        onEditSubmit={editUser}
        fetchData={fetchData}
        sendInvites={sendInvites}
        data={data && data.data}
        locations={orgData && orgData.locations}
        isLoading={usersLoad || editUserLoad || invitesLoad || orgDataLoad}
        error={error}
        setError={setError}
      />
    </Layout>
  )
}
