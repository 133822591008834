import React from 'react'

import { FieldWrapper, Input, InputWrapper, SearchIcon } from './styles'

export function Search({
  placeholder = 'Search',
  disabled = false,
  onChange,
  value,
  ...otherProps
}) {
  return (
    <FieldWrapper {...otherProps}>
      <InputWrapper>
        <Input
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
        />
        <SearchIcon />
      </InputWrapper>
    </FieldWrapper>
  )
}
