import axios from 'axios'
import { useMutation } from 'react-query'

import { FORGOT_PASSWORD_ENDPOINT } from '@/constants/endpoints'

export const useForgotPasswordMutation = (options) =>
  useMutation(
    (payload) => axios.post(FORGOT_PASSWORD_ENDPOINT, payload),
    options
  )
