import { loadStripe } from '@stripe/stripe-js'

const stripeKey = process.env.REACT_APP_STRIPE_KEY

let promiseStripe
const {
  REACT_APP_PRO_PRICE_MONTHLY_3_15_ID,
  REACT_APP_PRO_PRICE_MONTHLY_16_30_ID,
  REACT_APP_PRO_PRICE_MONTHLY_31_50_ID,
  REACT_APP_PRO_PRICE_YEARLY_3_15_ID,
  REACT_APP_PRO_PRICE_YEARLY_16_30_ID,
  REACT_APP_PRO_PRICE_YEARLY_31_50_ID,
  REACT_APP_ELITE_PRICE_MONTHLY_50_PLUS_ID,
  REACT_APP_ELITE_PRICE_MONTHLY_100_PLUS_ID,
  REACT_APP_ELITE_PRICE_YEARLY_50_PLUS_ID,
  REACT_APP_ELITE_PRICE_YEARLY_100_PLUS_ID,
} = process.env

const stripePrices = {
  Pro: {
    monthly: {
      '3-15': {
        price: REACT_APP_PRO_PRICE_MONTHLY_3_15_ID,
        quantity: 1,
      },
      '16-30': {
        price: REACT_APP_PRO_PRICE_MONTHLY_16_30_ID,
        quantity: 1,
      },
      '31-50': {
        price: REACT_APP_PRO_PRICE_MONTHLY_31_50_ID,
        quantity: 1,
      },
    },
    yearly: {
      '3-15': {
        price: REACT_APP_PRO_PRICE_YEARLY_3_15_ID,
        quantity: 1,
      },
      '16-30': {
        price: REACT_APP_PRO_PRICE_YEARLY_16_30_ID,
        quantity: 1,
      },
      '31-50': {
        price: REACT_APP_PRO_PRICE_YEARLY_31_50_ID,
        quantity: 1,
      },
    },
  },
  Elite: {
    monthly: {
      '50+': {
        price: REACT_APP_ELITE_PRICE_MONTHLY_50_PLUS_ID,
        quantity: 1,
      },
      '100+': {
        price: REACT_APP_ELITE_PRICE_MONTHLY_100_PLUS_ID,
        quantity: 1,
      },
    },
    yearly: {
      '50+': {
        price: REACT_APP_ELITE_PRICE_YEARLY_50_PLUS_ID,
        quantity: 1,
      },
      '100+': {
        price: REACT_APP_ELITE_PRICE_YEARLY_100_PLUS_ID,
        quantity: 1,
      },
    },
  },
}

export const paymentPlans = Object.freeze({
  premium: Object.freeze({
    monthly: 'premiumMonthly',
    yearly: 'premiumYearly',
  }),
  plus: Object.freeze({
    monthly: 'plusMonthly',
    yearly: 'plusYearly',
  }),
})

const checkoutOptions = (item) => ({
  lineItems: [item],
  mode: 'subscription',
  successUrl: `${window.location.origin}/congrats_you_are_in?session_id={CHECKOUT_SESSION_ID}`,
  cancelUrl: `${window.location.origin}/confirm_your_plan`,
})

export const getStripe = () => {
  if (!promiseStripe) {
    promiseStripe = loadStripe(stripeKey)
  }
  return promiseStripe
}

export const redirectToStripeCheckout = async (
  paymentFrequency,
  paymentPlan,
  totalUsers,
  paymentBand
) => {
  const product = stripePrices[paymentPlan][paymentFrequency][paymentBand]
  product.quantity = totalUsers

  const stripe = await getStripe()
  const stripeOptions = checkoutOptions(product)

  const { error } = await stripe.redirectToCheckout(stripeOptions)
  if (error) {
    throw error
  }
}
