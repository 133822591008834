import React from 'react'

import { TitleContainer } from './styles'

export function Title({ children, as, ...otherProps }) {
  return (
    <TitleContainer as={as} {...otherProps}>
      {children}
    </TitleContainer>
  )
}
