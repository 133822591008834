import React from 'react'

import { HelpButton } from '@/components/HelpButton'
import { ContentWrapper } from '@/components/ContentWrapper'
import { ImageBlock } from '@/components/ImageBlock'
import { Button } from '@/components/Button'
import { ProgressBar } from '@/components/ProgressBar'
import spreadsheetImage from '@/assets/images/create-organization-spreadsheet-image.png'
import onlineImage from '@/assets/images/create-organization-online-image.png'
import uploadIcon from '@/assets/icons/upload.svg'
import pencilIcon from '@/assets/icons/pencil.svg'
import { Link } from '@/components/Link'
import { Icon } from '@/components/Icon'
import {
  DOWNLOAD_SPREADSHEET_ROUTE,
  ORGANIZATION_DATA_ROUTE,
} from '@/constants/routes'

import { HelpButtonWrapper, ImageBlockWrapper, ButtonWrapper } from './styles'

export function CreateOrganizationForm() {
  return (
    <>
      <HelpButtonWrapper>
        <HelpButton />
      </HelpButtonWrapper>
      <ProgressBar active={1} />
      <ContentWrapper
        size="medium"
        title="How would you like to add your organization’s information?"
        titleSize={9}
        padding="37px 50px"
      >
        <ImageBlockWrapper>
          <ImageBlock image={spreadsheetImage} imageWidth={100} border bg>
            <ButtonWrapper>
              <Link to={DOWNLOAD_SPREADSHEET_ROUTE}>
                <Button padding={4}>
                  <Icon src={uploadIcon} />
                  Upload A Spreadsheet
                </Button>
              </Link>
            </ButtonWrapper>
          </ImageBlock>
          <ImageBlock image={onlineImage} imageWidth={100} border bg>
            <ButtonWrapper>
              <Link to={ORGANIZATION_DATA_ROUTE}>
                <Button padding={4}>
                  <Icon src={pencilIcon} />
                  Use Our Easy Online Process
                </Button>
              </Link>
            </ButtonWrapper>
          </ImageBlock>
        </ImageBlockWrapper>
      </ContentWrapper>
    </>
  )
}
