import React, { useEffect, useState, useContext } from 'react'
import { If, Then, Else } from 'react-if'
import { Formik } from 'formik'
import axios from 'axios'
import { useActor } from '@xstate/react'

import { Button } from '@/components/Button'
import { CheckboxField } from '@/fields/Checkbox'
import { CloseButton } from '@/components/CloseButton'
import { Divider } from '@/components/Divider'
import { DualFields } from '@/components/DualFields'
import { ExpertiseField } from '@/components/Expertise'
import { InfoCircle } from '@/components/InfoCircle'
import { InputField } from '@/fields/Input'
import { MANAGER_DATA_ENDPOINT } from '@/constants/endpoints'
import { MaskedInputField } from '@/fields/MaskedInput'
import { ModalWindow } from '@/components/ModalWindow'
import { SelectField } from '@/fields/Select'
import { SelectOrganization } from '@/components/SelectOrganization'
import { SelectSearchField } from '@/fields/SelectSearch'
import { Title } from '@/components/Title'
import { formatSearchUsers } from '@/helpers/formatData'
import { orgQuery } from '@/components/SelectOrganization/organization.query'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { getLocations } from '@/utils/utils'
import { SoarStateContext } from '@/global/soar.context'

import { DEFAULT_VALUES, validationSchema } from './config'
import {
  CheckboxWrapper,
  DualItemWrapper,
  ModalButtonsWrapper,
  TitleWrapper,
} from './styles'

export function SettingsAddModalForm({
  initialValues,
  isOpen,
  closeModal,
  onSubmit,
  locations = [],
  selectOrgInAddEmployee,
}) {
  let searchTimeout
  const [isManager, setIsManager] = useState('Sales Manager')
  const [locationsByOrg, setLocationsByOrg] = useState([])
  const [orgSelected, setOrgSelected] = useState(null)
  const isMobile = useMediaQuery('(max-width: 550px)')
  const soarContext = useContext(SoarStateContext)
  const [state] = useActor(soarContext.dashboardService)
  const { orgId, orgName } = state.context

  const loadOptions = (id) => (inputValue, callback) => {
		if (id) {
			clearTimeout(searchTimeout)
			searchTimeout = setTimeout(() => {
				axios
					.post(`${MANAGER_DATA_ENDPOINT}/${id}`, {
						skip: 0,
						limit: 10,
						search: inputValue,
						role: 'all',
						originManager: isManager === 'Sales Manager',
					})
					.then(({ data: users }) => {
						const options = formatSearchUsers(
							users.list,
							isManager === 'Sales Manager'
						)
						callback(options)
					})
			}, 1000)
		}
  }

  const handleOnChangeOrg = async (org) => {
    if (org) {
      soarContext.dashboardService.send({
        type: 'ORGANIZATION_SELECTED',
        orgId: org.value,
        orgName: org.label,
        isImpersonate: true,
      })
			const id = org.value
			setOrgSelected(id)
			const locations = await getLocations(id)
			setLocationsByOrg(locations)
    }
  }

	useEffect(() => {
		if(locations.length > 0) setLocationsByOrg(locations)
	}, [locations])

  return (
    <ModalWindow isOpen={isOpen} handleClose={closeModal} width={550}>
      <CloseButton onClick={closeModal} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(data) => {
          onSubmit(data, orgSelected)
        }}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <TitleWrapper marginBottom={5}>
              <Title bold fontSize={7}>
                Add Employee
              </Title>
            </TitleWrapper>
            <If condition={selectOrgInAddEmployee}>
              <Then>
                <div className="flex items-center mb-4">
                  <span className="mr-4 text-white text-xl">ORGANIZATION:</span>
                  <SelectOrganization
                    orgQuery={orgQuery}
                    onChange={handleOnChangeOrg}
                    orgId={orgId}
                    orgName={orgName}
                    style={{
                      width: '31rem',
                      backgroundColor: '#1E293B',
                      color: 'white',
                    }}
                    inputStyle={{
                      color: 'white',
                    }}
                  />
                </div>
              </Then>
            </If>
            <DualFields margin={isMobile ? 2 : 3} gap={5}>
              <DualItemWrapper>
                <InputField name="name" label="NAME" placeholder="Full name" />
              </DualItemWrapper>
              <DualItemWrapper>
                <InputField
                  type="email"
                  name="email"
                  label="EMAIL"
                  placeholder="test@soar.com"
                />
              </DualItemWrapper>
            </DualFields>
            <DualFields margin={isMobile ? 2 : 3} gap={5}>
              <DualItemWrapper>
                <MaskedInputField
                  name="phone"
                  label="MOBILE PHONE NUMBER"
                  placeholder="123-456-7890"
                />
              </DualItemWrapper>
              <DualItemWrapper>
                <SelectField
                  name="location"
                  label="LOCATION"
                  placeholder="Location"
                  options={locationsByOrg.map((item) => {
                    return { value: item, label: item }
                  })}
                />
              </DualItemWrapper>
            </DualFields>
            <DualFields margin={isMobile ? 2 : 3} gap={5}>
              <DualItemWrapper>
                <SelectField
                  name="role"
                  label="ROLE"
                  options={[
                    { label: 'Manager', value: 'Sales Manager' },
                    { label: 'Employee', value: 'Sales Person' },
                  ]}
                  onChange={setIsManager}
                />
              </DualItemWrapper>
              <DualItemWrapper>
                <If condition={!orgSelected}>
                  <Then>
                    <SelectSearchField
                      key={values.role}
                      name="manager"
                      label={
                        values.role === 'Sales Manager'
                          ? 'MANAGER THEY REPORT TO'
                          : 'REPORTING MANAGER'
                      }
                      placeholder="None (top-level manager)"
                      loadOptions={loadOptions(orgId)}
                      hideIndicator={false}
                      menuPlacement="top"
                    />
                  </Then>
                  <Else>
                    <SelectSearchField
                      key={orgSelected}
                      name="manager"
                      label={
                        values.role === 'Sales Manager'
                          ? `MANAGER THEY REPORT TO`
                          : 'REPORTING MANAGER'
                      }
                      placeholder="None (top-level manager)"
                      loadOptions={loadOptions(orgSelected)}
                      hideIndicator={false}
                      menuPlacement="top"
                    />
                  </Else>
                </If>
              </DualItemWrapper>
            </DualFields>
            <DualFields gap={5} margin={isMobile ? 2 : 6}>
              <DualItemWrapper>
                <CheckboxWrapper>
                  <CheckboxField
                    name="certifiedCoach"
                    size={20}
                    label="Certified coach"
                  />
                  <InfoCircle right marginLeft={3}>
                    You can designate any employee as a certified coach if you
                    want them to mentor other employees.
                  </InfoCircle>
                </CheckboxWrapper>
              </DualItemWrapper>
              <DualItemWrapper>
                <CheckboxWrapper>
                  <CheckboxField name="isAdmin" size={20} label="Admin role" />
                  <InfoCircle right marginLeft={3}>
                    You can designate any employee as an admin of this
                    organization if you want them to manage the admin console.
                  </InfoCircle>
                </CheckboxWrapper>
              </DualItemWrapper>
            </DualFields>
            {values.certifiedCoach && (
              <ExpertiseField values={values} isModal />
            )}

            {!isMobile && <Divider size={1} margin={5} />}
            <ModalButtonsWrapper>
              <Button type="submit" width="65px" fontSize={5}>
                Save
              </Button>
              <Button gray width="80px" fontSize={5} onClick={closeModal}>
                Cancel
              </Button>
            </ModalButtonsWrapper>
          </form>
        )}
      </Formik>
    </ModalWindow>
  )
}

SettingsAddModalForm.defaultProps = {
  initialValues: DEFAULT_VALUES,
}
