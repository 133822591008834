export const getRolesNames = (usrRoles = []) => {
  return usrRoles.map((r) => r.name)
}

export const isSuperAdmin = (usrRoles = []) => {
  const rolesLower = usrRoles.map((role) => role.toLower())
  const index = rolesLower.findIndex('superadmin')
  if (index >= 0) {
    return true
  }

  return false
}
