import styled from 'styled-components'

export const HelpButtonWrapper = styled.div`
  position: absolute;
  right: 42px;
  top: 37px;
`

export const ImageBlockWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[6]}px;
  justify-content: stretch;

  ${({ theme }) => theme.below.tablet`
    flex-direction: column;
  `}
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spaces[5]}px;
  width: 100%;
`
