import React, { useState } from 'react'
import { Formik } from 'formik'

import { ModalWindow } from '@/components/ModalWindow'
import { Button } from '@/components/Button'
import { Title } from '@/components/Title'
import { Divider } from '@/components/Divider'
import { InputField } from '@/fields/Input'
import { CloseButton } from '@/components/CloseButton'
import { Icon } from '@/components/Icon'
import { useChangePasswordMutation } from '@/queries/change-password'
import { createToast } from '@/helpers/createToast'
import { InfoCircle } from '@/components/InfoCircle'
import EyeHidden from '@/assets/icons/eyeHidden.svg'
import EyeVisible from '@/assets/icons/eyeVisible.svg'

import { validationSchema, DEFAULT_VALUES } from './config'
import {
  TitleWrapper,
  ModalButtonsWrapper,
  InputWrapper,
  IconWrapper,
  WatchWrapper,
} from './styles'

export function HeaderPasswordModalForm({ initialValues, isOpen, closeModal }) {
  const [isPasswordVisible, setIsPasswordVisible] = useState({
    oldPassword: false,
    newPassword: false,
    reNewPassword: false,
  })

  const { mutate: updatePassword, isLoading } = useChangePasswordMutation({
    onSuccess: () => {
      createToast('success', 'Password changed!')
      closeModal()
    },
  })
  return (
    <ModalWindow
      additional
      isOpen={isOpen}
      handleClose={closeModal}
      closeOnOverlay={false}
      width={440}
      padding={24}
    >
      <CloseButton onClick={closeModal} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        isLoading={isLoading}
        onSubmit={(values) => updatePassword(values)}
      >
        {({ handleSubmit, values, isValid }) => (
          <form onSubmit={handleSubmit}>
            <TitleWrapper marginBottom={3}>
              <Title bold fontSize={7}>
                Edit Information
              </Title>
            </TitleWrapper>
            <InputWrapper>
              <IconWrapper>
                <InfoCircle right={1}>
                  Your old password must be between 8 and 16 characters long
                </InfoCircle>
              </IconWrapper>
              <WatchWrapper
                onClick={() =>
                  setIsPasswordVisible({
                    ...isPasswordVisible,
                    oldPassword: !isPasswordVisible.oldPassword,
                  })
                }
              >
                <Icon
                  src={isPasswordVisible.oldPassword ? EyeVisible : EyeHidden}
                  margin={0}
                  size={20}
                />
              </WatchWrapper>
              <InputField
                type={isPasswordVisible.oldPassword ? 'text' : 'password'}
                name="oldPassword"
                label="OLD PASSWORD"
                value={values.oldPassword}
                autocomplete="off"
              />
            </InputWrapper>
            <InputWrapper>
              <IconWrapper>
                <InfoCircle right={1}>
                  Your new password must be between 8 and 16 characters long
                </InfoCircle>
              </IconWrapper>
              <WatchWrapper
                onClick={() =>
                  setIsPasswordVisible({
                    ...isPasswordVisible,
                    newPassword: !isPasswordVisible.newPassword,
                  })
                }
              >
                <Icon
                  src={isPasswordVisible.newPassword ? EyeVisible : EyeHidden}
                  margin={0}
                  size={20}
                />
              </WatchWrapper>
              <InputField
                type={isPasswordVisible.newPassword ? 'text' : 'password'}
                name="newPassword"
                label="NEW PASSWORD"
                margin={5}
                value={values.newPassword}
              />
            </InputWrapper>
            <InputWrapper>
              <WatchWrapper
                onClick={() =>
                  setIsPasswordVisible({
                    ...isPasswordVisible,
                    reNewPassword: !isPasswordVisible.reNewPassword,
                  })
                }
              >
                <Icon
                  src={isPasswordVisible.reNewPassword ? EyeVisible : EyeHidden}
                  margin={0}
                  size={20}
                />
              </WatchWrapper>
              <InputField
                type={isPasswordVisible.reNewPassword ? 'text' : 'password'}
                name="reNewPassword"
                label="RE-ENTER NEW PASSWORD"
                value={values.reNewPassword}
              />
            </InputWrapper>
            <Divider size={1} margin={5} />
            <ModalButtonsWrapper>
              <Button
                disabled={!isValid}
                type="submit"
                width="65px"
                fontSize={5}
              >
                Save
              </Button>
              <Button
                gray
                color="headerText"
                width="80px"
                fontSize={5}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </ModalButtonsWrapper>
          </form>
        )}
      </Formik>
    </ModalWindow>
  )
}

HeaderPasswordModalForm.defaultProps = {
  initialValues: DEFAULT_VALUES,
}
