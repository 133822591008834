import React from 'react'
import { useNavigate } from 'react-router-dom'

import { SPREADSHEET_STORAGE_KEY } from '@/constants/storage-keys'
import Layout from '@/components/Layout'
import { createToast } from '@/helpers/createToast'
import { ADD_MANAGERS_ROUTE, REVIEW_INFO_ROUTE } from '@/constants/routes'
import { OrganizationDataForm } from '@/forms/OrganizationData'
import backgroundImage from '@/assets/images/layout-background.png'
import { useOrganizationDataMutation } from '@/queries/organization-data'

export default function OrganizationData() {
  const navigate = useNavigate()
  const { mutate: organizationData, isLoading } = useOrganizationDataMutation({
    onSuccess: () => {
      createToast('success', 'Organization has been created!')
      if (localStorage.getItem(SPREADSHEET_STORAGE_KEY)) {
        localStorage.removeItem(SPREADSHEET_STORAGE_KEY)
        navigate(REVIEW_INFO_ROUTE)
      }
      return navigate(ADD_MANAGERS_ROUTE)
    },
  })

  return (
    <Layout logo justify="start" background={backgroundImage}>
      <OrganizationDataForm isLoading={isLoading} onSubmit={organizationData} />
    </Layout>
  )
}
