/* eslint-disable no-unused-vars */

import { getDaysInMonth, format } from 'date-fns'

import { months } from '@/constants/months'
import { salesFunnelValues } from '@/constants/sales-funnel'

const getMonthPrediction = (data) => {
  const totalDays = getDaysInMonth(new Date())
  const today = format(new Date(), 'dd')
  const prediction = (totalDays * data) / today
  return prediction
}

export const toFixedDown = (input, digits) => {
  const re = new RegExp(`(\\d+\\.\\d{${digits}})(\\d)`)
  const m = input.toString().match(re)
  return m ? parseFloat(m[1]) : input.valueOf()
}

export const formatGraph = (data, total, funnelData) => {
  const formattedData = []
  const month = +data.date.split('-')[1] - 1

  const lastMonthValues = { name: months[month - 1], month: month - 1 }
  const monthGoalValues = { name: months[month], month }

  if (!data.lastMonth?.length) {
    funnelData?.forEach((item) => {
      if (item.name === 'Leads & Opportunities') {
        lastMonthValues.Leads = 1
        return null
      }
      if (item.name === 'Appointment Shows') {
        lastMonthValues.Shows = 1
        return null
      }
      lastMonthValues[item.name] = 1
      return null
    })
  } else {
    data.lastMonth?.forEach((item) => {
      if (item.name === 'Leads & Opportunities') {
        lastMonthValues.Leads = item.value / total
        return null
      }
      if (item.name === 'Appointment Shows') {
        lastMonthValues.Shows = item.value / total
        return null
      }
      lastMonthValues[item.name] = item.value / total
      return null
    })
  }

  if (!data.monthGoal?.length) {
    funnelData?.forEach((item) => {
      if (item.name === 'Leads & Opportunities') {
        monthGoalValues.Leads = 1
        return null
      }
      if (item.name === 'Appointment Shows') {
        monthGoalValues.Shows = 1
        return null
      }
      monthGoalValues[item.name] = 1
      return null
    })
    monthGoalValues.empty = true
  } else
    data.monthGoal?.forEach((item) => {
      if (item.name === 'Leads & Opportunities') {
        monthGoalValues.Leads = item.value / total
        return null
      }
      if (item.name === 'Appointment Shows') {
        monthGoalValues.Shows = item.value / total
        return null
      }
      monthGoalValues[item.name] = item.value / total
      return null
    })

  formattedData.push(lastMonthValues)
  formattedData.push(monthGoalValues)

  return formattedData
}

export const formatFunnelGraph = (data, field, total) => {
  const now = new Date()
  const date = new Date()
  date.setMonth(+data.date.split('-')[1] - 1)
  const firstDate = new Date(date.setDate(1)).getTime()
  const lastDate = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getTime()

  const max = 1

  const formattedData = [
    {
      name: firstDate,
      Goal: 0,
      Actual: 0,
      'Last Month': 0,
    },
  ]

  const todayValues = {
    name: now.getTime(),
    Goal: 0,
    Actual: 0,
    'Last Month': 0,
  }

  if (now.getMonth() === date.getMonth()) {
    data.lastMonth?.forEach((item) => {
      if (item.name === field) {
        todayValues['Last Month'] =
          ((item.value / new Date(lastDate).getDate()) *
            new Date(now).getDate())
      }
      return null
    })

    if (!data.lastMonth?.length) todayValues['Last Month'] = 0

    data.monthProgress?.forEach((item) => {
      if (item.name === field) {
        todayValues.Actual =
          field === 'Sales'
            ? toFixedDown(item.value, 1)
            : Math.trunc(item.value)
      }
      return null
    })

    if (!data.monthProgress?.length) todayValues.Actual = 0

    data.monthGoal?.forEach((item) => {
      if (item.name === field) {
        todayValues.Goal =
          ((item.value / new Date(lastDate).getDate()) *
            new Date(now).getDate())
      }
      return null
    })
    if (!data.monthGoal?.length) todayValues.Goal = 0

    formattedData.push(todayValues)
  }

  const values = {
    name: lastDate,
    Goal: 0,
    Actual: 0,
    'Last Month': 0,
  }

  data.lastMonth?.forEach((item) => {
    if (item.name === field) {
      values['Last Month'] = item.value
    }
    return null
  })

  if (!data.lastMonth?.length) values['Last Month'] = 0

  data.monthProgress?.forEach((item) => {
    if (item.name === field) {
      values.Actual =
        now.getMonth() === date.getMonth()
          ? getMonthPrediction(item.value)
          : item.value
    }
    return null
  })

  if (!data.monthProgress?.length) values.Actual = 0

  data.monthGoal?.forEach((item) => {
    if (item.name === field) {
      values.Goal = item.value || 0
    }
    return null
  })

  if (!data.monthGoal?.length) values.Goal = 0

  formattedData.push(values)

  return formattedData
}

export const formatGoalsGraph = (data, total, funnelData) => {
  const values = []

  let monthProgressValues =
    data.monthProgress
      ?.map((item) => {
        return {
          title: item.name,
          result:
            item.name !== 'Sales'
              ? Math.trunc(item.value / total)
              : toFixedDown(item.value / total, 1),
          order: salesFunnelValues.indexOf(item.name),
        }
      })
      .sort((a, b) => a.order - b.order) || []

  if (!monthProgressValues.length) {
    monthProgressValues = funnelData?.map((item) => ({
      title: item.name,
      result: 0,
    }))
  }

  let monthGoalValues =
    data.monthGoal
      ?.map((item) => {
        return {
          title: item.name,
          goal:
            item.name === 'Sales'
              ? toFixedDown(item.value / total, 1)
              : Math.trunc(item.value / total),
          order: salesFunnelValues.indexOf(item.name),
        }
      })
      .sort((a, b) => a.order - b.order) || []

  if (!monthGoalValues.length) {
    monthGoalValues = funnelData?.map((item) => ({
      title: item.name,
      goal: 0,
    }))
  }

  monthProgressValues.forEach((item, index) => {
    values.push({
      title: item.title,
      result: item.result,
      goal: monthGoalValues[index]?.goal || 0,
    })
  })

  return values
}
