import styled from 'styled-components'

export const HeadingSection = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundInactive};
  display: flex;
  margin-bottom: ${({ theme }) => theme.spaces[3]}px;
  padding: ${({ theme }) => theme.spaces[6]}px;
  width: 100%;
`

export const HeadingContent = styled.div`
  align-items: center;
  display: flex;
  height: 42px;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.below.tablet`
    flex-direction: column;
    height: 80px;
  `}
`

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[3]}px;
`

export const ContentSection = styled.div`
  align-items: stretch;
  border: 1px solid ${({ theme }) => theme.colors.backgroundInactive};
  border-radius: ${({ theme }) => theme.spaces[3]}px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[2]}px;
  margin-bottom: ${({ theme }) => theme.spaces[3]}px;
  min-height: 667px;
`

export const SearchBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spaces[6]}px;

  ${({ theme }) => theme.below.tablet`
    align-items: center;
    flex-direction: column;
    gap: 16px;
  `}
`

export const TableBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 0 0 8px 8px;
  color: ${({ theme }) => theme.colors.promptText};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spaces[4]}px;
  padding-bottom: ${({ theme }) => theme.spaces[4]}px;
  position: relative;
`

export const TableImageBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spaces[10]}px;
`

export const AdminSection = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[3]}px;
  min-height: 320px;
  width: 100%;

  ${({ theme }) => theme.below.tablet`
    flex-direction: column;
  `}
`

export const AdminSectionItem = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.primaryText};
  flex-basis: 50%;
  padding: ${({ theme }) => theme.spaces[6]}px
    ${({ theme }) => theme.spaces[5]}px;
`

export const AdminItemContent = styled.div``

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Funnel = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const FunnelItem = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  border: 2px solid ${({ theme }) => theme.colors.backgroundInactive};
  border-radius: ${({ theme }) => theme.spaces[2]}px;
  cursor: default;
  display: flex;
  font-family: ${({ theme }) => theme.fontFamilies.Oswald};
  font-size: 12px;
  height: 40px;
  justify-content: center;
  max-width: ${({ width }) => width}px;
  width: 100%;
`

export const Locations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const Location = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundItems};
  border-radius: ${({ theme }) => theme.spaces[2]}px;
  display: flex;
  gap: ${({ theme }) => theme.spaces[5]}px;
  height: 50px;
  padding: 0 ${({ theme }) => theme.spaces[4]}px;
`
export const UploadButtonWrapper = styled.label`
  position: absolute;
  right: 310px;
  & > input {
    display: none;
  }
`

export const UploadButton = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundActive};
  border-radius: ${({ theme }) => theme.spaces[2]}px;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  height: 40px;
  justify-content: center;
  transition: 0.1s all;
  width: 192px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundActiveLight};
  }
`
