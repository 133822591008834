import { Text } from 'grommet'
import styled from 'styled-components'

export const Subtitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[6]}px;
  margin: ${({ theme }) => theme.spaces[3]}px 0;
`

export const LogoContainer = styled.div`
  left: 30px;
  position: absolute;
  top: 30px;

  ${({ theme }) => theme.below.tablet`
    display: none;
  `}
`
