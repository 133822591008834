import React, { useCallback } from 'react'
import { Box } from 'grommet'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import LogoIcon from '@/assets/icons/logo.svg'
import logoSizes from '@/constants/logo-size'
import { DASHBOARD_ROUTE } from '@/constants/routes'

const LogoImageWrapper = styled(Box)`
  cursor: ${({ clickable }) => clickable && 'pointer'};
  display: ${({ display }) => display};
  margin-bottom: ${({ theme, margin }) =>
    margin !== undefined ? theme.spaces[margin] : 26}px;

  ${({ theme }) => theme.below.tablet`
    margin-bottom: 0;
  `}
`

const LogoImage = styled.img`
  display: block;
  width: ${({ size }) => logoSizes[size]}px;
`

export function Logo({ size = 'small', margin, clickable, display }) {
  const navigate = useNavigate()
  const handleClick = useCallback(() => {
    if (clickable) return navigate(DASHBOARD_ROUTE)
    return null
  }, [clickable])
  return (
    <LogoImageWrapper
      clickable={clickable}
      onClick={handleClick}
      margin={margin}
      focusIndicator={false}
      display={display}
    >
      <LogoImage src={LogoIcon} size={size} />
    </LogoImageWrapper>
  )
}
