import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const LinkContainer = styled(Link)`
  color: ${({ theme, variant }) => {
    switch (variant) {
      case 'dark':
        return theme.colors.backgroundInactive
      default:
        return theme.colors.backgroundActive
    }
  }};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  margin: ${({ theme, margin }) => theme.spaces[margin]}px 0;
  text-decoration: none;
  text-transform: capitalize;
  transition: border 100ms ease-out;

  &:hover {
    color: ${({ theme }) => theme.colors.backgroundActiveDark};
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    text-decoration: none;
  }
`
