import React from 'react'

import { Link } from '@/components/Link'
import { HOME_ROUTE } from '@/constants/routes'

import { Wrapper, ButtonWrapper, QuestionBox } from './styles'

export function HelpButton() {
  return (
    <Wrapper>
      <Link to={HOME_ROUTE}>
        <ButtonWrapper>
          <QuestionBox>?</QuestionBox>
          Need help?
        </ButtonWrapper>
      </Link>
    </Wrapper>
  )
}
