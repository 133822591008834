import {
  ADD_MANAGERS_ROUTE,
  CREATE_ORGANIZATION_ROUTE,
  DASHBOARD_ROUTE,
} from '@/constants/routes'
import React, { useContext, useEffect, useState } from 'react'

import { Button } from '@/components/Button'
import Layout from '@/components/Layout'
import { Loader } from '@/components/Loader'
import { SoarStateContext } from '../../global/soar.context'
import { Subtitle } from '@/components/Subtitle'
import { ThumpsUp } from './styles'
import { Title } from '@/components/Title'
import { useMeQuery } from '@/queries/get-me'
import { useNavigate } from 'react-router-dom'
import { useSelector } from '@xstate/react'
import { useUpdatePaymentStatusMutation } from '@/queries/payment-status'

export function CongratsYourIn() {
  const navigate = useNavigate()
  const soarContext = useContext(SoarStateContext)
  const [userInfo, setUserInfo] = useState({})
  const [updateStatus, setUpdateStatus] = useState(false)
  const { name, users, price, frequency, band } = useSelector(
    soarContext.subscriptionService,
    (state) => state.context.planDetails
  )

  const selectedPlanFromStorage =
    localStorage.getItem('SELECTED_PLAN_DETAILS') || ''

  const selectedPlanObject =
    selectedPlanFromStorage.length > 0
      ? JSON.parse(selectedPlanFromStorage)
      : {}

  const checkStripeStatus = () => {
    const queryParams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })
    const searchId = queryParams.session_id
    return searchId
  }

  const { data, isLoading, refetch } = useMeQuery({
    onSuccess: (data) => {
      setUserInfo(data)
      soarContext.dashboardService.send({
        type: 'ORGANIZATION_SELECTED',
        orgId: data.organization,
        orgName: '',
      })
    },
    onError: (err) => {
      console.log('error while fetching admin info', err)
    },
  })

  const { mutate: updatePaymentStatus } = useUpdatePaymentStatusMutation({
    onMutate: (variables) => {
      setUpdateStatus(false)
    },
    onSuccess: () => {
      soarContext.subscriptionService.send('CLEAR_PLAN_DETAILS')
      localStorage.setItem('SELECTED_PLAN_DETAILS', '')
      setUpdateStatus(true)
    },
    onError: (err) => {
      console.log('error', err)
    },
  })

  useEffect(() => {
    console.log(userInfo)
    const session_id = checkStripeStatus()
    if (!userInfo.isReg && userInfo.isPaid && userInfo.organization) {
      return navigate(ADD_MANAGERS_ROUTE)
    }
    if (!userInfo.isReg && userInfo.isPaid) {
      console.log('paid but not registered user ')
      return navigate(CREATE_ORGANIZATION_ROUTE)
    }
    if (userInfo.isReg && userInfo.isPaid) {
      return navigate(DASHBOARD_ROUTE)
    }

    if (userInfo.isPaid === false && session_id) {
      updatePaymentStatus({
        isPaid: true,
        plan: selectedPlanObject.name || name || '',
        mobile: userInfo.mobile,
        email: userInfo.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        stripeSessionId: session_id,
      })
    }
  }, [userInfo])

  if (isLoading) {
    return <Loader />
  }

  return (
    <Layout logo>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '7rem',
        }}
      >
        {updateStatus ? (
          <>
            <div style={{ marginBottom: '2rem' }}>
              <ThumpsUp />
            </div>
            <Title>Congrats! You're in!</Title>
            <Subtitle
              style={{
                maxWidth: '50rem',
                textAlign: 'center',
                fontSize: '28px',
                marginBottom: '1rem',
                marginTop: '2rem',
              }}
            >
              Welcome to SOAR!
            </Subtitle>
            <Title
              semiBold
              as="h2"
              fontSize={6}
              style={{
                color: 'green',
                marginTop: '2rem',
                marginBottom: '3rem',
              }}
            >
              Let's get started
            </Title>
            <Button
              width="40rem"
              onClick={() => navigate(CREATE_ORGANIZATION_ROUTE)}
            >
              Start Setup
            </Button>
          </>
        ) : (
          <h1>Updating order, please wait...</h1>
        )}
      </div>
    </Layout>
  )
}
