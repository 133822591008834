import React from 'react'

import Layout from '@/components/Layout'
import backgroundImage from '@/assets/images/layout-background.png'
import { DownloadSpreadsheetForm } from '@/forms/DownloadSpreadsheet'

export default function DownloadSpreadsheet() {
  return (
    <Layout logo justify="start" background={backgroundImage}>
      <DownloadSpreadsheetForm />
    </Layout>
  )
}
