import { proPlanData, elitePlanData } from '../constants/lang/plans'

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const calculatePriceBand = (plan, totalUsers, paymentFrequency) => {
  console.log(plan, totalUsers, paymentFrequency)
  let band = '3-15'
  if (totalUsers > 15 && totalUsers <= 30) {
    band = '16-30'
  }
  if (totalUsers > 30 && totalUsers <= 50) {
    band = '31-50'
  }
  if (totalUsers > 50 && totalUsers <= 100) {
    band = '50+'
  }
  if (totalUsers > 100) {
    band = '100+'
  }

  if (plan === 'Pro') {
    return {
      paymentPrice: proPlanData.pricing[paymentFrequency][band],
      paymentBand: band,
    }
  }
  if (plan === 'Elite') {
    return {
      paymentPrice: elitePlanData.pricing[paymentFrequency][band],
      paymentBand: band,
    }
  }
  return {
    paymentPrice: proPlanData.pricing.monthly[band],
    paymentBand: band,
  }
}
