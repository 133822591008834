import React, { useEffect, useState, useCallback, useContext } from 'react'
import { DateRangePicker } from 'rsuite'
import { format, subMonths } from 'date-fns'

import { SoarStateContext } from '@/global/soar.context'
import { SelectDashboard } from '@/components/SelectDashboard'
import { Icon } from '@/components/Icon'
import searchIcon from '@/assets/icons/search.svg'
import officeIcon from '@/assets/icons/office.svg'
import calendarIcon from '@/assets/icons/calendar.svg'
import closeIcon from '@/assets/icons/closeIcon.svg'
import userGroupIcon from '@/assets/icons/userGroup.svg'
import userIcon from '@/assets/icons/userIcon.svg'
import { useMediaQuery } from '@/hooks/useMediaQuery'

import {
  CloseButton,
  CustomDateRange,
  GraphFiltersBlock,
  RangeTitle,
  TopWrapper,
  SearchWrapper,
} from './styles'

export function FiltersWrapper({
  locationsData,
  setFiltersArray,
  managersList,
  resetMainData,
  employeesList,
  fetchCoacheeData,
}) {
  const { afterToday } = DateRangePicker
  const isDesktop = useMediaQuery('(min-width: 1023px)')

  const [locations, setLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState('All Locations')
  const [selectedManagers, setSelectedManagers] = useState('all')
  const [selectedEmployees, setSelectedEmployees] = useState('all')
  const [range, setRange] = useState('thisMonth')
  const [date, setDate] = useState()
  const [dateRange, setDateRange] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const soarContext = useContext(SoarStateContext)

  const formatLocations = useCallback(() => {
    if (locationsData) {
      const arr = locationsData.map((item) => {
        return { value: item, label: item, key: item }
      })
      arr.unshift({ label: 'All Locations', value: 'All Locations' })
      return setLocations(arr)
    }
    return setLocations(false)
  }, [locationsData])

  useEffect(() => {
    if (date) {
      resetMainData()
      fetchCoacheeData({ date })
    }
  }, [date])

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      resetMainData()
      fetchCoacheeData({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      })
    }
  }, [dateRange])

  useEffect(() => {
    formatLocations()
  }, [locationsData])

  useEffect(() => {
    const result = {
      range,
      searchValue,
      selectedLocation,
      selectedManagers,
      selectedEmployees,
      ...dateRange,
    }
    setFiltersArray(result)
  }, [
    selectedLocation,
    searchValue,
    selectedManagers,
    selectedEmployees,
    dateRange,
    range,
  ])

  const selectLocation = useCallback((data) => {
    if (data.label || data.name) {
      setSelectedLocation(data.label || data.name)
    }
  }, [])

  const handleChangeSelectDashboard = useCallback((event) => {
    soarContext.coachingService.send('CLEAN_DATE_RANGE')
    if (event.value === 'thisMonth') {
      setRange('thisMonth')
      setDateRange([])
      return setDate(format(new Date(), 'yyyy-MM-dd'))
    }

    if (event.value === 'lastMonth') {
      setRange('lastMonth')
      setDateRange([])
      const newDate = subMonths(new Date(), 1)
      return setDate(format(newDate, 'yyyy-MM-dd'))
    }

    if (event.value === 'range') {
      setRange('range')
      setIsDatePickerOpen(true)
    }

    return null
  }, [])

  const setRangeOfDates = (items) => {
    setDateRange({
      startDate: format(items[0], 'yyyy-MM-dd'),
      endDate: format(items[1], 'yyyy-MM-dd'),
    })
  }

  const handleOk = (value) => {
    soarContext.coachingService.send({
      type: 'SELECTED_DATE_RANGE',
      dateRange: {
        startDate: value[0],
        endDate: value[1],
      },
    })
    setIsDatePickerOpen(false)
  }

  return (
    <GraphFiltersBlock isDesktop={isDesktop}>
      <SearchWrapper>
        <Icon src={searchIcon} size={20} />
        <input
          type="text"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          placeholder="Search"
        />
      </SearchWrapper>
      <SelectDashboard
        color="primaryText"
        icon={officeIcon}
        isSearchable={false}
        placeholder="All Locations"
        options={locations}
        onChange={selectLocation}
        value={selectedLocation}
      />
      <SelectDashboard
        color="primaryText"
        icon={userGroupIcon}
        isSearchable={false}
        placeholder="All Managers"
        options={managersList}
        onChange={(e) => setSelectedManagers(e.value)}
        value={selectedManagers}
      />
      <SelectDashboard
        color="primaryText"
        icon={userIcon}
        isSearchable={false}
        placeholder="All Employees"
        options={employeesList}
        onChange={(e) => setSelectedEmployees(e.value)}
        value={selectedEmployees}
      />
      <SelectDashboard
        color="primaryText"
        icon={calendarIcon}
        isSearchable={false}
        placeholder="This Month"
        options={[
          { value: 'thisMonth', label: 'This Month' },
          { value: 'lastMonth', label: 'Last Month' },
          { value: 'range', label: 'Select Date Range' },
        ]}
        value={range}
        onChange={handleChangeSelectDashboard}
      />
      {isDatePickerOpen && (
        <CustomDateRange>
          <TopWrapper>
            <RangeTitle>Select Custom Date Range</RangeTitle>
            <CloseButton onClick={() => setIsDatePickerOpen(false)}>
              <Icon src={closeIcon} />
            </CloseButton>
          </TopWrapper>
          <DateRangePicker
            open={isDatePickerOpen}
            block
            character=" - "
            preventOverflow
            format="dd-MM-yyyy"
            ranges={[]}
            value={dateRange}
            onChange={setRangeOfDates}
            showOneCalendar={!isDesktop}
            placeholder="Select Range"
            placement="autoVerticalEnd"
            disabledDate={afterToday()}
            onOk={handleOk}
          />
        </CustomDateRange>
      )}
    </GraphFiltersBlock>
  )
}
