import axios from 'axios'
import { useMutation } from 'react-query'

import { MANAGER_ENDPOINT } from '@/constants/endpoints'

export const useEditManagerMutation = (options) =>
  useMutation(
    (payload) => axios.patch(`${MANAGER_ENDPOINT}/${payload.id}`, payload),
    options
  )
