import * as Yup from 'yup'

import { REQUIRED_FIELD_ERROR_MESSAGE, VIEW_NAME_ERROR_MESSAGE } from '@/constants/error-messages'
import { MAX_VIEW_NAME_LENGTH } from '@/constants/forms'

export const validationSchema = Yup.object().shape({
  title: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE).max(MAX_VIEW_NAME_LENGTH, VIEW_NAME_ERROR_MESSAGE),
})

export const DEFAULT_VALUES = {
  title: '',
}
