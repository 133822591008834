import { Config, S3 } from '@/services/digital-ocean'

export function awsUpload(blob, filename) {
  const params = {
    Body: blob,
    Bucket: `${Config.bucketName}`,
    Key: filename,
  }
  return S3.putObject(params).on('build', (request) => {
    request.httpRequest.headers.Host = `${Config.digitalOceanSpaces}`
    request.httpRequest.headers['Content-Length'] = blob.size
    request.httpRequest.headers['Content-Type'] = blob.type
    request.httpRequest.headers['x-amz-acl'] = 'public-read'
    request.httpRequest.headers['Access-Control-Allow-Origin'] = '*'
  })
}
