import React from 'react'

import { Wrapper, Arrow, Link } from './styles'

export function ArrowLink({ to = '/', children }) {
  return (
    <Link to={to}>
      <Wrapper>
        <Arrow />
        {children}
      </Wrapper>
    </Link>
  )
}
