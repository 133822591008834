export const SalesFunnelOptions = {
  DEFAULT: 0,
  LEADS_AND_OPPORTUNITIES: 1,
  STORE_VISIT_COUNT: 2,

  toString: function (type) {
    switch (type) {
      case this.DEFAULT:
        return '(Default) All percentages based on the previous step in your sales funnel'
      case this.LEADS_AND_OPPORTUNITIES:
        return 'All percentages based on your Lead and Opportunities count'
      case this.STORE_VISIT_COUNT:
        return 'Demo Drives, Write-Ups, and Sales percentages based on your Total Store Visit count'
      default:
        return '(Default) All percentages based on the previous step in your sales funnel'
    }
  },

  getList: function () {
    return [
      this.DEFAULT,
      this.LEADS_AND_OPPORTUNITIES,
      this.STORE_VISIT_COUNT,
    ].map((item) => ({
      id: item,
      label: this.toString(item),
    }))
  },
}
