import React from 'react'

import { ArrowLink } from '@/components/ArrowLink'

import { Wrapper, Title, ArrowLinkContainer } from './styles'

export function ContentWrapper({
  size,
  title,
  titleSize = 10,
  titleMargin,
  arrowLink,
  path,
  padding,
  children,
}) {
  return (
    <>
      {arrowLink && (
        <ArrowLinkContainer size={size}>
          <ArrowLink to={path}>Back</ArrowLink>
        </ArrowLinkContainer>
      )}
      <Wrapper size={size} padding={padding}>
        {title && (
          <Title semiBold as="h2" fontSize={titleSize} margin={titleMargin}>
            {title}
          </Title>
        )}
        {children}
      </Wrapper>
    </>
  )
}
