import styled, { css } from 'styled-components'

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${({ theme, margin }) => theme.spaces[margin]}px 0;
  width: 100%;

  input:-webkit-autofill {
    transition: all 0s 50000s;
  }
`

export const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.colors.backgroundItems} !important;
  border: 1px solid
    ${({ theme, hasError, value }) => {
      if (hasError) return theme.colors.error
      if (value) return theme.colors.backgroundActive
      return theme.colors.border
    }};
  border-radius: ${({ theme }) => theme.spaces[1]}px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.primaryText} !important;
  display: block;
  font-family: ${({ theme }) => theme.fontFamilies.default};
  font-size: ${({ theme }) => theme.fontSizes[5]}px;
  height: 40px;
  outline: 0;
  padding: 12px 14px 14px 10px;
  transition: 0.1s all;
  width: 100%;

  &:hover {
    border: 1px solid
      ${({ theme, hasError }) =>
        hasError ? theme.colors.errorActive : theme.colors.white};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }

  ${(props) =>
    props.disabled &&
    css`
      background: ${({ theme }) => theme.colors.disabled};
      border-color: ${({ theme }) => theme.colors.disabled};
      color: ${({ theme }) => theme.colors.primaryText};
    `}
`

export const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`

export const ErrorCircle = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.error};
  border-radius: ${({ theme }) => theme.spaces[3]}px;
  color: ${({ theme }) => theme.colors.backgroundItems};
  cursor: default;
  display: flex;
  font-size: 13px;
  font-weight: bold;
  height: ${({ theme }) => theme.spaces[5]}px;
  justify-content: center;
  min-width: ${({ theme }) => theme.spaces[5]}px;
  position: absolute;
  right: 35px;
`

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
  margin-bottom: ${({ theme }) => theme.spaces[3]}px;
  text-transform: uppercase;
`
export const InvaildMessage = styled.div`
  color: ${({ theme }) => theme.colors.error};
`
