import React from 'react'

import * as Routes from '@/constants/routes'
import HomePage from '@/pages/Home'
import WelcomePage from '@/pages/Welcome'
import SignInPage from '@/pages/SignIn'
import SignUpPage from '@/pages/SignUp'
import CreateOrganizationPage from '@/pages/CreateOrganization'
import CreatePasswordPage from '@/pages/SignUpUser'
import ForgotPasswordPage from '@/pages/ForgotPassword'
import RecoveryLinkPage from '@/pages/RecoveryLink'
import SuccessRegistrationPage from '@/pages/SuccessRegistration'
import DownloadSpreadsheetPage from '@/pages/DownloadSpreadsheet'
import UploadSpreadsheetPage from '@/pages/UploadSpreadsheet'
import OrganizationDataPage from '@/pages/OrganizationData'
import AddManagersPage from '@/pages/AddManagers'
import AddEmployeesPage from '@/pages/AddEmployees'
import ReviewInfoPage from '@/pages/ReviewInfo'
import SendInvitesPage from '@/pages/SendInvites'
import ManageReportingPage from '@/pages/ManageReporting'
import DashboardPage from '@/pages/Dashboard'
import BillingPage from '@/pages/Billing'
import SettingsPage from '@/pages/Settings'
import { PrivacyPolicy } from '@/pages/PrivacyPolicy'
import CoachingPage from '@/pages/Coaching'
import SubscriptionPlans from '@/pages/SubscriptionPlans'
import Payment from '@/pages/Payment'
import PaymentSuccess from '@/pages/PaymentSuccess'
import { ChooseYourPlan } from '@/pages/ChooseYourPlan'
import { CongratsYourIn } from '@/pages/CongratsYourIn'
import { ConfirmYourPlan } from '@/pages/ConfirmPlan'
import { Landing } from '@/pages/Landing'
import OrganizationsPage from '@/pages/Organizations'
import { SuperAdminMenuPage } from '@/pages/SuperAdminMenu'

export const routeConfig = [
  {
    isProtected: false,
    path: Routes.HOME_ROUTE,
    element: <HomePage />,
  },
  {
    path: Routes.SUCCESS_REGISTRATION_ROUTE,
    element: <SuccessRegistrationPage />,
  },
  {
    path: Routes.SIGN_IN_ROUTE,
    element: <SignInPage />,
  },
  {
    path: Routes.SIGN_UP_ROUTE,
    element: <SignUpPage />,
  },
  {
    path: Routes.CREATE_PASSWORD_ROUTE,
    element: <CreatePasswordPage />,
  },
  {
    path: Routes.FORGOT_PASSWORD_ROUTE,
    element: <ForgotPasswordPage />,
  },
  {
    path: Routes.RECOVERY_LINK_ROUTE,
    element: <RecoveryLinkPage />,
  },
  {
    isProtected: true,
    path: Routes.WELCOME_ROUTE,
    element: <WelcomePage />,
  },
  {
    isProtected: true,
    path: Routes.CREATE_ORGANIZATION_ROUTE,
    element: <CreateOrganizationPage />,
  },
  {
    isProtected: true,
    path: Routes.DOWNLOAD_SPREADSHEET_ROUTE,
    element: <DownloadSpreadsheetPage />,
  },
  {
    isProtected: true,
    path: Routes.UPLOAD_SPREADSHEET_ROUTE,
    element: <UploadSpreadsheetPage />,
  },
  {
    isProtected: true,
    path: Routes.ORGANIZATION_DATA_ROUTE,
    element: <OrganizationDataPage />,
  },
  {
    isProtected: true,
    path: Routes.ADD_MANAGERS_ROUTE,
    element: <AddManagersPage />,
  },
  {
    isProtected: true,
    path: Routes.ADD_EMPLOYEES_ROUTE,
    element: <AddEmployeesPage />,
  },
  {
    isProtected: true,
    path: Routes.REVIEW_INFO_ROUTE,
    element: <ReviewInfoPage />,
  },
  {
    isProtected: true,
    path: Routes.SEND_INVITES_ROUTE,
    element: <SendInvitesPage />,
  },
  {
    isProtected: true,
    path: Routes.MANAGE_REPORTING_ROUTE,
    element: <ManageReportingPage />,
  },
  {
    isProtected: true,
    path: Routes.DASHBOARD_ROUTE,
    element: <DashboardPage />,
  },
  {
    isProtected: true,
    path: Routes.BILLING_ROUTE,
    element: <BillingPage />,
  },
  {
    isProtected: true,
    path: Routes.SETTINGS_ROUTE,
    element: <SettingsPage />,
  },
  {
    isProtected: false,
    path: Routes.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
  },
  {
    isProtected: true,
    path: Routes.COACHING_ROUTE,
    element: <CoachingPage />,
  },
  {
    isProtected: false,
    path: Routes.SUBSCRIPTION_PLANS_ROUTE,
    element: <SubscriptionPlans />,
  },
  {
    isProtected: true,
    path: Routes.PAYMENT_ROUTE,
    element: <Payment />,
  },
  {
    isProtected: true,
    path: Routes.PAYMENT_SUCCESS_ROUTE,
    element: <PaymentSuccess />,
  },
  {
    isProtected: true,
    path: Routes.CHOOSE_YOUR_PLAN,
    element: <ChooseYourPlan />,
  },
  {
    isProtected: true,
    path: Routes.CONGRATS_YOU_ARE_IN,
    element: <CongratsYourIn />,
  },
  {
    isProtected: true,
    path: Routes.CONFIRM_YOUR_PLAN_ROUTE,
    element: <ConfirmYourPlan />,
  },
  {
    isProtected: false,
    path: Routes.LANDING_ROUTE,
    element: <Landing />,
  },
  {
    isProtected: true,
    path: Routes.ORGANIZATIONS_ROUTE,
    element: <OrganizationsPage />,
  },
  {
    isProtected: true,
    path: Routes.SUPER_ADMIN_MENU,
    element: <SuperAdminMenuPage />,
  },
]
