import * as Yup from 'yup'

import {
  getMaxLengthErrorMessage,
  getMinLengthErrorMessage,
  REQUIRED_FIELD_ERROR_MESSAGE,
} from '@/constants/error-messages'
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '@/constants/forms'

export const validationSchema = Yup.object().shape({
  email: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE).email(),
  password: Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .min(MIN_PASSWORD_LENGTH, getMinLengthErrorMessage(MIN_PASSWORD_LENGTH))
    .max(MAX_PASSWORD_LENGTH, getMaxLengthErrorMessage(MAX_PASSWORD_LENGTH)),
})

export const DEFAULT_VALUES = { email: '', password: '', rememberMe: false }
