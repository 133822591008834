import React from 'react'

import Layout from '@/components/Layout'
import { FormWrapper } from '@/components/FormWrapper'

export function PrivacyPolicy() {
  return (
    <Layout logo justify="start" pt={28}>
      <FormWrapper logo={false} title="PRIVACY POLICY" titleSize={12}>
        <p>
          This Privacy Policy establishes the terms in which SOAR uses and
          protects the information that is provided by its users when using its
          website. This company is committed to the security of its users' data.
          When we ask you to fill in the fields of personal information with
          which you can be identified, we do so ensuring that it will only be
          used in accordance with the terms of this document. However, this
          Privacy Policy may change over time or be updated, so we recommend and
          emphasize that you continually review this page to ensure that you
          agree to such changes.
        </p>
        <br />
        <h2>Information that is collected</h2>
        <p>
          Our website may collect personal information, for example: Name,
          contact information such as your email address, and sales-related
          activity or income data. Likewise, when necessary, specific
          information may be required to process an order or make a delivery or
          billing.
        </p>
        <br />
        <h2>Use of collected information</h2>
        <p>
          Our website uses the information in order to provide you, the
          customer, with the best possible service. It is possible that emails
          will be sent periodically through our site with special offers, new
          products and other advertising information that we consider relevant
          to you or that may provide you with some benefit. These emails will be
          sent to the address you provide and may be canceled anytime.
        </p>
        <p>
          SOAR is highly committed to fulfilling its commitment to keep your
          information secure. We use the most advanced systems and constantly
          update them to ensure that there is no unauthorized access.
        </p>
        <br />
        <h2>Cookies</h2>
        <p>
          A cookie refers to a file that is sent in order to request permission
          to be stored on your computer, by accepting said file it is created
          and the cookie then serves to have information regarding web traffic,
          and also facilitates future visits to a web recurrent. Another
          function that cookies have is that with them the web can recognize you
          individually and therefore provide you with the best personalized
          service on its web.
        </p>
        <p>
          Our website uses cookies to be able to identify the pages that are
          visited and their frequency. This information is used only for
          statistical analysis and then the information is permanently deleted.
          You can delete cookies at any time from your computer. However,
          cookies help to provide a better service on the websites, they do not
          give access to information from your computer or from you, unless you
          want it to and provide it directly with news. You can accept or deny
          the use of cookies, however, most browsers automatically accept
          cookies as it serves to have a better web service. You can also change
          your computer settings to decline cookies. If they are declined, you
          may not be able to use some of our services.
        </p>
        <br />
        <h2>Links to Third Parties</h2>
        <p>
          This website may contain links to other sites that may be of interest
          to you. Once you click on these links and leave our page, we no longer
          have control over the site to which you are redirected and therefore
          we are not responsible for the terms or privacy or the protection of
          your data on those other third-party sites. These sites are subject to
          their own privacy policies, so it is recommended that you consult them
          to confirm that you agree with them.
        </p>
        <br />
        <h2>Control of your personal information</h2>
        <p>
          This company will not sell, assign or distribute the personal
          information that is collected without your consent, unless required by
          a judge with a court order.
        </p>
        <p>
          SOAR management reserves the right to change the terms of this Privacy
          Policy at any time.
        </p>
      </FormWrapper>
    </Layout>
  )
}
