import React from 'react'
import { Formik } from 'formik'

import { ModalWindow } from '@/components/ModalWindow'
import { Button } from '@/components/Button'
import { Title } from '@/components/Title'
import { CloseButton } from '@/components/CloseButton'
import { Subtitle } from '@/components/Subtitle'

import {
  TitleWrapper,
  RowWrapper,
  SubtitleWrapper,
  ModalButtonsWrapper,
} from './styles'

export function SettingsAdditionalDeleteModalForm({
  isOpen,
  closeModal,
  onSubmit,
  item,
}) {
  const value = { location: item }

  return (
    <ModalWindow
      additional
      isOpen={isOpen}
      handleClose={closeModal}
      closeOnOverlay={false}
      width={530}
      padding={24}
    >
      <CloseButton onClick={closeModal} />
      <Formik initialValues={value} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <TitleWrapper marginBottom={3}>
              <Title bold fontSize={7}>
                Delete Location
              </Title>
            </TitleWrapper>
            <RowWrapper>
              <Title fontSize={6} color="promptText">
                Are you sure you want to delete <span>{item}</span> location?
              </Title>
            </RowWrapper>
            <SubtitleWrapper>
              <Subtitle as="p" margin={5}>
                If you do not want this location to be displayed in your list of
                locations, click “Delete Location”.
              </Subtitle>
            </SubtitleWrapper>
            <ModalButtonsWrapper>
              <Button type="submit" width="140px" fontSize={5}>
                Delete Location
              </Button>
              <Button
                gray
                color="headerText"
                width="80px"
                fontSize={5}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </ModalButtonsWrapper>
          </form>
        )}
      </Formik>
    </ModalWindow>
  )
}
