import React from 'react'

import { ArrowLink } from '@/components/ArrowLink'
import { Title } from '@/components/Title'
import { Subtitle } from '@/components/Subtitle'
import { Button } from '@/components/Button'
import { Icon } from '@/components/Icon'
import arrowIcon from '@/assets/icons/rightArrow.svg'

import {
  Wrapper,
  ArrowLinkContainer,
  TitleBlock,
  ContentBlock,
  TableWrapper,
  ContinueButtonContainer,
} from './styles'

export function TableSection({
  size,
  title,
  subtitle,
  arrowLinkPath,
  continueButtonText,
  onContinueButtonClick,
  padding,
  isLoading,
  children,
}) {
  return (
    <>
      {arrowLinkPath && (
        <ArrowLinkContainer size={size}>
          <ArrowLink to={arrowLinkPath}>Back</ArrowLink>
        </ArrowLinkContainer>
      )}
      <Wrapper size={size} padding={padding}>
        {title && (
          <TitleBlock>
            <Title semiBold as="h2" fontSize={9} marginBottom={6}>
              {title}
            </Title>
            {subtitle && <Subtitle fontSize={6}>{subtitle}</Subtitle>}
          </TitleBlock>
        )}
        <ContentBlock>
          <TableWrapper>{children}</TableWrapper>
        </ContentBlock>
      </Wrapper>
      {!isLoading && (
        <ContinueButtonContainer size={size}>
          <Button onClick={onContinueButtonClick} width="180px">
            <Icon src={arrowIcon} />
            {continueButtonText || 'Save & Continue'}
          </Button>
        </ContinueButtonContainer>
      )}
    </>
  )
}
