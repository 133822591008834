import React, { useCallback } from 'react'
import { Formik } from 'formik'

import { ModalWindow } from '@/components/ModalWindow'
import { CloseButton } from '@/components/CloseButton'
import { Divider } from '@/components/Divider'
import { Button } from '@/components/Button'
import { Title } from '@/components/Title'
import { InputField } from '@/fields/Input'
import { useMediaQuery } from '@/hooks/useMediaQuery'

import { validationSchema, DEFAULT_VALUES } from './config'
import { ModalButtonsWrapper, TitleWrapper } from './styles'

export function ViewAddModalForm({
  initialValues,
  isOpen,
  closeModal,
  onSubmit,
  type,
  range,
  endDate,
  location,
  startDate,
  manager,
  employee,
  search,
}) {
  const isMobile = useMediaQuery('(max-width: 550px)')

  const submitForm = useCallback(
    async (formData) => {
      const formattedData = {
        type,
        endDate,
        location,
        startDate,
        manager,
        employee,
        search,
        name: formData.title,
        date: range,
      }
      await onSubmit(formattedData)
      closeModal()
    },
    [type, endDate, location, startDate, manager, employee, search, range]
  )

  return (
    <ModalWindow isOpen={isOpen} handleClose={closeModal} width={550}>
      <CloseButton onClick={closeModal} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <TitleWrapper marginBottom={5}>
              <Title bold fontSize={7}>
                Save View
              </Title>
            </TitleWrapper>
            <InputField name="title" label="Title" placeholder="Custom View" />
            <TitleWrapper marginBottom={4} marginTop={4}>
              <Title fontSize={5}>The maximum character limit is 30.</Title>
            </TitleWrapper>
            {!isMobile && <Divider size={1} margin={5} />}
            <ModalButtonsWrapper>
              <Button type="submit" width="65px" fontSize={5}>
                Save
              </Button>
              <Button gray width="80px" fontSize={5} onClick={closeModal}>
                Cancel
              </Button>
            </ModalButtonsWrapper>
          </form>
        )}
      </Formik>
    </ModalWindow>
  )
}

ViewAddModalForm.defaultProps = {
  initialValues: DEFAULT_VALUES,
}
