/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react'
import { Formik } from 'formik'
import axios from 'axios'
import { If, Then } from 'react-if'

import {
  GET_COACH_MANAGER_ENDPOINT,
  MANAGER_DATA_ENDPOINT,
} from '@/constants/endpoints'
import { SelectSearch } from '@/components/SelectSearch'
import { ExpertiseField } from '@/components/Expertise'
import { ModalWindow } from '@/components/ModalWindow'
import { CloseButton } from '@/components/CloseButton'
import { InfoCircle } from '@/components/InfoCircle'
import { DualFields } from '@/components/DualFields'
import { Divider } from '@/components/Divider'
import { Button } from '@/components/Button'
import { Title } from '@/components/Title'
import { SelectSearchField } from '@/fields/SelectSearch'
import { MaskedInputField } from '@/fields/MaskedInput'
import { CheckboxField } from '@/fields/Checkbox'
import { SelectField } from '@/fields/Select'
import { InputField } from '@/fields/Input'
import { formatSearchUsers, setModalValues } from '@/helpers/formatData'
import { useMediaQuery } from '@/hooks/useMediaQuery'

import { validationSchema, DEFAULT_VALUES } from './config'
import {
  DualItemWrapper,
  ModalButtonsWrapper,
  CheckboxWrapper,
  TitleWrapper,
} from './styles'
import { createToast } from '@/helpers/createToast'

export function SettingsEditModalForm({
  initialValues,
  isOpen,
  closeModal,
  onSubmit,
  locations = [],
  user,
  rows,
  userCoaches,
  changeCoach,
  getUserCoach,
  managerCount,
}) {
  const { fbUid } = user
  const [isManager, setIsManager] = useState('');
  const [manager, setManager] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState([]);
	const [loadingText, setLoadingText] = useState('Loading...')

  const isMobile = useMediaQuery('(max-width: 600px)')

  let managersTimeout
  let searchTimeout

  const initValues = setModalValues(initialValues, user)

  const submitForm = useCallback(
    (data) => {
      const formattedData = { ...data }
      if (typeof formattedData.manager === 'object')
        formattedData.manager = formattedData.manager.value
      formattedData.managerName = manager?.label || formattedData.managerName
			const fbUids = []
			selectedCoach.forEach((selected) => {
				fbUids.push(selected.fbUid)
			})
			changeCoach({ user, fbUids })
      formattedData.role = data.role;
      formattedData.isAdmin = !data.isAdmin ? false : data.isAdmin
      formattedData.expertise = !data.expertise ? [] : data.expertise
      formattedData.organization = user.organization
      setManager(false)
      setSelectedCoach(false)
      delete formattedData.name
      onSubmit(formattedData, rows)
    },
    [user, selectedCoach, manager, rows]
  )

  const loadOptions = () => (inputValue, callback) => {
      clearTimeout(managersTimeout)
      managersTimeout = setTimeout(() => {
        axios
          .post(`${MANAGER_DATA_ENDPOINT}/${user.organization}`, {
            skip: 0,
            limit: 10,
            search: inputValue || '',
            role: 'all',
            originManager: isManager === 'Sales Manager',
          })
          .then(({ data: users }) => {
            const options = formatSearchUsers(
              users.list,
              isManager === 'Sales Manager'
            )
            callback(options)
          })
      }, 1000)
    }

  useEffect(() => {
    if (user) {
			setTimeout(() => setLoadingText('None'), 2000)
      getUserCoach(user);
      setIsManager(user.role);
      setManager(false);
    }
    if (managerCount === 1 && user.role === 'Sales Manager') {
      createToast('info', `${user.firstName} ${user.lastName} is the only manager left for this organization, the option to change their role is disabled.`)
    }
  }, [user, managerCount])

  useEffect(() => {
    if (userCoaches?.data?.length) {
      const coaches = []
      userCoaches.data.forEach((coach) => {
        coaches.push({
          label: `${coach.firstName} ${coach.lastName}`,
          value: coach.fbUid,
          fbUid: coach.fbUid,
        })
      })
			setSelectedCoach(coaches);
    }
  }, [userCoaches])

  const loadCoachOptions = useCallback(
    (inputValue, callback) => {
      clearTimeout(searchTimeout)
      searchTimeout = setTimeout(() => {
        axios
          .post(`${GET_COACH_MANAGER_ENDPOINT}/${fbUid}`, {
            skip: 0,
            limit: 1000,
            search: inputValue,
            originManager: false,
          })
          .then(({ data: users }) => {
            const options = formatSearchUsers(users.list, true).filter(
              (item) => item.value !== user.fbUid
            )
            callback(options)
          })
          .catch((err) => console.log(err))
      }, 1000)
    },
    [user]
  )

	const handleCoachSelection = (coachArr) => {
		if (coachArr.length === 0 || coachArr[coachArr.length - 1].value === '')
			setSelectedCoach([]);
		else
			setSelectedCoach(coachArr);
	}

	const handleClose = () => {
		setSelectedCoach([]);
		setLoadingText('Loading...');
		closeModal();
	}

  return (
    <ModalWindow isOpen={isOpen} handleClose={handleClose} width={550}>
      <CloseButton onClick={handleClose} />
			<Formik
				initialValues={initValues}
				validationSchema={validationSchema}
				onSubmit={submitForm}
			>
				{({ handleSubmit, values }) => {
					return (
						<form onSubmit={handleSubmit}>
							<TitleWrapper marginBottom={5}>
								<Title bold fontSize={7}>
									Edit{' '}
									{user.role === 'Sales Manager' ? 'Manager ' : 'Employee '}
									Details
								</Title>
							</TitleWrapper>
							<DualFields margin={isMobile ? 2 : 3} gap={5}>
								<DualItemWrapper>
									<InputField name="firstName" label="FIRST NAME" />
								</DualItemWrapper>
								<DualItemWrapper>
								<InputField name="lastName" label="LAST NAME" />
								</DualItemWrapper>
							</DualFields>
							<DualFields margin={isMobile ? 2 : 3} gap={5}>
								<DualItemWrapper>
									<MaskedInputField
										name="phone"
										label="MOBILE PHONE NUMBER"
										placeholder="123-456-7890"
									/>
								</DualItemWrapper>
								<DualItemWrapper>
									<SelectField
										name="location"
										label="LOCATION"
										options={locations.map((item) => {
											return { value: item, label: item }
										})}
									/>
								</DualItemWrapper>
							</DualFields>
							<DualFields margin={isMobile ? 2 : 3} gap={5}>
								<DualItemWrapper>
									<SelectField
										name="role"
										label="ROLE"
										disabled={isManager === 'Sales Manager' && managerCount === 1}
										options={[
											{ label: 'Manager', value: 'Sales Manager' },
											{ label: 'Employee', value: 'Sales Person' },
										]}
										onChange={setIsManager}
									/>
								</DualItemWrapper>
								<DualItemWrapper>
									<If condition={user.role}>
										<Then>
											<SelectSearchField
												key={values.role}
												name="manager"
												label={
													values.role === 'Sales Manager'
														? 'MANAGER THEY REPORT TO'
														: 'REPORTING MANAGER'
												}
												placeholder="None (top-level manager)"
												loadOptions={loadOptions()}
												hideIndicator={false}
												setManagerName={setManager}
												value={manager || values.manager}
												menuPlacement="top"
												coach
											/>
										</Then>
									</If>
								</DualItemWrapper>
							</DualFields>
							<DualFields margin={isMobile ? 2 : 3} gap={5}>
								<DualItemWrapper>
									<SelectSearch
										label="Assigned Coach"
										color="primaryText"
										placeholder={loadingText}
										loadOptions={loadCoachOptions}
										onChange={handleCoachSelection}
										menuPlacement="top"
										value={selectedCoach}
										isMulti
										coach
									/>
								</DualItemWrapper>
								<DualItemWrapper>
									<InputField disabled={true} type="email" name="email" label="EMAIL" />
								</DualItemWrapper>
							</DualFields>
							<DualFields gap={5} margin={isMobile ? 2 : 5}>
								<DualItemWrapper>
									<CheckboxWrapper>
										<CheckboxField
											name="certifiedCoach"
											size={20}
											label="Certified coach"
										/>
										<InfoCircle right marginLeft={3}>
											You can designate any employee as a certified coach if you
											want them to mentor other employees.
										</InfoCircle>
									</CheckboxWrapper>
								</DualItemWrapper>
								<DualItemWrapper>
									<CheckboxWrapper>
										<CheckboxField
											name="isAdmin"
											size={20}
											label="Admin role"
										/>
										<InfoCircle right marginLeft={3}>
											You can designate any employee as an admin of this
											organization if you want them to manage the admin console.
										</InfoCircle>
									</CheckboxWrapper>
								</DualItemWrapper>
							</DualFields>
							{values.certifiedCoach && (
								<ExpertiseField values={values} isModal />
							)}

							<Divider size={1} margin={5} />
							<ModalButtonsWrapper>
								<Button type="submit" width="65px" fontSize={5}>
									Save
								</Button>
								<Button gray width="80px" fontSize={5} onClick={handleClose}>
									Cancel
								</Button>
							</ModalButtonsWrapper>
						</form>
					)
				}}
			</Formik>
    </ModalWindow>
  )
}

SettingsEditModalForm.defaultProps = {
  initialValues: DEFAULT_VALUES,
}
