import React, { useContext } from 'react'
import { useActor } from '@xstate/react'

import Layout from '@/components/Layout'
import { ManageReportingForm } from '@/forms/ManageReporting'
import backgroundImage from '@/assets/images/layout-background.png'
import { useDeleteFunnelMutation } from '@/queries/delete-funnel'
import { useFunnelQuery } from '@/queries/get-funnel'
import { Loader } from '@/components/Loader'
import { SoarStateContext } from '@/global/soar.context'

export default function ManageReporting() {
  const soarContext = useContext(SoarStateContext)
  const [state] = useActor(soarContext.dashboardService)
  const { orgId } = state.context
  const { data, isLoading } = useFunnelQuery(orgId)
  const { mutate: deleteItem, isLoading: deleteLoading } =
    useDeleteFunnelMutation()

  return (
    <Layout
      logo
      justify={data ? 'start' : 'center'}
      pt={28}
      background={backgroundImage}
    >
      {data ? (
        <ManageReportingForm
          isLoading={isLoading}
          deleteLoading={deleteLoading}
          deleteItem={deleteItem}
          data={data}
        />
      ) : (
        <Loader />
      )}
    </Layout>
  )
}
