import React, { useContext } from 'react'
import cls from 'classnames'
import { useNavigate } from 'react-router-dom'

import { SelectOrganization } from '@/components/SelectOrganization'
import { SUPER_ADMIN_MENU } from '@/constants/routes'
import infoCircleIcon from '@/assets/icons/infoCircleBeige.svg'
import arrowBackIcon from '@/assets/icons/arrow_back_black.svg'
import { Icon } from '@/components/Icon'
import { SoarStateContext } from '@/global/soar.context'

export function SelectOrganizationBar({ orgQuery, onChange, orgId, orgName }) {
  const soarContext = useContext(SoarStateContext)

  const routeTo = (route) => {
    soarContext.dashboardService.send({
      type: 'ORGANIZATION_SELECTED',
      orgId: '',
      orgName: '',
      isImpersonate: false,
    })

    navigate(route);
  };

  const navigate = useNavigate()
  const selectOrgBarClass = cls(
    'flex',
    'items-center',
    'bg-yellow-100',
    'h-20',
    'font-bold'
  )
  const msg = 'You are impersonating as an admin of:'

  return (
    <div className={selectOrgBarClass}>
      <Icon
        src={arrowBackIcon}
        size={30}
        className="hover:cursor-pointer ml-20"
        onClick={() => routeTo(SUPER_ADMIN_MENU)}
      />
      <Icon src={infoCircleIcon} size={30} style={{ marginLeft: '4rem' }} />
      {msg}
      <SelectOrganization
        style={{ marginLeft: '2rem', width: '30rem' }}
        orgQuery={orgQuery}
        onChange={onChange}
        orgId={orgId}
        orgName={orgName}
      />
    </div>
  )
}
