import React, { useCallback } from 'react'
import { Formik } from 'formik'

import { ModalWindow } from '@/components/ModalWindow'
import { CloseButton } from '@/components/CloseButton'
import { Divider } from '@/components/Divider'
import { Button } from '@/components/Button'
import { Title } from '@/components/Title'
import { InputField } from '@/fields/Input'
import { useMediaQuery } from '@/hooks/useMediaQuery'

import { validationSchema } from './config'
import { ModalButtonsWrapper, TitleWrapper } from './styles'

export function OrganizationsAddModalForm({
  title,
  initialValues,
  isOpen,
  closeModal,
  onSubmit,
}) {
  const isMobile = useMediaQuery('(max-width: 550px)')

  const submitForm = useCallback((data) => {
    const formattedData = { ...data }
    if (data.name) {
      onSubmit(formattedData)
    }
  }, [])

  return (
    <ModalWindow isOpen={isOpen} handleClose={closeModal} width={550}>
      <CloseButton onClick={closeModal} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <TitleWrapper marginBottom={5}>
              <Title bold fontSize={7}>
                {title}
              </Title>
            </TitleWrapper>
            <InputField
              name="name"
              label="NAME"
              placeholder="Organization name"
            />
            {!isMobile && <Divider size={1} margin={5} />}
            <ModalButtonsWrapper>
              <Button type="submit" width="65px" fontSize={5}>
                Save
              </Button>
              <Button gray width="80px" fontSize={5} onClick={closeModal}>
                Cancel
              </Button>
            </ModalButtonsWrapper>
          </form>
        )}
      </Formik>
    </ModalWindow>
  )
}
