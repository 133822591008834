import styled from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 662px;
  width: 100%;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spaces[5]}px 0;
  width: 100%;
`
