import { BillingForm } from '@/forms/Billing'
import { Loader } from '@/components/Loader'
import { MenuLayout } from '@/components/MenuLayout'
import { useOrganizationImpersonate } from '@/hooks/useOrganizationImpersonate'
import React from 'react'
import backgroundImage from '@/assets/images/layout-background.png'
import { useGetBillingData } from '@/queries/get-billing'
import { useOrgDataQuery } from '@/queries/get-org'

export default function Billing() {
  const { data: orgData } = useOrgDataQuery()

  const { organizationId } = useOrganizationImpersonate(orgData?.id)
  const { data: billingInfo, isFetching } = useGetBillingData(
    {},
    organizationId
  )

  return (
    <MenuLayout adminData={orgData} background={backgroundImage}>
      {isFetching ? (
        <Loader />
      ) : (
        <BillingForm billingInfo={billingInfo} isFetching={isFetching} />
      )}
    </MenuLayout>
  )
}
