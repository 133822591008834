import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { If, Then, Else } from 'react-if'

export function RenderByRole({ userRoles = [], forRoles = [], children }) {
  const usrRolesLow = userRoles.map((e) => e.toLowerCase())
  const forRolesLow = forRoles.map((e) => e.toLowerCase())

  const roleExists = _.intersection(usrRolesLow, forRolesLow).length > 0

  return (
    <If condition={roleExists}>
      <Then>{children}</Then>
      <Else>{null}</Else>
    </If>
  )
}

RenderByRole.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  forRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}
