import React from 'react'
import { NavLink as BaseNavLink } from 'react-router-dom'

import { LinkContainer } from './styles'

export function NavLink({ to, children }) {
  return (
    <LinkContainer>
      <BaseNavLink to={to}>{children}</BaseNavLink>
    </LinkContainer>
  )
}
