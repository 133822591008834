import styled from 'styled-components'

import { Link } from '@/components/Link'

export const SignUpField = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
  gap: ${({ theme }) => theme.spaces[4]}px;
`

export const SignUpLink = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-decoration: underline;

  &:hover {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-decoration: underline;
  }
`

export const StyledLink = styled(Link)`
  text-align: right;
`

export const CheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
`
