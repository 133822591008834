import { Button, Text } from 'grommet'
import styled from 'styled-components'

export const Subtitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[6]}px;
  margin: ${({ theme }) => theme.spaces[3]}px 0;
`

export const ResendField = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
  gap: ${({ theme }) => theme.spaces[4]}px;
`

export const ResendButton = styled(Button)`
  color: ${({ theme }) => theme.colors.backgroundActive};
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
`
