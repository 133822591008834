import styled from 'styled-components'

import { Subtitle as BaseSubtitle } from '@/components/Subtitle'
import { rgbToRgba } from '@/helpers/rgbToRgba'

export const Subtitle = styled(BaseSubtitle)`
  margin-bottom: ${({ theme }) => theme.spaces[7]}px;
  text-align: center;
`

export const InputBlock = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`

export const FieldWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 11px;
  justify-content: center;
  position: relative;
  width: ${({ width }) => width}px;

  ${({ theme }) => theme.below.mobileXL`
    width: 100%;
    `}
`

export const Input = styled.input`
  background-color: ${({ theme, green }) =>
    green
      ? rgbToRgba(theme.colors.greenButton, '0.24')
      : theme.colors.background};
  border: 2px solid
    ${({ theme, green }) =>
      green ? theme.colors.greenButton : theme.colors.border};
  border-radius: 6px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.primaryText};
  cursor: pointer !important;
  display: block;
  font-family: ${({ theme }) => theme.fontFamilies.Oswald};
  font-size: 13px;
  height: 40px;
  letter-spacing: 0.07em;
  outline: 0;
  text-align: center;
  transition: 0.1s all;
  width: 100%;

  &:hover {
    border: 2px solid
      ${({ theme, green }) =>
        green ? theme.colors.greenButtonLight : theme.colors.white};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 160px;
`
