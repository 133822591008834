import React, { useState } from 'react'

import Layout from '@/components/Layout'
import backgroundImage from '@/assets/images/layout-background.png'
import { createToast } from '@/helpers/createToast'
import { useUsersMutation } from '@/queries/users'
import { useAddUserMutation } from '@/queries/add-user'
import { useEditUserMutation } from '@/queries/edit-user'
import { AddEmployeesForm } from '@/forms/AddEmployees'
import { useOrgDataQuery } from '@/queries/get-org'

export default function AddEmployees() {
  const [error, setError] = useState(false)

  const { data: orgData, isLoading: orgDataLoad } = useOrgDataQuery()

  const {
    mutate: fetchUsers,
    data: users,
    isLoading: usersLoad,
  } = useUsersMutation()

  const { mutate: addUser, isLoading: addUserLoad } = useAddUserMutation({
    onSuccess: () => {
      createToast('success', 'User added successfully!')
    },
    onError: (err) => {
      setError(err.response.data.message)
    },
  })
  const { mutate: editUser, isLoading: editUserLoad } = useEditUserMutation({
    onSuccess: () => {
      createToast('success', 'User data changed successfully!')
    },
    onError: (err) => {
      setError(err.response.data.message)
    },
  })

  return (
    <Layout logo justify="start" pt={28} background={backgroundImage}>
      <AddEmployeesForm
        onSubmit={addUser}
        onModalSubmit={editUser}
        fetchData={fetchUsers}
        data={users && users.data}
        locations={orgData && orgData.locations}
        isLoading={usersLoad || addUserLoad || editUserLoad || orgDataLoad}
        error={error}
        setError={setError}
      />
    </Layout>
  )
}
