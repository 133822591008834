/* eslint-disable no-unused-vars */
import styled from 'styled-components'

import arrowIcon from '@/assets/icons/graphArrow.svg'

export const Wrapper = styled.div`
  align-self: center;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fontFamilies.Oswald};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  gap: ${({ theme }) => theme.spaces[2]}px;
  //width: 270px;
  padding: 30px 20px;
`

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[2]}px;
`

export const Step = styled.div`
  align-items: center;
  display: flex;
  font-family: ${({ theme }) => theme.fontFamilies.Oswald};
  font-size: 10px;
  justify-content: space-between;
`

export const StepTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.Oswald};
  font-size: 16px;
  width: 95px;
`

export const StepValue = styled.div`
  color: ${({ theme, increase, decrease }) => {
    if (decrease) return theme.colors.decrease
    if (increase) return theme.colors.increase
    return theme.colors.white
  }};
  display: flex;
  font-family: ${({ theme }) => theme.fontFamilies.Oswald};
  font-size: 20px;
  justify-content: center;
  width: 45px;
`

export const StepChange = styled.div`
  align-items: center;
  color: ${({ theme, increase, decrease }) => {
    if (decrease) return theme.colors.decrease
    if (increase) return theme.colors.increase
    return theme.colors.white
  }};
  display: flex;
  font-family: ${({ theme }) => theme.fontFamilies.Oswald};
  font-size: 20px;
  gap: 1px;
`

export const DividerBlock = styled.div`
  display: flex;
  gap: 10px;
`

export const Arrow = styled.div`
  align-items: center;
  background-image: url(${arrowIcon});
  background-size: 60px 35px;
  cursor: default;
  display: flex;
  font-family: ${({ theme }) => theme.fontFamilies.Oswald};
  font-size: 14px;
  height: 35px;
  justify-content: center;
  min-width: 60px;
  padding-bottom: 2px;
  width: 60px;
`

export const ColumnTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.Oswald};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
  margin-left: ${({ marginLeft }) => {
    return `${marginLeft}px`
  }};
`

export const PercentText = styled.p`
  font-family: ${({ theme }) => theme.fontFamilies.Oswald};
  font-size: 12px;
  height: 35px;
  justify-content: center;
  min-width: 60px;
  padding-bottom: 2px;
  width: 60px;
  align-items: center;
  cursor: default;
  display: flex;
  margin-top: 8px;
`

export const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  //border-bottom: 1px solid #89898975;
  padding-top: 5px;
  align-items: start;

  &:last-child {
    border: none;
  }
`

export const ItemName = styled.h2`
  font-family: ${({ theme }) => theme.fontFamilies.Oswald};
  font-size: 14px;
  margin-left: ${(props) => (props.isChild ? '20px' : 0)};
  line-height: 20px;
`

export const ValueContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 60px);
  gap: 5px;
`
export const ItemValue = styled.div`
  color: ${({ theme, color }) => {
    return theme.colors[color] || theme.colors.white
  }};
  font-size: ${(props) => props.fontSize + 'px' || '20px'};
  font-family: ${({ theme }) => theme.fontFamilies.Oswald};
  text-align: center;
  display: flex;
  justify-content: center;
`

export const WithIcon = styled.div`
  display: grid;
  grid-template-columns: 12px 1fr;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamilies.Oswald};
  font-size: ${(props) => props.fontSize + 'px' || '20px'};
  margin-bottom: 20px;
`
