import { Grid } from 'grommet'
import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from 'react'
import { useActor } from '@xstate/react'
import axios from 'axios'

import { SoarStateContext } from '../../global/soar.context'

import tableImage from '@/assets/images/billing-table.png'
import editIcon from '@/assets/icons/pencil.svg'
import homeIcon from '@/assets/icons/home.svg'
import addUserIcon from '@/assets/icons/addUser.svg'
import { InfoCircle } from '@/components/InfoCircle'
import { ImageBlock } from '@/components/ImageBlock'
import { Checkbox } from '@/components/Checkbox'
import { Divider } from '@/components/Divider'
import { Search } from '@/components/Search'
import { Button } from '@/components/Button'
import { Loader } from '@/components/Loader'
import { Table } from '@/components/Table'
import { Title } from '@/components/Title'
import { Icon } from '@/components/Icon'
import { settingsColumns } from '@/constants/table-columns'
import { SettingsAdditionalDeleteModalForm } from '@/forms/SettingsAdditionalDeleteModal'
import { SettingsAdditionalEditModalForm } from '@/forms/SettingsAdditionalEditModal'
import { SettingsAdditionalAddModalForm } from '@/forms/SettingsAdditionalAddModal'
import { SettingsLocationsModalForm } from '@/forms/SettingsLocationsModal'
import { SettingsFunnelModalForm } from '@/forms/SettingsFunnelModal'
import { SettingsDeleteModalForm } from '@/forms/SettingsDeleteModal'
import { SettingsResetModalForm } from '@/forms/SettingsResetModal'
import { SettingsEditModalForm } from '@/forms/SettingsEditModal'
import { SettingsAddModalForm } from '@/forms/SettingsAddModal'
import { ModalImageWindow } from '@/components/ModalImageWindow'
import { ModalWindow } from '@/components/ModalWindow'
import { fetchConfig } from '@/constants/fetch-data-config'
import { salesFunnelArray } from '@/constants/sales-funnel'
import { formatSettingsData, removeDashes } from '@/helpers/formatData'
import { createToast } from '@/helpers/createToast'
import modalImage from '@/assets/images/file-uploaded.png'
import uploadIcon from '@/assets/icons/upload.svg'
import { awsUpload } from '@/helpers/aws'
import { UPDATE_USERS_ENDPOINT } from '@/constants/endpoints'

import {
  HeadingSection,
  HeadingContent,
  CheckboxWrapper,
  ContentSection,
  SearchBlock,
  TableBlock,
  TableImageBlockWrapper,
  AdminSection,
  AdminSectionItem,
  AdminItemContent,
  TitleWrapper,
  Funnel,
  FunnelItem,
  Locations,
  Location,
  UploadButtonWrapper,
  UploadButton,
} from './styles'

export function SettingsForm({
  users,
  funnelItems,
  orgData,
  userCoaches,
  fetchUsersData,
  addUser,
  editUser,
  deleteUser,
  resetUser,
  fetchFunnel,
  addFunnelItem,
  deleteFunnelItem,
  fetchOrgData,
  updateOrgData,
  changeCoach,
  getUserCoach,
  isUsersLoading,
  isFunnelLoading,
  isOrgLoading,
  isCoachLoading,
  error,
  setError,
  orgId,
  submitFile,
}) {
  const [rows, setRows] = useState([])
  const [changingUser, setChangingUser] = useState(false)
  const [config, setConfig] = useState(fetchConfig)
  const [locations, setLocations] = useState([])
  const [modal, setModal] = useState('')
  const [additionalModal, setAdditionalModal] = useState('')
  const [editItem, setEditItem] = useState('')
  const [isRequestLoading, setRequestLoading] = useState(false)
  const [selectedFunnelItems, setSelectedFunnel] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    true,
  ])
  const soarContext = useContext(SoarStateContext)
  const [state] = useActor(soarContext.settingsService)
  const isChecked = state.context.all_organization
  const [fileModalIsOpen, setFileModalIsOpen] = useState(false)
  const [isFileLoading, setIsFileLoading] = useState(false)

  const refFile = useRef()

  const submitFunnel = useCallback(
    (selected) => {
      const values = [false, false, false, false, false, false, true]
      funnelItems.forEach((item) => {
        values[item.order] = true
        return null
      })

      selected.forEach((item, index) => {
        if (item && !values[index]) {
          addFunnelItem(salesFunnelArray[index])
        }
        if (
          !item &&
          values[index] &&
          salesFunnelArray[index].name !== 'Sales'
        ) {
          let deletingItem
          funnelItems.forEach((funnelItem) => {
            if (funnelItem.order === index) {
              deletingItem = funnelItem
            }
            return null
          })
          deleteFunnelItem(deletingItem)
        }
      })

      setTimeout(() => fetchFunnel(), 2000)
    },
    [funnelItems, selectedFunnelItems]
  )

  let searchTimeout

  const handleCheck = () => {
    soarContext.settingsService.send({
      type: 'SELECTED_ALL_ORGANIZATION',
      allOrganization: !isChecked,
    })
  }

  const openAddModal = useCallback(() => {
    setModal('add')
  }, [])
  const openEditModal = (user) => {
    setModal('edit')
    setChangingUser(user)
  }
  const openDeleteModal = useCallback((user) => {
    setModal('delete')
    setChangingUser(user)
  }, [])

  const openResetModal = useCallback((user) => {
    setModal('reset')
    setChangingUser(user)
  }, [])

  // const openFunnelModal = useCallback(() => setModal('funnel'), [])
  const openLocationsModal = useCallback(() => setModal('locations'), [])
  const closeModal = useCallback(() => {
    setModal('')
    setChangingUser(false)
  }, [])

  const openAdditionalAddModal = useCallback(() => {
    setAdditionalModal('add')
  }, [])
  const openAdditionalEditModal = useCallback((item) => {
    setAdditionalModal('edit')
    setEditItem(item)
  }, [])
  const openAdditionalDeleteModal = useCallback((item) => {
    setAdditionalModal('delete')
    setEditItem(item)
  }, [])
  const closeAdditionalModal = useCallback(() => {
    setAdditionalModal('')
    setEditItem('')
  }, [])

  const submitEditLocationModal = useCallback(
    (updatedLocations, oldLocation, newLocation) => {
      updateOrgData({
        id: orgData.id,
        name: orgData.orgName,
        locations: updatedLocations,
        oldLocation: oldLocation,
        newLocation: oldLocation && newLocation,
      })
      setLocations(updatedLocations);
      closeAdditionalModal()
      closeModal()
      if (oldLocation) {
        const newRows = rows.map((row) => {
          if (row.location === oldLocation)
            row.location = newLocation;
          return row;
        });
        setRows([...newRows]);
      }
      setTimeout(async () => await fetchUsersData(config), 2000)
    },
    [orgData, locations]
  )

  const submitDeleteLocationModal = useCallback(
    ({ location }) => {
      const newLocArr = locations.filter((item) => item !== location);
      updateOrgData({
        id: orgData.id,
        name: orgData.orgName,
        locations: newLocArr,
        deletedLocation: location,
      })
      setLocations(newLocArr);
      closeAdditionalModal()
      closeModal()
      const newRows = rows.map((row) => {
        if (row.location === location)
          row.location = newLocArr[0];
        return row;
      });
      setRows([...newRows]);
      setTimeout(async () => await fetchUsersData(config), 2000)
    },
    [orgData, locations]
  )

  const submitAddForm = useCallback(
    (formData) => {
      const phone = removeDashes(formData.phone)
      const formattedData = { ...formData, phone }
      formattedData.organization = orgData.id
      addUser(formattedData)
      setRequestLoading(true)
      closeModal()
      setTimeout(() => {
        fetchUsersData(config)
        setRequestLoading(false)
      }, 1000)
    },
    [config, orgData]
  )

  const submitEditForm = async (formData, usersData) => {
    const formattedData = { ...formData, phone: removeDashes(formData.phone) }
    formattedData.organization = orgData.id
    closeModal()
    await editUser(formattedData)
    await fetchUsersData(config)
  }

  const submitDeleteForm = useCallback(
    async (formData) => {
      const result = await axios.patch(UPDATE_USERS_ENDPOINT, {
        action: 'inactive',
        userIds: [formData.id],
      })
      setRequestLoading(true)
      closeModal()
      setTimeout(() => {
        fetchUsersData(config)
        setRequestLoading(false)
      }, 1000)
    },
    [config]
  )

  const submitResetForm = useCallback(
    (formData) => {
      resetUser(formData)
      setRequestLoading(true)
      closeModal()
      setTimeout(() => {
        fetchUsersData(config)
        setRequestLoading(false)
      }, 1000)
    },
    [config]
  )

  const handleSearch = useCallback((value) => {
    setConfig({
      skip: 0,
      limit: 10,
      search: value,
      role: 'all',
      originManager: false,
    })
  }, [])

  const debounceSearch = useCallback(
    (event) => {
      clearTimeout(searchTimeout)
      searchTimeout = setTimeout(() => {
        handleSearch(event.target.value)
      }, 1000)
    },
    [searchTimeout]
  )

  useEffect(() => {
    fetchUsersData(config)
  }, [config])

  useEffect(() => {
    if (error) {
      if (typeof error === 'object') createToast('error', error[0])
      else createToast('error', error)
      fetchUsersData(config)
    }
    setError(false)
  }, [error, setError, config])

  useEffect(() => {
    setRows(
      (users &&
        formatSettingsData(
          users.list,
          openEditModal,
          openDeleteModal,
          openResetModal
        )) ||
        []
    )
  }, [users])

  useEffect(() => {
    if (funnelItems) {
      const values = [false, false, false, false, false, false, true]
      funnelItems.forEach((item) => {
        values[item.order] = true
        return null
      })
      setSelectedFunnel(values)
    }
  }, [funnelItems])

  useEffect(() => {
    if (orgData) setLocations(orgData.locations)
  }, [orgData])

  const openFileModal = useCallback(() => setFileModalIsOpen(true), [])

  const closeFileModal = useCallback(() => {
    setFileModalIsOpen(false)
  }, [])

  const uploadFile = (event) => {
    let fileName = Date.now()
    if (event.target.files && event.target.files[0]) {
      const blob = event.target.files[0]
      fileName += blob.name.slice(blob.name.lastIndexOf('.'))
      setIsFileLoading(true)
      awsUpload(blob, fileName).send(async (err) => {
        if (err) {
          createToast('error', err)
        } else {
          const result = await submitFile(fileName)
          setIsFileLoading(false)

          if (result?.data?.message === 'success') {
            openFileModal()
          }
        }

        refFile.current.value = ''
      })
    }
  }

  return (
    <>
      <SettingsAdditionalAddModalForm
        isOpen={additionalModal === 'add'}
        closeModal={closeAdditionalModal}
        onSubmit={submitEditLocationModal}
        locations={locations}
      />
      <SettingsAdditionalEditModalForm
        isOpen={additionalModal === 'edit'}
        closeModal={closeAdditionalModal}
        onSubmit={submitEditLocationModal}
        locations={locations}
        item={editItem}
      />
      <SettingsAdditionalDeleteModalForm
        isOpen={additionalModal === 'delete'}
        closeModal={closeAdditionalModal}
        onSubmit={submitDeleteLocationModal}
        item={editItem}
      />
      {/* <SettingsFunnelModalForm
        isOpen={modal === 'funnel'}
        closeModal={closeModal}
        items={selectedFunnelItems}
        onSubmit={submitFunnel}
      /> */}
      <SettingsLocationsModalForm
        isOpen={modal === 'locations'}
        closeModal={closeModal}
        items={locations}
        openAddAdditional={openAdditionalAddModal}
        openEditAdditional={openAdditionalEditModal}
        openDeleteAdditional={openAdditionalDeleteModal}
      />
      <SettingsAddModalForm
        isOpen={modal === 'add'}
        closeModal={closeModal}
        onSubmit={submitAddForm}
        locations={locations}
        orgId={orgId}
      />
      <SettingsEditModalForm
        isOpen={modal === 'edit'}
        closeModal={closeModal}
        onSubmit={submitEditForm}
        locations={locations}
        user={changingUser}
        userCoaches={userCoaches}
        changeCoach={changeCoach}
        getUserCoach={getUserCoach}
        isCoachLoading={isCoachLoading}
        rows={rows}
        managerCount={users && users.list.filter((emp) => emp.role === 'Sales Manager').length}
      />
      <SettingsDeleteModalForm
        isOpen={modal === 'delete'}
        closeModal={closeModal}
        onSubmit={submitDeleteForm}
        user={changingUser}
      />
      <SettingsResetModalForm
        isOpen={modal === 'reset'}
        closeModal={closeModal}
        onSubmit={submitResetForm}
        user={changingUser}
      />

      {isFileLoading ? (
        <ModalWindow isOpen={isFileLoading} closeOnOverlay={false}>
          <Loader />
        </ModalWindow>
      ) : (
        <ModalImageWindow
          isOpen={fileModalIsOpen}
          handleClose={closeFileModal}
          closeOnOverlay
          title={error || 'Awesome! Your file is uploaded'}
          imageSrc={!error && modalImage}
        />
      )}

      <HeadingSection>
        <HeadingContent>
          <Title bold fontSize={9}>
            Settings
          </Title>
          {/* <CheckboxWrapper>
            <Checkbox
              checked={isChecked}
              onChange={handleCheck}
              size={20}
              label="Managers can see entire org’s sales data"
            />
            <InfoCircle>
              By default, managers can only see data for their stores and their
              employees.
            </InfoCircle>
          </CheckboxWrapper> */}
        </HeadingContent>
      </HeadingSection>
      <ContentSection>
        <SearchBlock>
          <Search onChange={debounceSearch} width="315px" />
          <UploadButtonWrapper>
            <input
              type="file"
              accept=".xlsx"
              onChange={uploadFile}
              ref={refFile}
            />
            <UploadButton>
              <Icon src={uploadIcon} />
              Upload spreadsheet
            </UploadButton>
          </UploadButtonWrapper>
          <Button onClick={openAddModal} width="180px">
            <Icon src={addUserIcon} />
            Add Employee
          </Button>
        </SearchBlock>
        <TableBlock>
          {(isUsersLoading || isRequestLoading) && (
            <TableImageBlockWrapper>
              <Loader />
            </TableImageBlockWrapper>
          )}
          {!isUsersLoading &&
            !isRequestLoading &&
            (rows.length ? (
              <Table
                wide
                noBorder
                align="left"
                data={rows}
                columns={settingsColumns}
              />
            ) : (
              <TableImageBlockWrapper>
                <ImageBlock
                  image={tableImage}
                  imageWidth="337px"
                  subtitle="Your employees will be shown here."
                  padding={2}
                />
              </TableImageBlockWrapper>
            ))}
        </TableBlock>
      </ContentSection>
      {!isChecked && (
        <AdminSection>
          <AdminSectionItem>
            <TitleWrapper>
              <Title bold fontSize={7}>
                Funnel
              </Title>
              {/* <Button
                height="24px"
                width="70px"
                fontSize={5}
                onClick={openFunnelModal}
              >
                <Icon src={editIcon} margin={1} size={14} />
                Edit
              </Button> */}
            </TitleWrapper>
            <Divider size={1} margin={5} />
            {isFunnelLoading ? (
              <Loader />
            ) : (
              <AdminItemContent>
                <Funnel>
                  {salesFunnelArray.map(
                    (item, index) =>
                      item && (
                        <>
                          {index === 2 ? (
                            <div style={{ width: `${370 - index * 36}px` }}>
                              <Grid
                                columns={['1/2', '1/2']}
                                gap="small"
                                width={370 - index * 36}
                              >
                                <FunnelItem key={salesFunnelArray[index]?.name}>
                                  {salesFunnelArray[index]?.name}
                                </FunnelItem>
                                <FunnelItem
                                  key={salesFunnelArray[index + 1]?.name}
                                >
                                  {salesFunnelArray[index + 1]?.name}
                                </FunnelItem>
                              </Grid>
                            </div>
                          ) : (
                            <FunnelItem
                              key={salesFunnelArray[index]?.name}
                              width={370 - index * 36}
                            >
                              {item.order === 2.1 ? 'Total Store Visits' : salesFunnelArray[index]?.name}
                            </FunnelItem>
                          )}
                        </>
                      )
                  )}
                </Funnel>
              </AdminItemContent>
            )}
          </AdminSectionItem>
          <AdminSectionItem>
            <TitleWrapper>
              <Title bold fontSize={7}>
                Locations
              </Title>
              <Button
                height="24px"
                width="70px"
                fontSize={5}
                onClick={openLocationsModal}
              >
                <Icon src={editIcon} margin={1} size={14} />
                Edit
              </Button>
            </TitleWrapper>
            <Divider size={1} margin={5} />
            {isOrgLoading ? (
              <Loader />
            ) : (
              <AdminItemContent>
                <Locations>
                  {locations.map((item) => (
                    <Location key={item}>
                      <Icon src={homeIcon} margin={0} />
                      {item}
                    </Location>
                  ))}
                </Locations>
              </AdminItemContent>
            )}
          </AdminSectionItem>
        </AdminSection>
      )}
    </>
  )
}
