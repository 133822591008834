import React from 'react'

import { SubscriptionBoxPrice } from './styles'

export function PlanPrice({ paymentFrequencyFlag, data, planPrice }) {
  return (
    <>
      <SubscriptionBoxPrice>
        {!planPrice &&
        paymentFrequencyFlag === 'yearly' &&
        data.title === 'Elite' ? (
          <span>${data.pricing.yearly['100+'].toFixed(2)}</span>
        ) : !planPrice &&
          paymentFrequencyFlag === 'monthly' &&
          data.title === 'Elite' ? (
          <span>${data.pricing.monthly['100+'].toFixed(2)}</span>
        ) : !planPrice &&
          paymentFrequencyFlag === 'yearly' &&
          data.title === 'Pro' ? (
          <span>${data.pricing.yearly['31-50'].toFixed(2)}</span>
        ) : !planPrice &&
          paymentFrequencyFlag === 'monthly' &&
          data.title === 'Pro' ? (
          <span>${data.pricing.monthly['31-50'].toFixed(2)}</span>
        ) : (
          planPrice.toFixed(2)
        )}
      </SubscriptionBoxPrice>
      <span className="subscription-box-frequency"> {data.frequency}</span>
    </>
  )
}
