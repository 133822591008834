import styled, { css } from 'styled-components'

export const PricingTable = styled.div`
  height: 9.5rem;
  table {
    width: 100%;
  }

  table th {
    color: #fff;
    font-weight: 600;
    padding: 0;
    border: none;
  }

  table td {
    background: none !important;
    color: #f3f4f6;
    border: none !important;
    padding: 0px;
    font-size: 12px;
    line-height: inherit;
  }
  table td:last-child {
    text-align: right;
  }

  .subscription-box-price-before {
    text-decoration-line: line-through;
    color: #9ca3af;
    padding-right: 10px;
  }

  .subscription-box-price-after {
    color: #5cae6d;
  }
`
