import styled from 'styled-components'
import { Link as BaseLink } from 'react-router-dom'

import arrowIcon from '@/assets/icons/arrow.svg'

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes[6]}px;
  gap: 5px;

  &:hover {
    filter: brightness(0.7);
  }
`

export const Arrow = styled.img.attrs({
  src: arrowIcon,
})``

export const Link = styled(BaseLink)`
  color: ${({ theme, variant }) => {
    switch (variant) {
      case 'dark':
        return theme.colors.backgroundInactive
      default:
        return theme.colors.backgroundActive
    }
  }};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  margin: ${({ theme, margin }) => theme.spaces[margin]}px 0;
  text-decoration: none;
  text-transform: capitalize;
  transition: border 100ms ease-out;
`
