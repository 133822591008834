import { salesFunnelValues } from '@/constants/sales-funnel'

const getMonth = (data) =>
  +data.substring(data.indexOf('-') + 1, data.lastIndexOf('-'))

export const formatGraphRange = (data, total, funnelData) => {
  const formattedData = []

  const lastMonthValues = {
    name: data.startDate,
    month: getMonth(data.startDate),
  }
  const goalRangeValues = {
    name: data.endDate,
    month: getMonth(data.endDate),
  }

  if (!data.prevRangeProgress?.length) {
    funnelData?.forEach((item) => {
      if (item.name === 'Leads & Opportunities') {
        lastMonthValues.Leads = 1
        return null
      }
      if (item.name === 'Appointment Shows') {
        lastMonthValues.Shows = 1
        return null
      }
      lastMonthValues[item.name] = 1
      return null
    })
  } else {
    data.prevRangeProgress?.forEach((item) => {
      if (item.name === 'Leads & Opportunities') {
        lastMonthValues.Leads = item.value / total
        return null
      }
      if (item.name === 'Appointment Shows') {
        lastMonthValues.Shows = item.value / total
        return null
      }
      lastMonthValues[item.name] = item.value / total
      return null
    })
  }

  if (!data.goalRange?.length) {
    funnelData?.forEach((item) => {
      if (item.name === 'Leads & Opportunities') {
        goalRangeValues.Leads = 1
        return null
      }
      if (item.name === 'Appointment Shows') {
        goalRangeValues.Shows = 1
        return null
      }
      goalRangeValues[item.name] = 1
      return null
    })
    goalRangeValues.empty = true
  } else
    data.goalRange?.forEach((item) => {
      if (item.name === 'Leads & Opportunities') {
        goalRangeValues.Leads = item.value / total
        return null
      }
      if (item.name === 'Appointment Shows') {
        goalRangeValues.Shows = item.value / total
        return null
      }
      goalRangeValues[item.name] = item.value / total
      return null
    })

  formattedData.push(lastMonthValues)
  formattedData.push(goalRangeValues)

  return formattedData
}

export const formatFunnelGraphRange = (data, field, total) => {
  const firstDate = new Date(data.startDate).getTime()
  const lastDate = new Date(data.endDate).getTime()

  const formattedData = [
    {
      name: firstDate,
      Goal: 0,
      Actual: 0,
      'Last Period': 0,
    },
  ]

  const endDateValue = {
    name: lastDate,
    Goal: 0,
    Actual: 0,
    'Last Period': 0,
  }

  data.prevRangeProgress?.forEach((item) => {
    if (item.name === field) {
      endDateValue['Last Period'] =
        ((item.value / lastDate) * firstDate) / total
    }
    return null
  })

  if (!data.prevRangeProgress?.length) endDateValue['Last Period'] = 0

  data.rangeProgress?.forEach((item) => {
    if (item.name === field) {
      endDateValue.Actual = ((item.value / lastDate) * firstDate) / total
    }
    return null
  })

  if (!data.rangeProgress?.length) endDateValue.Actual = 0

  data.goalRange?.forEach((item) => {
    if (item.name === field) {
      endDateValue.Goal = ((item.value / lastDate) * firstDate) / total
    }
    return null
  })

  if (!data.rangeProgress?.length) endDateValue.Goal = 0

  formattedData.push(endDateValue)

  const values = {
    name: lastDate,
    Goal: 0,
    Actual: 0,
    'Last Period': 0,
  }

  data.prevRangeProgress?.forEach((item) => {
    if (item.name === field) {
      values['Last Period'] = item.value / total
    }
    return null
  })

  if (!data.prevRangeProgress?.length) values['Last Period'] = 0

  data.rangeProgress?.forEach((item) => {
    if (item.name === field) {
      values.Actual = item.value / total
    }
    return null
  })

  if (!data.rangeProgress?.length) values.Actual = 0

  data.goalRange?.forEach((item) => {
    if (item.name === field) {
      values.Goal = item.value / total || 0
    }
    return null
  })

  if (!data.goalRange?.length) values.Goal = 0

  formattedData.push(values)

  return formattedData
}

export const formatGoalsGraphRange = (data, total, funnelData) => {
  const values = []

  let rangeProgressValues =
    data.rangeProgress
      ?.map((item) => {
        return {
          title: item.name,
          result: Math.trunc(item.value / total),
          order: salesFunnelValues.indexOf(item.name),
        }
      })
      .sort((a, b) => a.order - b.order) || []

  if (!rangeProgressValues.length) {
    rangeProgressValues = funnelData?.map((item) => ({
      title: item.name,
      result: 0,
    }))
  }

  let goalRangeValues =
    data.goalRange
      ?.map((item) => {
        return {
          title: item.name,
          goal: Math.trunc(item.value / total),
          order: salesFunnelValues.indexOf(item.name),
        }
      })
      .sort((a, b) => a.order - b.order) || []

  if (!goalRangeValues.length) {
    goalRangeValues = funnelData?.map((item) => ({
      title: item.name,
      goal: 0,
    }))
  }

  rangeProgressValues.forEach((item, index) => {
    values.push({
      title: item.title,
      result: item.result,
      goal: goalRangeValues[index]?.goal || 0,
    })
  })

  return values
}
