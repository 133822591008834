import React from 'react'
import { Formik } from 'formik'

import { ModalWindow } from '@/components/ModalWindow'
import { Button } from '@/components/Button'
import { Title } from '@/components/Title'
import { CloseButton } from '@/components/CloseButton'
import { Subtitle } from '@/components/Subtitle'
import { InputField } from '@/fields/Input'
import * as Yup from 'yup'

import { TitleWrapper, SubtitleWrapper, ModalButtonsWrapper } from './styles'
import { Box } from 'grommet'
import {
  getMaxLengthErrorMessage,
  getMinLengthErrorMessage,
  REQUIRED_FIELD_ERROR_MESSAGE,
} from '@/constants/error-messages'
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '@/constants/forms'

export const validationSchema = Yup.object().shape({
  password: Yup.string()
	.required(REQUIRED_FIELD_ERROR_MESSAGE)
	.min(MIN_PASSWORD_LENGTH, getMinLengthErrorMessage(MIN_PASSWORD_LENGTH))
	.max(MAX_PASSWORD_LENGTH, getMaxLengthErrorMessage(MAX_PASSWORD_LENGTH))
	.matches(/^(?=.*[a-z])/, 'Must must contain at least one lowercase character')
	.matches(/^(?=.*[A-Z])/, 'Must must contain at least one uppercase character')
	.matches(/^(?=.*[0-9])/, 'Must must contain at least one numeric character'),
})

export function SettingsResetModalForm({ isOpen, closeModal, onSubmit, user }) {
  const id = { id: user?.fbUid }
  return (
    <ModalWindow
      additional
      isOpen={isOpen}
      handleClose={closeModal}
      closeOnOverlay={false}
      width={450}
      padding={24}
    >
      <CloseButton onClick={closeModal} />
			<TitleWrapper marginBottom={3}>
				<Title bold fontSize={7}>
					Reset Password
				</Title>
			</TitleWrapper>
			<SubtitleWrapper>
				<Subtitle as="p" margin={5}>
					Are you sure you want to{' '}
					<span>
						reset the password for{' '}
						{user?.name || `${user?.firstName} ${user?.lastName}`}?
					</span>
				</Subtitle>
			</SubtitleWrapper>
      <Formik initialValues={id} validationSchema={validationSchema} onSubmit={onSubmit}>
				{({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
						<Box style={{ margin: '20px 0' }}>
							<InputField name="password" label="New Password" placeholder="Enter Password" />
							<Button type="submit" width="200px" style={{ marginTop: '10px' }} fontSize={5}>
								Reset Password
							</Button>
						</Box>
					</form>
				)}
			</Formik>
			<Subtitle center as="p" margin={5}>Or</Subtitle>
      <Formik initialValues={id} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalButtonsWrapper>
              <Button type="submit" width="200px" fontSize={5}>
                Send Reset Password Link
              </Button>
              <Button
                gray
                color="headerText"
                width="80px"
                fontSize={5}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </ModalButtonsWrapper>
          </form>
        )}
      </Formik>
    </ModalWindow>
  )
}
