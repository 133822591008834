import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: ${({ theme, margin }) =>
      margin !== undefined ? theme.spaces[margin] : theme.spaces[3]}px
    0;
  width: ${({ width }) => width || '100%'};
`

export const Line = styled.hr`
  border: none;
  border-top: ${({ theme, color, size }) => `${size || 2}px solid
      ${theme.colors[color] || theme.colors.backgroundInactive}`};
`
