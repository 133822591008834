import axios from 'axios'
import { useQuery } from 'react-query'

import { GET_INFO_ENDPOINT } from '@/constants/endpoints'
import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'

export const useMeQuery = (options) =>
  useQuery(
    GET_INFO_ENDPOINT,
    async () => {
      const { data } = await axios.get(GET_INFO_ENDPOINT, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            AUTH_TOKEN_STORAGE_KEY
          )}`,
        },
      })

      return data
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      ...options,
    }
  )
