import React, { useState } from 'react'

import Layout from '@/components/Layout'
import { AddManagersForm } from '@/forms/AddManagers'
import { useUsersMutation } from '@/queries/users'
import { useAddUserMutation } from '@/queries/add-user'
import { useEditUserMutation } from '@/queries/edit-user'
import backgroundImage from '@/assets/images/layout-background.png'
import { createToast } from '@/helpers/createToast'
import { useOrgDataQuery } from '@/queries/get-org'

export default function AddManagers() {
  const [error, setError] = useState(false)

  const { data: orgData, isLoading: orgDataLoad } = useOrgDataQuery()

  const {
    mutate: fetchData,
    data,
    isLoading: managersLoad,
  } = useUsersMutation()

  const { mutate: addManager, isLoading: addManagerLoad } = useAddUserMutation({
    onSuccess: () => {
      createToast('success', 'Manager added successfully!')
    },
    onError: (err) => {
      setError(err.response.data.message)
    },
  })
  const { mutate: editManager, isLoading: editManagerLoad } =
    useEditUserMutation({
      onSuccess: () => {
        createToast('success', 'Manager data changed successfully!')
      },
      onError: (err) => {
        setError(err.response.data.message)
      },
    })

  return (
    <Layout logo justify="start" pt={28} background={backgroundImage}>
      <AddManagersForm
        onSubmit={addManager}
        onModalSubmit={editManager}
        fetchData={fetchData}
        data={data && data.data}
        locations={orgData && orgData.locations}
        isLoading={
          managersLoad || addManagerLoad || editManagerLoad || orgDataLoad
        }
        error={error}
        setError={setError}
      />
    </Layout>
  )
}
