import styled from 'styled-components'

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
  flex-direction: column;
  max-width: 500px;
  min-width: 300px;
  padding: 0 ${({ theme }) => theme.spaces[13]}px;
  position: relative;
  width: 59%;

  ${({ theme }) => theme.below.tablet`
    width: 80%;
  `}
`

export const MobileLogoWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces[9]}px;
  max-width: 298px;

  @media (max-width: 768px) and (max-height: 750px) {
    margin-top: ${({ theme }) => theme.spaces[7]}px;
    width: 200px;
  }
`
