import React, { useCallback, useEffect, useState } from 'react'
import { Box } from 'grommet'

import { Title } from '@/components/Title'
import { Button } from '@/components/Button'
import { Divider } from '@/components/Divider'
import { Subtitle } from '@/components/Subtitle'
import { CloseButton } from '@/components/CloseButton'
import { ModalWindow } from '@/components/ModalWindow'
import { salesFunnelArray } from '@/constants/sales-funnel'

import { Input, InputBlock, FieldWrapper, ButtonsWrapper } from './styles'

export function SettingsFunnelModalForm({
  isOpen,
  closeModal,
  onSubmit,
  items,
}) {
  const [selectedValues, setSelectedValues] = useState()

  useEffect(() => {
    setSelectedValues(items)
  }, [items])

  const selectHandler = useCallback(
    (id) => {
      setSelectedValues((prevValues) =>
        prevValues.map((value, index) => (index === id ? !value : value))
      )
    },
    [selectedValues]
  )

  const submitItems = useCallback(() => {
    onSubmit(selectedValues)
    closeModal()
  }, [selectedValues])

  return (
    <ModalWindow
      isOpen={isOpen}
      handleClose={closeModal}
      width={450}
      padding={24}
    >
      <CloseButton onClick={closeModal} />
      <Title bold fontSize={8} marginBottom={3}>
        Edit Funnel Items
      </Title>
      <Subtitle margin={7}>
        Select the steps that you want your organization to track.
      </Subtitle>
      <InputBlock>
        {salesFunnelArray.map((item, index) => (
          <FieldWrapper width={390 - index * 36} key={item.name}>
            <Box
              focusIndicator={false}
              width="100%"
              onClick={() => selectHandler(index)}
            >
              <Input
                green={
                  index !== 6 && selectedValues ? selectedValues[index] : true
                }
                disabled
                value={item.name}
              />
            </Box>
          </FieldWrapper>
        ))}
      </InputBlock>
      <Divider size={1} margin={5} />
      <ButtonsWrapper>
        <Button onClick={submitItems} width="65px" fontSize={5}>
          Save
        </Button>
        <Button
          gray
          color="headerText"
          width="80px"
          fontSize={5}
          onClick={closeModal}
        >
          Cancel
        </Button>
      </ButtonsWrapper>
    </ModalWindow>
  )
}
