import styled from 'styled-components'

import contentSizes from '@/constants/content-sizes'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.white};
  justify-self: start;
  margin-bottom: ${({ theme }) => theme.spaces[5]}px;
  max-width: ${() => contentSizes.large}px;
  padding: ${({ theme }) => theme.spaces[6]}px 0;
  width: 90%;
`

export const ArrowLinkContainer = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spaces[5]}px;
  max-width: ${() => contentSizes.large}px;
  width: 90%;
`

export const ContinueButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.spaces[5]}px;
  max-width: ${() => contentSizes.large}px;
  width: 90%;
`

export const TitleBlock = styled.div`
  margin: 0 ${({ theme }) => theme.spaces[6]}px;
`

export const ContentBlock = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.backgroundItems};
  display: flex;
  padding: 24px 24px 0;
`

export const TableWrapper = styled.div`
  align-items: 'center';
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
`
