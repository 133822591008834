import axios from 'axios'
import { useMutation } from 'react-query'

import { USERS_CROSS_ORG_ENDPOINT } from '@/constants/endpoints'

export const useUsersCrossOrgMutation = (options) =>
  useMutation(
    (payload = { skip: 0, limit: 10, search: '' }) =>
      axios.post(USERS_CROSS_ORG_ENDPOINT, payload),
    options
  )
