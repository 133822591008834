/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-param-reassign */
import axios from 'axios'
import React, { useContext, createContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'
import { GET_INFO_ENDPOINT } from '@/constants/endpoints'
import { HTTP_STATUS_UNAUTHORIZED } from '@/constants/http-status'
import { createToast } from '@/helpers/createToast'
import { useSignInMutation } from '@/queries/sign-in'
import {
  DASHBOARD_ROUTE,
  CONFIRM_YOUR_PLAN_ROUTE,
  CREATE_ORGANIZATION_ROUTE,
} from '@/constants/routes'

const DEFAULT_TOKEN = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY) || ''

const DEFAULT_STATE = {
  authorized: false,
}

const AuthContext = createContext(DEFAULT_STATE)

export function AuthProvider({ children }) {
  const [authorized, setAuthorized] = useState(Boolean(DEFAULT_TOKEN))
  const [token, setToken] = useState(DEFAULT_TOKEN)
  const [route, setRoute] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)

  useEffect(() => {
    axios
      .get(GET_INFO_ENDPOINT, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        const { data } = response

        if (!data.isReg && !data.isPaid) {
          setRoute(CONFIRM_YOUR_PLAN_ROUTE)
        }
        if (!data.isReg && data.isPaid) {
          setRoute(CREATE_ORGANIZATION_ROUTE)
        }
        if (data.isReg && data.isPaid) {
          setRoute(DASHBOARD_ROUTE)
        }
      })
      .catch((error) => {
        if (
          error.status === HTTP_STATUS_UNAUTHORIZED ||
          error.response.status === HTTP_STATUS_UNAUTHORIZED
        ) {
          setAuthorized(false)
          localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, '')
        }
      })
  }, [])

  useEffect(() => {
    const interceptorRequestId = axios.interceptors.request.use(
      (config) => {
        if (!config.headers.Authorization)
          config.headers.Authorization = token ? `Bearer ${token}` : ''

        return config
      },
      (error) => Promise.reject(error)
    )

    // @todo qa for this case
    const interceptorResponseId = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          (typeof error.response.data?.message === 'string' &&
            error.response.data?.message ===
              "Cannot read property 'coachers' of null") ||
          (typeof error.response.data?.message === 'string' &&
            error.response.data?.message ===
              "Cannot read properties of null (reading 'coachers')")
        ) {
          return null
        }
        if (
          typeof error.response.data?.message === 'string' &&
          error.response.data?.message?.startsWith('Firebase token')
        ) {
          localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, '')
          return createToast('error', 'Please log in.')
        }
        if (error.response.status === HTTP_STATUS_UNAUTHORIZED) {
          setAuthorized(false)
          localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, '')
          return createToast('Not authorized.')
        }
        createToast('error', error.response.data.message)
        throw error
      }
    )
    setLoading(false)

    return () => {
      axios.interceptors.request.eject(interceptorRequestId)
      axios.interceptors.response.eject(interceptorResponseId)
      setLoading(true)
    }
  }, [token])


  const { mutate: signIn } = useSignInMutation({
    onSuccess: ({
      user: { accessToken },
      route: fetchedRoute,
      usrIsSuperAdmin: is,
    }) => {
      setIsSuperAdmin(is)
      setToken(accessToken)
      setRoute(fetchedRoute)
      setAuthorized(true)
      localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, accessToken)
    },
    onError: () => {
      toast.error('Log In failed.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      })
    },
  })

  const values = {
    token,
    route,
    authorized,
    signIn,
    isLoading,
    isSuperAdmin,
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
