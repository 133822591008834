import React from 'react'

import { DualScreenLayout } from '@/components/DualScreenLayout'
import backgroundImage from '@/assets/images/signUp-background.svg'
import welcomeImage from '@/assets/images/welcome-image.svg'
import { WelcomeForm } from '@/forms/Welcome'

export default function Welcome() {
  return (
    <DualScreenLayout
      image={welcomeImage}
      imageWidth="73%"
      background={backgroundImage}
    >
      <WelcomeForm />
    </DualScreenLayout>
  )
}
