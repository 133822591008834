import axios from 'axios'
import { useMutation } from 'react-query'

import { DASHBOARD_TABLE_DATA_RANGE_ENDPOINT } from '@/constants/endpoints'

export const useTableDataRangeMutation = (options) => {
  return useMutation(
    (payload = { date: '2022-04', skip: 0, limit: 1000000, search: '' }) =>
      axios.post(DASHBOARD_TABLE_DATA_RANGE_ENDPOINT, {
        ...payload,
        limit: 1000000,
      }),
    options
  )
}
