/* eslint-disable no-console */
import axios from 'axios'
import { useMutation } from 'react-query'

import { GET_USER_COACHES_ENDPOINT } from '@/constants/endpoints'

export const useGetUserCoachMutation = () =>
  useMutation((payload) => {
    const data = axios
      .get(`${GET_USER_COACHES_ENDPOINT}/${payload.fbUid}`)
      .catch((error) =>
        error.response.status === 400 ? [] : console.log(error)
      )
    return data
  })
