import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useOrganizationImpersonate = (orgId) => {
  const { search } = useLocation()
  const showImpersonate = new URLSearchParams(search).get('showImpersonate')
  const organizationIdInUrl = new URLSearchParams(search).get('orgId')

  const organizationId = useMemo(() => {
    return !isEmpty(organizationIdInUrl) ? organizationIdInUrl : orgId
  }, [organizationIdInUrl, orgId])

  return {
    search,
    organizationId,
    showImpersonate: Boolean(showImpersonate),
  }
}
