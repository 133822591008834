import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
`

export const HeadingSection = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundInactive};
  display: flex;
  height: 76px;
  padding-left: ${({ theme }) => theme.spaces[6]}px;
  width: 100%;
`

export const ContentSection = styled.div`
  align-items: stretch;
  display: flex;
  gap: ${({ theme }) => theme.spaces[3]}px;
  min-height: 700px;

  ${({ theme }) => theme.below.tablet`
  flex-direction: column-reverse;
    width: 100%;
  `}
`

export const TableBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.promptText};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spaces[5]}px;
  padding: ${({ theme }) => theme.spaces[6]}px;
`

export const TableImageBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spaces[10]}px;
`

export const PaymentBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 327px;
  padding: 20px 16px;
  width: 100%;

  ${({ theme }) => theme.below.tablet`
    max-width: 100%;
  `}
`

export const PaymentTitleBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const PaymentItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[4]}px;
  margin-bottom: auto;

  ${({ theme }) => theme.below.tablet`
    margin-bottom: ${theme.spaces[5]}px;
  `}
`

export const PaymentItem = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundItems};
  border-radius: ${({ theme }) => theme.spaces[4]}px;
  display: flex;
  height: 92px;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spaces[6]}px;
  width: 100%;
`

export const PaymentItemValue = styled.div`
  display: flex;
  flex-direction: column;
`

export const PaymentItemIcon = styled.img`
  height: 24px;
  width: 36px;
`

export const PaymentImageBlockWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spaces[10]}px;
`

export const IconButton = styled.button`
  align-items: center;
  background: none;
  color: ${({ theme }) => theme.colors.backgroundActive};
  display: flex;
  gap: 4px;
  height: 18px;
  text-decoration: underline;
  text-underline-offset: 2px;

  &:hover {
    filter: brightness(0.8);
  }
`
