import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'
import { UPDATE_PAYMENT_STATUS } from '@/constants/endpoints'
import axios from 'axios'
import { useMutation } from 'react-query'

export const useUpdatePaymentStatusMutation = (options) =>
  useMutation(
    (payload) =>
      axios.patch(`${UPDATE_PAYMENT_STATUS}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            AUTH_TOKEN_STORAGE_KEY
          )}`,
        },
      }),
    options
  )
