import styled from 'styled-components'

import { rgbToRgba } from '@/helpers/rgbToRgba'

export const Wrapper = styled.div`
  tspan,
  span,
  text {
    fill: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fontFamilies.Oswald};
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }

  tspan {
    font-size: 12px;
  }

  .recharts-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .recharts-legend-wrapper {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    width: 100% !important;
  }

  .recharts-layer {
    margin-bottom: 20px;
  }

  .recharts-layer > svg > text {
    font-size: 15px;
  }

  .xAxis {
    tspan {
      font-size: 15px;
    }
  }

  .yAxis {
    tspan {
      font-size: 15px;
    }
  }
`

export const LegendWrapper = styled.div`
  align-self: center;
  margin-left: 10px;
`

export const LegendRow = styled.div`
  display: flex;
  gap: ${({ singleLine }) => singleLine && 16}px;
  height: 0px;
  justify-content: center;
  width: ${({ singleLine }) => (singleLine ? 230 : 255)}px;
`

export const LegendItem = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-family: ${({ theme }) => theme.fontFamilies.Oswald};
  font-size: 15px;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  width: ${({ singleLine }) => (singleLine ? 'auto' : '85px')};
`

export const LegendCircle = styled.div`
  background-color: ${({ color }) => rgbToRgba(color, '0.4')};
  border: 1px solid ${({ color }) => color};
  border-radius: 5px;
  height: 10px;
  margin-right: ${({ theme }) => theme.spaces[2]}px;
  width: 10px;
`

export const IncomeLine = styled.div`
  border-top: 1px dashed #ffffff;
  margin-right: ${({ theme }) => theme.spaces[2]}px;
  width: 10px;
`
