import { useUsersMutation } from '@/queries/users'
import React, { useCallback, useEffect, useState } from 'react'
import { Formik } from 'formik'
import axios from 'axios'

import { GET_USERS_ENDPOINT } from '@/constants/endpoints'
import { fetchEmployeesConfig } from '@/constants/fetch-data-config'
import { ADD_MANAGERS_ROUTE, REVIEW_INFO_ROUTE } from '@/constants/routes'
import { employeeColumns } from '@/constants/table-columns'
import plusIcon from '@/assets/icons/plus.svg'
import emptyImage from '@/assets/images/add-employees.png'
import { Icon } from '@/components/Icon'
import { Table } from '@/components/Table'
import { Title } from '@/components/Title'
import { Button } from '@/components/Button'
import { Loader } from '@/components/Loader'
import { InfoCircle } from '@/components/InfoCircle'
import { ImageBlock } from '@/components/ImageBlock'
import { Pagination } from '@/components/Pagination'
import { ProgressBar } from '@/components/ProgressBar'
import { DualSection } from '@/components/DualSection'
import { ExpertiseField } from '@/components/Expertise'
import { InputField } from '@/fields/Input'
import { SelectField } from '@/fields/Select'
import { CheckboxField } from '@/fields/Checkbox'
import { MaskedInputField } from '@/fields/MaskedInput'
import { SelectSearchField } from '@/fields/SelectSearch'
import { AddEmployeesModalForm } from '@/forms/AddEmployeesModal'
import { formatData, formatSearchUsers } from '@/helpers/formatData'
import { createToast } from '@/helpers/createToast'

import { validationEmployeeSchema, DEFAULT_VALUES } from './config'
import {
  TitleWrapper,
  ContactIcon,
  CheckboxWrapper,
  TableSection,
  TableWrapper,
} from './styles'

export function AddEmployeesForm({
  initialValues,
  fetchData,
  data,
  onModalSubmit,
  locations,
  onSubmit,
  isLoading,
  error,
  setError,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [rows, setRows] = useState([])
  const [changingUser, setUser] = useState()
  const [config, setConfig] = useState(fetchEmployeesConfig)
  const [pages, setPages] = useState(1)
  const [currentPage, setPage] = useState(1)
  const [isRequestLoading, setRequestLoading] = useState(false)
  const [managers, setManagers] = useState([])

  let searchTimeout

  const fetchUsers = useCallback((manualConfig) => fetchData(manualConfig), [])
  const openModal = useCallback(() => setModalIsOpen(true), [])
  const closeModal = useCallback(() => setModalIsOpen(false), [])

  const openEditModalHandler = useCallback((user) => {
    openModal()
    setUser(user)
  }, [])
  const closeEditModalHandler = useCallback(() => {
    closeModal()
    setUser(false)
  }, [])

  const submitForm = useCallback(
    (formData) => {
      const formattedData = { ...formData }
      formattedData.role = 'Sales Person'
      setRequestLoading(true)
      onSubmit(formattedData)

      setTimeout(() => {
        fetchData(config)
        setRequestLoading(false)
      }, 1000)
    },
    [config, rows]
  )

  const submitModalForm = useCallback(
    (formData, users) => {
      const formattedData = { ...formData }
      onModalSubmit(formattedData)
      const res = users?.reduce(
        (arr, user) =>
          user.id !== formattedData.id
            ? [...arr, user]
            : [
                ...arr,
                {
                  firstName: formattedData.name.split(' ')[0],
                  lastName: formattedData.name.split(' ')[1] || '',
                  manager: formattedData.manager,
                  managerName: formattedData.managerName,
                  ...formattedData,
                },
              ],
        []
      )
      setRows(formatData(res, null, openEditModalHandler))
    },
    [config]
  )

  const handlePagination = useCallback((page) => {
    setPage(page)
    setConfig({
      skip: page * 10,
      limit: 1000,
      search: '',
      role: 'employee',
      originManager: false,
    })
  }, [])

  const loadOptions = useCallback(() => {
    clearTimeout(searchTimeout)
    searchTimeout = setTimeout(() => {
      axios
        .post(GET_USERS_ENDPOINT, {
          skip: 0,
          limit: 1000,
          search: '',
          role: 'manager',
          originManager: false,
        })
        .then(({ data: users }) => {
          setManagers({ data: { list: users.tempUsers } })
        })
    }, 1000)
  }, [])

  useEffect(() => {
    setPage(1)
    setConfig({
      skip: 0,
      limit: 1000,
      search: '',
      role: 'employee',
      originManager: false,
    })
    loadOptions()
  }, [])

  useEffect(() => {
    fetchUsers(config)
  }, [config])

  useEffect(() => {
    if (error) {
      if (typeof error === 'object') createToast('error', error[0])
      else createToast('error', error)
      fetchUsers(config)
    }
    setError(false)
  }, [error, setError, config])

  useEffect(() => {
    setRows(
      (data && formatData(data.tempUsers, null, openEditModalHandler)) || []
    )
  }, [data])

  useEffect(() => {
    setPages(data && data.total)
  }, [data])

  return (
    <>
      <ProgressBar active={3} />
      <AddEmployeesModalForm
        isManager={false}
        isOpen={modalIsOpen}
        onClose={closeEditModalHandler}
        onSubmit={submitModalForm}
        locations={locations}
        user={changingUser}
        rows={rows}
      />
      <DualSection
        tabs
        data={rows.length}
        title="Add Employees"
        subtitle="Add all employees who will be using the SOAR app"
        arrowLinkPath={ADD_MANAGERS_ROUTE}
        continuePath={REVIEW_INFO_ROUTE}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationEmployeeSchema}
            onSubmit={submitForm}
          >
            {({ values, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TitleWrapper>
                  <ContactIcon />
                  <Title bold fontSize={7}>
                    Add Employee
                  </Title>
                </TitleWrapper>
                <InputField
                  name="name"
                  label="NAME"
                  placeholder="Full name"
                  margin={12}
                />
                <InputField
                  type="email"
                  name="email"
                  label="EMAIL"
                  placeholder="test@soar.com"
                  margin={12}
                />
                <MaskedInputField
                  type="text"
                  name="phone"
                  label="MOBILE PHONE NUMBER"
                  placeholder="123-456-7890"
                  margin={12}
                />
                <SelectField
                  name="location"
                  label="LOCATION"
                  placeholder="Location"
                  options={
                    locations
                      ? locations.map((item) => {
                          return { value: item, label: item }
                        })
                      : []
                  }
                  margin={12}
                />
                <SelectSearchField
                  name="manager"
                  label="REPORTING MANAGER"
                  placeholder="None (top-level manager)"
                  loadOptions={loadOptions}
                  hideIndicator={false}
                  margin={12}
                  menuPlacement="top"
                  data={managers}
                />
                <CheckboxWrapper>
                  <CheckboxField
                    name="certifiedCoach"
                    label="Certified coach"
                  />
                  <InfoCircle right marginLeft={3}>
                    You can designate any employee as a certified coach if you
                    want them to mentor other employees.
                  </InfoCircle>
                </CheckboxWrapper>
                {values.certifiedCoach && <ExpertiseField values={values} />}
                <Button type="submit" fontSize={5} margin={{ top: '16px' }}>
                  <Icon src={plusIcon} />
                  Add Employees
                </Button>
              </form>
            )}
          </Formik>
        )}
        <TableSection>
          {(isLoading || isRequestLoading) && <Loader />}
          {!isLoading &&
            !isRequestLoading &&
            (rows.length ? (
              <TableWrapper>
                <Table columns={employeeColumns} data={rows} />
                {pages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    size={pages}
                    callback={handlePagination}
                  />
                )}
              </TableWrapper>
            ) : (
              <ImageBlock
                image={emptyImage}
                subtitle="Employees will appear here once you add them"
                imageWidth={100}
              />
            ))}
        </TableSection>
      </DualSection>
    </>
  )
}

AddEmployeesForm.defaultProps = {
  initialValues: DEFAULT_VALUES,
}
