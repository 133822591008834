import { ToastContainer as BaseContainer } from 'react-toastify'
import styled from 'styled-components'

export const ToastContainer = styled(BaseContainer)`
  .Toastify {
    &__toast-theme--dark {
      background: rgb(31, 41, 55);
    }
    &__toast--success {
      .Toastify__toast-icon > svg {
        fill: rgb(92, 174, 109);
      }
    }
    &__progress-bar--success {
      background: rgb(92, 174, 109);
    }
    &__toast--error {
      .Toastify__toast-icon > svg {
        fill: rgb(239, 68, 68);
      }
    }
    &__progress-bar--error {
      background: rgb(239, 68, 68);
    }
  }
`
