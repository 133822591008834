import {
  BILLING_ROUTE,
  DASHBOARD_ROUTE,
  SETTINGS_ROUTE,
  COACHING_ROUTE,
  ORGANIZATIONS_ROUTE,
} from '@/constants/routes'
import { superAdmin, admin, salesManager } from '@/constants/roles-constants'

export const headerLinks = [
  { label: 'Dashboard', path: DASHBOARD_ROUTE, roles: [admin, superAdmin, salesManager] },
  { label: 'Coaching', path: COACHING_ROUTE, roles: [admin, superAdmin, salesManager] },
  { label: 'Billing', path: BILLING_ROUTE, roles: [admin, superAdmin] },
  { label: 'Settings', path: SETTINGS_ROUTE, roles: [admin, superAdmin] },
]
