import React from 'react'
import Modal from 'react-modal'

import { theme } from '@/theme/baseTheme'
import { useMediaQuery } from '@/hooks/useMediaQuery'

export function ModalWindow({
  isOpen,
  handleClose,
  closeOnOverlay,
  padding,
  maxWidth,
  width,
  additional,
  children,
}) {
  const isDesktop = useMediaQuery('(min-width: 770px)')
  const isMobile = useMediaQuery('(max-width: 550px)')

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={closeOnOverlay}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.7)',
          overflow: 'hidden',
          zIndex: `${additional ? 7 : 6}`,
        },
        content: {
          backgroundColor: theme.colors.backgroundItems,
          border: 'none',
          borderRadius: 0,
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          maxHeight: `${isMobile && '100%'}`,
          maxWidth: `${!isMobile && maxWidth ? `${maxWidth}px` : '95%'}`,
          minWidth: `${isDesktop && `${width}px`}`,
          padding: `${padding || 28}px`,
          overflow: 'hidden',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      {children}
    </Modal>
  )
}
