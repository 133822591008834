import { createGlobalStyle, css } from 'styled-components'
import { grommet as baseTheme } from 'grommet'

const SCREEN_SIZES = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  mobileXL: 500,
  tablet: 769,
  laptop: 1024,
  laptopM: 1200,
  desktop: 2560,
}

const above = Object.keys(SCREEN_SIZES).reduce((acc, key) => {
  acc[key] = (...args) => css`
    @media (min-width: ${SCREEN_SIZES[key]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})

const below = Object.keys(SCREEN_SIZES).reduce((acc, key) => {
  acc[key] = (...args) => css`
    @media (max-width: ${SCREEN_SIZES[key]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const theme = {
  name: 'Dark',
  ...baseTheme,
  above,
  below,
  colors: {
    primaryText: 'rgb(249, 250, 251)',
    background: 'rgb(17, 24, 39)',
    percentage: 'rgb(156,163,175)',
    backgroundDark: 'rgb(13, 22, 34)',
    backgroundActive: 'rgb(92, 174, 109)',
    backgroundActiveDark: 'rgb(50, 97, 61)',
    backgroundActiveLight: 'rgb(114, 199, 135)',
    backgroundInactive: 'rgb(75, 85, 99)',
    backgroundInactiveDark: 'rgb(65, 75, 89)',
    backgroundItems: 'rgb(31, 41, 55)',
    border: 'rgb(107, 114, 128)',
    graphDivider: 'rgb(30, 42, 51)',
    tableBorder: 'rgb(55, 65, 81)',
    greenButton: 'rgb(0, 168, 67)',
    greenButtonLight: 'rgb(60, 228, 127)',
    subtitleText: 'rgb(229, 231, 235)',
    inactiveText: 'rgb(156, 163, 175)',
    headerText: 'rgb(156, 163, 175)',
    promptText: 'rgb(209, 213, 219)',
    increase: 'rgb(39, 200, 89)',
    decrease: 'rgb(223, 45, 25)',
    error: 'rgb(239, 68, 68)',
    errorActive: 'rgb(249, 108, 108)',
    black: 'rgb(0, 0, 0)',
    white: 'rgb(255, 255, 255)',
    beige: 'rgb(254, 243, 199)',
    grey: 'rgb(128 128 128)',
  },
  filter: {
    inactiveSvg: 'invert(79%) sepia(9%) saturate(350%) hue-rotate(179deg) brightness(81%) contrast(90%)',
  },
  spaces: [
    0, // 0
    2, // 1
    4, // 2
    8, // 3
    12, // 4
    16, // 5
    24, // 6
    32, // 7
    48, // 8
    56, // 9
    64, // 10
    128, // 11
    6, // 12
    20, // 13
  ],
  fontSizes: [
    2, // 0
    4, // 1
    8, // 2
    10, // 3
    12, // 4
    14, // 5
    16, // 6
    18, // 7
    20, // 8
    24, // 9
    28, // 10
    30, // 11
    32, // 12
    40, // 13
    48, // 14
  ],
  lineHeights: [
    4, // 0
    6, // 1
    12, // 2
    14, // 3
    18, // 4
    20, // 5
    22, // 6
    24, // 7
    28, // 8
    32, // 9
    36, // 10
    38, // 11
    40, // 12
    48, // 13
    58, // 14
  ],
  fontWeights: {
    light: '300',
    normal: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
  },
  fontStyles: {
    normal: 'normal',
    italic: 'italic',
  },
  fontFamilies: {
    default: "'Inter', sans-serif",
    Montserrat: "'Montserrat-Regular', sans-serif",
    Oswald: "'Oswald', sans-serif",
  },
  boxShadows: [
    '0px 2px 8px rgba(0, 0, 0, 0.15)', // 0
    '0 0 0 0.2rem rgba(84,160,99,.25)', // 1
  ],
}

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 12px;
  }
  *, *:after, *:before {
    box-sizing: inherit;
  }
  body {
    margin: 0;
    width: 100%;
    position: relative;
    padding: 0;
    height: 100%;
    font-family: ${theme.fontFamilies.default};
    font-size: ${theme.fontSizes[5]}px;
    background: ${theme.colors.background};
    div {
      font-family: ${theme.fontFamilies.default};
      font-size: ${theme.fontSizes[5]}px;
    }
  }
  h1,h2,h3,h4,h5,h6,p,ol,ul {
    padding: 0;
    margin: 0;
  }
  a {
    text-decoration: none;
    cursor: pointer;
  }
  button {
    padding: 0;
    outline: none;
    border: none;
    cursor: pointer;
  }
  .ReactModal__Body--open,
  .ReactModal__Html--open {
    overflow: hidden;
  }
`
