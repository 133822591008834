import styled from 'styled-components'

import { Title as BaseTitle } from '@/components/Title'
import contentSizes from '@/constants/content-sizes'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.primaryText};
  justify-self: start;
  margin-bottom: ${({ theme }) => theme.spaces[8]}px;
  max-width: ${({ size }) => contentSizes[size]}px;
  padding: ${({ padding }) => padding};
  width: 90%;

  ${({ theme }) => theme.below.laptop`
     padding: 30px 20px 20px;
  `}
`

export const Title = styled(BaseTitle)`
  font-size: ${({ theme, fontSize }) =>
    theme.fontSizes[fontSize] || theme.fontSizes[8]}px;
  margin-bottom: ${({ theme, margin }) =>
    theme.spaces[margin] || theme.spaces[6]}px;
`

export const ArrowLinkContainer = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spaces[5]}px;
  max-width: ${({ size }) => contentSizes[size]}px;
  width: 90%;
`
