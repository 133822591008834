import styled from 'styled-components'

export const TableWrapper = styled.div`
  min-width: 800px;
`

export const HeadingSection = styled.div`
  align-items: center;
  background: #1f2937;
  border: 1px solid #353e4b;
  border-radius: 8px 8px 0px 0px;
  display: grid;
  grid-template-columns: 20% 14% 15% 15% 20% 8% 8%;
`

export const HeaderItem = styled.div`
  align-items: center;
  border-right: 1px solid #353e4b;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  line-height: 20px;
  padding: 10px 20px 10px 40px;
  position: relative;
  text-align: center;
  &:last-child {
    border: none;
  }
`

export const IconWrapper = styled.div`
  margin-left: 8px;
  opacity: 0;
  right: 0px;
  top: calc(50% - 5px);
  transition: 0.3s;
  &.asc {
    margin-left: 0;
    margin-right: 8px;
    opacity: 1;
    transform: rotate(180deg);
  }
  &.desc {
    opacity: 1;
  }
`

export const OpenIconWrapper = styled.div`
  left: 25px;
  position: absolute;
  top: 15px;
  transition: 0.3s;
  &.open {
    top: 19px;
    transform: rotate(90deg);
  }
`

export const RowWrapper = styled.div`
  background: #111827;
  border-bottom: 1px solid #353e4b;
  display: grid;
  grid-template-columns: 20% 80%;
  transition: 0.3s;
  &.odd {
    background: #1f2937;
  }
  &.collapsed {
    height: 50px !important;
  }
  &.bold div {
    color: #fff;
    font-weight: 600;
  }
  &.divider {
    border-top: 2px solid #6b7280;
  }
  &.border {
    border-bottom: 0;
    border-radius: 0px 0px 8px 8px;
  }
`

export const BodySection = styled.div`
  border: 1px solid #353e4b;
  border-radius: 0px 0px 8px 8px;
  border-top: none;
`

export const CoachWrapper = styled.div`
  align-items: flex-start;
  border-right: 1px solid #353e4b;
  color: #72c787;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 65px;
  padding-top: 15px;
  position: relative;
  transition: padding-top 0.3s align-items 0s;
  &.collapsed {
    align-items: center;
    padding-top: 0;
  }
`

export const CoacheeWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

export const ItemWrapper = styled.div`
  border-bottom: 1px solid #353e4b;
  color: #f9fafb;
  display: grid;
  font-size: 14px;
  font-weight: 400;
  grid-template-columns: 17.5% 18.75% 18.75% 25% 10% 10%;
  height: 50px;
  height: 50px;
  line-height: 20px;
  overflow: hidden;
  transition: opacity 0.2s, height 0.3s;
  width: 100%;
  & > div {
    align-items: center;
    border-right: 1px solid #353e4b;
    display: flex;
    justify-content: center;
    max-height: 50px;
    padding: 5px 10px;
    text-align: center;
    text-align: center;
    &:last-child {
      border: none;
    }
  }
  &:last-child {
    border: none;
  }
  &.total {
    font-weight: 700;
    height: 50px;
  }
  &.collapsed {
    border: 0px;
    height: 0;
    opacity: 0;
  }
`
