import React from 'react'
import { Formik } from 'formik'

import { ModalWindow } from '@/components/ModalWindow'
import { Button } from '@/components/Button'
import { Title } from '@/components/Title'
import { CloseButton } from '@/components/CloseButton'
import { Subtitle } from '@/components/Subtitle'

import { TitleWrapper, SubtitleWrapper, ModalButtonsWrapper } from './styles'

export function OrganizationsDeleteModalForm({
  isOpen,
  closeModal,
  onSubmit,
  org,
}) {
  const id = { id: org?.id }

  return (
    <ModalWindow
      additional
      isOpen={isOpen}
      handleClose={closeModal}
      closeOnOverlay={false}
      width={450}
      padding={24}
    >
      <CloseButton onClick={closeModal} />
      <Formik initialValues={id} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <TitleWrapper marginBottom={3}>
              <Title bold fontSize={7}>
                Deactivate Organization
              </Title>
            </TitleWrapper>
            <SubtitleWrapper>
              <Subtitle as="p" margin={5}>
                Are you sure you want to{' '}
                <span>deactivate the organization {org?.name}</span>? You won’t be
                able to reverse this action.
              </Subtitle>
            </SubtitleWrapper>
            <ModalButtonsWrapper>
              <Button type="submit" width="150px" fontSize={5}>
                Deactivate
              </Button>
              <Button
                gray
                color="headerText"
                width="80px"
                fontSize={5}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </ModalButtonsWrapper>
          </form>
        )}
      </Formik>
    </ModalWindow>
  )
}
