import axios from 'axios'
import { useMutation } from 'react-query'

import { GET_USERS_ENDPOINT } from '@/constants/endpoints'

export const useUsersMutation = (options) =>
  useMutation(
    (payload = { skip: 0, limit: 10, search: '' }) =>
      axios.post(GET_USERS_ENDPOINT, payload),
    options
  )
