import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useForgotPasswordMutation } from '@/queries/forgot-password'
import backgroundImage from '@/assets/images/layout-background.png'
import { ForgotPasswordForm } from '@/forms/ForgotPassword'
import { RECOVERY_LINK_ROUTE } from '@/constants/routes'
import Layout from '@/components/Layout'

export default function ForgotPassword() {
  const navigate = useNavigate()
  const { mutate: forgotPassword } = useForgotPasswordMutation({
    onSuccess: (data, variables) => {
      toast.success('Recovery link has been sent!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      })
      navigate({
        pathname: RECOVERY_LINK_ROUTE,
        search: `?email=${variables.email}`,
      })
    },
  })

  return (
    <Layout background={backgroundImage}>
      <ForgotPasswordForm onSubmit={forgotPassword} />
    </Layout>
  )
}
