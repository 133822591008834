import React, { useCallback, useEffect, useState } from 'react'

import tableImage from '@/assets/images/billing-table.png'
import orgIcon from '@/assets/icons/organization.svg'
import { ImageBlock } from '@/components/ImageBlock'
import { Search } from '@/components/Search'
import { Button } from '@/components/Button'
import { Loader } from '@/components/Loader'
import { Table } from '@/components/Table'
import { Title } from '@/components/Title'
import { Icon } from '@/components/Icon'
import { orgsColumns } from '@/constants/table-columns'
import { formatOrgsData } from '@/helpers/formatData'
import { orgsConfig } from '@/constants/fetch-data-config'
import { OrganizationsAddModalForm } from '@/forms/OrganizationsAddModal'
import { OrganizationsDeleteModalForm } from '@/forms/OrganizationsDeleteModal'

import {
  HeadingSection,
  HeadingContent,
  ContentSection,
  SearchBlock,
  TableBlock,
  TableImageBlockWrapper,
} from './styles'

export function OrganizationsForm({
  orgs,
  fetchOrgsData,
  isOrgsLoading,
  addOrg,
  editOrg,
  deleteOrg,
}) {
  const [rows, setRows] = useState([])
  const [config, setConfig] = useState(orgsConfig)
  const [modal, setModal] = useState('')
  const [changingOrg, setChangingOrg] = useState(false)
  const [isRequestLoading, setRequestLoading] = useState(false)

  let searchTimeout

  const openAddModal = useCallback(() => {
    setModal('add')
  }, [])
  const openEditModal = useCallback((org) => {
    setChangingOrg(org)
    setModal('edit')
  }, [])
  const openDeleteModal = useCallback((org) => {
    setChangingOrg(org)
    setModal('delete')
  }, [])

  const closeModal = useCallback(() => {
    setModal('')
    setChangingOrg(false)
  }, [])

  const submitAddForm = useCallback((formData) => {
    const formattedData = { ...formData }
    addOrg(formattedData)
    setRequestLoading(true)
    closeModal()
    setTimeout(() => {
      fetchOrgsData(config)
      setRequestLoading(false)
    }, 1000)
  }, [])

  const submitEditForm = useCallback((formData) => {
    const formattedData = { ...formData }
    editOrg(formattedData)
    setRequestLoading(true)
    closeModal()
    setTimeout(() => {
      fetchOrgsData(config)
      setRequestLoading(false)
    }, 1000)
  }, [])

  const submitDeleteForm = useCallback((formData) => {
    const formattedData = { ...formData }
    deleteOrg(formattedData)
    setRequestLoading(true)
    closeModal()
    setTimeout(() => {
      fetchOrgsData(config)
      setRequestLoading(false)
    }, 1000)
  }, [])

  const handleSearch = useCallback((value) => {
    setConfig({ name: value })
  }, [])

  const debounceSearch = useCallback(
    (event) => {
      clearTimeout(searchTimeout)
      searchTimeout = setTimeout(() => {
        handleSearch(event.target.value)
      }, 1000)
    },
    [searchTimeout]
  )

  useEffect(() => {
    if (orgs?.data) {
      setRows(
        (orgs &&
          orgs.data.length > 0 &&
          formatOrgsData(orgs.data, openEditModal, openDeleteModal)) ||
          []
      )
    }
  }, [orgs])

  useEffect(() => {
    fetchOrgsData(config)
  }, [config])

  return (
    <>
      <OrganizationsAddModalForm
        title="Add Organization"
        isOpen={modal === 'add'}
        closeModal={closeModal}
        onSubmit={submitAddForm}
        initialValues={{ name: '' }}
      />
      <OrganizationsAddModalForm
        title="Edit Organization"
        isOpen={modal === 'edit'}
        closeModal={closeModal}
        onSubmit={submitEditForm}
        initialValues={{ name: changingOrg.name, id: changingOrg.id }}
      />
      <OrganizationsDeleteModalForm
        isOpen={modal === 'delete'}
        closeModal={closeModal}
        onSubmit={submitDeleteForm}
        org={{ name: changingOrg.name, id: changingOrg.id }}
      />
      <HeadingSection>
        <HeadingContent>
          <Title bold fontSize={9}>
            Organizations
          </Title>
        </HeadingContent>
      </HeadingSection>
      <ContentSection>
        <SearchBlock>
          <Search onChange={debounceSearch} width="315px" />
          <Button
            onClick={openAddModal}
            width="180px"
            style={{ float: 'right' }}
          >
            <Icon src={orgIcon} height="24px" />
            Add Organization
          </Button>
        </SearchBlock>
        <TableBlock>
          {(isOrgsLoading || isRequestLoading) && (
            <TableImageBlockWrapper>
              <Loader />
            </TableImageBlockWrapper>
          )}
          {!isOrgsLoading &&
            !isRequestLoading &&
            (rows.length ? (
              <Table
                wide
                noBorder
                align="left"
                data={rows}
                columns={orgsColumns}
              />
            ) : (
              <TableImageBlockWrapper>
                <ImageBlock
                  image={tableImage}
                  imageWidth="337px"
                  subtitle="Your organizations will be shown here."
                  padding={2}
                />
              </TableImageBlockWrapper>
            ))}
        </TableBlock>
      </ContentSection>
    </>
  )
}
