import React from 'react'
import './index.css'

export function FAQSection() {
  const handleFAQVisibility = (elementId) => {
    const element = document.getElementById(elementId)
    let attribute = element && element.getAttribute('class')
    const index = attribute.indexOf('hide')

    if (index !== -1) {
      attribute = attribute.replace(/hide/g, 'visible')
      element.setAttribute('class', attribute)
    } else {
      attribute = attribute.replace(/visible/g, 'hide')
      element.setAttribute('class', attribute)
    }
  }

  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-7f69a22 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="7f69a22"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7889213"
            data-id="7889213"
            data-element_type="column"
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-c9999cc elementor-view-default elementor-widget elementor-widget-icon"
                  data-id="c9999cc"
                  data-element_type="widget"
                  data-widget_type="icon.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-icon-wrapper">
                      <div className="elementor-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 500 500"
                          style={{ enableBackground: 'new 0 0 500 500' }}
                          xmlSpace="preserve"
                        >
                          <g>
                            {' '}
                            <g id="Group_6405">
                              {' '}
                              <g id="Path_4924">
                                {' '}
                                <path d="M190.31,197.62h22.14c-1.06-24.26,17.75-44.78,42.01-45.84c2.3-0.1,4.6-0.02,6.88,0.24    c25.15-2.3,47.41,16.22,49.72,41.38c0.19,2.06,0.24,4.14,0.15,6.2c0,45.33-35.36,52.54-73.34,52.54h-8.6l1.31,52.88h19.82    l1-36.34c47.58,0,81.94-19.16,81.94-69.05c0-40.96-29.74-67.41-72.03-67.41c-41.63,0-71.02,25.44-71.02,65.41 M257.05,350.59    c0.19-9.21-7.12-16.84-16.33-17.03c-9.21-0.19-16.84,7.12-17.03,16.33c0,0.23,0,0.45,0,0.7c0,9.21,7.47,16.68,16.68,16.68    C249.58,367.28,257.05,359.81,257.05,350.59" />{' '}
                                <path d="M240.37,367.77c-9.47,0-17.18-7.71-17.18-17.18v-0.69c0.1-4.6,1.97-8.88,5.29-12.05c3.31-3.18,7.67-4.88,12.25-4.78    c9.47,0.2,17.02,8.06,16.82,17.54C257.54,360.07,249.84,367.77,240.37,367.77z M240.36,334.06c-4.19,0-8.16,1.59-11.19,4.5    c-3.12,2.99-4.89,7.02-4.98,11.34v0.68c0,8.92,7.26,16.18,16.18,16.18c8.92,0,16.18-7.26,16.18-16.18v-0.01    c0.19-8.92-6.92-16.33-15.84-16.52C240.59,334.06,240.47,334.06,240.36,334.06z M250.89,305.52h-20.8l-0.01-0.49l-1.33-53.4h9.11    c36.02,0,72.84-6.18,72.84-52.04c0.09-2.06,0.04-4.12-0.14-6.16c-2.27-24.84-24.33-43.2-49.17-40.92l-0.05,0l-0.05-0.01    c-2.25-0.26-4.54-0.34-6.8-0.24c-23.95,1.04-42.58,21.37-41.54,45.32l0.02,0.52h-22.66v-0.48h-0.52    c0-39.42,28.74-65.91,71.52-65.91c43.38,0,72.53,27.29,72.53,67.91c0,57.24-44.45,69.45-81.96,69.55L250.89,305.52z     M231.06,304.52h18.85l1-36.34h0.49c37.15,0,81.44-11.89,81.44-68.55c0-40.02-28.75-66.91-71.53-66.91    c-41.99,0-70.26,25.86-70.51,64.4h21.13c-0.78-24.27,18.19-44.78,42.51-45.84c2.29-0.1,4.62-0.02,6.91,0.24    c25.36-2.3,47.89,16.46,50.21,41.83c0.19,2.08,0.24,4.19,0.15,6.27c0,46.71-37.33,53.01-73.84,53.01h-8.08L231.06,304.52z" />{' '}
                              </g>{' '}
                              <g id="Path_4925">
                                {' '}
                                <path d="M249.98,479.66c-126.84,0-229.67-102.83-229.67-229.67S123.13,20.32,249.98,20.32s229.67,102.83,229.67,229.67    C479.49,376.77,376.75,479.5,249.98,479.66 M249.98,40.39c-115.74,0.02-209.56,93.86-209.54,209.6    c0.02,115.74,93.86,209.56,209.6,209.54c115.72-0.02,209.53-93.82,209.54-209.54c-0.13-115.7-93.9-209.46-209.6-209.57" />{' '}
                                <path d="M249.98,480.16L249.98,480.16c-61.48,0-119.28-23.94-162.76-67.42S19.81,311.47,19.81,249.99    c0-61.48,23.94-119.28,67.42-162.75C130.7,43.76,188.5,19.82,249.98,19.82s119.28,23.94,162.75,67.42    c43.47,43.47,67.42,101.27,67.42,162.75c-0.08,61.45-24.05,119.22-67.5,162.67C369.2,456.11,311.43,480.09,249.98,480.16z     M249.98,20.82c-126.36,0-229.17,102.81-229.17,229.17c0,126.36,102.82,229.16,229.17,229.17    C376.18,479,478.99,376.2,479.15,249.99C479.15,123.63,376.34,20.82,249.98,20.82z M250.01,460.04    c-56.1,0-108.84-21.84-148.52-61.51c-39.68-39.67-61.54-92.42-61.55-148.54c-0.02-115.83,94.21-210.09,210.04-210.1v0.53l0-0.5    c56.09,0.06,108.83,21.93,148.49,61.59c39.67,39.66,61.55,92.39,61.61,148.48c-0.01,56.1-21.86,108.84-61.53,148.51    c-39.67,39.67-92.41,61.52-148.51,61.53C250.03,460.04,250.02,460.04,250.01,460.04z M249.98,40.89    c-115.28,0.02-209.06,93.82-209.04,209.1c0.01,55.85,21.76,108.35,61.26,147.83c39.49,39.47,91.98,61.21,147.81,61.21    c0.01,0,0.02,0,0.03,0c115.25-0.02,209.03-93.79,209.04-209.04c-0.13-115.17-93.94-208.96-209.1-209.07L249.98,40.89z" />{' '}
                              </g>{' '}
                            </g>
                          </g>
                        </svg>{' '}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="elementor-element elementor-element-23f123b elementor-widget elementor-widget-heading"
                  data-id="23f123b"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h2 className="elementor-heading-title elementor-size-default">
                      Frequently Asked<strong> Questions</strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-1d3a5e9 elementor-widget elementor-widget-accordion"
                  data-id="1d3a5e9"
                  data-element_type="widget"
                  data-widget_type="accordion.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-accordion" role="tablist">
                      <div className="elementor-accordion-item">
                        <div
                          id="elementor-tab-title-3061"
                          className="accordion elementor-tab-title"
                          data-tab="1"
                          role="tab"
                          aria-controls="elementor-tab-content-3061"
                          aria-expanded="true"
                          tabIndex="0"
                          aria-selected="true"
                          onClick={() =>
                            handleFAQVisibility('elementor-tab-content-3061')
                          }
                        >
                          <span
                            className="elementor-accordion-icon elementor-accordion-icon-left"
                            aria-hidden="true"
                          >
                            <span className="elementor-accordion-icon-closed">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="elementor-accordion-icon-opened">
                              <i className="fas fa-minus" />
                            </span>
                          </span>
                          <span className="elementor-accordion-title" href="#">
                            I just created an account. Now what?
                          </span>
                        </div>
                        <div
                          id="elementor-tab-content-3061"
                          className="panel elementor-tab-content elementor-clearfix hide"
                          data-tab="1"
                          role="tabpanel"
                          aria-labelledby="elementor-tab-title-3061"
                        >
                          <p>
                            Once you have an account set up, you’ll need to add
                            your locations, employees, managers, and payment
                            information. Then you’ll invite your employees
                            digitally to download the App and start using it.
                            You’ll also want to set up your sales funnel and
                            determine your coaches.
                          </p>
                        </div>
                      </div>
                      <div className="elementor-accordion-item">
                        <div
                          id="elementor-tab-title-3062"
                          onClick={() =>
                            handleFAQVisibility('elementor-tab-content-3062')
                          }
                          className="accordion elementor-tab-title"
                          data-tab="2"
                          role="tab"
                          aria-controls="elementor-tab-content-3062"
                          aria-expanded="false"
                        >
                          <span
                            className="elementor-accordion-icon elementor-accordion-icon-left"
                            aria-hidden="true"
                          >
                            <span className="elementor-accordion-icon-closed">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="elementor-accordion-icon-opened">
                              <i className="fas fa-minus" />
                            </span>
                          </span>
                          <span className="elementor-accordion-title">
                            I'm having trouble logging in.
                          </span>
                        </div>
                        <div
                          id="elementor-tab-content-3062"
                          className="panel elementor-tab-content elementor-clearfix hide"
                          data-tab="2"
                          role="tabpanel"
                          aria-labelledby="elementor-tab-title-3062"
                        >
                          <p>
                            If you’re not able to log in, simply click on
                            ‘Forgot Password’ or ‘Forgot Account Name’ and
                            follow the directions to gain access. SOAR has
                            online chat support if you get stuck.
                          </p>
                        </div>
                      </div>
                      <div className="elementor-accordion-item">
                        <div
                          id="elementor-tab-title-3063"
                          onClick={() =>
                            handleFAQVisibility('elementor-tab-content-3063')
                          }
                          className="accordion elementor-tab-title"
                          data-tab="3"
                          role="tab"
                          aria-controls="elementor-tab-content-3063"
                          aria-expanded="false"
                        >
                          <span
                            className="elementor-accordion-icon elementor-accordion-icon-left"
                            aria-hidden="true"
                          >
                            <span className="elementor-accordion-icon-closed">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="elementor-accordion-icon-opened">
                              <i className="fas fa-minus" />
                            </span>
                          </span>
                          <span className="elementor-accordion-title">
                            What happens if I don't meet my goals?
                          </span>
                        </div>
                        <div
                          id="elementor-tab-content-3063"
                          className="panel elementor-tab-content elementor-clearfix hide"
                          data-tab="3"
                          role="tabpanel"
                          aria-labelledby="elementor-tab-title-3063"
                        >
                          <p>
                            No problem. Just try again next month! The purpose
                            of the App isn’t to punish anyone for not reaching
                            their goals. In fact, we strongly recommend that it
                            NOT be used that way. SOAR is meant to provide a
                            quick and easy tracking mechanism for your sales
                            activities, opportunities, and results so that you
                            (and your coach) can get insight into where you need
                            help to improve your selling skills.
                          </p>
                        </div>
                      </div>
                      <div className="elementor-accordion-item">
                        <div
                          id="elementor-tab-title-3064"
                          onClick={() =>
                            handleFAQVisibility('elementor-tab-content-3064')
                          }
                          className="accordion elementor-tab-title"
                          data-tab="4"
                          role="tab"
                          aria-controls="elementor-tab-content-3064"
                          aria-expanded="false"
                        >
                          <span
                            className="elementor-accordion-icon elementor-accordion-icon-left"
                            aria-hidden="true"
                          >
                            <span className="elementor-accordion-icon-closed">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="elementor-accordion-icon-opened">
                              <i className="fas fa-minus" />
                            </span>
                          </span>
                          <span className="elementor-accordion-title">
                            Can I change my goal at any point throughout the
                            month?
                          </span>
                        </div>
                        <div
                          id="elementor-tab-content-3064"
                          className="panel elementor-tab-content elementor-clearfix hide"
                          data-tab="4"
                          role="tabpanel"
                          aria-labelledby="elementor-tab-title-3064"
                        >
                          <p>
                            The purpose of setting a goal is to motivate you to
                            achieve new levels. You may not achieve them on your
                            first try, but don’t give up! Changing your goals
                            within the month is discouraged, but changing goals
                            the next month is part of the process. As you learn
                            more about your performance capabilities, you’ll
                            become more tuned in to what good goals should be!
                          </p>
                        </div>
                      </div>
                      <div className="elementor-accordion-item">
                        <div
                          id="elementor-tab-title-3065"
                          onClick={() =>
                            handleFAQVisibility('elementor-tab-content-3065')
                          }
                          className="accordion elementor-tab-title"
                          data-tab="5"
                          role="tab"
                          aria-controls="elementor-tab-content-3065"
                          aria-expanded="false"
                        >
                          <span
                            className="elementor-accordion-icon elementor-accordion-icon-left"
                            aria-hidden="true"
                          >
                            <span className="elementor-accordion-icon-closed">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="elementor-accordion-icon-opened">
                              <i className="fas fa-minus" />
                            </span>
                          </span>
                          <span className="elementor-accordion-title">
                            I reported unsuccessfully by mistake, how do I go
                            back and change it?
                          </span>
                        </div>
                        <div
                          id="elementor-tab-content-3065"
                          className="panel elementor-tab-content elementor-clearfix hide"
                          data-tab="5"
                          role="tabpanel"
                          aria-labelledby="elementor-tab-title-3065"
                        >
                          <p>
                            Easy. You can use any of the controls in your daily
                            tracking to adjust prior days. If you forgot to
                            report one day, you can access that day through the
                            ‘Schedule’ icon – just click on the day, then enter
                            your results for that day. Any previous data can be
                            overridden at any time.
                          </p>
                        </div>
                      </div>
                      <div className="elementor-accordion-item">
                        <div
                          id="elementor-tab-title-3066"
                          onClick={() =>
                            handleFAQVisibility('elementor-tab-content-3066')
                          }
                          className="accordion elementor-tab-title"
                          data-tab="6"
                          role="tab"
                          aria-controls="elementor-tab-content-3066"
                          aria-expanded="false"
                        >
                          <span
                            className="elementor-accordion-icon elementor-accordion-icon-left"
                            aria-hidden="true"
                          >
                            <span className="elementor-accordion-icon-closed">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="elementor-accordion-icon-opened">
                              <i className="fas fa-minus" />
                            </span>
                          </span>
                          <span className="elementor-accordion-title">
                            How do I cancel or delete my account?
                          </span>
                        </div>
                        <div
                          id="elementor-tab-content-3066"
                          className="panel elementor-tab-content elementor-clearfix hide"
                          data-tab="6"
                          role="tabpanel"
                          aria-labelledby="elementor-tab-title-3066"
                        >
                          <p>
                            If you choose not to use SOAR any longer, your
                            administrative person can simply cancel their
                            subscription in the ‘Billing’ section of the
                            enterprise website
                          </p>
                        </div>
                      </div>
                      <div className="elementor-accordion-item">
                        <div
                          id="elementor-tab-title-3067"
                          onClick={() =>
                            handleFAQVisibility('elementor-tab-content-3067')
                          }
                          className="accordion elementor-tab-title"
                          data-tab="7"
                          role="tab"
                          aria-controls="elementor-tab-content-3067"
                          aria-expanded="false"
                        >
                          <span
                            className="elementor-accordion-icon elementor-accordion-icon-left"
                            aria-hidden="true"
                          >
                            <span className="elementor-accordion-icon-closed">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="elementor-accordion-icon-opened">
                              <i className="fas fa-minus" />
                            </span>
                          </span>
                          <span className="elementor-accordion-title">
                            How do I turn off notifications?
                          </span>
                        </div>
                        <div
                          id="elementor-tab-content-3067"
                          className="panel elementor-tab-content elementor-clearfix hide"
                          data-tab="7"
                          role="tabpanel"
                          aria-labelledby="elementor-tab-title-3067"
                          style={{ display: 'none' }}
                        >
                          <p>
                            Notifications can be turned off in your mobile
                            device or in the ‘settings’ portion of the App
                            itself.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
