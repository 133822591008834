import styled from 'styled-components'

export const HelpButtonWrapper = styled.div`
  position: absolute;
  right: 42px;
  top: 37px;
`

export const CheckboxWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
  height: 100%;
  margin: ${({ theme }) => theme.spaces[4]}px 0;
  padding-bottom: ${({ theme, modal }) => (modal ? 12 : theme.spaces[0])}px;
`

export const CheckIcon = styled.img`
  height: 18px;
  width: 18px;
`

export const CheckIconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[4]}px;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`
