import { useOrganizationImpersonate } from '@/hooks/useOrganizationImpersonate'
import React, { useState, useEffect, useContext } from 'react'
import { useActor } from '@xstate/react'

import { SoarStateContext } from '@/global/soar.context'
import { MenuLayout } from '@/components/MenuLayout'
import backgroundImage from '@/assets/images/layout-background.png'
import { SettingsForm } from '@/forms/Settings'
import { useOrgDataQuery } from '@/queries/get-org'
import { useUpdateOrgDataMutation } from '@/queries/update-org-data'
import { useAllUsersDataMutation } from '@/queries/all-users-data'
import { useEditManagerMutation } from '@/queries/edit-manager'
import { useDeleteManagerMutation } from '@/queries/delete-manager'
import { useResetManagerMutation } from '@/queries/reset-password'
import { useFunnelQuery } from '@/queries/get-funnel'
import { useAddFunnelMutation } from '@/queries/add-funnel'
import { useDeleteFunnelMutation } from '@/queries/delete-funnel'
import { useAddManagerMutation } from '@/queries/add-manager'
import { useGetUserCoachMutation } from '@/queries/get-user-coach'
import { createToast } from '@/helpers/createToast'
import { useChangeMultipleCoachMutation } from '@/queries/change-coach'
import { useBatchUploadService } from '@/queries/upload-batch'

export default function Settings() {
  const defaultSearch = {
    skip: 0,
    limit: 1000000,
    search: '',
    originManager: false,
  }
  const soarContext = useContext(SoarStateContext)
  const [state] = useActor(soarContext.dashboardService)
  const { orgId } = state.context

  const [error, setError] = useState(false)
  const [isLoadingCount, setIsLoadingCount] = useState(0)

  const { organizationId } = useOrganizationImpersonate(orgId)

  const {
    mutate: fetchUsersData,
    mutateAsync: fetchUsersDataAsync,
    data: users,
    isLoading: usersLoad,
  } = useAllUsersDataMutation(null, organizationId)

  const { mutate: addUser, isLoading: addUserLoad } = useAddManagerMutation({
    onSuccess: () => {
      createToast('success', 'User added successfully!')
    },
    onError: (err) => {
      setError(err.response.data.message)
    },
  })

  const {
    mutate: editUser,
    mutateAsync: editUserAsync,
    isLoading: editUserLoad,
  } = useEditManagerMutation({
    onSuccess: () => {
      createToast('success', 'User data edited successfully!')
    },
    onError: (err) => {
      setError(err.response.data.message)
    },
  })

  const { mutate: deleteUser, isLoading: deleteUserLoad } =
    useDeleteManagerMutation()

  const { mutate: resetUser, isLoading: resetUserLoad } =
    useResetManagerMutation()

  const {
    refetch: fetchFunnel,
    data: funnelItems,
    isRefetching: funnelLoad,
  } = useFunnelQuery(organizationId)

  const { mutate: addFunnelItem, isLoading: addFunnelLoad } =
    useAddFunnelMutation()
  const { mutate: deleteFunnelItem, isLoading: deleteFunnelLoad } =
    useDeleteFunnelMutation()

  const {
    data: orgData,
    refetch: fetchOrgData,
    isLoading: orgDataLoad,
  } = useOrgDataQuery(organizationId)

  const { mutate: updateOrgData, isLoading: orgDataUpdate } =
    useUpdateOrgDataMutation()

  const { mutate: changeCoach, isLoading: changeCoachLoad } =
    useChangeMultipleCoachMutation()

  const {
    mutate: getUserCoach,
    data: userCoaches,
    isLoading: getCoachLoad,
  } = useGetUserCoachMutation()

  const sendFile = async (file) => {
    let result = null
    try {
      result = await useBatchUploadService(organizationId, file)
      if (result?.data?.message === 'success') {
        fetchUsersData(defaultSearch, organizationId)
        fetchOrgData(organizationId)
        fetchFunnel()
      } else {
        createToast('error', result?.message)
      }
    } catch (err) {
      createToast('error', err)
    }
    return result
  }

  useEffect(() => {
    if (addFunnelLoad || deleteFunnelLoad) {
      setIsLoadingCount(1)
    } else if (isLoadingCount && funnelLoad) {
      setIsLoadingCount(2)
    } else if (isLoadingCount > 1 && !funnelLoad) {
      setIsLoadingCount(0)
    }
  }, [addFunnelLoad, deleteFunnelLoad, funnelLoad])

  useEffect(() => {
    fetchUsersData(defaultSearch, organizationId)
    fetchOrgData(organizationId)
    fetchFunnel()
  }, [organizationId])

  return (
    <MenuLayout adminData={orgData} background={backgroundImage}>
      <SettingsForm
        users={users && users.data}
        funnelItems={funnelItems}
        orgData={orgData}
        userCoaches={userCoaches && userCoaches}
        fetchUsersData={fetchUsersDataAsync}
        addUser={addUser}
        editUser={editUserAsync}
        deleteUser={deleteUser}
        resetUser={resetUser}
        fetchFunnel={fetchFunnel}
        addFunnelItem={addFunnelItem}
        deleteFunnelItem={deleteFunnelItem}
        fetchOrgData={fetchOrgData}
        updateOrgData={updateOrgData}
        changeCoach={changeCoach}
        getUserCoach={getUserCoach}
        isCoachLoading={getCoachLoad || changeCoachLoad}
        isUsersLoading={
          usersLoad ||
          addUserLoad ||
          editUserLoad ||
          deleteUserLoad ||
          resetUserLoad
        }
        isFunnelLoading={isLoadingCount > 0}
        isOrgLoading={orgDataLoad || orgDataUpdate}
        error={error}
        setError={setError}
        orgId={organizationId}
        submitFile={sendFile}
      />
    </MenuLayout>
  )
}
