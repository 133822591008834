import React from 'react'

import { SubtitleContainer } from './styles'

export function Subtitle({ children, as, ...otherProps }) {
  return (
    <SubtitleContainer as={as} {...otherProps}>
      {children}
    </SubtitleContainer>
  )
}
