import React from 'react'

import { Icon } from '@/components/Icon'
import dataCRM from '@/assets/icons/dataCRM.svg'
import measure from '@/assets/icons/measure.svg'
import retention from '@/assets/icons/retention.svg'
import dataBars from '@/assets/icons/dataBars.svg'
import focus from '@/assets/icons/focus.svg'
import income from '@/assets/icons/income.svg'
import increaseSales from '@/assets/icons/increaseSales.svg'
import performance from '@/assets/icons/performance.svg'
import roi from '@/assets/icons/roi.svg'

export function FeaturesSection() {
  return (
    <>
      <div id="features" className="elementor-menu-anchor" />
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-fde6334 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="fde6334"
        data-element_type="section"
      >
        <div className="elementor-background-overlay" />
        <div
          className="elementor-shape elementor-shape-top"
          data-negative="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 700 10"
            preserveAspectRatio="none"
          >
            <path
              className="elementor-shape-fill"
              d="M350,10L340,0h20L350,10z"
            />
          </svg>
        </div>
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ce7f971"
              data-id="ce7f971"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-c2d9328 elementor-widget elementor-widget-heading"
                    data-id="c2d9328"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Power your sales with
                        <strong> infinite possibilities.</strong>
                      </h2>
                    </div>
                  </div>
                  <section
                    className="elementor-section elementor-inner-section elementor-element elementor-element-6ecf1fd elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="6ecf1fd"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-row">
                        <div
                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-40170d2"
                          data-id="40170d2"
                          data-element_type="column"
                        >
                          <div className="elementor-column-wrap elementor-element-populated">
                            <div className="elementor-widget-wrap">
                              <div
                                className="elementor-element elementor-element-33a9282 elementor-position-left elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                data-id="33a9282"
                                data-element_type="widget"
                                data-widget_type="icon-box.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-icon">
                                      <span className="elementor-icon elementor-animation-">
                                        <Icon src={dataCRM} size={55} />
                                      </span>
                                    </div>
                                    <div className="elementor-icon-box-content">
                                      <p className="elementor-icon-box-description">
                                        Provides instantaneous, real-time data
                                        on the go, quicker and easier than a
                                        CRM.{' '}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-080b059 elementor-position-left elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                data-id="080b059"
                                data-element_type="widget"
                                data-widget_type="icon-box.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-icon">
                                      <span className="elementor-icon elementor-animation-">
                                        <Icon src={measure} size={55} />
                                      </span>
                                    </div>
                                    <div className="elementor-icon-box-content">
                                      <p className="elementor-icon-box-description">
                                        Provides a standard format for measuring
                                        and analyzing sales activities and
                                        results across your entire team.{' '}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-4f5c971 elementor-position-left elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                data-id="4f5c971"
                                data-element_type="widget"
                                data-widget_type="icon-box.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-icon">
                                      <span className="elementor-icon elementor-animation-">
                                        <Icon src={retention} size={55} />
                                      </span>
                                    </div>
                                    <div className="elementor-icon-box-content">
                                      <p className="elementor-icon-box-description">
                                        Increases retention through greater
                                        motivation, transparency, and
                                        accountability.{' '}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-84320a6 elementor-position-left elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                data-id="84320a6"
                                data-element_type="widget"
                                data-widget_type="icon-box.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-icon">
                                      <span className="elementor-icon elementor-animation-">
                                        <Icon src={roi} size={55} />
                                      </span>
                                    </div>
                                    <div className="elementor-icon-box-content">
                                      <p className="elementor-icon-box-description">
                                        Simple, fast, and easy interface ensures
                                        higher utilization and a significantly
                                        greater ROI.{' '}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-f65e348 elementor-position-left elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                data-id="f65e348"
                                data-element_type="widget"
                                data-widget_type="icon-box.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-icon">
                                      <span className="elementor-icon elementor-animation-">
                                        <Icon src={performance} size={55} />
                                      </span>
                                    </div>
                                    <div className="elementor-icon-box-content">
                                      <p className="elementor-icon-box-description">
                                        Increased effectiveness with every lead
                                        delivers higher performance and
                                        accountability for every dollar of ad
                                        spend.{' '}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-e0931db"
                          data-id="e0931db"
                          data-element_type="column"
                        >
                          <div className="elementor-column-wrap elementor-element-populated">
                            <div className="elementor-widget-wrap">
                              <div
                                className="elementor-element elementor-element-76d7ba0 elementor-position-left elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                data-id="76d7ba0"
                                data-element_type="widget"
                                data-widget_type="icon-box.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-icon">
                                      <span className="elementor-icon elementor-animation-">
                                        <Icon src={focus} size={55} />
                                      </span>
                                    </div>
                                    <div className="elementor-icon-box-content">
                                      <p className="elementor-icon-box-description">
                                        Focuses managers on coaching and
                                        improving the performance of their
                                        people through conversion management.{' '}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-90b5360 elementor-position-left elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                data-id="90b5360"
                                data-element_type="widget"
                                data-widget_type="icon-box.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-icon">
                                      <span className="elementor-icon elementor-animation-">
                                        <Icon src={increaseSales} size={55} />
                                      </span>
                                    </div>
                                    <div className="elementor-icon-box-content">
                                      <p className="elementor-icon-box-description">
                                        Motivates producers to increase their
                                        sales by focusing on income.{' '}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-db220fc elementor-position-left elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                data-id="db220fc"
                                data-element_type="widget"
                                data-widget_type="icon-box.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-icon">
                                      <span className="elementor-icon elementor-animation-">
                                        <Icon src={income} size={55} />
                                      </span>
                                    </div>
                                    <div className="elementor-icon-box-content">
                                      <p className="elementor-icon-box-description">
                                        Helps producers connect the dots between
                                        completing tasks and their personal
                                        income.{' '}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-bce4e02 elementor-position-left elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                data-id="bce4e02"
                                data-element_type="widget"
                                data-widget_type="icon-box.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-box-wrapper">
                                    <div className="elementor-icon-box-icon">
                                      <span className="elementor-icon elementor-animation-">
                                        <Icon src={dataBars} size={55} />
                                      </span>
                                    </div>
                                    <div className="elementor-icon-box-content">
                                      <p className="elementor-icon-box-description">
                                        Data-driven decision making that ensures
                                        precise outcomes for converting a lead
                                        to a sale.{' '}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
