import axios from 'axios'
import { useMutation } from 'react-query'

import { ALL_ORGANIZATIONS_ENDPOINT } from '@/constants/endpoints'
import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'

export const useAllOrgsMutation = (options) => {
  return useMutation(
    (payload = { name: '' }) =>
      axios.post(ALL_ORGANIZATIONS_ENDPOINT, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            AUTH_TOKEN_STORAGE_KEY
          )}`,
        },
      }),
    options
  )
}
