import React from 'react'
import { Formik } from 'formik'

import { ModalWindow } from '@/components/ModalWindow'
import { Button } from '@/components/Button'
import { Title } from '@/components/Title'
import { Divider } from '@/components/Divider'
import { InputField } from '@/fields/Input'
import { MaskedInputField } from '@/fields/MaskedInput'
import { CloseButton } from '@/components/CloseButton'

import { validationSchema, DEFAULT_VALUES } from './config'
import { TitleWrapper, ModalButtonsWrapper } from './styles'

export function HeaderEditModalForm({
  initialValues,
  isOpen,
  closeModal,
  onSubmit,
}) {
  return (
    <ModalWindow
      additional
      isOpen={isOpen}
      handleClose={closeModal}
      closeOnOverlay={false}
      width={440}
      padding={24}
    >
      <CloseButton onClick={closeModal} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <TitleWrapper marginBottom={3}>
              <Title bold fontSize={7}>
                Edit Information
              </Title>
            </TitleWrapper>
            <InputField name="firstName" label="FIRST NAME" margin={5} />
            <InputField name="lastName" label="LAST NAME" />
            <InputField name="email" label="EMAIL" margin={5} disabled />
            <MaskedInputField
              type="text"
              name="mobile"
              label="MOBILE PHONE NUMBER"
            />
            <Divider size={1} margin={5} />
            <ModalButtonsWrapper>
              <Button type="submit" width="65px" fontSize={5}>
                Save
              </Button>
              <Button
                gray
                color="headerText"
                width="80px"
                fontSize={5}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </ModalButtonsWrapper>
          </form>
        )}
      </Formik>
    </ModalWindow>
  )
}

HeaderEditModalForm.defaultProps = {
  initialValues: DEFAULT_VALUES,
}
