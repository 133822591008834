import axios from 'axios'
import { useMutation } from 'react-query'

import { CHANGE_PASSWORD_ENDPOINT } from '@/constants/endpoints'
import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'

export const useChangePasswordMutation = (options) =>
  useMutation(async (payload) => {
    return axios.post(
      CHANGE_PASSWORD_ENDPOINT,
      { password: payload.reNewPassword, oldPassword: payload.oldPassword },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            AUTH_TOKEN_STORAGE_KEY
          )}`,
        },
      }
    )
  }, options)
