import AWS from 'aws-sdk'

const spacesEndpoint = new AWS.Endpoint('fra1.digitaloceanspaces.com')

export const S3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: 'U3OTH7CLXQRXJFM63QUU',
  secretAccessKey: 'JvX069v5CnbS5jXrcqAZIoNl6mS6NfTTvtstkx8DzOQ',
})

export const Config = {
  digitalOceanSpaces: 'https://soarapp-bucket.fra1.digitaloceanspaces.com',
  bucketName: 'soarapp-bucket',
}
