import React from 'react'

import { ModalWindow } from '@/components/ModalWindow'
import { Title } from '@/components/Title'
import { Button } from '@/components/Button'

import { Image, Wrapper, ButtonWrapper } from './styles'

export function ModalImageWindow({
  title,
  width,
  imageSrc,
  imageWidth,
  handleClose,
  ...otherProps
}) {
  return (
    <ModalWindow handleClose={handleClose} {...otherProps}>
      <Wrapper width={width}>
        <Title bold fontSize={9} marginBottom={4}>
          {title}
        </Title>
        {imageSrc && <Image src={imageSrc} imageWidth={imageWidth} />}
        <ButtonWrapper>
          <Button onClick={handleClose} padding={4}>
            Continue
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </ModalWindow>
  )
}
