import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  background-image: url(${(props) => props.background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-grow: 1;
  height: 100%;
  position: relative;
`

export const LogoContainer = styled.div`
  left: 30px;
  position: absolute;
  top: 30px;
`

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify};
  min-height: 100vh;
  padding-top: ${({ justify, pt }) => {
    if (pt) return pt
    if (justify) return 60
    return 0
  }}px;
  width: 100%;

  ${({ theme }) => theme.below.tablet`
     padding-bottom: 20px;
  `}
`
