import React from 'react'

import { Icon } from '@/components/Icon'
import check from '@/assets/icons/check.svg'

import {
  Wrapper,
  Text,
  Item,
  Counter,
  CompletedCounter,
  ActiveCounter,
} from './styles'

const steps = [
  'Organization',
  'Managers',
  'Employees',
  'Review & Invite',
  'Funnel',
]

export function ProgressBar({ active }) {
  return (
    <Wrapper>
      {steps.map((step, index) => {
        if (index + 1 < active) {
          return (
            <Item key={step + active}>
              <CompletedCounter>
                <Icon src={check} margin={0} />
              </CompletedCounter>
              <Text>{step}</Text>
            </Item>
          )
        }
        if (index + 1 === active) {
          return (
            <Item key={step + active} active>
              <ActiveCounter>{index + 1}</ActiveCounter>
              <Text>{step}</Text>
            </Item>
          )
        }
        return (
          <Item key={step + active}>
            <Counter>{index + 1}</Counter>
            <Text>{step}</Text>
          </Item>
        )
      })}
    </Wrapper>
  )
}
