import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { fetchAllConfig } from '@/constants/fetch-data-config'
import { reviewInfoColumns } from '@/constants/table-columns'
import { ADD_EMPLOYEES_ROUTE, SEND_INVITES_ROUTE } from '@/constants/routes'
import plusIcon from '@/assets/icons/plus.svg'
import { Icon } from '@/components/Icon'
import { Table } from '@/components/Table'
import { Title } from '@/components/Title'
import { Search } from '@/components/Search'
import { Button } from '@/components/Button'
import { Loader } from '@/components/Loader'
import { Subtitle } from '@/components/Subtitle'
import { Pagination } from '@/components/Pagination'
import { ModalWindow } from '@/components/ModalWindow'
import { ProgressBar } from '@/components/ProgressBar'
import { TableSection } from '@/components/TableSection'
import { ReviewInfoModalForm } from '@/forms/ReviewInfoModal'
import { formatData } from '@/helpers/formatData'
import { createToast } from '@/helpers/createToast'

import { ContentWrapper, ButtonsWrapper, ErrorModalWrapper } from './styles'

export function ReviewInfoForm({
  onAddSubmit,
  onEditSubmit,
  fetchData,
  data,
  locations,
  isLoading,
  error,
  setError,
}) {
  const navigate = useNavigate()
  const [rows, setRows] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [config, setConfig] = useState(fetchAllConfig)
  const [pages, setPages] = useState(1)
  const [changingUser, setUser] = useState(false)
  const [currentPage, setPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const [isRequestLoading, setRequestLoading] = useState(false)

  let searchTimeout

  const openAddModal = useCallback(() => setOpenModal('add'), [])
  const openEditModal = useCallback((user) => {
    setOpenModal('edit')
    setUser(user)
  }, [])
  const openErrorModal = useCallback(() => setOpenModal('error'), [])
  const closeModal = useCallback(() => {
    setOpenModal(false)
    setUser(false)
  }, [])

  const submitAddForm = useCallback(
    (formData) => {
      const formattedData = { ...formData }
      onAddSubmit(formattedData)
      setRequestLoading(true)

      setTimeout(() => {
        fetchData(config)
        setRequestLoading(false)
      }, 1000)
      closeModal()
    },
    [config]
  )

  const submitEditForm = useCallback(
    (formData, users) => {
      const formattedData = { ...formData }
      onEditSubmit(formattedData)
      const res = users?.reduce(
        (arr, user) =>
          user.id !== formattedData.id
            ? [...arr, user]
            : [
                ...arr,
                {
                  firstName: formattedData.name.split(' ')[0],
                  lastName: formattedData.name.split(' ')[1] || '',
                  manager: formattedData.manager,
                  managerName: formattedData.managerName,
                  ...formattedData,
                },
              ],
        []
      )
      setRows(formatData(res, null, openEditModal))
      closeModal()
    },
    [closeModal]
  )

  const handlePagination = useCallback(
    (page) => {
      setPage(page)
      setConfig({
        skip: page * 10,
        limit: 10,
        search: searchValue,
        role: 'all',
        originManager: false,
      })
    },
    [searchValue]
  )

  const handleSearch = useCallback((value) => {
    setSearchValue(value)
    setPage(1)
    setConfig({
      skip: 0,
      limit: 10,
      search: value,
      role: 'all',
      originManager: false,
    })
  }, [])

  const debounceSearch = useCallback(
    (event) => {
      clearTimeout(searchTimeout)
      searchTimeout = setTimeout(() => {
        handleSearch(event.target.value)
      }, 1000)
    },
    [searchTimeout]
  )

  const checkData = useCallback(() => {
    let isInvalidUsers = 0
    rows.forEach((item) => {
      if (item.invalid) isInvalidUsers += 1
      return null
    })
    if (isInvalidUsers) return openErrorModal()
    return navigate(SEND_INVITES_ROUTE)
  }, [rows])

  useEffect(() => {
    fetchData(config)
  }, [config])

  useEffect(() => {
    if (error) {
      if (typeof error === 'object') createToast('error', error[0])
      else createToast('error', error)
      fetchData(config)
    }
    setError(false)
  }, [error, setError, config])

  useEffect(() => {
    setRows((data && formatData(data.tempUsers, null, openEditModal)) || [])
    setPages(data?.total)
  }, [data])

  const styleRows = useCallback((row) =>
    row.values.email || row.values.phoneNumber
      ? {
          style: {
            color: 'rgb(156, 163, 175)',
          },
        }
      : {
          style: {
            color: 'rgb(239, 68, 68)',
          },
        }
  )

  return (
    <>
      <ProgressBar active={4} />
      <ReviewInfoModalForm
        title="Add User"
        locations={locations}
        isOpen={openModal === 'add'}
        onClose={closeModal}
        onSubmit={submitAddForm}
        user={{ role: 'Sales Manager' }}
        rows={rows}
      />
      <ReviewInfoModalForm
        title="Edit Details"
        locations={locations}
        isOpen={openModal === 'edit'}
        onClose={closeModal}
        onSubmit={submitEditForm}
        user={changingUser}
        rows={rows}
      />
      <ModalWindow isOpen={openModal === 'error'} closeModal={closeModal}>
        <ErrorModalWrapper>
          <Title bold fontSize={9} marginBottom={10}>
            An email or a phone number is required for each person
          </Title>
          <Button onClick={closeModal} width="230px">
            Edit
          </Button>
        </ErrorModalWrapper>
      </ModalWindow>
      <TableSection
        title="Review Info"
        arrowLinkPath={ADD_EMPLOYEES_ROUTE}
        onContinueButtonClick={checkData}
        isLoading={isLoading || isRequestLoading}
      >
        <ContentWrapper>
          <Subtitle margin={0}>
            You can review your info and correct any errors.
          </Subtitle>
          <ButtonsWrapper>
            <Search onChange={debounceSearch} width="310px" />
            <Button value="add" onClick={openAddModal} width="180px">
              <Icon src={plusIcon} />
              Add User
            </Button>
          </ButtonsWrapper>
          {isLoading || isRequestLoading || !data ? (
            <Loader />
          ) : (
            <>
              <Table
                wide
                align="left"
                columns={reviewInfoColumns}
                data={rows}
                getRowProps={styleRows}
              />
              <Pagination
                mt={rows.length === 10 && 4}
                currentPage={currentPage}
                size={pages}
                callback={handlePagination}
              />
            </>
          )}
        </ContentWrapper>
      </TableSection>
    </>
  )
}
