import React, { useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import { useActor } from '@xstate/react'
import { format } from 'date-fns'

import { SoarStateContext } from '../../global/soar.context'

import { fetchCoacheeConfig } from '@/constants/fetch-data-config'
import { coachingColumns } from '@/constants/table-columns'
import { Icon } from '@/components/Icon'
import { FiltersWrapper } from '@/components/FilterWrapper'
import { Loader } from '@/components/Loader'
import { CustomReport } from '@/components/CustomReport'
import { Title } from '@/components/Title'
import sortItemIcon from '@/assets/icons/sortItem.svg'
import chevronIcon from '@/assets/icons/chevron.svg'
import { addTotalToCoachee } from '@/helpers/addTotaltoCoachee'

import {
  HeadingSection,
  HeaderItem,
  IconWrapper,
  BodySection,
  CoachWrapper,
  CoacheeWrapper,
  RowWrapper,
  OpenIconWrapper,
  TableWrapper,
} from './styles'
import TotalRows from './components/totalRows'
import CoacheeRows from './components/coacheeRows'

export default function CoachingForm({
  orgData,
  coacheeData,
  savedViewData,
  fetchCoacheeData,
}) {
  const [columns, setColumns] = useState(coachingColumns)
  const [sortColumn, setSortColumn] = useState('')
  const [mainData, setMainData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [filtersArray, setFiltersArray] = useState([])
  const [managersList, setManagersList] = useState([])
  const [employeesList, setEmployeesList] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const soarContext = useContext(SoarStateContext)
  const [state] = useActor(soarContext.coachingService)

  useEffect(() => {
    fetchCoacheeData(fetchCoacheeConfig)
  }, [])

  useEffect(() => {
    // TO DO filtering works only for first level of data, not including coachees array
    let cloneArray = [...mainData]

    if (
      filtersArray.searchValue !== '' ||
      filtersArray.selectedLocation !== 'All Locations' ||
      filtersArray.selectedManagers !== 'all' ||
      filtersArray.selectedEmployees !== 'all'
    ) {
      if (filtersArray.searchValue !== '') {
        // filter by search value
        cloneArray = cloneArray.filter((obj) =>
          Object.values(obj).some((val) => {
            if (
              val &&
              typeof val === 'string' &&
              val.toLowerCase().includes(filtersArray.searchValue.toLowerCase())
            ) {
              return true
            }
            return false
          })
        )
      }
      // filter by location
      if (filtersArray.selectedLocation !== 'All Locations') {
        cloneArray = cloneArray.filter(
          (item) => item.location === filtersArray.selectedLocation
        )
      }
      // filter by managers
      if (filtersArray.selectedManagers !== 'all') {
        cloneArray = cloneArray.filter(
          (item) =>
            filtersArray.selectedManagers ===
            `${item.firstName.trim()} ${item.lastName.trim()}`
        )
      }
      // filter by employees
      if (filtersArray.selectedEmployees !== 'all') {
        cloneArray = cloneArray.filter((item) =>
          item.coachees.some(
            (coachee) =>
              filtersArray.selectedEmployees ===
              `${coachee.firstName.trim()} ${coachee.lastName.trim()}`
          )
        )
      }
      setFilteredData(cloneArray)
    } else {
      setFilteredData(mainData)
    }
  }, [filtersArray, mainData])

  useEffect(() => {
    const item = columns.find((column) => column.accessor === sortColumn)
    let newData = []
    if (item && item.sorting) {
      newData = _.orderBy(filteredData, [sortColumn], [item.sorting])
    } else {
      newData = _.orderBy(filteredData, ['id'], ['asc'])
    }
    setMainData(newData)
  }, [columns])

  const getManagersList = (data) => {
    const newList = [{ label: 'All Managers', value: 'all', key: 'all' }]
    data.map((item) => {
      if (item.role.toLowerCase().includes('manager')) {
        const value = `${item.firstName.trim()} ${item.lastName.trim()}`
        newList.push({
          label: value,
          value,
          key: value,
        })
      }
      return item
    })
    setManagersList(
      Array.from(new Set(newList.map(JSON.stringify))).map(JSON.parse)
    )
  }

  const generateEmployeesItem = (data) => {
    if (data && data.role.toLowerCase().includes('person')) {
      const newItem = { label: '', value: '', key: '' }
      const value = `${data.firstName.trim()} ${data.lastName.trim()}`

      newItem.label = value
      newItem.value = value
      newItem.key = value

      return newItem
    }
    return null
  }

  const getEmployeesList = (data) => {
    const newList = [{ label: 'All Employees', value: 'all', key: 'all' }]
    data.map((item) => {
      const res = generateEmployeesItem(item)
      if (res) newList.push(res)

      if (item.coachees.length > 0) {
        item.coachees.map((coachee) => {
          const res2 = generateEmployeesItem(coachee)
          if (res2) newList.push(res2)
          return coachee
        })
      }
      return item
    })
    setEmployeesList(
      Array.from(new Set(newList.map(JSON.stringify))).map(JSON.parse)
    )
  }

  useEffect(() => {
    if (coacheeData && coacheeData.data.list) {
      const res = addTotalToCoachee(coacheeData.data.list)
      getManagersList(res)
      getEmployeesList(res)
      setMainData(res)
      setIsLoaded(true)
    }
  }, [coacheeData])

  const setSorting = (item) => {
    const newColumns = []
    columns.map((newColumn) => {
      let newSort = ''
      if (newColumn.accessor === item) {
        if (newColumn.sorting === '') newSort = 'asc'
        else if (newColumn.sorting === 'asc') newSort = 'desc'
      }
      newColumns.push({ ...newColumn, sorting: newSort })
      return newColumns
    })
    setSortColumn(item)
    setColumns(newColumns)
  }

  const rotateIcon = (sort) => {
    if (sort === 'asc') return 'asc'
    if (sort === 'desc') return 'desc'
    return ''
  }

  const collapseRow = (index) => {
    const newData = [...filteredData]
    newData[index].open = !newData[index].open
    setMainData(newData)
  }

  let coachingTitle = ''
  const { startDate, endDate } = state.context.dateRange
  const strStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : ''
  const strEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : ''
  if (strStartDate) {
    coachingTitle = `(${strStartDate} to ${strEndDate})`
  }

  return (
    <>
      <CustomReport
        type="COACH"
        range={filtersArray?.range}
        location={filtersArray?.selectedLocation}
        endDate={filtersArray?.endDate}
        startDate={filtersArray?.startDate}
        manager={filtersArray?.selectedManagers}
        employee={filtersArray?.selectedEmployees}
        search={filtersArray?.searchValue}
        savedViewData={savedViewData}
        setFiltersArray={setFiltersArray}
        resetMainData={() => setMainData([])}
        fetchCoacheeData={fetchCoacheeData}
        title={coachingTitle}
      />
      <FiltersWrapper
        locationsData={orgData && orgData.locations}
        setFiltersArray={setFiltersArray}
        managersList={managersList}
        employeesList={employeesList}
        fetchCoacheeData={fetchCoacheeData}
        resetMainData={() => setMainData([])}
      />
      {mainData.length === 0 && !isLoaded && <Loader />}
      {isLoaded && filteredData.length < 1 && (
        <div style={{ textAlign: 'center' }}>
          <Title bold fontSize={7}>
            No data found
          </Title>
        </div>
      )}
      {mainData.length > 0 && filteredData.length > 0 && (
        <TableWrapper>
          <HeadingSection>
            {columns.map((column) => (
              <HeaderItem
                key={column.accessor}
                onClick={() => setSorting(column.accessor)}
              >
                {column.Header}
                <IconWrapper className={rotateIcon(column.sorting)}>
                  <Icon src={sortItemIcon} size={10} />
                </IconWrapper>
              </HeaderItem>
            ))}
          </HeadingSection>
          <BodySection>
            {filteredData.map((item, index) => (
              <RowWrapper
                className={`${index % 2 === 0 ? 'even' : 'odd'} ${
                  item.open ? 'opened' : 'collapsed'
                }`}
                style={{ height: (item.coachees.length + 1) * 50 }}
                key={item.fbUid}
              >
                <CoachWrapper
                  className={item.open ? 'opened' : 'collapsed'}
                  onClick={() => collapseRow(index)}
                >
                  {item.coachees.length > 0 && (
                    <OpenIconWrapper className={item.open ? 'open' : 'closed'}>
                      <Icon src={chevronIcon} size={20} />
                    </OpenIconWrapper>
                  )}
                  {`${item.firstName} ${item.lastName}`}
                </CoachWrapper>
                <CoacheeWrapper>
                  <CoacheeRows item={item} />
                </CoacheeWrapper>
              </RowWrapper>
            ))}
            <TotalRows mainData={filteredData} />
          </BodySection>
        </TableWrapper>
      )}
    </>
  )
}
