import axios from 'axios'
import { useMutation } from 'react-query'

import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'
import { UPDATE_INFO_ENDPOINT } from '@/constants/endpoints'

export const useEditInfoMutation = (options) =>
  useMutation(
    (payload) =>
      axios.patch(`${UPDATE_INFO_ENDPOINT}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            AUTH_TOKEN_STORAGE_KEY
          )}`,
        },
      }),
    options
  )
