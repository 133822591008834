import styled from 'styled-components'

export const SubtitleContainer = styled.p`
	text-align: ${({ center }) => (center ? 'center' : 'left')};
  color: ${({ theme, color }) => {
    if (color) {
      return theme.colors[color] || color
    }

    return theme.colors.subtitleText
  }};
  font-size: ${({ theme, fontSize }) =>
    fontSize ? theme.fontSizes[fontSize] : theme.fontSizes[6]}px;
  font-weight: ${({ theme, medium, semiBold, bold }) => {
    if (medium) {
      return theme.fontWeights.medium
    }

    if (semiBold) {
      return theme.fontWeights.semiBold
    }

    if (bold) {
      return theme.fontWeights.bold
    }

    return theme.fontWeights.normal
  }};

  line-height: ${({ theme, fontSize }) =>
    fontSize ? theme.lineHeights[fontSize] : theme.lineHeights[7]}px;
  margin-bottom: ${({ theme, margin }) =>
    margin !== undefined ? theme.spaces[margin] : theme.spaces[6]}px;
`
