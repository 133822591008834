import React from 'react'

import infoCircleIcon from '@/assets/icons/infoCircle.svg'

import { Image, Message } from './styles'

export default function CircleLegend({ iconSrc, children }) {
  return (
    <div>
      <Image src={iconSrc || infoCircleIcon} />
      <Message>{children}</Message>
    </div>
  )
}
