import axios from 'axios'
import { useMutation } from 'react-query'

import { FUNNEL_ENDPOINT } from '@/constants/endpoints'
import { createToast } from '@/helpers/createToast'

export const useAddFunnelMutation = () =>
  useMutation(
    (payload) =>
      axios.post(`${FUNNEL_ENDPOINT}`, {
        name: payload.name,
        order: payload.order,
      }),
    {
      onSuccess: () => {
        createToast('success', 'Funnel item added!')
      },
    }
  )
