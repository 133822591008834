import React from 'react'
import Input from 'react-text-mask'

import {
  Field,
  Label,
  InputWrapper,
  ErrorCircle,
  InvaildMessage,
} from './styles'

const phoneNumberMask = [
  /[1-9]/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export function MaskedInput({
  label = '',
  placeholder = '',
  error = '',
  name,
  type,
  value,
  touched,
  onChange,
  onBlur,
  ...otherProps
}) {
  return (
    <Field value={value} hasError={error} {...otherProps}>
      {label && <Label>{label}</Label>}
      <InputWrapper>
        <Input
          guide={false}
          mask={phoneNumberMask}
          type={type}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          placeholder={placeholder}
          value={value}
        />
        {error && <ErrorCircle title={error}>!</ErrorCircle>}
      </InputWrapper>
      {error && <InvaildMessage>{error}</InvaildMessage>}
    </Field>
  )
}
