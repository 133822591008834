import { Box } from 'grommet'
import React from 'react'
import { Field } from 'formik'

import { MaskedInput } from '@/components/MaskedInput'

export function MaskedInputField({ name, type = 'text', ...otherProps }) {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <Box>
          {meta.touched && meta.error ? (
            <MaskedInput
              error={meta.error}
              type={type}
              {...field}
              {...otherProps}
            />
          ) : (
            <MaskedInput type={type} {...field} {...otherProps} />
          )}
        </Box>
      )}
    </Field>
  )
}
