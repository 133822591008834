import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: ${({ isDesktop }) => (isDesktop ? 'none' : 'center')};
  display: ${({ type, isDesktop }) =>
    type === 'COACH' && isDesktop ? 'grid' : 'flex'};
  flex-direction: ${({ isDesktop }) => (isDesktop ? 'none' : 'column')};
  gap: 22px;
  grid-template-columns: ${({ isDesktop }) =>
    isDesktop ? 'repeat(5, 1fr)' : 'none'};
  justify-content: end;
  margin: ${({ theme }) => theme.spaces[5]}px 0;
  position: relative;
`

export const Grid = styled.div`
  display: ${({ isDesktop }) => (isDesktop ? 'grid' : 'none')};
`

export const TitleSection = styled.span`
  color: ${({ theme }) => theme.colors.primaryText};
  font-family: ${({ theme }) => theme.fontFamilies.default};
`
