import PropTypes from 'prop-types'
import React from 'react'
import SelectAsync from 'react-select/async'

export function SelectOrganization({
  orgQuery,
  onChange,
  orgId,
  orgName,
  style,
  inputStyle,
}) {
  const defaultValue = !orgId
    ? 'Select Organization'
    : { value: orgId, label: orgName }
  return (
    <div>
      <SelectAsync
        defaultValue={defaultValue}
        value={defaultValue}
        cacheOptions
        defaultOptions
        loadOptions={orgQuery}
        onChange={onChange}
        placeholder="Select Organization"
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            ...style,
            borderColor: state.isFocused ? 'grey' : 'black',
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            ...style,
          }),
          option: (baseStyles) => ({
            ...baseStyles,
          }),
          input: (baseStyle) => ({
            ...baseStyle,
            ...inputStyle,
          }),
        }}
      />
    </div>
  )
}

SelectOrganization.propTypes = {
  orgQuery: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  orgId: PropTypes.string,
  orgName: PropTypes.string,
}

SelectOrganization.defaultProps = {
  orgId: '',
  orgName: '',
}
