import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${({ theme, margin }) => theme.spaces[margin]}px 0;
  max-width: ${({ width }) => width};
  position: relative;
  width: 100%;
  margin-left: ${({ marginLeft }) => marginLeft};

  .react-select {
    &__control {
      border: 1px solid
        ${({ theme, error, value }) => {
          if (error) return theme.colors.error
          if (value) return theme.colors.backgroundActive
          return theme.colors.border
        }};
      border-radius: ${({ theme }) => theme.spaces[2]}px;
      box-shadow: none;
      color: ${({ theme }) => theme.colors.white};
      display: flex;
      background-color: ${({ theme }) => theme.colors.backgroundItems};
      min-height: 40px;
      width: 100%;
    }

    &__control:hover {
      border: 1px solid
        ${({ theme, error }) =>
          error ? theme.colors.errorActive : theme.colors.white};
    }

    &__container {
      width: 100%;
    }

    &__placeholder {
      color: ${({ theme, color }) => theme.colors[color]};
      white-space: nowrap;
    }

    &__value-container {
      padding-left: ${({ icon }) => (icon ? 46 : 8)}px;
    }

    &__multi-value {
      background-color: ${({ theme }) => theme.colors.tableBorder};
    }

    &__multi-value__label {
      color: ${({ theme }) => theme.colors.white};
    }

    &__multi-value__remove {
      :hover {
        background-color: ${({ theme }) => theme.colors.tableBorder};
      }
    }

    &__input-container {
      color: ${({ theme }) => theme.colors.white};
    }

    &__single-value {
      color: ${({ theme }) => theme.colors.white};
    }

    &__menu {
      background-color: ${({ theme }) => theme.colors.backgroundItems};
    }

    &__control--is-focused,
    &__control--is-focused:hover {
      border-color: ${({ theme }) => theme.colors.white};
    }

    &__option {
      color: ${({ theme }) => theme.colors.white};
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};
      padding: ${({ theme }) => theme.spaces[3]}px;
    }

    &__option:last-child {
      border-bottom: none;
    }

    &__option--is-selected {
      background-color: ${({ theme }) => theme.colors.border};
    }

    &__option--is-focused {
      background-color: ${({ theme }) => theme.colors.border};
    }

    &__option:active {
      background-color: ${({ theme }) => theme.colors.border};
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      color: ${({ theme }) => theme.colors.white};
      cursor: pointer;
      display: ${({ hideIndicator }) => (hideIndicator ? 'none' : 'flex')};
    }

    &__dropdown-indicator:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

export const IconWrapper = styled.div`
  justify-content: center;
  left: 12px;
  position: absolute;
`

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
  margin-bottom: ${({ theme }) => theme.spaces[3]}px;
  text-transform: uppercase;
`
