import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme, gap }) => theme.spaces[gap]}px;
  justify-content: space-between;
  margin-bottom: ${({ theme, margin }) => theme.spaces[margin]}px;
  margin-top: ${({ theme, marginTop }) => theme.spaces[marginTop]}px;

  ${({ theme }) => theme.below.tablet`
    ${({ noWrap }) => !noWrap && 'flex-direction: column;'}
    `}
`

export function DualFields({ children, ...props }) {
  return <Wrapper {...props}>{children}</Wrapper>
}
