import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { FormWrapper } from '@/components/FormWrapper'
import PaymentForm from '@/forms/PaymentForm'

const { REACT_APP_STRIPE_KEY } = process.env

const stripePromise = loadStripe(REACT_APP_STRIPE_KEY)

export default function StripeContainer() {
  return (
    <FormWrapper logo logoSize="regular" title="Payment">
      <Elements stripe={stripePromise}>
        <PaymentForm />
      </Elements>
    </FormWrapper>
  )
}
