import * as Yup from 'yup'

import { LOCATION_LENGTH_ERROR_MESSAGE, REQUIRED_FIELD_ERROR_MESSAGE } from '@/constants/error-messages'
import { MAX_LOCATION_LENGTH } from '@/constants/forms'

export const validationSchema = Yup.object().shape({
  value: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE).max(MAX_LOCATION_LENGTH, LOCATION_LENGTH_ERROR_MESSAGE),
})

export const DEFAULT_VALUES = {
  value: '',
}
