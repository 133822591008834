import React, { useCallback, useState } from 'react'
import { Formik } from 'formik'
import axios from 'axios'

import { GET_USERS_ENDPOINT } from '@/constants/endpoints'
import { Title } from '@/components/Title'
import { Button } from '@/components/Button'
import { Divider } from '@/components/Divider'
import { InfoCircle } from '@/components/InfoCircle'
import { DualFields } from '@/components/DualFields'
import { ModalWindow } from '@/components/ModalWindow'
import { ExpertiseField } from '@/components/Expertise'
import { InputField } from '@/fields/Input'
import { SelectField } from '@/fields/Select'
import { CheckboxField } from '@/fields/Checkbox'
import { MaskedInputField } from '@/fields/MaskedInput'
import { SelectSearchField } from '@/fields/SelectSearch'
import { formatSearchUsers, setModalValues } from '@/helpers/formatData'

import { validationSchema, DEFAULT_VALUES } from './config'
import {
  DualItemWrapper,
  ModalButtonsWrapper,
  CheckboxWrapper,
  ContactIcon,
  TitleWrapper,
} from './styles'

export function AddManagersModalForm({
  user,
  rows,
  locations,
  initialValues,
  isOpen,
  onClose,
  onSubmit,
}) {
  const [managerName, setManagerName] = useState(false)
  let searchTimeout

  const initValues = setModalValues(initialValues, user)

  const closeModal = useCallback(() => {
    setManagerName(false)
    onClose()
  }, [])

  const onFormSubmit = useCallback(
    (data) => {
      const formattedData = { ...data }
      if (typeof formattedData.manager === 'object') {
        formattedData.manager = formattedData.manager.value
      }
      formattedData.managerName =
        managerName?.label || formattedData.managerName
      onSubmit(formattedData, rows)
      closeModal()
    },
    [managerName, rows]
  )

  const loadOptions = useCallback(
    (inputValue, callback) => {
      clearTimeout(searchTimeout)
      searchTimeout = setTimeout(() => {
        axios
          .post(GET_USERS_ENDPOINT, {
            skip: 0,
            limit: 10,
            search: inputValue,
            role: 'manager',
            originManager: true,
          })
          .then(({ data: users }) => {
            const options = formatSearchUsers(users.tempUsers, true).filter(
              (item) => item.value !== user?.id
            )
            callback(options)
          })
      }, 1000)
    },
    [user]
  )

  return (
    <ModalWindow
      isOpen={isOpen}
      handleClose={closeModal}
      closeOnOverlay={false}
    >
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <TitleWrapper marginBottom={5}>
              <ContactIcon />
              <Title bold fontSize={7}>
                Edit Manager Details
              </Title>
            </TitleWrapper>
            <DualFields gap={5} margin={5}>
              <InputField name="name" label="NAME" placeholder="Full name" />
              <InputField
                type="email"
                name="email"
                label="EMAIL"
                placeholder="test@soar.com"
              />
            </DualFields>
            <DualFields gap={5} margin={5}>
              <DualItemWrapper>
                <SelectField
                  name="location"
                  label="LOCATION"
                  placeholder="Location"
                  options={
                    locations
                      ? locations.map((item) => {
                          return { value: item, label: item }
                        })
                      : []
                  }
                />
              </DualItemWrapper>
              <DualItemWrapper>
                <MaskedInputField
                  type="text"
                  name="phone"
                  label="MOBILE PHONE NUMBER"
                  placeholder="123-456-7890"
                />
              </DualItemWrapper>
            </DualFields>
            <DualFields gap={5} margin={5}>
              <DualItemWrapper>
                <SelectSearchField
                  name="manager"
                  label="MANAGER THEY REPORT TO"
                  placeholder="None (top-level manager)"
                  loadOptions={loadOptions}
                  hideIndicator={false}
                  margin={12}
                  setManagerName={setManagerName}
                  value={managerName || values.manager}
                  menuPlacement="top"
                  coach
                />
              </DualItemWrapper>
              <DualItemWrapper>
                <CheckboxWrapper modal>
                  <CheckboxField
                    name="certifiedCoach"
                    size={20}
                    label="Certified coach"
                  />
                  <InfoCircle marginLeft={3}>
                    You can designate any employee as a certified coach if you
                    want them to mentor other employees.
                  </InfoCircle>
                </CheckboxWrapper>
              </DualItemWrapper>
            </DualFields>
            {values.certifiedCoach && (
              <ExpertiseField values={values} isModal />
            )}
            <Divider size={1} margin={5} />
            <ModalButtonsWrapper>
              <Button type="submit" width="65px" fontSize={5}>
                Save
              </Button>
              <Button gray width="80px" fontSize={5} onClick={closeModal}>
                Cancel
              </Button>
            </ModalButtonsWrapper>
          </form>
        )}
      </Formik>
    </ModalWindow>
  )
}

AddManagersModalForm.defaultProps = {
  initialValues: DEFAULT_VALUES,
}
