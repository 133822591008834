import styled from 'styled-components'

export const CheckIcon = styled.img`
  height: 18px;
  width: 18px;
`

export const IconButton = styled.button`
  align-items: center;
  background: none;
  display: flex;
  height: 100%;
  margin-right: 5px;
  padding: 0;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 662px;
  width: 100%;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spaces[5]}px 0;
  width: 100%;

  ${({ theme }) => theme.below.tablet`
    align-items: center;
    flex-direction: column;
    gap: 10px;
    `}
`

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`

export const ErrorModalWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 466px;
  width: 100%;
`
