import * as Yup from 'yup'

import {
  getMaxLengthErrorMessage,
  getMinLengthErrorMessage,
  REQUIRED_FIELD_ERROR_MESSAGE,
} from '@/constants/error-messages'
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '@/constants/forms'

export const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .min(MIN_PASSWORD_LENGTH, getMinLengthErrorMessage(MIN_PASSWORD_LENGTH))
    .max(MAX_PASSWORD_LENGTH, getMaxLengthErrorMessage(MAX_PASSWORD_LENGTH))
    .matches(/^(?=.*[a-z])/, 'Must must contain at least one lowercase character')
    .matches(/^(?=.*[A-Z])/, 'Must must contain at least one uppercase character')
    .matches(/^(?=.*[0-9])/, 'Must must contain at least one numeric character'),
  newPassword: Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .min(MIN_PASSWORD_LENGTH, getMinLengthErrorMessage(MIN_PASSWORD_LENGTH))
    .max(MAX_PASSWORD_LENGTH, getMaxLengthErrorMessage(MAX_PASSWORD_LENGTH))
    .matches(/^(?=.*[a-z])/, 'Must must contain at least one lowercase character')
    .matches(/^(?=.*[A-Z])/, 'Must must contain at least one uppercase character')
    .matches(/^(?=.*[0-9])/, 'Must must contain at least one numeric character')
    .when('oldPassword', {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().notOneOf(
        [Yup.ref('oldPassword')],
        "Don't use your old password"
      ),
    }),
  reNewPassword: Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .when('newPassword', {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        'Passwords must match'
      ),
    }),
})

export const DEFAULT_VALUES = {
  oldPassword: '',
  newPassword: '',
  reNewPassword: '',
}
