import React from 'react'

import Layout from '@/components/Layout'
import { Logo } from '@/components/Logo'

import { Label, Wrapper, HeadingSection, IconWrapper } from './styles'
import '../../index.css'

export default function PaymentSuccess() {
  return (
    <Layout hideLogo>
      <div className="max-w-fit mx-auto">
        <div className="max-w-fit text-center mx-auto logo-container">
          <Logo size="regular" display="inline-block" />
        </div>

        <HeadingSection>
          <div className="text-4xl font-bold leading-8 my-2 text-white">
            Congrats, you’re in!
          </div>
        </HeadingSection>

        <div className="text-4xl font-bold text-center leading-12 my-6 text-white">
          Welcome to the SOAR. Our app is specifically designed to help you
          increase your sales and income.
        </div>

        <div className="grid lg:grid-cols-2 sm:grid-cols-1 lg:gap-2 mx-auto w-full">
          Start Setup
        </div>
      </div>
    </Layout>
  )
}
