import React from 'react'

export function OverviewSection() {
  return (
    <>
      <div id="overview" className="elementor-menu-anchor" />
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-46d4c47 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="46d4c47"
        data-element_type="section"
      >
        <div
          className="elementor-shape elementor-shape-top"
          data-negative="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 700 10"
            preserveAspectRatio="none"
          >
            <path
              className="elementor-shape-fill"
              d="M350,10L340,0h20L350,10z"
            />
          </svg>
        </div>
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-29fc296"
              data-id="29fc296"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-ba3fc5e elementor-hidden-tablet elementor-hidden-mobile elementor-widget elementor-widget-image"
                    data-id="ba3fc5e"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          width="800"
                          height="293"
                          src={`${process.env.REACT_APP_DOMAIN}/landing/logo-w-phone-1024x375.png`}
                          className="attachment-large size-large"
                          alt=""
                          loading="lazy"
                          srcSet={`${process.env.REACT_APP_DOMAIN}/landing/logo-w-phone-1024x375.png 1024w, ${process.env.REACT_APP_DOMAIN}/landing/logo-w-phone-300x110.png 300w, ${process.env.REACT_APP_DOMAIN}/landing/logo-w-phone-768x282.png 768w, ${process.env.REACT_APP_DOMAIN}/landing/logo-w-phone.png 1192w`}
                          sizes="(max-width: 800px) 100vw, 800px"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-4bf6175 elementor-hidden-desktop elementor-widget elementor-widget-image"
                    data-id="4bf6175"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          width="745"
                          height="341"
                          src="./soar_files/logo-with-tagline.png"
                          className="attachment-large size-large"
                          alt=""
                          loading="lazy"
                          srcSet="./soar_files/logo-with-tagline.png 745w, ./soar_files/logo-with-tagline-300x137.png 300w"
                          sizes="(max-width: 745px) 100vw, 745px"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-c5d4d1f elementor-widget elementor-widget-heading"
                    data-id="c5d4d1f"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h3 className="elementor-heading-title elementor-size-default">
                        Intelligence that gives you the edge you need to
                        <strong> SOAR</strong>.
                      </h3>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-06edd69 elementor-widget elementor-widget-heading"
                    data-id="06edd69"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h4 className="elementor-heading-title elementor-size-default">
                        How can soar help?
                      </h4>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-1abef7b elementor-widget elementor-widget-text-editor"
                    data-id="1abef7b"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-text-editor elementor-clearfix">
                        <p>
                          Our conversion tracking application will give you the
                          clarity and transparency you need to boost your sales
                          and increase your income.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="elementor-element elementor-element-74e24cd elementor-widget elementor-widget-html"
                    data-id="74e24cd"
                    data-element_type="widget"
                    data-widget_type="html.default"
                  >
                    <div className="elementor-widget-container" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6155d33"
              data-id="6155d33"
              data-element_type="column"
              style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-feb33af uael-aspect-ratio-16_9 uael-youtube-subscribe-no elementor-widget elementor-widget-uael-video"
                    data-id="feb33af"
                    data-element_type="widget"
                    data-widget_type="uael-video.default"
                  >
                    <div className="elementor-widget-container">
                      <div
                        className="uael-video__outer-wrap  uael-video-type-youtube uael-video-play-lightbox"
                        data-device="false"
                        data-vsticky="no"
                        data-hidedesktop=""
                        data-hidetablet=""
                        data-hidemobile=""
                        data-vsticky-viewport="0"
                        data-elementor-open-lightbox="yes"
                        data-elementor-lightbox='{"type":"video","videoType":"youtube","url":"https:\/\/www.youtube.com\/embed\/HJRzUQMhJMQ?autoplay=1&amp;rel=0&amp;start&amp;end&amp;controls=1&amp;mute=0&amp;modestbranding=0&amp;autoplay=1","modalOptions":{"id":"elementor-lightbox-feb33af","entranceAnimation":"","entranceAnimation_tablet":"","entranceAnimation_mobile":"","videoAspectRatio":"169"}}'
                      >
                        <div className="uael-video-inner-wrap">
                          <div
                            className="uael-video__play"
                            data-src="https://www.youtube.com/embed/fKqXGpTcjPs?autoplay=1&amp;rel=0&amp;start&amp;end&amp;controls=1&amp;mute=0&amp;modestbranding=0&amp;autoplay=1"
                          >
                            <iframe
                              src="https://www.youtube.com/embed/fKqXGpTcjPs"
                              width="560"
                              height="315"
                              title="SOAR promotional video"
                              frameBorder="0"
                              allowfullscreen
                            />
                            <div className="uael-video__play-icon uael-animation-">
                              <i
                                aria-hidden="true"
                                className="fa fa-play-circle"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
