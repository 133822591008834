import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-left: ${({ theme, marginLeft }) =>
    marginLeft && theme.spaces[marginLeft]}px;
  position: relative;

  &:hover {
    > div {
      display: block;
    }
  }
`

export const Message = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: ${({ theme }) => theme.spaces[2]}px;
  color: ${({ theme }) => theme.colors.primaryText};
  cursor: pointer;
  display: none;
  line-height: ${({ theme }) => theme.lineHeights[5]}px;
  max-width: 300px;
  padding: ${({ theme }) => theme.spaces[4]}px
    ${({ theme }) => theme.spaces[5]}px;
  position: absolute;
  right: ${({ right }) => (right ? -150 : 0)}px;
  top: 22px;
  width: max-content;
  z-index: 5;
`
