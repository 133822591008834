import '../../../index.css'

import React, { useCallback, useEffect, useState } from 'react'
import { filter, orderBy } from 'lodash'
import {
  searchAndFilterArrayOfObjects,
  searchAndFilterArrayOfObjectsViaKeyValue,
} from '@/utils/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icon } from '@/components/Icon'
import { SearchAdminMenu } from '@/pages/SuperAdminMenu/SearchAdminMenu'
import { SettingsResetModalForm } from '@/forms/SettingsResetModal'
import { Table } from '@/components/Table'
import { UserStatusFilters } from '@/pages/SuperAdminMenu/UserStatusFilters'
import { createToast } from '@/helpers/createToast'
import editIcon from '@/assets/icons/editIcon.svg'
import { fetchConfig } from '@/constants/fetch-data-config'
import reloadIcon from '@/assets/icons/reloadIcon.svg'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useResetManagerMutation } from '@/queries/reset-password'
import { useUpdateUsersQuery } from '@/queries/update-users'
import { useUsersCrossOrgMutation } from '@/queries/users-cross-org'
import { ThreeDots } from  'react-loader-spinner'

function SortArrow({ sort, direction }) {
  const iconDir =
    direction === 'desc' ? solid('chevron-down') : solid('chevron-up')
  return (
    <FontAwesomeIcon
      onClick={() => sort()}
      icon={iconDir}
      className="ml-4 text-green-600"
    />
  )
}

export function EmployeeCrossOrg({ sectionToShow, onEditEmployee }) {
  const [employeeRecords, setEmployeeRecords] = useState([])
  const [error, setError] = useState('')
  const [nameDir, setNameDir] = useState('desc')
  const [emailDir, setEmailDir] = useState('desc')
  const [statusDir, setStatusDir] = useState('desc')
  const [locationDir, setLocationDir] = useState('desc')
  const [organizationDir, setOrganizationDir] = useState('desc')
  const [filteredRows, setFilteredRows] = useState([])
  const [checkedRow, setCheckedRow] = useState({})
  const [config, setConfig] = useState(fetchConfig)
  const [selectAllRows, setSelectAllRows] = useState(false)
  const [selectedRowsCount, setSelectedRowsCount] = useState(0)
  const [changingUser, setChangingUser] = useState(false)
  const [modal, setModal] = useState('')
  const [isRequestLoading, setRequestLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const closeModal = useCallback(() => {
    setModal('')
    setChangingUser(false)
  }, [])

  const statusClass = {
    newUser: 'font-bold text-white rounded-md bg-sky-500 py-1 px-2',
    setupComplete: 'font-bold text-gray-200 rounded-md bg-slate-500 py-1 px-2',
    trainingComplete:
      'font-bold text-gray-200 rounded-md bg-slate-500 py-1 px-2',
    active: 'font-bold text-green-200 rounded-md bg-green-900 py-1 px-2',
    inActive: 'font-bold text-red-200 rounded-md bg-red-900 py-1 px-2',
  }

  const setStatusClass = (statusValue) => {
    switch (statusValue) {
      case 'New User':
        return statusClass.newUser
        break
      case 'Setup Complete':
        return statusClass.setupComplete
        break
      case 'Training Complete':
        return statusClass.trainingComplete
        break
      case 'Active':
        return statusClass.active
        break
      case 'Inactive':
        return statusClass.inActive
        break
    }
  }

  const {
    mutate: fetchUsersCrossOrg,
    data: usrData,
    isLoading: usersIsLoad,
  } = useUsersCrossOrgMutation({
    onSuccess: (usrData) => {
      if (usrData?.data?.list.length === 0)
        createToast('error', 'No Records found!');
      else
        setEmployeeRecords(usrData?.data?.list || []);
    },
    onError: (err) => {
      createToast('error', err.response.data.message)
    },
  })

  const calRowsCount = () => {
    let count = 0
    for (const rowId in checkedRow) {
      if (checkedRow[rowId].checked) count++
    }
    setSelectedRowsCount(count)
  }

  const handleToggleRecord = (row) => {
    setCheckedRow((prev) => {
      const clone = { ...prev }
      clone[row.row.id].checked = !clone[row.row.id].checked
      return clone
    })

    calRowsCount()
  }

  const handleToggleAllRecords = () => {
    const cloneData = checkedRow
    for (const key in cloneData) {
      cloneData[key].checked = !selectAllRows
    }
    setCheckedRow(cloneData)
		if(!selectAllRows)
			setSelectedRowsCount(employeeRecords.length)
		else
			setSelectedRowsCount(0);

    setSelectAllRows((current) => !current)
    // calRowsCount()
  }

  const filterSelectedRows = async (newStatus) => {
    const cloneData = checkedRow

    const checkedIds = []
    for (const key in cloneData) {
      if (checkedRow[key].checked) {
        checkedIds.push(checkedRow[key].fbUid)
        checkedRow[key].checked = false
      }
    }

    if (checkedIds.length > 0) {
      const result = await useUpdateUsersQuery(newStatus, checkedIds)

      if (result.data.message === 'success') {
        createToast(
          'success',
          `User${checkedIds.length > 1 ? 's' : ''} updated!`
        )

        await fetchUsersCrossOrg({
          skip: 0,
          limit: 50,
          search: searchQuery,
          originManager: false,
        })
      } else {
        createToast('error', result.message)
      }
    }

    calRowsCount()
  }

  const toggleInactive = (e) => {
		if(selectAllRows)
			handleToggleAllRecords();
    let isChecked = null
    if (e.target.tagName === 'INPUT') {
      isChecked = e.target.checked
    }

    if (e.target.tagName === 'DIV') {
      isChecked = !e.target.firstChild.checked
      e.target.firstChild.checked = isChecked
    }

    if (isChecked)
      setEmployeeRecords(
        searchAndFilterArrayOfObjects(employeeRecords, 'Inactive')
      )
    if (!isChecked) {
      setEmployeeRecords(usrData?.data?.list)
    }
  }

  const searchRecordsViaText = async (searchInput) => {
    const searchString = searchInput.toString().toLowerCase().trim()
    setSearchQuery(searchString)
    const searchResult = await fetchUsersCrossOrg({
      skip: 0,
      limit: 50,
      search: searchString,
      originManager: false,
    })
  }

  const sortByName = (order) => {
    const sortedRecords = orderBy(employeeRecords, ['name'], [order])
    setEmployeeRecords(sortedRecords)
  }

  const sortByEmail = (order) => {
    const sortedRecords = orderBy(employeeRecords, ['email'], [order])
    setEmployeeRecords(sortedRecords)
  }

  const sortByStatus = (order) => {
    const sortedRecords = orderBy(employeeRecords, ['status'], [order])
    setEmployeeRecords(sortedRecords)
  }

  const sortByLocation = (order) => {
    const sortedRecords = orderBy(employeeRecords, ['location'], [order])
    setEmployeeRecords(sortedRecords)
  }

  const sortByOrganization = (order) => {
    const sortedRecords = orderBy(employeeRecords, ['organization'], [order])
    setEmployeeRecords(sortedRecords)
  }

  const { mutate: resetUser, isLoading: resetUserLoad } =
    useResetManagerMutation({
      onError: (err) => {
        setError(err.response.data.message)
      },
    })

  const submitResetForm = useCallback(
    (formData) => {
      resetUser(formData)
      setRequestLoading(true)
      closeModal()
      setTimeout(() => {
        setRequestLoading(false)
      }, 1000)
    },
    [config]
  )

  const handleResetPassword = (user) => {
    setChangingUser(user)
    setModal('reset')
  }

  const handleRoleDropDown = (selectedValue) => {
		if(selectAllRows)
			handleToggleAllRecords();
    const dataList = usrData?.data?.list
    if (selectedValue === 'All Employees') {
      setEmployeeRecords(dataList)
      return
    }
    setEmployeeRecords(
      searchAndFilterArrayOfObjectsViaKeyValue(
        usrData?.data?.list,
        selectedValue.toString().toLowerCase().trim()
      )
    )
  }

  useEffect(() => {
    if (employeeRecords) {
      employeeRecords.forEach((emp, index) => {
        checkedRow[index] = emp
        checkedRow[index].checked = false
      })
      setCheckedRow(checkedRow)
    }
  }, [employeeRecords])

  const handleEditEmployee = (usrData) => {
    onEditEmployee(usrData)
  }

  useEffect(() => {
    setConfig({
      skip: 0,
      limit: 100,
      search: '',
      originManager: false,
    })
  }, [])

  useEffect(() => {
    fetchUsersCrossOrg({
      skip: 0,
      limit: 50,
      search: '',
      originManager: false,
    })
  }, [])

  return (
    <div className="text-white pt-12">
      <UserStatusFilters
        selectedCount={selectedRowsCount}
        onClick={async (newStatus) => await filterSelectedRows(newStatus)}
      />
      <SearchAdminMenu
        fetchData={fetchUsersCrossOrg}
        toggleInactive={toggleInactive}
        onSearch={(searchText) => searchRecordsViaText(searchText)}
        sectionToShow={sectionToShow}
        onChange={handleRoleDropDown}
        searchInputWidth="w-1/2"
      />

      <SettingsResetModalForm
        isOpen={modal === 'reset'}
        closeModal={closeModal}
        onSubmit={submitResetForm}
        user={changingUser}
      />
      {usersIsLoad ? (
        <ThreeDots 
          height="100" 
          width="100" 
          radius="12"
          color="#4fa94d" 
          ariaLabel="three-dots-loading"
          wrapperStyle={{ 'margin-left': '45%', 'margin-top': '20%' }}
          wrapperClassName=""
          visible={true}
        />
      ) : (
        <Table
          className="mt-8 border rounded-xl"
          style={{ borderColor: '#64748b' }}
          wide
          align="left"
          data={employeeRecords}
          filterable
          defaultFilterMethod={(filter, row) =>
            row[filter.id] !== undefined
              ? String(row[filter.id])
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              : false
          }
          columns={[
            {
              id: 'checkbox',
              accessor: '',
              Cell: (row) => {
                return (
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={checkedRow[row.row.id]?.checked}
                    onChange={() => handleToggleRecord(row)}
                  />
                )
              },
              Header: (row) => {
                return (
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={selectAllRows}
                    onChange={() => handleToggleAllRecords()}
                  />
                )
              },
              sortable: false,
              width: 45,
            },
            {
              Header: () => {
                return (
                  <div className="flex items-center" style={{position: 'relative', top: 0, right: 0}}>
                    Employee Name
                    <SortArrow
                      sort={() => {
                        sortByName(nameDir)
                        if (nameDir === 'desc') return setNameDir('asc')
                        return setNameDir('desc')
                      }}
                      direction={nameDir}
                    />
                  </div>
                )
              },
              accessor: 'Employee Name',
              Cell: (row) => {
                return (
                  <div>
                    {row.cell.row.original.name}
                    {row.cell.row.original.isAdmin ? (
                      <FontAwesomeIcon
                        icon={solid('a')}
                        className="bg-yellow-500 text-black rounded-full p-1 text-xs ml-2"
                      />
                    ) : null}
                    {row.cell.row.original.certifiedCoach ? (
                      <FontAwesomeIcon
                        icon={solid('c')}
                        className="bg-green-500 text-black rounded-full p-1 text-xs ml-2"
                      />
                    ) : null}
                  </div>
                )
              },
            },
            {
              Header: () => {
                return (
                  <div className="flex items-center" style={{position: 'relative', top: 0, right: 0}}>
                    Email
                    <SortArrow
                      sort={() => {
                        sortByEmail(emailDir)
                        if (emailDir === 'desc') return setEmailDir('asc')
                        return setEmailDir('desc')
                      }}
                      direction={emailDir}
                    />
                  </div>
                )
              },
              accessor: 'email',
            },
            {
              Header: 'Phone',
              accessor: 'phone',
            },
            {
              accessor: 'location',
              Header: () => {
                return (
                  <div className="flex items-center" style={{position: 'relative', top: 0, right: 0}}>
                    Location
                    <SortArrow
                      sort={() => {
                        sortByLocation(locationDir)
                        if (locationDir === 'desc') return setLocationDir('asc')
                        return setLocationDir('desc')
                      }}
                      direction={locationDir}
                    />
                  </div>
                )
              },
            },
            {
              accessor: 'organization',
              Header: () => {
                return (
                  <div className="flex items-center" style={{position: 'relative', top: 0, right: 0}}>
                    Organization
                    <SortArrow
                      sort={() => {
                        sortByOrganization(organizationDir)
                        if (organizationDir === 'desc')
                          return setOrganizationDir('asc')
                        return setOrganizationDir('desc')
                      }}
                      direction={organizationDir}
                    />
                  </div>
                )
              },
            },
            {
              Header: 'Role',
              accessor: 'role',
            },
            {
              Header: () => {
                return (
                  <div className="flex items-center" style={{position: 'relative', top: 0, right: 0}}>
                    Status
                    <SortArrow
                      sort={() => {
                        sortByStatus(statusDir)
                        if (statusDir === 'desc') return setStatusDir('asc')
                        return setStatusDir('desc')
                      }}
                      direction={statusDir}
                    />
                  </div>
                )
              },
              accessor: 'status',
              Cell: (row) => {
                return (
                  <div style={{ minWidth: '130px' }}>
                    <div
                      id={`status${row.row.id}`}
                      style={{
                        width: 'fit-content',
                        padding: '1px 8px 1px 8px !important',
                        fontSize: '13px',
                      }}
                      className={setStatusClass(row.cell.row.original.status)}
                    >
                      {row.cell.row.original.status}
                    </div>
                  </div>
                )
              },
            },
            {
              Header: 'Action',
              accessor: '',
              Cell: (row) => {
                return (
                  <div className="flex">
                    <button
                      className="hover:bg-transparent w-4 h-full mr-3"
                      onClick={() =>
                        handleResetPassword({
                          fbUid: row.cell.row.original.fbUid,
                          name: row.cell.row.original.name,
                          email: row.cell.row.original.email,
                        })
                      }
                    >
                      <Icon style={{ marginRight: '0px' }} src={reloadIcon} />
                    </button>
                    <button className="hover:bg-transparent w-5 h-full">
                      <Icon
                        style={{ marginRight: '0px' }}
                        src={editIcon}
                        onClick={() => handleEditEmployee(row?.row?.original)}
                      />
                    </button>
                  </div>
                )
              },
            },
          ]}
        />
      )}
    </div>
  )
}
