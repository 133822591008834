import React from 'react'
import { Box } from 'grommet'

import { Wrapper } from './styles'

export function Button({
  border,
  width,
  padding,
  type,
  children,
  margin,
  onClick,
  ...props
}) {
  return (
    <Box
      border={typeof border === 'boolean' && border}
      margin={margin}
      {...props}
    >
      <Wrapper
        border={border}
        margin={typeof margin === 'number' && margin}
        width={width}
        padding={padding}
        type={type}
        onClick={onClick}
        {...props}
      >
        {children}
      </Wrapper>
    </Box>
  )
}
