import { createMachine, assign } from 'xstate'

export const settingsMachine = createMachine({
  id: 'settings',
  initial: 'init',
  context: {
    all_organization: false,
  },
  states: {
    init: {
      on: {
        SELECTED_ALL_ORGANIZATION: {
          actions: assign({
            all_organization: (context, event) => {
              return event.allOrganization
            },
          }),
        },
      },
    },
    end: {},
  },
})
