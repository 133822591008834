export const HOME_ROUTE = '/home'
export const WELCOME_ROUTE = '/welcome'
export const SIGN_IN_ROUTE = '/sign-in'
export const SIGN_UP_ROUTE = '/sign-up'
export const CREATE_PASSWORD_ROUTE = '/setup-password'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const RECOVERY_LINK_ROUTE = '/recovery-link'
export const SUCCESS_REGISTRATION_ROUTE = '/success-registration'
export const CREATE_ORGANIZATION_ROUTE = '/registration'
export const DOWNLOAD_SPREADSHEET_ROUTE = '/spreadsheet'
export const UPLOAD_SPREADSHEET_ROUTE = '/spreadsheet/2'
export const ORGANIZATION_DATA_ROUTE = '/registration/1'
export const ADD_MANAGERS_ROUTE = '/registration/2'
export const ADD_EMPLOYEES_ROUTE = '/registration/3'
export const REVIEW_INFO_ROUTE = '/registration/4'
export const SEND_INVITES_ROUTE = '/registration/4/invites'
export const MANAGE_REPORTING_ROUTE = '/registration/5'
export const DASHBOARD_ROUTE = '/dashboard'
export const BILLING_ROUTE = '/billing'
export const SETTINGS_ROUTE = '/settings'
export const PRIVACY_POLICY = '/privacy_policy'
export const COACHING_ROUTE = '/coaching'
export const SUBSCRIPTION_PLANS_ROUTE = '/plans'
export const PAYMENT_ROUTE = '/payment'
export const PAYMENT_SUCCESS_ROUTE = '/thankyou'
export const CHOOSE_YOUR_PLAN = '/choose_your_plan'
export const CONGRATS_YOU_ARE_IN = '/congrats_you_are_in'
export const CONFIRM_YOUR_PLAN_ROUTE = '/confirm_your_plan'
export const LANDING_ROUTE = '/'
export const ORGANIZATIONS_ROUTE = '/organizations'
export const SUPER_ADMIN_MENU = '/super_admin_menu'
