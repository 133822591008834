import { NoDataDiv } from '@/components/TrendsGraph/styles'
import React from 'react'

import { Title } from '@/components/Title'
import { Loader } from '@/components/Loader'

import { Wrapper, TitleWrapper, ContentWrapper } from './styles'

export function GraphWrapper({ title, children, isLoading }) {
  return (
    <Wrapper
      style={{
        width: title === 'Trends' ? '690px' : '375px',
        height: 'auto',
        // height: '561px',
      }}
    >
      <TitleWrapper style={{ marginTop: '10px' }}>
        <Title bold fontSize={7}>
          {title}
        </Title>
      </TitleWrapper>
      <ContentWrapper>
        {isLoading ? (
          <Loader size={50} />
        ) : children ? (
          children
        ) : (
          <NoDataDiv>No Data</NoDataDiv>
        )}
      </ContentWrapper>
    </Wrapper>
  )
}
