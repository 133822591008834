import axios from 'axios'
import { useMutation } from 'react-query'

import { DASHBOARD_GRAPH_DATA_ENDPOINT } from '@/constants/endpoints'

export const useGraphDataMutation = (options, orgId) =>
  useMutation(
    (
      payload = {
        date: '2022-04',
        locations: [],
        search: '',
      }
    ) => {
      if (!payload?.locations?.length) return null
      return axios.post(`${DASHBOARD_GRAPH_DATA_ENDPOINT}/${orgId}`, payload)
    },
    options
  )
