import { CoachingSection } from '@/components/CoachingSection'
import { FAQSection } from '@/components/FAQSection'
import { FeaturesSection } from '@/components/FeaturesSection'
import { Footer } from '@/components/Footer'
import { HeaderSection } from '@/components/HeaderSection'
import { HowItWorksSection } from '@/components/HowItWorksSection'
import { OverviewSection } from '@/components/OverviewSection'
import { PricingSection } from '@/components/PricingSection'
import React from 'react'

export function Landing() {
  return (
    <div>
      <style>
        {
          '\
        body .home.page-template div {\
          font-size: 18px;\
        }\
      '
        }
      </style>
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/initial.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/soar.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/general.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/style.min_1.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing//theme.min.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/elementor-icons.min.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/frontend-legacy.min.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/frontend.min_0.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing//post-429.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/default-overrides.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/frontend.min_1.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/uael-frontend.min.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/all.min.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/v4-shims.min.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/nicons.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/frontend.min_2.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/post-326.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/post-394.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/post-358.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/fontawesome.min.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/solid.min.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/brands.min.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/soar-style.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/awesome-list.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/post-773.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/post-1172.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/formreset.min.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/formsmain.min.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/readyclass.min.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/browsers.min.css`}
      />
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_DOMAIN}/landing/animations.min.css`}
      />

      <div className="home page-template page-template-elementor_header_footer page page-id-326 wp-custom-logo elementor-default elementor-template-full-width elementor-kit-429 elementor-page elementor-page-326 e--ua-blink e--ua-chrome e--ua-mac e--ua-webkit">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width="0"
          height="0"
          focusable="false"
          role="none"
          style={{
            visibility: 'hidden',
            position: 'absolute',
            left: '-9999px',
            overflow: 'hidden',
          }}
        >
          <defs>
            <filter id="wp-duotone-dark-grayscale">
              <fecolormatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <fecomponenttransfer colorInterpolationFilters="sRGB">
                <fefuncr type="table" tableValues="0 0.498039215686" />
                <fefuncg type="table" tableValues="0 0.498039215686" />
                <fefuncb type="table" tableValues="0 0.498039215686" />
                <fefunca type="table" tableValues="1 1" />
              </fecomponenttransfer>
              <fecomposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width="0"
          height="0"
          focusable="false"
          role="none"
          style={{
            visibility: 'hidden',
            position: 'absolute',
            left: '-9999px',
            overflow: 'hidden',
          }}
        >
          <defs>
            <filter id="wp-duotone-grayscale">
              <fecolormatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <fecomponenttransfer colorInterpolationFilters="sRGB">
                <fefuncr type="table" tableValues="0 1" />
                <fefuncg type="table" tableValues="0 1" />
                <fefuncb type="table" tableValues="0 1" />
                <fefunca type="table" tableValues="1 1" />
              </fecomponenttransfer>
              <fecomposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width="0"
          height="0"
          focusable="false"
          role="none"
          style={{
            visibility: 'hidden',
            position: 'absolute',
            left: '-9999px',
            overflow: 'hidden',
          }}
        >
          <defs>
            <filter id="wp-duotone-purple-yellow">
              <fecolormatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <fecomponenttransfer colorInterpolationFilters="sRGB">
                <fefuncr
                  type="table"
                  tableValues="0.549019607843 0.988235294118"
                />
                <fefuncg type="table" tableValues="0 1" />
                <fefuncb
                  type="table"
                  tableValues="0.717647058824 0.254901960784"
                />
                <fefunca type="table" tableValues="1 1" />
              </fecomponenttransfer>
              <fecomposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width="0"
          height="0"
          focusable="false"
          role="none"
          style={{
            visibility: 'hidden',
            position: 'absolute',
            left: '-9999px',
            overflow: 'hidden',
          }}
        >
          <defs>
            <filter id="wp-duotone-blue-red">
              <fecolormatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <fecomponenttransfer colorInterpolationFilters="sRGB">
                <fefuncr type="table" tableValues="0 1" />
                <fefuncg type="table" tableValues="0 0.278431372549" />
                <fefuncb
                  type="table"
                  tableValues="0.592156862745 0.278431372549"
                />
                <fefunca type="table" tableValues="1 1" />
              </fecomponenttransfer>
              <fecomposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width="0"
          height="0"
          focusable="false"
          role="none"
          style={{
            visibility: 'hidden',
            position: 'absolute',
            left: '-9999px',
            overflow: 'hidden',
          }}
        >
          <defs>
            <filter id="wp-duotone-midnight">
              <fecolormatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <fecomponenttransfer colorInterpolationFilters="sRGB">
                <fefuncr type="table" tableValues="0 0" />
                <fefuncg type="table" tableValues="0 0.647058823529" />
                <fefuncb type="table" tableValues="0 1" />
                <fefunca type="table" tableValues="1 1" />
              </fecomponenttransfer>
              <fecomposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width="0"
          height="0"
          focusable="false"
          role="none"
          style={{
            visibility: 'hidden',
            position: 'absolute',
            left: '-9999px',
            overflow: 'hidden',
          }}
        >
          <defs>
            <filter id="wp-duotone-magenta-yellow">
              <fecolormatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <fecomponenttransfer colorInterpolationFilters="sRGB">
                <fefuncr type="table" tableValues="0.780392156863 1" />
                <fefuncg type="table" tableValues="0 0.949019607843" />
                <fefuncb
                  type="table"
                  tableValues="0.352941176471 0.470588235294"
                />
                <fefunca type="table" tableValues="1 1" />
              </fecomponenttransfer>
              <fecomposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width="0"
          height="0"
          focusable="false"
          role="none"
          style={{
            visibility: 'hidden',
            position: 'absolute',
            left: '-9999px',
            overflow: 'hidden',
          }}
        >
          <defs>
            <filter id="wp-duotone-purple-green">
              <fecolormatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <fecomponenttransfer colorInterpolationFilters="sRGB">
                <fefuncr
                  type="table"
                  tableValues="0.650980392157 0.403921568627"
                />
                <fefuncg type="table" tableValues="0 1" />
                <fefuncb type="table" tableValues="0.447058823529 0.4" />
                <fefunca type="table" tableValues="1 1" />
              </fecomponenttransfer>
              <fecomposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width="0"
          height="0"
          focusable="false"
          role="none"
          style={{
            visibility: 'hidden',
            position: 'absolute',
            left: '-9999px',
            overflow: 'hidden',
          }}
        >
          <defs>
            <filter id="wp-duotone-blue-orange">
              <fecolormatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <fecomponenttransfer colorInterpolationFilters="sRGB">
                <fefuncr type="table" tableValues="0.0980392156863 1" />
                <fefuncg type="table" tableValues="0 0.662745098039" />
                <fefuncb
                  type="table"
                  tableValues="0.847058823529 0.419607843137"
                />
                <fefunca type="table" tableValues="1 1" />
              </fecomponenttransfer>
              <fecomposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>

        <HeaderSection />

        <div
          data-elementor-type="wp-post"
          data-elementor-id="326"
          className="elementor elementor-326"
        >
          <div className="elementor-inner">
            <div className="elementor-section-wrap">
              <section
                className="header-banner-main elementor-section elementor-top-section elementor-element elementor-element-703d86d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="703d86d"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div
                      className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-a964916"
                      data-id="a964916"
                      data-element_type="column"
                    >
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div
                            className="elementor-element elementor-element-1ec947a elementor-headline--style-highlight elementor-widget elementor-widget-animated-headline"
                            data-id="1ec947a"
                            data-element_type="widget"
                            data-widget_type="animated-headline.default"
                          >
                            <div className="elementor-widget-container">
                              <h1 className="elementor-headline e-animated">
                                <span className="elementor-headline-plain-text elementor-headline-text-wrapper">
                                  Get more<strong> sales</strong> from{' '}
                                </span>
                                <span className="elementor-headline-dynamic-wrapper elementor-headline-text-wrapper">
                                  <span className="elementor-headline-dynamic-text elementor-headline-text-active">
                                    every lead.
                                  </span>

                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 500 150"
                                    preserveAspectRatio="none"
                                  >
                                    <path d="M9.3,127.3c49.3-3,150.7-7.6,199.7-7.4c121.9,0.4,189.9,0.4,282.3,7.2C380.1,129.6,181.2,130.6,70,139 c82.6-2.9,254.2-1,335.9,1.3c-56,1.4-137.2-0.3-197.1,9" />
                                  </svg>

                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 500 150"
                                    preserveAspectRatio="none"
                                  >
                                    <path d="M9.3,127.3c49.3-3,150.7-7.6,199.7-7.4c121.9,0.4,189.9,0.4,282.3,7.2C380.1,129.6,181.2,130.6,70,139 c82.6-2.9,254.2-1,335.9,1.3c-56,1.4-137.2-0.3-197.1,9" />
                                  </svg>
                                </span>
                              </h1>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-91490c3 elementor-widget elementor-widget-text-editor"
                            data-id="91490c3"
                            data-element_type="widget"
                            data-widget_type="text-editor.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-text-editor elementor-clearfix">
                                <p>
                                  The fast, simple, and easy way to <br />
                                  make{' '}
                                  <strong>
                                    sales, results and income
                                  </strong>{' '}
                                  SOAR.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-27ceef3 elementor-widget__width-auto elementor-widget elementor-widget-button"
                            data-id="27ceef3"
                            data-element_type="widget"
                            data-widget_type="button.default"
                          >
                            <div className="elementor-widget-container" style={{display: 'none'}}>
                              <div className="elementor-button-wrapper">
                                <a
                                  href="#overview"
                                  className="elementor-button-link elementor-button elementor-size-sm elementor-animation-grow"
                                  role="button"
                                >
                                  <span className="elementor-button-content-wrapper">
                                    <span className="elementor-button-text">
                                      View Demo
                                    </span>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-11ba98d elementor-widget__width-auto elementor-widget elementor-widget-button"
                            data-id="11ba98d"
                            data-element_type="widget"
                            data-widget_type="button.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-button-wrapper">
                                <a
                                  href="#features"
                                  className="elementor-button-link elementor-button elementor-size-sm elementor-animation-grow"
                                  role="button"
                                >
                                  <span className="elementor-button-content-wrapper">
                                    <span className="elementor-button-text">
                                      View Features
                                    </span>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-4bcf13e"
                      data-id="4bcf13e"
                      data-element_type="column"
                    >
                      <div className="elementor-column-wrap">
                        <div className="elementor-widget-wrap" />
                      </div>
                    </div>
                    <div
                      className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-1027871"
                      data-id="1027871"
                      data-element_type="column"
                    >
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div
                            className="elementor-element elementor-element-d5963d5 elementor-widget elementor-widget-image"
                            data-id="d5963d5"
                            data-element_type="widget"
                            data-widget_type="image.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-image header-foreground">
                                <img
                                  width="800"
                                  height="433"
                                  src={`${process.env.REACT_APP_DOMAIN}/landing/Group-7673-1024x554.png`}
                                  className="attachment-large size-large"
                                  alt=""
                                  loading="lazy"
                                  srcSet={`${process.env.REACT_APP_DOMAIN}/landing/Group-7673-1024x554.png 1024w, ${process.env.REACT_APP_DOMAIN}/landing/Group-7673-300x162.png 300w, ${process.env.REACT_APP_DOMAIN}/landing/Group-7673-768x415.png 768w, ${process.env.REACT_APP_DOMAIN}/landing/Group-7673-1536x831.png 1536w, ${process.env.REACT_APP_DOMAIN}/Group-7673.png 1794w`}
                                  sizes="(max-width: 800px) 100vw, 800px"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <OverviewSection />

              <FeaturesSection />

              <CoachingSection />

              <HowItWorksSection />

              <PricingSection />

              <FAQSection />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  )
}
