import styled from 'styled-components'

import { Icon as BaseIcon } from '@/components/Icon'

export const Wrapper = styled.div`
  height: 72px;
  position: relative;
`

export const Field = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundItems};
  border: 1px solid
    ${({ theme, value }) =>
      value ? theme.colors.backgroundActive : theme.colors.border};
  border-radius: ${({ theme }) => theme.spaces[2]}px;
  color: ${({ theme, value }) =>
    value ? theme.colors.primaryText : theme.colors.inactiveText};
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.fontFamilies.default};
  font-size: ${({ theme }) => theme.fontSizes[5]}px;
  height: 40px;
  padding: 12px 14px 14px 10px;
  position: relative;
  transition: 0.1s all;
  width: 100%;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.white};
  }
`

export const Icon = styled(BaseIcon)`
  position: absolute;
  right: 10px;
`

export const Modal = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundItems};
  border: ${({ theme, modal }) =>
    modal && `1px solid ${theme.colors.tableBorder}`};
  border-radius: ${({ theme }) => theme.spaces[2]}px;
  bottom: ${({ isDesktop, modal }) => (isDesktop || modal) && 42}px;
  cursor: default;
  display: ${({ isOpen }) => !isOpen && 'none'};
  height: 242px;
  padding: ${({ theme }) => theme.spaces[2]}px;
  position: absolute;
  top: ${({ isDesktop, modal }) => !isDesktop && !modal && 72}px;
  width: 100%;
  z-index: 7;
`

export const CheckboxWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.inactiveText};
  display: flex;
  margin: 10px;
`

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
  margin-bottom: ${({ theme }) => theme.spaces[3]}px;
  text-transform: uppercase;
`
