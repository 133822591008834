import React from 'react'

import { Title } from '@/components/Title'
import { Logo } from '@/components/Logo'
import { APP_IOS_URI, APP_ANDROID_URI } from '@/constants/endpoints'

import {
  Subtitle,
  MobileStoreLink,
  MobileStoreImage,
  SuccessRegistrationContainer,
  Header,
  QrContainer,
  QrLink,
} from './styles'

export function SuccessRegistrationForm() {
  return (
    <SuccessRegistrationContainer>
      <Header>
        <Logo size="regular" />
        <Title semiBold as="span" fontSize={13} margin={6}>
          Congrats, you’re in!
        </Title>
        <Subtitle margin={10}>
          Press the button below to download the app and sign in using the email
          associated with your SOAR app invitation
        </Subtitle>
      </Header>
      <QrContainer>
        <QrLink>
          <MobileStoreLink href={APP_IOS_URI}>
            <MobileStoreImage src="/appStore.png" />
          </MobileStoreLink>
          <MobileStoreLink href={APP_IOS_URI}>
            <MobileStoreImage src="/qrcode-ios.png" />
          </MobileStoreLink>
        </QrLink>
        <QrLink>
          <MobileStoreLink href={APP_ANDROID_URI}>
            <MobileStoreImage src="/googlePlay.png" />
          </MobileStoreLink>
          <MobileStoreLink href={APP_ANDROID_URI}>
            <MobileStoreImage src="/qrcode-android.png" />
          </MobileStoreLink>
        </QrLink>
      </QrContainer>
    </SuccessRegistrationContainer>
  )
}
