import React from 'react'

import { DualScreenLayout } from '@/components/DualScreenLayout'
import backgroundImage from '@/assets/images/signUp-password-background.svg'
import signUpPasswordImage from '@/assets/images/signUp-password-image.png'
import { SuccessRegistrationForm } from '@/forms/SuccessRegistration'

export default function SuccessRegistration() {
  return (
    <DualScreenLayout
      image={signUpPasswordImage}
      imageWidth="90%"
      background={backgroundImage}
    >
      <SuccessRegistrationForm />
    </DualScreenLayout>
  )
}
