import React, { useState } from 'react'

import activeStatus from '@/assets/icons/activeStatus.svg'
import inactiveStatus from '@/assets/icons/inactiveStatus.svg'
import setupComplete from '@/assets/icons/setupComplete.svg'
import trainingComplete from '@/assets/icons/trainingComplete.png'

export function UserStatusFilters({ selectedCount, onClick, ...props }) {
  return (
    <div className="flex items-center pb-10">
      <button
        onClick={async () => await onClick('Active')}
        {...props}
        className="bg-transparent rounded-md text-white py-2 px-4 w-auto mr-5 text-base border-solid border-slate-500 bg-slate-800"
      >
        <span
          style={{
            backgroundImage: `url(${activeStatus})`,
            backgroundPosition: 'left',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            paddingLeft: '1.8rem',
          }}
          className="w-8 h-6 align-middle display-inline-block"
        />
        Mark as Active ({selectedCount})
      </button>
      <button
        onClick={async () => await onClick('Inactive')}
        {...props}
        className="bg-transparent rounded-md text-white py-2 px-4 w-auto mr-5 text-base border-solid border-slate-500 bg-slate-800"
      >
        <span
          style={{
            backgroundImage: `url(${inactiveStatus})`,
            backgroundPosition: 'left',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            paddingLeft: '1.8rem',
          }}
          className="w-8 h-6 align-middle display-inline-block"
        />
        Mark as Inactive ({selectedCount})
      </button>
      <button
        onClick={async () => await onClick('Setup Complete')}
        {...props}
        className="bg-transparent rounded-md text-white py-2 px-4 w-auto mr-5 text-base border-solid border-slate-500 bg-slate-800"
      >
        <span
          style={{
            backgroundImage: `url(${setupComplete})`,
            backgroundPosition: 'left',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            paddingLeft: '1.8rem',
          }}
          className="w-8 h-6 align-middle display-inline-block"
        />
        Mark as Setup Complete ({selectedCount})
      </button>
      <button
        onClick={async () => await onClick('Training Complete')}
        {...props}
        className="bg-transparent rounded-md text-white py-2 px-4 w-auto mr-5 text-base border-solid border-slate-500 bg-slate-800"
      >
        <span
          style={{
            backgroundImage: `url(${trainingComplete})`,
            backgroundPosition: 'left',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundSize: 'contain',
            paddingLeft: '1.8rem',
          }}
          className="w-8 h-6 align-middle display-inline-block"
        />
        Mark as Training Complete ({selectedCount})
      </button>
    </div>
  )
}
