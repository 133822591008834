import React from 'react'

import Layout from '@/components/Layout'
import { CreateOrganizationForm } from '@/forms/CreateOrganization'
import backgroundImage from '@/assets/images/layout-background.png'

export default function CreateOrganization() {
  return (
    <Layout logo justify="start" background={backgroundImage}>
      <CreateOrganizationForm />
    </Layout>
  )
}
