import axios from 'axios'

import { SUPER_ADMIN_ORGANIZATIONS_ENDPOINT } from '@/constants/endpoints'
import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'

export const orgQuery = async (inputValue) => {
  const { data } = await axios.get(
    `${SUPER_ADMIN_ORGANIZATIONS_ENDPOINT}/${inputValue}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    }
  )

  const organizations = data.orgs.map((org) => {
    return {
      value: org.id,
      label: org.name,
    }
  })
  return organizations
}
