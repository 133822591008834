import { useOrganizationImpersonate } from '@/hooks/useOrganizationImpersonate'
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext,
} from 'react'
import { useActor } from '@xstate/react'
import { Box } from 'grommet'

import pencilIcon from '@/assets/icons/pencilOutlined.svg'
import { Icon } from '@/components/Icon'
import { Logo } from '@/components/Logo'
import { Title } from '@/components/Title'
import { Button } from '@/components/Button'
import { Divider } from '@/components/Divider'
import { NavLink } from '@/components/NavLink'
import { Subtitle } from '@/components/Subtitle'
import { headerLinks } from '@/constants/header-links'
import { HeaderEditModalForm } from '@/forms/HeaderEditModal'
import { HeaderPasswordModalForm } from '@/forms/HeaderPasswordModal'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { createToast } from '@/helpers/createToast'
import { useMeQuery } from '@/queries/get-me'
import { useEditInfoMutation } from '@/queries/edit-info'
import { SIGN_IN_ROUTE } from '@/constants/routes'
import { RenderByRole } from '@/components/RenderByRole'
import { getRolesNames } from '@/services/roles.service'
import { superAdmin } from '@/constants/roles-constants'
import { SoarStateContext } from '@/global/soar.context'
import { formatPhoneNumber } from '@/helpers/formatData'
import { SelectOrganizationBar } from '@/forms/SelectOrganizationBar'
import { orgQuery } from '@/components/SelectOrganization/organization.query'
import charts from '@/assets/icons/charts.svg'
import table from '@/assets/icons/table.svg'
import { DASHBOARD_ROUTE } from '@/constants/routes'
import { useNavigate } from 'react-router-dom'

import {
  Wrapper,
  LogoSection,
  LinksSection,
  UserSection,
  ProfileModal,
  TitleWrapper,
  InfoRow,
  ButtonsWrapper,
} from './styles'
import { VIEW_FLAG } from '@/forms/Dashboard/constants'
import { theme } from '@/theme/baseTheme'

const TabsMenu = function ({
  headerLinks,
  showTabsMenu,
  usrRoles = [],
  urlSearch = '',
}) {
  if (!showTabsMenu || !usrRoles) return null
  return headerLinks.map((item) => {
    return (
      <RenderByRole userRoles={usrRoles} forRoles={item.roles}>
        <Box style={{ marginRight: '8px' }}>
          <NavLink key={item.path} to={item.path + urlSearch}>
            {item.label}
          </NavLink>
        </Box>
      </RenderByRole>
    )
  })
}

export function Header({
  showTabsMenu = true,
  showImpersonation = false,
  children,
  page = '',
  viewFlag = '',
  setViewFlag = {},
}) {
  const soarContext = useContext(SoarStateContext)
  const [state] = useActor(soarContext.dashboardService)
  const [modal, setModal] = useState('')
  const [profileModal, setProfileModal] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 770px)')
  const profileRef = useRef()
  const { orgId, orgName } = state.context
  const navigate = useNavigate()

  const { search, organizationId } = useOrganizationImpersonate(orgId)

  const handleProfileModal = useCallback(
    () => setProfileModal((prev) => !prev),
    []
  )

  const logout = useCallback(() => {
    localStorage.clear()
    createToast('success', 'You`ve been logged out!')
    setTimeout(() => {
      window.location.href = SIGN_IN_ROUTE
    }, 500)
  }, [])

  const openEditModal = useCallback(() => setModal('edit'), [])
  const openPasswordModal = useCallback(() => setModal('password'), [])
  const closeModal = useCallback(() => setModal(''), [])

  useEffect(() => {
    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        handleProfileModal()
      }
    }
    if (profileModal) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [profileModal, profileRef])

  const { data: meData, isLoading, refetch } = useMeQuery()
  const usrRoles =
    meData?.role === 'Sales Manager'
      ? [meData?.role]
      : getRolesNames(meData?.roles)

  const { mutate: editInfo } = useEditInfoMutation({
    onSuccess: () => {
      createToast('success', 'Information was successfully updated!')
      refetch()
      closeModal()
    },
    onError: (err) => {
      createToast('error', err.response.data.message || 'Something went wrong!')
    },
  })

  const initialValues = useMemo(() => {
    if (isLoading) {
      return null
    }

    return {
      firstName: meData?.firstName,
      lastName: meData?.lastName,
      email: meData?.email,
      mobile: formatPhoneNumber(meData?.mobile || meData?.phone) || '',
      isPaid: meData?.isPaid,
      plan: meData?.plan,
    }
  })

  const handleOnChangeOrg = (org) => {
    soarContext.dashboardService.send({
      type: 'ORGANIZATION_SELECTED',
      orgId: org.value,
      orgName: org.label,
      isImpersonate: true,
    })
    navigate({
      pathname: DASHBOARD_ROUTE,
      search: `?orgId=${org.value}&showImpersonate=true`,
    })
  }

  return (
    <>
      <HeaderEditModalForm
        initialValues={initialValues}
        isOpen={modal === 'edit'}
        closeModal={closeModal}
        onSubmit={editInfo}
      />
      <HeaderPasswordModalForm
        isOpen={modal === 'password'}
        closeModal={closeModal}
      />

      {showImpersonation === true ? (
        <RenderByRole userRoles={usrRoles} forRoles={[superAdmin]}>
          <SelectOrganizationBar
            onChange={handleOnChangeOrg}
            orgId={organizationId}
            orgName={orgName}
            orgQuery={orgQuery}
          />
        </RenderByRole>
      ) : null}
      <Wrapper>
        <LogoSection>
          <Logo
            size={!isDesktop ? 'xsmall' : 'small'}
            margin="0"
            clickable={showImpersonation}
          />
        </LogoSection>
        <LinksSection>
          <TabsMenu
            urlSearch={search}
            showTabsMenu={showTabsMenu}
            headerLinks={headerLinks}
            usrRoles={usrRoles}
          />
        </LinksSection>
        {page === 'Dashboard' && (
          <Wrapper width="fit-content">
            <Button
              padding={4}
              width={''}
              onClick={() => setViewFlag(VIEW_FLAG.CHARTS)}
              style={{
                backgroundColor:
                  viewFlag === VIEW_FLAG.TABLES && theme.colors.backgroundItems,
                borderRadius: '4px 0px 0px 4px',
                border:
                  viewFlag === VIEW_FLAG.TABLES
                    ? `0.2px solid ${theme.colors.inactiveText}`
                    : `0.2px solid ${theme.colors.backgroundActive}`,
                color:
                  viewFlag === VIEW_FLAG.TABLES && theme.colors.inactiveText,
              }}
            >
              <Icon
                src={charts}
                size={24}
                iconColorSvg={
                  viewFlag === VIEW_FLAG.TABLES && theme.filter.inactiveSvg
                }
              />{' '}
              Charts
            </Button>
            <Button
              padding={4}
              width={''}
              onClick={() => setViewFlag(VIEW_FLAG.TABLES)}
              style={{
                backgroundColor:
                  viewFlag === VIEW_FLAG.CHARTS && theme.colors.backgroundItems,
                borderRadius: '0px 4px 4px 0px',
                border:
                  viewFlag === VIEW_FLAG.CHARTS
                    ? `0.2px solid ${theme.colors.inactiveText}`
                    : `0.2px solid ${theme.colors.backgroundActive}`,
                color:
                  viewFlag === VIEW_FLAG.CHARTS && theme.colors.inactiveText,
              }}
            >
              <Icon
                src={table}
                size={24}
                iconColorSvg={
                  viewFlag === VIEW_FLAG.CHARTS && theme.filter.inactiveSvg
                }
              />{' '}
              Tables
            </Button>
          </Wrapper>
        )}
        <UserSection onClick={handleProfileModal}>{children}</UserSection>
        <ProfileModal ref={profileRef} isOpen={profileModal}>
          <TitleWrapper>
            <Title bold fontSize={9}>
              Account
            </Title>
            <Button
              outlined
              border={1}
              fontSize={4}
              width="120px"
              height="34px"
              onClick={openEditModal}
            >
              <Icon src={pencilIcon} size={16} />
              Edit Details
            </Button>
          </TitleWrapper>
          <InfoRow>
            <Subtitle margin={0} color="headerText">
              Name
            </Subtitle>
            <Subtitle margin={0}>
              {initialValues
                ? `${initialValues.firstName} ${initialValues.lastName}`
                : '-'}
            </Subtitle>
          </InfoRow>
          <InfoRow>
            <Subtitle margin={0} color="headerText">
              Email
            </Subtitle>
            <Subtitle margin={0}>{initialValues?.email || '-'}</Subtitle>
          </InfoRow>
          <InfoRow>
            <Subtitle margin={0} color="headerText">
              Mobile No.
            </Subtitle>
            <Subtitle margin={0}>{initialValues?.mobile || '-'}</Subtitle>
          </InfoRow>
          <Divider size={1} margin={5} />
          <ButtonsWrapper>
            <Button
              outlined
              border={1}
              fontSize={4}
              width="120px"
              height="34px"
              onClick={openPasswordModal}
            >
              Change Password
            </Button>
            <Button onClick={logout} fontSize={4} width="78px" height="32px">
              Log Out
            </Button>
          </ButtonsWrapper>
        </ProfileModal>
      </Wrapper>
    </>
  )
}
