import axios from 'axios'
import { useQuery } from 'react-query'

import { GET_SAVED_VIEWS_ENDPOINT } from '@/constants/endpoints'
import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'

export const useGetSavedViewsQuery = (orgId) =>
  useQuery(
    `${GET_SAVED_VIEWS_ENDPOINT}`,
    async () => {
      const { data: userData } = await axios.get(
        `${GET_SAVED_VIEWS_ENDPOINT}/${orgId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              AUTH_TOKEN_STORAGE_KEY
            )}`,
          },
        }
      )

      return userData
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
