export const teamSizeData = [
  {
    value: '1',
    label: '3-15',
  },
  {
    value: '2',
    label: '16-30',
  },
  {
    value: '3',
    label: '31-50',
  },
  {
    value: '4',
    label: '50-99',
  },
  {
    value: '5',
    label: '100+ Users',
  },
]

export const proPlanData = {
  title: 'Pro',
  offer: 'Save 15%',
  users: '(3-50 users)',
  currency: '$',
  price: '26',
  frequency: '/mo',
  priceStrikeOut: '$28',
  billingInfo: 'Per user/per month (billed annually)',
  annualPrice: '$244.80 per year',
  buttonLabel: 'Try for free',
  features: [
    'Customized sales funnel',
    'Daily activity tracking',
    'Income tracker',
    'Monthly work schedule',
    'Progress tracking analytics',
    'Leaderboard',
    'Coaching',
    'Rollup enterprise reporting',
    'User admin management',
    'Product support',
    'Monthly recap',
    '24/7 technical support',
  ],
  pricing: {
    monthly: {
      '3-15': 28,
      '16-30': 26,
      '31-50': 24,
    },
    yearly: {
      '3-15': 23.8,
      '16-30': 22.1,
      '31-50': 20.4,
    },
  },
}

export const elitePlanData = {
  title: 'Elite',
  offer: 'Save 15%',
  users: '(50+ users required)',
  currency: '$',
  price: '12.95',
  frequency: '/mo',
  priceStrikeOut: '$19',
  billingInfo: 'Per user/per month (billed annually)',
  annualPrice: '$193.80 per year',
  buttonLabel: 'Try for free',
  features: [
    'All features from Pro plan, plus:',
    'Priority installation',
    'Dedicated account manager',
    'Dedicated 24/7 elite support line',
    'Free unlimited training',
  ],
  pricing: {
    monthly: {
      '50+': 19,
      '100+': 12.95,
    },
    yearly: {
      '50+': 16.15,
      '100+': 11.01,
    },
  },
}
