import { Text } from 'grommet'
import styled from 'styled-components'

import { rgbToRgba } from '@/helpers/rgbToRgba'

export const Wrapper = styled.div`
  align-items: center;
  border: ${({ theme, border }) =>
    border && `1px solid ${theme.colors.backgroundItems}`};
  border-radius: ${({ theme }) => theme.spaces[3]}px;
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
  flex-direction: column;
  padding: ${({ theme, padding }) =>
    padding !== undefined ? theme.spaces[padding] : theme.spaces[5]}px;
  width: 100%;
`

export const ImageWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme, bg, dark }) =>
    bg &&
    (dark
      ? theme.colors.background
      : rgbToRgba(theme.colors.backgroundItems, 0.4))};
  border-radius: ${({ theme }) => theme.spaces[3]}px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[4]}px;
  height: 100%;
  justify-content: center;
  padding: ${({ theme, padding }) =>
    padding !== undefined ? theme.spaces[padding] : theme.spaces[7]}px;
  width: 100%;
`

export const Image = styled.img`
  display: block;
  width: ${({ imageWidth }) => imageWidth || '100%'};
`

export const Subtitle = styled(Text)`
  font-size: ${({ theme, fontSize }) =>
    theme.fontSizes[fontSize] || theme.fontSizes[6]}px;
  font-weight: ${({ theme, bold }) => bold && theme.fontWeights.bold};
  margin: ${({ theme }) => theme.spaces[3]}px 0;
`
