export const managerColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Location',
    accessor: 'location',
  },
  {
    Header: 'Reports to',
    accessor: 'manager',
  },
  {
    Header: 'Certified Coach',
    accessor: 'certifiedCoach',
  },
  {
    Header: '',
    accessor: 'edit',
  },
]

export const employeeColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Location',
    accessor: 'location',
  },
  {
    Header: 'Reports to',
    accessor: 'manager',
  },
  {
    Header: 'Certified Coach',
    accessor: 'certifiedCoach',
  },
  {
    Header: '',
    accessor: 'edit',
  },
]

export const settingsColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Location',
    accessor: 'location',
  },
  {
    Header: 'Reports to',
    accessor: 'manager',
  },
  {
    Header: 'Role',
    accessor: 'role',
  },
  {
    Header: 'Coach',
    accessor: 'certifiedCoach',
  },
  {
    Header: 'Expertise',
    accessor: 'expertise',
  },
  {
    Header: 'Actions',
    accessor: 'edit',
  },
]

export const reviewInfoColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Location',
    accessor: 'location',
  },
  {
    Header: 'Reports to',
    accessor: 'manager',
  },
  {
    Header: 'Role',
    accessor: 'role',
  },
  {
    Header: 'Coach',
    accessor: 'certifiedCoach',
  },
  {
    Header: 'Expertise',
    accessor: 'expertise',
  },
  {
    Header: '',
    accessor: 'edit',
  },
]

export const invitesColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Location',
    accessor: 'location',
  },
  {
    Header: 'Reports to',
    accessor: 'manager',
  },
  {
    Header: 'Role',
    accessor: 'role',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: '',
    accessor: 'edit',
  },
]

export const coachingColumns = [
  {
    Header: 'Coach',
    accessor: 'firstName',
    sorting: '',
  },
  {
    Header: 'Coachee',
    accessor: 'coachee',
    sorting: '',
  },
  {
    Header: 'Performance views',
    accessor: 'performance',
    sorting: '',
  },
  {
    Header: 'Contacts from coach',
    accessor: 'contactsFromCoach',
    sorting: '',
  },
  {
    Header: 'Contacts from coachee',
    accessor: 'contactsFromCoachee',
    sorting: '',
  },
  {
    Header: 'Sales',
    accessor: 'sales',
    sorting: '',
  },
  {
    Header: 'Overall Conversions',
    accessor: 'overall',
    sorting: '',
  },
]

export const orgsColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: '',
    accessor: 'edit',
  },
]

export const allOrgsColumns = [
  {
    Header: 'Organization Name',
    accessor: 'name',
  },
  {
    Header: 'Billing Period',
    accessor: 'billing',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
  },
]
