import React from 'react'
import ReactSelect from 'react-select'

import { Icon } from '@/components/Icon'

import { Label, Wrapper, IconWrapper } from './styles'

export function SelectDashboard({
  label = '',
  placeholder = '',
  error = '',
  name,
  type,
  value,
  touched,
  children,
  options,
  form,
  setFieldValue,
  onBlur,
  onChange,
  icon,
  isSearchable,
  ...otherProps
}) {
  return (
    <Wrapper error={error} value={value} icon={icon} {...otherProps}>
      {label && <Label>{label}</Label>}
      <ReactSelect
        classNamePrefix="react-select"
        options={options}
        hasError={error}
        placeholder={placeholder}
        name={name}
        defaultValue={options && options[0]}
        value={options ? options.find((option) => option.value === value) : ''}
        onChange={onChange}
        onBlur={onBlur}
        isSearchable={isSearchable}
      />
      {icon && (
        <IconWrapper>
          <Icon size={20} src={icon} />
        </IconWrapper>
      )}
    </Wrapper>
  )
}
