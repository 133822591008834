/* eslint-disable no-unused-vars */
import React from 'react'

import { Title } from '@/components/Title'
import { makeArray } from '@/helpers/makeArray'

import { PaginationWrapper, PaginationItem } from './styles'

export function Pagination({ mt, currentPage, size, callback }) {
  let array = makeArray(size)
  let additionalSteps = false
  let additionalEndStep = false
  if (currentPage > 2 && array.length > 5) {
    additionalSteps = true
    if (currentPage + 2 < array.length) additionalEndStep = array.length - 1
    array = array.slice(currentPage - 1, currentPage + 2)
  }
  if (currentPage <= 2 && array.length > 5) {
    additionalEndStep = array.length - 1
    array = array.slice(0, currentPage + 2)
  }

  return (
    <PaginationWrapper mt={mt} size={size}>
      <Title fontSize={5}>—</Title>
      {additionalSteps && (
        <>
          <PaginationItem key={0} onClick={() => callback(0)}>
            1
          </PaginationItem>
          <Title fontSize={5}>...</Title>
        </>
      )}
      {array.map((item) => (
        <PaginationItem key={item} onClick={() => callback(item)}>
          {item + 1}
        </PaginationItem>
      ))}
      {additionalEndStep && (
        <>
          <Title fontSize={5}>...</Title>
          <PaginationItem
            key={additionalEndStep}
            onClick={() => callback(additionalEndStep)}
          >
            {additionalEndStep + 1}
          </PaginationItem>
        </>
      )}
      <Title fontSize={5}>—</Title>
    </PaginationWrapper>
  )
}
