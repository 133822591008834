import { GET_BILLING_DATA } from '@/constants/endpoints'
import axios from 'axios'
import { useQuery } from 'react-query'

export const useGetBillingData = (options, orgId) =>
  useQuery(
    'billing',
    async () => {
      const data = await axios
        .get(`${GET_BILLING_DATA}/${orgId}`)
        .catch((error) =>
          error.response.status === 400 ? [] : console.log(error)
        )
      return data.data
    },
    {
      retry: true,
      refetchOnWindowFocus: false,
      ...options,
    }
  )
