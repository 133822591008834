import axios from 'axios'
import { useMutation } from 'react-query'

import { createToast } from '@/helpers/createToast'
import { RESET_PASSWORD_ENDPOINT } from '@/constants/endpoints'

export const useResetManagerMutation = () =>
  useMutation(
    (payload) => axios.get(`${RESET_PASSWORD_ENDPOINT}/${payload.id}`, {
			params: {
				password: payload.password,
			}
		}),
    {
      onSuccess: () => {
        createToast('success', 'User password has been reset!')
      },
    }
  )
