import * as Yup from 'yup'

import { REQUIRED_FIELD_ERROR_MESSAGE } from '@/constants/error-messages'

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
})

export const DEFAULT_VALUES = {
  name: '',
}
