import React from 'react'
import { ThreeDots } from 'react-loader-spinner'
import styled, { useTheme } from 'styled-components'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`

export function Loader({ size }) {
  const theme = useTheme()
  return (
    <Wrapper>
      <ThreeDots
        heigth={size || '100'}
        width={size || '100'}
        color={theme.colors.backgroundActive}
        ariaLabel="loading"
      />
    </Wrapper>
  )
}
