import styled from 'styled-components'

import contentSizes from '@/constants/content-sizes'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-self: start;
  margin-bottom: ${({ theme }) => theme.spaces[5]}px;
  max-width: ${() => contentSizes.large}px;
  min-height: 790px;
  padding: ${({ theme }) => theme.spaces[6]}px 0;
  width: 90%;
`

export const ArrowLinkContainer = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spaces[5]}px;
  max-width: ${() => contentSizes.large}px;
  width: 90%;
`

export const ContinueButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.spaces[5]}px;
  max-width: ${() => contentSizes.large}px;
  width: 90%;
`

export const TitleBlock = styled.div`
  margin: 0 ${({ theme }) => theme.spaces[6]}px;
`

export const ContentBlock = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.backgroundItems};
  display: flex;
  flex-grow: 1;

  ${({ theme }) => theme.below.laptopM`
    flex-direction: column;
    `}
`

export const FormWrapper = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colors.backgroundItems};
  min-width: 250px;
  padding: ${({ theme }) => theme.spaces[6]}px
    ${({ theme }) => theme.spaces[6]}px 0;
  width: 290px;

  ${({ theme }) => theme.below.laptopM`
    align-self: center;
    border-bottom: 1px solid ${theme.colors.backgroundItems};
    border-right: none;
    padding-bottom: 20px;
    width: 500px;
    `}

  ${({ theme }) => theme.below.mobileXL`
    width: 100%;
    `}
`

export const TableWrapper = styled.div`
  align-items: ${({ data, tabs }) => (!tabs && !data ? 'center' : 'start')};
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: ${({ tabs }) => (tabs ? '13px 17px' : '27px 20px 0')};

  ${({ theme }) => theme.below.laptopM`
    width: 100%;
    `}
`
