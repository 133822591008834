import React from 'react'

import { DualScreenLayout } from '@/components/DualScreenLayout'
import { SignUpForm } from '@/forms/SignUp'
import backgroundImage from '@/assets/images/signUp-background.svg'
import signUpImage from '@/assets/images/signUp-image.svg'
import { useSignUpMutation } from '@/queries/sign-up'
import { createToast } from '@/helpers/createToast'

export default function SignUp() {
  const { mutate: signUp, isLoading } = useSignUpMutation({
    onSuccess: () => {
      createToast('success', `A confirmation link has been sent to your email.`)
    },
  })

  return (
    <DualScreenLayout
      image={signUpImage}
      imageWidth="73%"
      background={backgroundImage}
    >
      <SignUpForm isLoading={isLoading} onSubmit={signUp} />
    </DualScreenLayout>
  )
}
