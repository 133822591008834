import styled from 'styled-components'

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme }) => theme.spaces[5]}px;
`

export const SubtitleWrapper = styled.div`
  max-width: 480px;

  & > p > span {
    font-weight: bold;
  }
`

export const ModalButtonsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[5]}px;
`
