/* eslint-disable no-console */
import React, { useCallback, useState, useRef, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  CREATE_ORGANIZATION_ROUTE,
  HOME_ROUTE,
  DASHBOARD_ROUTE,
} from '@/constants/routes'
import { DOWNLOAD_SPREADSHEET_LINK } from '@/constants/endpoints'
import { SPREADSHEET_STORAGE_KEY } from '@/constants/storage-keys'
import { Icon } from '@/components/Icon'
import { Button } from '@/components/Button'
import { Loader } from '@/components/Loader'
import { Subtitle } from '@/components/Subtitle'
import { ProgressBar } from '@/components/ProgressBar'
import { ModalWindow } from '@/components/ModalWindow'
import { ContentWrapper } from '@/components/ContentWrapper'
import { ModalImageWindow } from '@/components/ModalImageWindow'
import uploadIcon from '@/assets/icons/upload.svg'
import modalImage from '@/assets/images/file-uploaded.png'
import downloadIcon from '@/assets/icons/downloadGreen.svg'
import { awsUpload } from '@/helpers/aws'
import { SoarStateContext } from '@/global/soar.context'

import {
  ButtonsWrapper,
  UploadButtonWrapper,
  UploadButton,
  Link,
} from './styles'

export function UploadSpreadsheetForm({ submitFile, error }) {
  const navigate = useNavigate()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const openModal = useCallback(() => setModalIsOpen(true), [])
  const refFile = useRef()
  const soarContext = useContext(SoarStateContext)
  const [isFileLoading, setIsFileLoading] = useState(false)

  const closeModal = () => {
    setModalIsOpen(false)
    return navigate(DASHBOARD_ROUTE)
  }

  const uploadFile = (event) => {
    let fileName = Date.now()
    setIsFileLoading(true)
    setModalIsOpen(true)
    if (event.target.files && event.target.files[0]) {
      const blob = event.target.files[0]
      fileName += blob.name.slice(blob.name.lastIndexOf('.'))

      awsUpload(blob, fileName).send(async (err) => {
        if (err) {
          hideModal()
          return
        }

        const result = await submitFile(fileName)
        if (result?.data?.message === 'success') {
          soarContext.dashboardService.send({
            type: 'ORGANIZATION_SELECTED',
            orgId: result.data.organization.id,
            orgName: result.data.organization.name,
          })
        }

        hideModal(result?.data?.message === 'success')
      })
    }
  }

  const hideModal = (isSuccess) => {
    setIsFileLoading(false)
    setModalIsOpen(isSuccess)
    refFile.current.value = ''
  }

  return (
    <>
      <ProgressBar active={1} />
      <ContentWrapper
        size="medium"
        title="Upload the spreadsheet when you’re ready."
        titleSize={9}
        titleMargin={5}
        arrowLink
        path={CREATE_ORGANIZATION_ROUTE}
        padding="48px"
      >
        {isFileLoading ? (
          <ModalWindow isOpen={modalIsOpen} closeOnOverlay={false}>
            <Loader />
          </ModalWindow>
        ) : (
          <ModalImageWindow
            isOpen={modalIsOpen}
            handleClose={closeModal}
            closeOnOverlay
            title={error || 'Awesome! Your file is uploaded.'}
            imageSrc={!error && modalImage}
          />
        )}
        <Subtitle>
          If you have any questions or issues, you can{' '}
          <Link to={HOME_ROUTE}>Contact Us</Link>
        </Subtitle>
        <ButtonsWrapper>
          <UploadButtonWrapper>
            <input type="file" accept=".xlsx" onChange={uploadFile} />
            <UploadButtonWrapper>
              <input
                type="file"
                accept=".xlsx"
                onChange={uploadFile}
                ref={refFile}
              />
              <UploadButton>
                <Icon src={uploadIcon} />
                Upload spreadsheet
              </UploadButton>
            </UploadButtonWrapper>
          </UploadButtonWrapper>
          <a
            href={DOWNLOAD_SPREADSHEET_LINK}
            target="_blank"
            rel="noreferrer"
            download
          >
            <Button outlined width="192px" fontSize={5}>
              <Icon src={downloadIcon} />
              Download file again
            </Button>
          </a>
        </ButtonsWrapper>
      </ContentWrapper>
    </>
  )
}
