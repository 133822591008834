import { toast } from 'react-toastify'

export function createToast(type, title) {
  if (type === 'success') {
    return toast.success(title, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    })
  }
  if (type === 'error') {
    return toast.error(title || 'Something went wrong', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    })
  }
  if (type === 'info') {
    return toast.info(title, {
      position: "top-center",
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      pauseOnHover: true,
      theme: "dark",
    })
  }
  return null
}
