import React, { useCallback, useEffect, useState } from 'react'

import { MANAGE_REPORTING_ROUTE, REVIEW_INFO_ROUTE } from '@/constants/routes'
import { invitesColumns } from '@/constants/table-columns'
import { fetchAllConfig } from '@/constants/fetch-data-config'
import modalImage from '@/assets/images/send-invites.png'
import { Table } from '@/components/Table'
import { Search } from '@/components/Search'
import { Loader } from '@/components/Loader'
import { Subtitle } from '@/components/Subtitle'
import { Pagination } from '@/components/Pagination'
import { ProgressBar } from '@/components/ProgressBar'
import { TableSection } from '@/components/TableSection'
import { ModalImageWindow } from '@/components/ModalImageWindow'
import { SendInvitesModalForm } from '@/forms/SendInvitesModal'
import { formatData } from '@/helpers/formatData'
import { createToast } from '@/helpers/createToast'

import { ContentWrapper, ButtonsWrapper } from './styles'

export function SendInvitesForm({
  onEditSubmit,
  fetchData,
  sendInvites,
  data,
  locations,
  isLoading,
  error,
  setError,
}) {

  const [rows, setRows] = useState()
  const [openModal, setOpenModal] = useState('')
  const [config, setConfig] = useState(fetchAllConfig)
  const [pages, setPages] = useState(1)
  const [currentPage, setPage] = useState(1)
  const [changingUser, setUser] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [inviteUser, setInviteUser] = useState([])

  let searchTimeout

  const openEditModal = useCallback((user) => {
    setOpenModal('edit')
    setUser(user)
  }, [])

  const handleClickCheck = useCallback(
    (user, users, type) => {
      let res
      if (type === 'email') {
        res = users?.reduce(
          (arr, row) =>
            row.id !== user.id
              ? [...arr, row]
              : [
                  ...arr,
                  {
                    ...user,
                    checkedEmail: !user?.checkedEmail,
                  },
                ],
          []
        )
      } else {
        res = users?.reduce(
          (arr, row) =>
            row.id !== user.id
              ? [...arr, row]
              : [
                  ...arr,
                  {
                    ...user,
                    checkedPhone: !user?.checkedPhone,
                  },
                ],
          []
        )
      }

      const invites = []
      res.map((row) => {
        if (row?.checkedEmail || row?.checkedPhone)
          invites.push({
            id: row.id,
            email: row.checkedEmail || false,
            phone: row.checkedPhone || false,
          })
        return null
      })
      setInviteUser(invites)
      setRows(formatData(res, handleClickCheck, openEditModal))
    },
    [rows]
  )

  const openSuccessModal = useCallback(() => setOpenModal('success'))

  const closeModal = useCallback(() => {
    setOpenModal('')
    setUser(false)
  }, [])

  const handleInvites = useCallback(() => {
    sendInvites({ infos: inviteUser }, {
      onSuccess: () => {
        openSuccessModal()
      },
      onError: (err) => {
        setOpenModal('')
        createToast('error', err)
      },
    })
  }, [inviteUser])

  const submitEditForm = useCallback((formData, users) => {
    const formattedData = { ...formData }
    onEditSubmit(formattedData)
    const res = users?.reduce(
      (arr, user) =>
        user.id !== formattedData.id
          ? [...arr, user]
          : [
              ...arr,
              {
                firstName: formattedData.name.split(' ')[0],
                lastName: formattedData.name.split(' ')[1] || '',
                manager: formattedData.manager,
                managerName: formattedData.managerName,
                ...formattedData,
              },
            ],
      []
    )
    setRows(formatData(res, handleClickCheck, openEditModal))
    closeModal()
  }, [])

  const handlePagination = useCallback(
    (page) => {
      setPage(page)
      setConfig({
        skip: page * 10,
        limit: 10,
        search: searchValue,
        role: 'all',
        originManager: false,
      })
    },
    [searchValue]
  )

  const handleSearch = useCallback((value) => {
    setSearchValue(value)
    setPage(1)
    setConfig({
      skip: 0,
      limit: 10,
      search: value,
      role: 'all',
      originManager: false,
    })
  }, [])

  const debounceSearch = useCallback(
    (event) => {
      clearTimeout(searchTimeout)
      searchTimeout = setTimeout(() => {
        handleSearch(event.target.value)
      }, 1000)
    },
    [searchTimeout]
  )

  useEffect(() => {
    fetchData(config)
  }, [config])

  useEffect(() => {
    if (error) {
      if (typeof error === 'object') createToast('error', error[0])
      else createToast('error', error)
      fetchData(config)
    }
    setError(false)
  }, [error, setError, config])

  useEffect(() => {
    setRows(
      (data && formatData(data.tempUsers, handleClickCheck, openEditModal)) ||
        []
    )
    setPages(data && data.total)
  }, [data])

  return (
    <>
      <ProgressBar active={4} />
      <SendInvitesModalForm
        title="Edit Details"
        isOpen={openModal === 'edit'}
        onClose={closeModal}
        onSubmit={submitEditForm}
        locations={locations}
        user={changingUser}
        rows={rows}
      />
      <ModalImageWindow
        isOpen={openModal === 'success'}
        handleClose={() => location.href=MANAGE_REPORTING_ROUTE}
        title="Congrats, Your invites have been sent successfully"
        imageSrc={modalImage}
        imageWidth="310px"
        width="466px"
      />
      <TableSection
        title="Send Invites"
        arrowLinkPath={REVIEW_INFO_ROUTE}
        continueButtonText="Send Invites"
        onContinueButtonClick={handleInvites}
      >
        <ContentWrapper>
          <Subtitle margin={0}>
            Select which employees you want to use the SOAR app for your
            organization. Invites will be sent via email and/or text.
          </Subtitle>
          <ButtonsWrapper>
            <Search onChange={debounceSearch} width="310px" />
          </ButtonsWrapper>
          {isLoading || !data ? (
            <Loader />
          ) : (
            <>
              <Table align="left" columns={invitesColumns} data={rows} />
              <Pagination
                mt={rows.length === 10 && 4}
                currentPage={currentPage}
                size={pages}
                callback={handlePagination}
              />
            </>
          )}
        </ContentWrapper>
      </TableSection>
    </>
  )
}
