import { Box } from 'grommet'
import React from 'react'
import { Field } from 'formik'

import { Input } from '@/components/Input'

export function InputField({
  name,
  type = 'text',
  errorMsg = null,
  ...otherProps
}) {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <Box>
          {meta.touched && meta.error ? (
            <Input
              error={errorMsg ?? meta.error}
              type={type}
              {...field}
              {...otherProps}
            />
          ) : (
            <Input type={type} {...field} {...otherProps} />
          )}
        </Box>
      )}
    </Field>
  )
}
