import React, { useRef, useCallback, useState } from 'react'

import Layout from '@/components/Layout'
import TeamSizeDropDown from '@/components/TeamSizeDropDown'
import { Logo } from '@/components/Logo'
import PlanBox from '@/components/PlanBox'

import { HeadingSection } from './styles'

import '../../index.css'
import {
  proPlanData,
  elitePlanData,
  teamSizeData,
} from '../../constants/lang/plans'

import ToggleButton from 'react-toggle-button'

export function SubscriptionPlans() {
  const viewRef = useRef()
  const [toggleValue, setToggleValue] = useState(false)

  const handleChanged = useCallback(() => {
    return true
  })
  return (
    <Layout hideLogo>
      <div className="max-w-fit mx-auto">
        <div className="max-w-fit text-center mx-auto logo-container">
          <Logo size="regular" display="inline-block" />
        </div>
        <div className="text-xl font-bold text-gray-500 my-2">Step 2 of 3</div>
        <HeadingSection>
          <div className="text-4xl font-bold leading-8 my-2 text-white">
            Select your team size
          </div>
        </HeadingSection>

        <div className="grid lg:grid-cols-2 sm:grid-cols-1 lg:gap-2 w-full">
          <div className="lg:col-span-1 sm:col-span-2">
            <div className="text-xl leading-8 my-2 text-white">TEAM SIZE</div>
            <TeamSizeDropDown
              ref={viewRef}
              width={140}
              data={teamSizeData}
              options={teamSizeData}
              onChange={handleChanged}
            />
          </div>
          <div className="lg:col-span-1 sm:col-span-2">
            <div className="text-xl leading-8 my-2 text-white">
              Billed Monthly / Billed Annually{' '}
            </div>
            <ToggleButton
              value={toggleValue}
              onToggle={(toggleValue) => setToggleValue(!toggleValue)}
            />
          </div>
        </div>

        <div className="text-4xl font-bold text-center leading-12 my-6 text-green-400">
          Choose your plan
        </div>

        <div className="grid lg:grid-cols-2 sm:grid-cols-1 lg:gap-2 mx-auto w-full">
          <div className="lg:col-span-1 sm:col-span-2">
            <PlanBox data={proPlanData} />
          </div>
          <div className="lg:col-span-1 sm:col-span-2">
            <PlanBox data={elitePlanData} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SubscriptionPlans
