import styled from 'styled-components'

export const SubscriptionBoxPrice = styled.span`
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;

  .subscription-box-frequency {
    color: #9ca3af;
  }
`
