import axios from 'axios'
import { useMutation } from 'react-query'

import { DASHBOARD_TABLE_LOCATION_DATA_ENDPOINT } from '@/constants/endpoints'

export const useTableLocationDataMutation = (options, orgId) =>
  useMutation(
    (payload = { date: '2022-04', skip: 0, limit: 10, search: '' }) =>
      axios.post(`${DASHBOARD_TABLE_LOCATION_DATA_ENDPOINT}/${orgId}`, payload),
    options
  )
