import axios from 'axios'
import { useQuery } from 'react-query'

import { FUNNEL_ENDPOINT } from '@/constants/endpoints'
import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'

export const useFunnelQuery = (orgId) =>
  useQuery(
    FUNNEL_ENDPOINT,
    async () => {
      const { data } = await axios.get(`${FUNNEL_ENDPOINT}/${orgId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            AUTH_TOKEN_STORAGE_KEY
          )}`,
        },
      })
      data.sort((a, b) => {
        return a.order - b.order
      })
      return data
    },
    {
      retry: true,
      refetchOnWindowFocus: false,
    }
  )
