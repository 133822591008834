import styled from 'styled-components'

export const Wrapper = styled.button`
  align-items: center;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.backgroundActive : theme.colors.backgroundInactive};
  border-radius: 13px;
  color: ${({ theme, active }) =>
    active ? theme.colors.black : theme.colors.primaryText};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes[5]}px;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  gap: ${({ theme }) => theme.spaces[12]}px;
  height: 40px;
  justify-content: center;
  margin: ${({ theme, margin }) => theme.spaces[margin]}px 0;
  transition: all 0.1s;
  width: ${(props) => props.width || '100%'};
  &:hover {
    background-color: ${({ theme, active }) =>
      active
        ? theme.colors.backgroundActiveLight
        : theme.colors.backgroundInactiveDark};
    border-color: ${({ theme, outlined }) =>
      outlined && theme.colors.backgroundActiveLight};
    color: ${({ theme, outlined }) =>
      outlined && theme.colors.backgroundActiveLight};
  }
`

export const Label = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 15px;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  height: 23px;
  justify-content: center;
  width: 37px;
`
