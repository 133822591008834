import axios from 'axios'
import { useMutation } from 'react-query'

import { VIEW_ENDPOINT } from '@/constants/endpoints'
import { createToast } from '@/helpers/createToast'

export const useAddViewMutation = () =>
  useMutation((payload) => axios.post(VIEW_ENDPOINT, payload), {
    onSuccess: (data) => {
      if (data.status >= 300) {
        return createToast('error', data.statusText)
      }

      window.location.reload()
      return createToast('success', 'Current View added successfully!')
    },
  })
