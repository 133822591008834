import styled from 'styled-components'
import 'rsuite/dist/rsuite.min.css'
import './styles.css'

export const HeadingSection = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spaces[5]}px;
  justify-content: space-between;
  margin-bottom: 40px;
  width: 100%;

  ${({ theme }) => theme.below.laptop`
     flex-direction: column;
     gap: ${theme.spaces[6]}px;
  `}
`

export const GraphFiltersBlock = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 22px;
  height: 40px;
  justify-content: flex-end;
  position: relative;

  ${({ theme }) => theme.below.laptop`
    align-items: center;
    flex-direction: column;
    min-height: 165px;
  `}
`

export const GraphsSection = styled.div`
  cursor: 'grab';
  display: flex;
  flex-wrap: ${({ wrap }) => (wrap ? 'nowrap' : 'wrap')};
  gap: ${({ theme }) => theme.spaces[5]}px;
  justify-content: ${({ wrap }) => (wrap ? 'start' : 'center')};
  width: 100%;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme, mb }) =>
    mb ? theme.spaces[mb] : theme.spaces[4]}px;
  width: 100%;
`

export const TableSection = styled.div`
  color: ${({ theme }) => theme.colors.promptText};
  min-height: 40vh;
`

export const TableFiltersBlock = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[4]}px;
  margin-bottom: ${({ theme }) => theme.spaces[5]}px;

  ${({ theme }) => theme.below.tablet`
    align-items: center;
    flex-direction: column;
    gap: ${theme.spaces[5]}px;
  `}
`

export const TableLoaderWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  height: 250px;
  justify-content: center;
  width: 100%;
`

export const UserTableWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[5]}px;
  width: 100%;
`

export const TopWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 38px;
  width: 100%;
`

export const CloseButton = styled.div`
  cursor: pointer;
  font-family: 'Inter';
`

export const SuccsessElement = styled.div`
  align-items: center;
  background: #5cae6d;
  border-radius: 4px;
  color: #000000;
  cursor: pointer;
  display: flex;
  height: 45px;
  height: 45px;
  justify-content: center;
  margin: 0 0px 0 22px;
  margin: 0 0px 0 22px;
  width: 113px;
  &.disabled {
    background: #4b5563;
    color: #9ca3af;
    pointer-events: none;
  }
`

export const CancelElement = styled.div`
  align-items: center;
  background: #4b5563;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  height: 45px;
  justify-content: center;
  margin: 0 0px 0 22px;
  width: 91px;
`

export const BottomWrapper = styled.div`
  align-items: center;
  border-top: 1px solid #4b5563;
  display: flex;
  justify-content: end;
  padding: 25px;
`

export const RangeTitle = styled.div`
  color: #ffffff;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`

export const CustomDateRange = styled.div`
  align-items: center;
  background: #1f2937;
  border: 1px solid #4b5563;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgb(255 255 255 / 4%);
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: start;
  left: 0;
  left: 50% !important;
  max-width: 575px;
  padding: 38px 0;
  position: fixed;
  top: 20%;
  transform: translateX(-50%);
  width: 90vw;
  z-index: 7;
`
