import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'

import App from '@/components/App'

import { SoarStateProvider } from './global/soar.stateProvider'
import '@/services/axios'

ReactDOM.render(
  <React.StrictMode>
    <SoarStateProvider>
      <App />
    </SoarStateProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
