import React from 'react'

import { Button } from '@/components/Button'
import checkWhite from '@/assets/icons/checkWhite.svg'
import { PlanPricingDetails } from '@/components/PlanPricingDetails'
import { PlanPrice } from '@/components/PlanPrice'

import { SubscriptionBox, Icon } from './styles'

export default function PlanBox(props) {
  const {
    data,
    paymentFrequencyFlag,
    highlightedPlan,
    onClick,
    noButtonTheme,
    background,
    planPrice,
    planPriceElite,
  } = props

  return (
    <SubscriptionBox background={background}>
      <div
        className={
          !noButtonTheme && highlightedPlan === data.title
            ? 'pricing-group-box pricing-group-box-highlighted'
            : 'pricing-group-box'
        }
      >
        <div className="subscription-box-title">
          {noButtonTheme ? highlightedPlan : data.title}{' '}
        </div>
        <div className="subscription-box-users">{data.users}</div>
        {!noButtonTheme && (
          <div className="subscription-box-price-row">As low as</div>
        )}

        <div className="subscription-box-price-row">
          <PlanPrice
            paymentFrequencyFlag={paymentFrequencyFlag}
            data={data}
            planPrice={highlightedPlan === 'Elite' ? planPriceElite : planPrice}
          />
        </div>

        <div className="you-save">
          {!noButtonTheme && paymentFrequencyFlag === 'yearly' ? (
            <span>You save 15%</span>
          ) : null}
        </div>

        <PlanPricingDetails
          paymentFrequencyFlag={paymentFrequencyFlag}
          data={data}
        />
        {!noButtonTheme && (
          <div className="subscription-box-get-started">
            <Button
              gray={
                highlightedPlan !== data.title && highlightedPlan !== ''
                  ? 'gray'
                  : null
              }
              width="100%"
              onClick={onClick}
              style={{ fontWeight: 'normal' }}
            >
              {data.buttonLabel}
            </Button>
          </div>
        )}
      </div>

      <div className="subscription-box-features">
        <ul className="elementor-icon-list-items">
          {data.features.map((name, index) => (
            <li
              key={name}
              className={
                data.title === 'Elite' && index === 0
                  ? 'elementor-icon-list-item text-bold'
                  : 'elementor-icon-list-item'
              }
            >
              <Icon src={checkWhite} />
              <span className="feature-text">{name}</span>
            </li>
          ))}
        </ul>
      </div>
    </SubscriptionBox>
  )
}
