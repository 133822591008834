import React, { useState } from 'react'
import Toggle from 'react-toggle'

import {
  BillingPeriodContainer,
  BillingYearlyMsg,
  BillingMonthlyMsg,
} from './styles'
import './billingPeriodToggle.css'

export function BillingPeriodToggle({ onChange, ...props }) {
  const billedYearly = 'Billed Yearly'
  const billedMonthly = 'Billed Monthly'
  const [isChecked] = useState(true)
  const [darkMsgYearly, setDarkMsgYearly] = useState(false)
  const [darkMsgMonthly, setDarkMsgMonthly] = useState(true)

  const handleToggle = (e) => {
    const checked = e?.target?.checked
    onChange(checked)
    if (checked) {
      setDarkMsgYearly(false)
      setDarkMsgMonthly(true)
      return
    }

    setDarkMsgYearly(true)
    setDarkMsgMonthly(false)
  }

  return (
    <BillingPeriodContainer {...props}>
      <BillingMonthlyMsg darkStyle={darkMsgMonthly}>
        {billedMonthly}
      </BillingMonthlyMsg>
      <Toggle defaultChecked={isChecked} onChange={handleToggle} on />
      <BillingYearlyMsg darkStyle={darkMsgYearly}>
        {billedYearly}
      </BillingYearlyMsg>
    </BillingPeriodContainer>
  )
}
