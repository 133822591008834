import '../../index.css'

import React, { useContext, useRef, useState } from 'react'
import { elitePlanData, proPlanData } from '../../constants/lang/plans'

import { BillingPeriodToggle } from '@/components/BillingPeriodToggle'
import { Input } from '@/components/Input'
import Layout from '@/components/Layout'
import PlanBox from '@/components/PlanBox'
import { SIGN_UP_ROUTE } from '@/constants/routes'
import { SoarStateContext } from '../../global/soar.context'
import { calculatePriceBand } from '@/utils/paymentPlanUtils'
import { useNavigate } from 'react-router-dom'
import { useSelector } from '@xstate/react'

export function ChooseYourPlan() {
  const nextSection = useRef()
  const navigate = useNavigate()
  const [hideSavePercentage, setHideSavePercentage] = useState(false)
  const [paymentFrequency, setPaymentFrequency] = useState('yearly')
  const [totalUsers, setTotalUsers] = useState(0)
  const [planPrice, setPlanPrice] = useState(0)
  const [planPriceElite, setPlanPriceElite] = useState(0)
  const [paymentBand, setPaymentBand] = useState('3-15')
  const [highlightedPlan, setHighlightedPlan] = useState('')
  const soarContext = useContext(SoarStateContext)
  const planDetails = useSelector(
    soarContext.subscriptionService,
    (state) => state.context
  )

  const handleBillingPeriodOnChange = (checked) => {
    if (checked) {
      setHideSavePercentage(false)
      setPaymentFrequency('yearly')

      const { paymentPrice } = calculatePriceBand(
        highlightedPlan,
        totalUsers,
        'yearly'
      )

      if (highlightedPlan === 'Elite') {
        setPlanPriceElite(paymentPrice)
      } else {
        setPlanPrice(paymentPrice)
      }
    } else {
      setHideSavePercentage(true)
      setPaymentFrequency('monthly')

      const { paymentPrice } = calculatePriceBand(
        highlightedPlan,
        totalUsers,
        'monthly'
      )

      if (highlightedPlan === 'Elite') {
        setPlanPriceElite(paymentPrice)
      } else {
        setPlanPrice(paymentPrice)
      }
    }
  }

  const handleGoToConfirm = (planTitle) => {
    if (highlightedPlan && planTitle !== highlightedPlan) return
    const price = highlightedPlan === 'Elite' ? planPriceElite : planPrice
    localStorage.setItem(
      'SELECTED_PLAN_DETAILS',
      JSON.stringify({
        name: highlightedPlan,
        users: totalUsers,
        price,
        frequency: paymentFrequency,
        band: paymentBand,
      })
    )

    soarContext.subscriptionService.send({
      type: 'SELECTED_PLAN_DETAILS',
      planDetails: {
        name: highlightedPlan,
        users: totalUsers,
        price,
        frequency: paymentFrequency,
        band: paymentBand,
      },
    })

    return navigate(SIGN_UP_ROUTE)
  }

  const handleIncreaseQuantity = (e) => {
    const totalUsers = parseInt(e.target.value)
    setTotalUsers(totalUsers)
    if (totalUsers <= 50) {
      setHighlightedPlan('Pro')

      const { paymentPrice, paymentBand } = calculatePriceBand(
        'Pro',
        totalUsers,
        paymentFrequency
      )

      setPaymentBand(paymentBand)
      setPlanPrice(paymentPrice)
    } else {
      setHighlightedPlan('Elite')

      const { paymentPrice, paymentBand } = calculatePriceBand(
        'Elite',
        totalUsers,
        paymentFrequency
      )

      setPlanPriceElite(paymentPrice)
      setPaymentBand(paymentBand)
    }
  }

  return (
    <Layout hideLogo>
      <div className="max-w-5xl mx-auto">
        <div className="text-3xl font-bold leading-8 my-1 text-white text-center">
          Try it free for 30 days.
        </div>
        <div className="text-base leading-8 my-4 text-white text-center">
          We won't charge for the first 30 days & you can cancel online anytime.
        </div>
        <div className="grid lg:grid-cols-2 sm:grid-cols-1 lg:gap-2 w-full">
          <div className="lg:col-span-1 sm:col-span-2">
            <div className="font-bold leading-8 my-2 text-white">
              How many sales accounts do you need?
            </div>
            <div className="userQuantityWrapper w-28">
              <Input
                name="userQuantity"
                type="text"
                placeholder="i.e. 10"
                onChange={handleIncreaseQuantity}
              />
            </div>
          </div>
          <div className="lg:col-span-1 sm:col-span-2 text-right">
            <div className="w-auto">
              <BillingPeriodToggle
                style={{ marginBottom: '1rem' }}
                onChange={handleBillingPeriodOnChange}
              />
            </div>
            <div style={{ color: '#72C787' }}>
              Save 15% when you bill yearly
            </div>
          </div>
        </div>

        <div className="grid lg:grid-cols-2 sm:grid-cols-1 lg:gap-2 mx-auto w-full">
          <div className="lg:col-span-1 sm:col-span-2 mr-2">
            <PlanBox
              data={proPlanData}
              planPrice={planPrice}
              noButtonTheme={false}
              onClick={() => handleGoToConfirm(proPlanData.title)}
              paymentFrequencyFlag={paymentFrequency}
              highlightedPlan={highlightedPlan}
            />
          </div>
          <div className="lg:col-span-1 sm:col-span-2 ml-2">
            <PlanBox
              data={elitePlanData}
              planPriceElite={planPriceElite}
              noButtonTheme={false}
              onClick={() => handleGoToConfirm(elitePlanData.title)}
              paymentFrequencyFlag={paymentFrequency}
              highlightedPlan={highlightedPlan}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
