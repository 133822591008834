import { Box, Tip, Text } from 'grommet'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import cls from 'classnames'
import downloadIcon from '@/assets/icons/downloadGreen.svg'
import checkIcon from '@/assets/icons/check.svg'
import checkRedIcon from '@/assets/icons/checkRed.svg'
import pencilIcon from '@/assets/icons/pencilGreen.svg'
import resetIcon from '@/assets/icons/reset.svg'
import deleteIcon from '@/assets/icons/deleteRed.svg'
import eyeGreen from '@/assets/icons/eyeVisibleGreen.svg'
import chevronIcon from '@/assets/icons/chevron.svg'
import { Icon } from '@/components/Icon'
import { Button } from '@/components/Button'
import { Checkbox } from '@/components/Checkbox'
import { formatMoney } from '@/utils/utils'

const CheckIconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`

export const IconButton = styled.button`
  align-items: center;
  background: none;
  display: flex;
  height: 100%;
  margin-right: 5px;
  padding: 0;
  width: 18px;

  &:hover {
    filter: brightness(1.2);
  }
`

export const formatPhoneNumber = (phoneNumber = '0000000000') => {
  const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (!match) {
    return phoneNumber
  }

  return `${match[1]}-${match[2]}-${match[3]}`
}

export const removeDashes = (str = '') => {
  return str.replace(/-/gi, '')
}

export const EditIconsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[1]}px;
  padding-right: ${({ theme }) => theme.spaces[1]}px;
`

export const CheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
`

export const certifyRow = (row) => {
  if (row.certifiedСoach) {
    if (row.email.length || row.phone.length)
      return (
        <CheckIconWrapper>
          <Icon margin={0} src={checkIcon} />
        </CheckIconWrapper>
      )
    return (
      <CheckIconWrapper>
        <Icon margin={0} src={checkRedIcon} />
      </CheckIconWrapper>
    )
  }
  return ''
}

export const formatData = (data, checkUser, openModal) => {
  return data.map((item) => {
    const formatItem = { ...item }
    formatItem.name = `${item.firstName} ${item.lastName}`
    formatItem.manager = item.manager ? item.managerName || '+' : ''
    formatItem.managerName = item.managerName || '+'
    formatItem.certifiedCoach = item.certifiedCoach ? (
      <CheckIconWrapper>
        <Icon
          margin={0}
          src={item.phone || item.email ? checkIcon : checkRedIcon}
        />
      </CheckIconWrapper>
    ) : (
      ''
    )
    formatItem.checkEmail = (
      <CheckboxWrapper>
        <Checkbox
          onChange={() => checkUser(item, data, 'email')}
          checked={formatItem?.checkedEmail}
          label={item.email}
        />
      </CheckboxWrapper>
    )
    formatItem.checkPhone = (
      <CheckboxWrapper>
        <Checkbox
          onChange={() => checkUser(item, data, 'phone')}
          checked={formatItem?.checkedPhone}
          label={item.phone}
        />
      </CheckboxWrapper>
    )
    formatItem.edit = (
      <IconButton onClick={() => openModal(item)}>
        <Icon margin={0} src={pencilIcon} />
      </IconButton>
    )
    if (formatItem.expertise?.length > 1) formatItem.expertise = 'Multi'

    return formatItem
  })
}

export const formatSettingsData = (
  data,
  openEditModal,
  openDeleteModal,
  openResetModal
) => {
  return data.map((item) => {
    const formatItem = { ...item }
    formatItem.name = (
      <p style={{ minWidth: '100px', display: 'block' }}>
        {formatItem.name || `${item.firstName} ${item.lastName}`}
      </p>
    )
    formatItem.manager = (
      <p style={{ minWidth: '100px', display: 'block' }}>
        {item.manager ? item?.managerName || '+' : ''}
      </p>
    )
    formatItem.expertise =
      item?.expertise.length > 1 ? (
        <Tip
          plain
          content={
            <Box background="background-front" pad="small" gap="small">
              {item?.expertise.map((expertise) => (
                <p>{expertise}</p>
              ))}
            </Box>
          }
        >
          <p>
            {item?.expertise[0]} & {item?.expertise.length - 1} more
          </p>
        </Tip>
      ) : item.expertise.length === 1 ? (
        <p>{item?.expertise[0]}</p>
      ) : (
        '-'
      )

    formatItem.certifiedCoach = item.certifiedCoach ? (
      <CheckIconWrapper>
        <Icon
          margin={0}
          src={item.phone || item.email ? checkIcon : checkRedIcon}
        />
      </CheckIconWrapper>
    ) : (
      ''
    )

    formatItem.phone = (
      <a
        href={`tel:${item?.phone}`}
        style={{ color: 'inherit', minWidth: '100px', display: 'block' }}
      >
        {formatPhoneNumber(item?.phone)}
      </a>
    )

    formatItem.email = (
      <a
        href={`mailto:${item.email}`}
        style={{ color: 'inherit' }}
        target="_blank"
      >
        {item.email}
      </a>
    )
		if (item.role !== 'Admin')
			formatItem.edit = (
				<EditIconsWrapper>
					<IconButton onClick={() => openResetModal(item)} title="Reset password">
						<Icon pointer margin={0} src={resetIcon} />
					</IconButton>
					<IconButton onClick={() => openEditModal(item)} title="Edit User">
						<Icon margin={0} src={pencilIcon} />
					</IconButton>
					<IconButton
						onClick={() => openDeleteModal(item)}
						title="Deactivate User"
					>
						<Icon margin={0} src={deleteIcon} />
					</IconButton>
				</EditIconsWrapper>
			)

    delete formatItem.firstName
    delete formatItem.lastName

    return formatItem
  })
}

export const setModalValues = (initialValues, user) => {
  const values = initialValues
  values.id = user?.id || user?.fbUid || ''
  values.fbUid = user?.id || user?.fbUid || ''
  values.firstName = user?.firstName
  values.lastName = user?.lastName
	values.name = `${user?.firstName} ${user?.lastName}`
  values.email = user?.email || ''
  values.phone = formatPhoneNumber(user?.phone, '###-###-####')
  values.location = user?.location || ''
  values.manager = user?.manager
    ? { label: user?.managerName || 'No manager name', value: user?.manager }
    : ''
  values.managerName = user?.managerName
  values.certifiedCoach = !!user?.certifiedCoach
  values.expertise = user?.expertise
  values.isAdmin = user?.isAdmin

  if (user?.role) values.role = user?.role || ''
  delete values.edit

  return values
}

const toFixedDown = (input, digits) => {
  const re = new RegExp(`(\\d+\\.\\d{${digits}})(\\d)`)
  const m = input.toString().match(re)
  return m ? parseFloat(m[1]) : input.valueOf()
}

export const formatDashboardData = (
  data,
  callback,
  funnelData,
  conversionMeasurementOption
) => {
  return !data
    ? []
    : data.map((item) => {
        const formatItem = {}
        //For dropdown filter
        formatItem.name = item.name
        formatItem.label = item.name
        formatItem.value = item.id || item.fbUid || ''
        //end

        formatItem.managerName = item.managerName
        let funnelArray = []
        funnelData?.forEach((funnelItem, index) => {
          funnelArray[index] = funnelItem.name
          return null
        })
        funnelArray = funnelArray.filter((funnelArrayItem) => !!funnelArrayItem)

        formatItem.salesperson = item.name
        formatItem.fbUid = item.fbUid

        formatItem.manager = item?.manager

        formatItem.Employees = item.employees

        formatItem.Usage = item?.usage ? `${parseInt(item?.usage, 10)}%` : '0%'

        formatItem.expertise =
          (item?.expertise?.length &&
            (item?.expertise?.length > 1 ? 'Multiple' : item.expertise[0])) ||
          ''

        item.funnels.forEach((funnel) => {
          if (funnel.name === 'Sales') {
            formatItem[funnel.name] = toFixedDown(funnel.value || 0, 1)
          } else {
            formatItem[funnel.name] = funnel.value || 0
          }
        })

        const keys = funnelArray
          .map((funnelItem) => {
            if (formatItem[funnelItem]) return funnelItem
            else {
              formatItem[funnelItem] = 0
              return funnelItem
            }
          })
          .filter((filterItem) => !!filterItem)

        let totalVisits =
          parseFloat(formatItem['Appointment Shows']) +
          parseFloat(
            formatItem['In-Store Visits'] ? formatItem['In-Store Visits'] : 0
          )

        keys.forEach((funnelArrayItem, index) => {
          let percent = 0
          if (!conversionMeasurementOption) {
            if (index === 3) {
              percent = parseInt(
                (formatItem[funnelArrayItem] /
                  parseInt(formatItem[keys[0]], 10)) *
                  100,
                10
              )
            } else if (index === 4) {
              percent = Math.trunc(
                (formatItem[funnelArrayItem] / totalVisits) * 100
              )
            } else {
              percent = Math.trunc(
                (formatItem[funnelArrayItem] /
                  parseInt(formatItem[keys[index - 1]], 10)) *
                  100
              )
            }
          } else if (conversionMeasurementOption === 1) {
            percent = Math.trunc(
              (formatItem[funnelArrayItem] /
                parseInt(formatItem[keys[0]], 10)) *
                100
            )
          } else {
            if (index < 3) {
              percent = Math.trunc(
                (formatItem[funnelArrayItem] /
                  parseInt(formatItem[keys[0]], 10)) *
                  100
              )
            } else if (index === 3) {
              percent = parseInt(
                (formatItem[funnelArrayItem] /
                  parseInt(formatItem[keys[1]], 10)) *
                  100,
                10
              )
            } else {
              percent = Math.trunc(
                (formatItem[funnelArrayItem] / totalVisits) * 100
              )
            }
          }
          if (index && formatItem[funnelArrayItem]) {
            formatItem[funnelArrayItem] = `${formatItem[funnelArrayItem]}\n${
              percent || 0
            }%`
          }
          return null
        })

        formatItem.coach = item.coach ? (
          <CheckIconWrapper>
            <Icon margin={0} src={checkIcon} />
          </CheckIconWrapper>
        ) : (
          ''
        )

        formatItem.edit = (
          <Button height="24px" width="50px" onClick={() => callback(item)}>
            View
          </Button>
        )
        return formatItem
      })
}

export const formatManagerDashboardData = (
  data,
  callback,
  funnelData,
  conversionMeasurementOption
) => {
  return !data
    ? []
    : data.map((item) => {
        const formatItem = {}
        //For dropdown filter
        formatItem.name = item.name
        formatItem.label = item.name
        formatItem.value = item.id || item.fbUid || ''
        //end

        formatItem.salesperson = ''
        let funnelArray = []
        funnelData?.forEach((funnelItem) => {
          funnelArray[funnelItem.order] = funnelItem.name
          return null
        })
        funnelArray = funnelArray.filter((funnelArrayItem) => !!funnelArrayItem)

        formatItem.managerName = item.name
        formatItem.open = false
        formatItem.fbUid = item.fbUid

        formatItem.Usage = ''

        formatItem.expertise =
          (item?.expertise?.length &&
            (item?.expertise?.length > 1 ? 'Multiple' : item.expertise[0])) ||
          ''

        formatItem.subordinates = formatDashboardData(
          item.employees,
          callback,
          funnelData,
          conversionMeasurementOption
        )

        if (formatItem.subordinates.length > 0)
          formatItem.subordinates[0].managerName = formatItem.managerName

        formatItem.coach = item.coach ? (
          <CheckIconWrapper>
            <Icon margin={0} src={checkIcon} />
          </CheckIconWrapper>
        ) : (
          ''
        )

        formatItem.edit = (
          <Button height="24px" width="50px" onClick={() => callback(item)}>
            View
          </Button>
        )

        formatItem.footer = formatTotalandAvgforManager(
          formatItem.subordinates,
          conversionMeasurementOption
        )

        formatItem['Leads & Opportunities'] = formatItem.footer?.leads?.total
        formatItem['Appointments'] = formatItem.footer?.appointments?.total
        formatItem['Appointment Shows'] = formatItem.footer?.shows?.total
        formatItem['In-Store Visits'] = formatItem.footer?.instore?.total
        formatItem['Sales'] = formatItem.footer?.sales?.total
        formatItem['Write-Ups'] = formatItem.footer?.writeUps?.total
        formatItem['Presentations'] = formatItem.footer?.presentations?.total
        formatItem['Demo Drives'] = formatItem.footer?.demos?.total
        formatItem['Employees'] = formatItem.footer?.employees?.total
        formatItem['Usage'] = formatItem.footer?.usage?.total

        return formatItem
      })
}

export const formatTotalandAvgforManager = (
  rows,
  conversionMeasurementOption
) => {
  const footer = {
    sales: { total: 0, avg: 0 },
    shows: { total: 0, avg: 0 },
    instore: { total: 0, avg: 0 },
    totVisits: { total: 0, avg: 0 },
    writeUps: { total: 0, avg: 0 },
    leads: { total: 0, avg: 0 },
    demos: { total: 0, avg: 0 },
    presentations: { total: 0, avg: 0 },
    appointments: { total: 0, avg: 0 },
    employees: { total: 0, avg: 0 },
    usage: { total: 0, avg: 0 },
  }

  rows.forEach((row) => {
    footer.leads.total += parseFloat(row['Leads & Opportunities'], 10) || 0
    footer.appointments.total += parseFloat(row.Appointments, 10) || 0
    footer.shows.total += parseFloat(row['Appointment Shows'], 10) || 0
    footer.instore.total += parseFloat(row['In-Store Visits'], 10) || 0
    footer.totVisits.total = footer.shows.total + footer.instore.total
    footer.sales.total += parseFloat(row.Sales, 10) || 0
    footer.writeUps.total += parseFloat(row['Write-Ups'], 10) || 0
    footer.presentations.total += parseFloat(row.Presentations, 10) || 0
    footer.demos.total += parseFloat(row['Demo Drives'], 10) || 0
    footer.employees.total += parseFloat(row.Employees, 10) || 0
    footer.usage.total += parseFloat(row.Usage, 10) || 0
  })

  const keys = Object.keys(footer)

  keys.forEach((key) => {
    footer[key].avg = Math.trunc((footer[key].total / rows.length) * 10) / 10
  })

  const newKeys = []

  if (footer.leads) newKeys.push('leads')
  if (footer.appointments) newKeys.push('appointments')
  if (footer.shows) newKeys.push('shows')
  if (footer.instore) newKeys.push('instore')
  if (footer.presentations) newKeys.push('presentations')
  if (footer.demos) newKeys.push('demos')
  if (footer.writeUps) newKeys.push('writeUps')
  if (footer.sales) newKeys.push('sales')

  if (newKeys.length > 0) {
    newKeys.forEach((item, index) => {
      if (item !== 'leads' && index > 0) {
        let percent = 0
        if (!conversionMeasurementOption) {
          percent = parseInt(
            (parseFloat(footer[item].total, 10) /
              parseFloat(footer[newKeys[index - 1]].total, 10)) *
              100,
            10
          )
        } else if (conversionMeasurementOption === 1) {
          percent = parseInt(
            (parseFloat(footer[item].total, 10) /
              parseFloat(footer[newKeys[0]].total, 10)) *
              100,
            10
          )
        } else {
          if (index < 3) {
            percent = parseInt(
              (parseFloat(footer[item].total, 10) /
                parseFloat(footer[newKeys[0]].total, 10)) *
                100,
              10
            )
          } else if (index === 3) {
            percent = parseInt(
              (parseFloat(footer[item].total, 10) /
                parseFloat(footer[newKeys[1]].total, 10)) *
                100,
              10
            )
          } else {
            percent = parseInt(
              (parseFloat(footer[item].total, 10) /
                parseFloat(footer.totVisits.total, 10)) *
                100,
              10
            )
          }
        }
        footer[item].total = `${footer[item].total}\n${
          isNaN(percent) ? 0 : percent
        }%`
      }
    })
  }

  return footer
}

export const formatDashboardLocations = (data, callback, funnelData) => {
  return data.map((item) => {
    const formatItem = {}

    let funnelArray = []
    funnelData?.forEach((funnelItem) => {
      funnelArray[funnelItem.order] = funnelItem.name
      return null
    })
    funnelArray = funnelArray.filter((funnelArrayItem) => !!funnelArrayItem)

    formatItem.name = item.name

    formatItem.Employees = item.employees

    formatItem.Usage = item?.usage ? `${parseInt(item?.usage, 10)}%` : '0%'

    item.funnels.forEach((funnel) => {
      if (funnel.name === 'Sales') {
        formatItem[funnel.name] = toFixedDown(funnel.value || 0, 1)
      } else {
        formatItem[funnel.name] = funnel.value || 0
      }
    })

    const keys = funnelArray
      .map((funnelItem) => {
        if (formatItem[funnelItem]) return funnelItem
        return null
      })
      .filter((filterItem) => !!filterItem)

    keys.forEach((funnelArrayItem, index) => {
      if (index && formatItem[funnelArrayItem]) {
        formatItem[funnelArrayItem] = `${formatItem[funnelArrayItem]} (${
          Math.trunc(
            (formatItem[funnelArrayItem] /
              parseInt(formatItem[keys[index - 1]], 10)) *
              100
          ) || 0
        }%)`
      }
      return null
    })

    formatItem.coach = item.coach ? (
      <CheckIconWrapper>
        <Icon margin={0} src={checkIcon} />
      </CheckIconWrapper>
    ) : (
      ''
    )

    formatItem.edit = (
      <IconButton onClick={() => callback(item)}>
        <Icon margin={0} src={chevronIcon} />
      </IconButton>
    )

    return formatItem
  })
}

export const formatDashboardUserData = (user, data) => {
  const formatItem = {}

  formatItem.name = user.name

  data.funnels?.forEach((funnel) => {
    formatItem[funnel.name] = funnel.value
  })

  formatItem.coach = user.coach ? (
    <CheckIconWrapper>
      <Icon margin={0} src={checkIcon} />
    </CheckIconWrapper>
  ) : (
    ''
  )

  return [formatItem]
}

export const formatSearchUsers = (users, manager) => {
  if (users?.length) {
    const arr = users.map((user) => {
      const option = user
      option.name = `${user.firstName} ${user.lastName}`
      option.label = `${user.firstName} ${user.lastName}`
      option.value = user.id || user.fbUid || ''
      return option
    })
    if (manager) arr.unshift({ label: 'None', value: '' })
    return arr
  }
  return []
}

export const formatOrgsData = (
  data,
  handleEditOrg,
  handleImpersonate,
  handleToggleRecord
) => {
  const activeStyle = cls(
    'font-bold',
    'text-green-200',
    'rounded-md',
    'bg-green-900',
    'py-1',
    'px-2',
    'display-inline-block',
    'flex',
    'justify-center',
    'w-3/6'
  )

  const inActiveStyle = cls(
    'font-bold',
    'text-green-200',
    'rounded-md',
    'bg-red-900',
    'py-1',
    'px-2',
    'display-inline-block',
    'flex',
    'justify-center',
    'w-3/6'
  )
  if (data && data.length > 0) {
    return data.map((item) => {
      const formatItem = { ...item }
      formatItem.checked = (
        <input
          type="checkbox"
          className="checkbox"
          checked={item.checked}
          onChange={(e) => {
            handleToggleRecord(
              { ...item, checked: e.target.checked },
              e.target.checked
            )
          }}
        />
      )
      formatItem.name = <div>{item.name}</div>
      formatItem.status =
        item.status.toLowerCase() === 'active' ? (
          <div className={activeStyle}>{item.status}</div>
        ) : (
          <div className={inActiveStyle}>{item.status}</div>
        )

      formatItem.actions = (
        <div className="flex gap-1">
          <IconButton onClick={() => handleImpersonate(item)}>
            <Icon margin={0} src={eyeGreen} title="Impersonate" />
          </IconButton>
          <IconButton onClick={() => handleEditOrg(item)}>
            <Icon margin={0} src={pencilIcon} title="Edit Name" />
          </IconButton>
        </div>
      )

      return formatItem
    })
  }
  return null
}

export const IconButtonBilling = styled.button`
  align-items: center;
  background: none;
  color: ${({ theme }) => theme.colors.backgroundActive};
  display: flex;
  gap: 4px;
  height: 18px;
  text-decoration: underline;
  text-underline-offset: 2px;

  &:hover {
    filter: brightness(0.8);
  }
`

export const formatBillingInfo = (billingInfo) => {
  if (!billingInfo) {
    return []
  }
  return billingInfo.map((data) => {
    const formatItem = { ...data }
    const { number } = formatItem
    const { invoice_pdf } = formatItem
    formatItem.invoice_pdf = (
      <IconButtonBilling onClick={() => window.open(invoice_pdf, '_blank')}>
        {number}
        <Icon src={downloadIcon} />
      </IconButtonBilling>
    )

    formatItem.quantity = data.lines.data[0].quantity
    const date = new Date(formatItem.created * 1000)
    formatItem.created = date.toUTCString()
    formatItem.amount_paid = formatMoney(data.amount_paid / 100)
    return formatItem
  })
}
