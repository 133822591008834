import { IMPERSONATING_ORGANIZATION } from '@/constants/storage-keys'
import { useOrganizationImpersonate } from '@/hooks/useOrganizationImpersonate'
import React, { useContext, useEffect, useState } from 'react'
import { useActor } from '@xstate/react'
import { useNavigate } from 'react-router-dom'

import { DASHBOARD_ROUTE, ORGANIZATIONS_ROUTE } from '@/constants/routes'
import { Header } from '@/components/Header'
import { OrganizationsAddModalForm } from '@/forms/OrganizationsAddModal'
import { OrganizationsEditModalForm } from '@/forms/OrganizationsEditModal'
import { SettingsAddModalForm } from '@/forms/SettingsAddModal'
import { SoarStateContext } from '@/global/soar.context'
import { createToast } from '@/helpers/createToast'
import { orgQuery } from '@/components/SelectOrganization/organization.query'
import { useAddOrgMutation } from '@/queries/add-organization'
import { useDeleteOrgMutation } from '@/queries/delete-organization'
import { useEditOrgMutation } from '@/queries/edit-organization'
import { useOrgDataQuery } from '@/queries/get-org'
import { useRestoreOrgMutation } from '@/queries/restore-organization'
import { useAddManagerMutation } from '@/queries/add-manager'
import { removeDashes } from '@/helpers/formatData'
import { SettingsEditModalForm } from '@/forms/SettingsEditModal'
import { useGetUserCoachMutation } from '@/queries/get-user-coach'
import { useChangeMultipleCoachMutation } from '@/queries/change-coach'
import { getLocations, getUsrInfo } from '@/utils/utils'
import { useEditManagerMutation } from '@/queries/edit-manager'

import { useAllOrganizationsQuery } from './AllOrganizations/all_organizations_query'
import { getSelectedOrgsIds } from './SuperAdminMenu.service'
import { SearchOptions } from './SearchOptions'
import { EmployeeCrossOrg } from './EmployeeCrossOrg'
import { AllUsersHeader } from './AllUserHeader'
import { AllOrganizations } from './AllOrganizations'

export function SuperAdminMenuPage() {
  const [editUsrData, setEditUsrData] = useState({})
  const [editLocations, setEditLocations] = useState([])
  const [modalOpen, setModalOpen] = useState('')
  const [changingOrg, setChangingOrg] = useState({})
  const [sectionToShow, setSectionToShow] = useState('orgs')
  const [isRequestLoading, setRequestLoading] = useState(false)
  const [selectOrgInAddEmployee, setSelectOrgInAddEmployee] = useState(false)
  const locations = []
  const navigate = useNavigate()
  const orgId = ''
  const { organizationId } = useOrganizationImpersonate(orgId)

  const { data: orgData, isLoading: orgDataLoad } =
    useOrgDataQuery(organizationId)
  const orgName = 'Select Organization'
  const soarContext = useContext(SoarStateContext)
  const [state] = useActor(soarContext.dashboardService)
  const {
    mutate: getUserCoach,
    data: userCoaches,
    isLoading: getCoachLoad,
  } = useGetUserCoachMutation()

  const { mutate: addUser, isLoading: addUserLoad } = useAddManagerMutation({
    onSuccess: () => {
      createToast('success', 'User added successfully!')
    },
    onError: (err) => {
      createToast('error', err?.response?.data?.message)
    },
  })

  const { mutate: deleteOrg, isLoading: deleteOrgLoad } = useDeleteOrgMutation({
    onSuccess: () => {
      createToast('success', 'Organization deactivated successfully!')
    },
    onError: (err) => {
      createToast('error', err?.response?.data?.message)
    },
  })

  const { mutate: editOrg, isLoading: editOrgLoad } = useEditOrgMutation({
    onSuccess: () => {
      createToast('success', 'Organization edited successfully!')
    },
    onError: (err) => {
      createToast('error', err?.response?.data?.message)
    },
  })

  const { mutate: changeCoach, isLoading: changeCoachLoad } =
    useChangeMultipleCoachMutation()

  const { mutate: restoreOrg, isLoading: restoreOrgLoad } =
    useRestoreOrgMutation({
      onSuccess: () => {
        createToast('success', 'Organization activated successfully!')
      },
      onError: (err) => {
        createToast('error', err?.response?.data?.message)
      },
    })

  const { mutate: editUser, isLoading: editUserLoad } = useEditManagerMutation({
    onSuccess: () => {
      createToast('success', 'User data edited successfully!')
    },
    onError: (err) => {
      createToast(err.response.data.message)
    },
  })

  const handleOnChangeOrg = (org) => {
    if (org) {
      soarContext.dashboardService.send({
        type: 'ORGANIZATION_SELECTED',
        orgId: org.value,
        orgName: org.label,
        isImpersonate: true,
      })
    }
  }

  const handleOnImpersonate = (id) => {
    const { orgId } = state.context
    if (!orgId) {
      return
    }

    navigate({
      pathname: DASHBOARD_ROUTE,
      search: `?orgId=${orgId}&showImpersonate=true`,
    })
  }

  const handleOnImpersonateByOrgId = (org) => {
    navigate({
      pathname: DASHBOARD_ROUTE,
      search: `?orgId=${org.id}&showImpersonate=true`,
    })
  }

  const closeModal = () => {
    setModalOpen('')
    setSelectOrgInAddEmployee(false)
  }

  const handleGotoOrganizations = () => {
    navigate(ORGANIZATIONS_ROUTE)
  }

  const handleOnMenuSelection = (section) => {
    setSectionToShow(section)
  }

  const { mutate: addOrg, isLoading: addOrgLoad } = useAddOrgMutation({
    onSuccess: () => {
      createToast('success', 'Organization added successfully!')
    },
    onError: (err) => {
      createToast('error', err?.response?.data?.message)
    },
  })

  const {
    data: allOrgs,
    isLoading: allOrgsIsLoading,
    refetch,
  } = useAllOrganizationsQuery()

  const submitAddOrg = (formData) => {
    addOrg({
      name: formData.name,
      locations: [],
    })
    setRequestLoading(true)
    closeModal()
    setTimeout(() => {
      refetch()
      setRequestLoading(false)
    }, 1000)
  }

  const submitEditForm = async (formData) => {
    editOrg({
      ...formData,
    })
    closeModal()
    setRequestLoading(true)
    setTimeout(() => {
      refetch()
      setRequestLoading(false)
    }, 1000)
  }

  const handleEditOrg = (formData) => {
    setChangingOrg({
      name: formData.name,
      id: formData.id,
      licenseQuantity: formData.licenseQuantity,
      locations: formData.locations,
    })
    setModalOpen('editOrg')
  }

  const handleRestoreOrg = (selectedOrgs) => {
    const orgsIds = getSelectedOrgsIds(selectedOrgs)
    restoreOrg(orgsIds)
    setRequestLoading(true)
    setTimeout(() => {
      refetch()
      setRequestLoading(false)
    }, 1000)
  }

  const handleMarkAsInactive = (selectedOrgs) => {
    const orgsIds = getSelectedOrgsIds(selectedOrgs)
    if (orgsIds.length === 0) {
      return
    }
    deleteOrg(orgsIds)
    setRequestLoading(true)
    setTimeout(() => {
      refetch()
      setRequestLoading(false)
    }, 1000)
  }

  const submitSaveEmployee = (formData, orgId) => {
    const phone = removeDashes(formData.phone)
    const formattedData = { ...formData, phone }
    formattedData.organization = orgId
    addUser(formattedData)
    setRequestLoading(true)
    closeModal()
    setTimeout(() => {
      setRequestLoading(false)
    }, 1000)
  }

  const onSubmitEditEmployee = async (usrData) => {
    await editUser(usrData)
    closeModal()
    setRequestLoading(true)
    setTimeout(() => {
      setRequestLoading(false)
    }, 1000)
  }

  useEffect(() => {
    handleOnChangeOrg('')
  }, [])

  return (
    <>
      <SettingsAddModalForm
        isOpen={modalOpen === 'addEmployee'}
        closeModal={closeModal}
        onSubmit={submitSaveEmployee}
        locations={locations}
        orgId=""
        orgName={orgName}
        selectOrgInAddEmployee={selectOrgInAddEmployee}
      />
      <SettingsEditModalForm
        isOpen={modalOpen === 'editEmployee'}
        closeModal={closeModal}
        onSubmit={onSubmitEditEmployee}
        locations={editLocations}
        user={editUsrData}
        userCoaches={userCoaches && userCoaches}
        changeCoach={changeCoach}
        getUserCoach={getUserCoach}
        isCoachLoading={getCoachLoad || changeCoachLoad}
        rows={[]}
      />
      <OrganizationsAddModalForm
        title="Add Organization"
        isOpen={modalOpen === 'addOrg'}
        closeModal={closeModal}
        onSubmit={submitAddOrg}
        initialValues={{ name: '' }}
      />
      <OrganizationsEditModalForm
        title="Edit Organization"
        isOpen={modalOpen === 'editOrg'}
        closeModal={closeModal}
        onSubmit={submitEditForm}
        initialValues={{
          name: changingOrg.name,
          id: changingOrg.id,
          licenseQuantity: changingOrg.licenseQuantity,
          locations: changingOrg.locations,
        }}
      />
      <Header
        adminData={orgData}
        showTabsMenu={false}
        showImpersonation={false}
      />
      <div className="flex min-h-screen pt-8 bg-gray-700">
        <SearchOptions
          handleOnChangeOrg={handleOnChangeOrg}
          handleOnImpersonate={handleOnImpersonate}
          orgQuery={orgQuery}
          gotoOrganizations={handleGotoOrganizations}
          onMenuSelection={handleOnMenuSelection}
        />
        {sectionToShow === 'usr' ? (
          <div className="flex flex-col basis-9/12">
            <AllUsersHeader
              openModal={() => {
                setModalOpen('addEmployee')
                setSelectOrgInAddEmployee(true)
              }}
            />
            <EmployeeCrossOrg
              sectionToShow={sectionToShow}
              onEditEmployee={async (userData) => {
                try {
                  const usrInfo = await getUsrInfo(userData.fbUid)
                  const locations = await getLocations(
                    usrInfo.data.organization
                  )
                  setEditLocations(locations)
                  setEditUsrData(usrInfo?.data)
                  setModalOpen('editEmployee')
                } catch (e) {
                  console.log(e)
                }
              }}
            />
          </div>
        ) : null}
        {sectionToShow === 'orgs' ? (
          <div className="flex flex-col basis-9/12">
            <AllOrganizations
              isLoading={
                allOrgsIsLoading ||
                editOrgLoad ||
                deleteOrgLoad ||
                restoreOrgLoad ||
                addOrgLoad ||
                isRequestLoading
              }
              allOrgs={allOrgs}
              openModal={() => setModalOpen('addOrg')}
              handleOnChangeOrg={handleOnChangeOrg}
              handleOnImpersonate={handleOnImpersonate}
              handleEditOrg={handleEditOrg}
              handleMarkAsInactive={handleMarkAsInactive}
              handleRestoreOrg={handleRestoreOrg}
              handleOnImpersonateByOrgId={handleOnImpersonateByOrgId}
            />
          </div>
        ) : null}
      </div>
    </>
  )
}
