import axios from 'axios'
// import { useMutation } from 'react-query'

import { UPLOAD_SPREADSHEET_ENDPOINT } from '@/constants/endpoints'

export const useBatchUploadService = async (org, file) => {
  if (org) {
    return axios.post(`${UPLOAD_SPREADSHEET_ENDPOINT}/${org}/${file}`, {
      headers: { 'Content-Type': 'application/json' },
    })
  }

  return axios.post(`${UPLOAD_SPREADSHEET_ENDPOINT}/${file}`, {
    headers: { 'Content-Type': 'application/json' },
  })
}
