import styled from 'styled-components'

import profileImage from '@/assets/images/profile.svg'

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundItems};
  background-size: cover;
  display: flex;
  height: 60px;
  justify-content: start;
  padding: 10px 20px;
  position: relative;
  width: ${({width}) => (width || '100%')};

  ${({ theme }) => theme.below.tablet`
    gap: 20px;
    justify-content: space-between;
    padding: 10px;
  `}

  ${({ theme }) => theme.below.mobileXL`
     gap: 14px;
  `}
`

export const LogoSection = styled.div`
  margin-right: ${({ theme }) => theme.spaces[10]}px;
  width: 92px;

  ${({ theme }) => theme.below.tablet`
    margin: 0;
  `}
`

export const LinksSection = styled.div`
  display: flex;
  margin-right: auto;
  max-width: 302px;
  width: 100%;
`

export const UserSection = styled.div`
  background-image: url(${profileImage});
  background-size: cover;
  border-radius: 16.5px;
  cursor: pointer;
  display: flex;
  height: 33px;
  justify-self: flex-end;
  min-width: 33px;
`

export const ProfileModal = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.backgroundInactive};
  border-radius: ${({ theme }) => theme.spaces[3]}px;
  display: ${({ isOpen }) => !isOpen && 'none'};
  max-width: 440px;
  padding: ${({ theme }) => theme.spaces[6]}px;
  position: absolute;
  right: 20px;
  top: 55px;
  width: 100%;
  z-index: 2;

  ${({ theme }) => theme.below.tablet`
    max-width: 90%;
  `}
`

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spaces[7]}px;
`

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spaces[6]}px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const HeaderLabel = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: ${({ theme }) => theme.fontSizes[6]}px;
  margin-right: 5px;
  text-decoration: none;
  text-transform: capitalize;
`

export const OrgHeader = styled.div`
  display: inline-flex;
  margin-right: 4rem;
  width: 50rem;
`
