import { format } from 'date-fns'

export const fetchConfig = {
  skip: 0,
  limit: 1000000,
  search: '',
  originManager: false,
}

export const fetchManagersConfig = {
  skip: 0,
  limit: 10,
  search: '',
  role: 'manager',
  originManager: false,
}

export const fetchEmployeesConfig = {
  skip: 0,
  limit: 10,
  search: '',
  role: 'employee',
  originManager: false,
}

export const fetchAllConfig = {
  skip: 0,
  limit: 10,
  search: '',
  role: 'all',
  originManager: false,
}

const date = new Date()

export const fetchAllDashboardConfig = {
  date: `${date.getFullYear()}-${date.getMonth() + 1}`,
  skip: 0,
  limit: 10,
  search: '',
}

export const fetchGraphConfig = {
  date: `${date.getFullYear()}-${date.getMonth() + 1}`,
  locations: [],
  search: '',
}

export const fetchCoacheeConfig = {
  date: format(new Date(), 'yyyy-MM-dd'),
}

export const orgsConfig = {
  name: '',
}
