import React from 'react'
import { Formik } from 'formik'

import { InputField } from '@/fields/Input'
import { Button } from '@/components/Button'
import { FormWrapper } from '@/components/FormWrapper'

import { validationSchema, DEFAULT_VALUES } from './config'

export function ForgotPasswordForm({ initialValues, onSubmit }) {
  return (
    <FormWrapper logo={false} title="Forgot Password" titleSize={12}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          onSubmit(values)
          actions.setSubmitting(false)
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <InputField name="email" label="WORK EMAIL" margin={3} />
            <Button type="submit" disabled={isSubmitting} margin={4}>
              Send Recovery Link to Email
            </Button>
          </form>
        )}
      </Formik>
    </FormWrapper>
  )
}

ForgotPasswordForm.defaultProps = {
  initialValues: DEFAULT_VALUES,
  onSubmit: () => {},
}
