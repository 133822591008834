import axios from 'axios'
import { useMutation } from 'react-query'

import { MANAGER_ENDPOINT } from '@/constants/endpoints'
import { createToast } from '@/helpers/createToast'

export const useAddManagerMutation = () =>
  useMutation((payload) => axios.post(MANAGER_ENDPOINT, payload), {
    onSuccess: (data) => {
      if (data.status < 300)
        return createToast('success', 'Employee added successfully!')
      return createToast('error', data.statusText)
    },
  })
