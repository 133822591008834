import React from 'react'

import { Logo } from '@/components/Logo'
import { Title } from '@/components/Title'
import { useMediaQuery } from '@/hooks/useMediaQuery'

import { Wrapper, MobileLogoWrapper } from './styles'

export function FormWrapper({
  logo,
  logoSize,
  title,
  titleSize = 10,
  margin,
  children,
}) {
  const isDesktop = useMediaQuery('(min-width: 770px)')

  return (
    <Wrapper>
      {isDesktop ? (
        logo && <Logo size={logoSize} margin={margin} />
      ) : (
        <MobileLogoWrapper>
          <Logo />
        </MobileLogoWrapper>
      )}
      {title && (
        <Title semiBold as="span" fontSize={titleSize} margin={6}>
          {title}
        </Title>
      )}
      {children}
    </Wrapper>
  )
}
