import React,{ useEffect, useState } from 'react'
import { Grommet, Main } from 'grommet'
import { QueryClientProvider } from 'react-query'
import 'react-toastify/dist/ReactToastify.css'

import { AuthProvider } from '@/contexts/auth'
import { Router } from '@/components/Router'
import { theme, GlobalStyle } from '@/theme'
import { queryClient } from '@/services/query-client'
import { ToastContainer } from '@/components/ToastContainer'


export const useBeforeRender = (callback, deps) => {
  const [isRun, setIsRun] = useState(false);

  if (!isRun) {
    callback();
    setIsRun(true);
  }

  useEffect(() => () => setIsRun(false), deps);
};

function App() {

	useBeforeRender(() => {
		window.addEventListener("error", (e) => {
			if (e) {
				const resizeObserverErrDiv = document.getElementById(
					"webpack-dev-server-client-overlay-div",
				);
				const resizeObserverErr = document.getElementById(
					"webpack-dev-server-client-overlay",
				);
				if (resizeObserverErr)
					resizeObserverErr.className = "hide-resize-observer";
				if (resizeObserverErrDiv)
					resizeObserverErrDiv.className = "hide-resize-observer";
			}
		});
	}, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Grommet theme={theme}>
          <Main className="App">
            <Router />
          </Main>
          <ToastContainer />
          <GlobalStyle />
        </Grommet>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
