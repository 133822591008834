import React from 'react'

import {
  Field,
  StyledInput,
  Label,
  InputWrapper,
  ErrorCircle,
  InvaildMessage,
} from './styles'

export function Input({
  label = '',
  placeholder = '',
  disabled = false,
  error = '',
  name,
  type,
  value,
  touched,
  onChange,
  onBlur,
  autocomplete,
  defaultValue,
  ...otherProps
}) {
  return (
    <Field {...otherProps}>
      {label && <Label>{label}</Label>}
      <InputWrapper>
        <StyledInput
          type={type}
          onChange={onChange}
          onBlur={onBlur}
          hasError={error}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          autocomplete={autocomplete}
          defaultValue={defaultValue}
        />
        {error && <ErrorCircle title={error}>!</ErrorCircle>}
      </InputWrapper>
      {error && <InvaildMessage>{error}</InvaildMessage>}
    </Field>
  )
}
