import React from 'react'

import { Logo } from '@/components/Logo'
import { useMediaQuery } from '@/hooks/useMediaQuery'

import { Wrapper, LogoContainer, ContentWrapper } from './styles'

function Layout({
  logo,
  justify = 'center',
  pt,
  background,
  children,
  hideLogo = false,
}) {
  const isDesktop = useMediaQuery('(min-width: 770px)')

  return (
    <Wrapper background={background}>
      {(isDesktop || logo) && !hideLogo && (
        <LogoContainer>
          <Logo size="small" />
        </LogoContainer>
      )}
      <ContentWrapper pt={pt} justify={justify}>
        {children}
      </ContentWrapper>
    </Wrapper>
  )
}

export default Layout
