/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react'
import { FieldArray, Formik } from 'formik'

import crossIcon from '@/assets/icons/cross.svg'
import plusIcon from '@/assets/icons/plusGreen.svg'
import arrowIcon from '@/assets/icons/smallArrow.svg'
import { Icon } from '@/components/Icon'
import { Title } from '@/components/Title'
import { Button } from '@/components/Button'
import { Loader } from '@/components/Loader'
import { Divider } from '@/components/Divider'
import { Subtitle } from '@/components/Subtitle'
import { HelpButton } from '@/components/HelpButton'
import { ProgressBar } from '@/components/ProgressBar'
import { ContentWrapper } from '@/components/ContentWrapper'
import { CREATE_ORGANIZATION_ROUTE } from '@/constants/routes'
import { InputField } from '@/fields/Input'

import {
  CloseIcon,
  LocationBlock,
  AdditionalLocationField,
  AdditionalLocationInputWrapper,
  CloseButton,
  HelpButtonWrapper,
} from './styles'
import { validationSchema, DEFAULT_VALUES } from './config'

const locationReqMsg = 'Location is a required field'

export function OrganizationDataForm({ initialValues, onSubmit, isLoading }) {
  const submitForm = useCallback((data) => {
    const formattedData = { ...data }
    formattedData.locations = formattedData.locations.filter((item) => !!item)
    onSubmit(formattedData)
  }, [])

  return (
    <>
      <HelpButtonWrapper>
        <HelpButton />
      </HelpButtonWrapper>
      <ProgressBar active={1} />
      <ContentWrapper
        size="small"
        title="Organization"
        titleSize={9}
        titleMargin={5}
        arrowLink
        path={CREATE_ORGANIZATION_ROUTE}
        padding="48px"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitForm}
          >
            {({ values, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <InputField
                  name="name"
                  label="NAME OF ORGANIZATION"
                  margin={3}
                />
                <LocationBlock>
                  <Title bold fontSize={7} margin={5}>
                    Locations
                  </Title>
                  <Subtitle fontSize={5} margin={0}>
                    Add any company stores/locations that have employees who
                    will be using the SOAR app.
                  </Subtitle>
                </LocationBlock>
                <FieldArray
                  name="locations"
                  render={({ remove, push }) => (
                    <>
                      {values.locations.length > 0 &&
                        values.locations.map((location, index) =>
                          index === 0 ? (
                            <InputField
                              name={`locations.${index}`}
                              label="Name of location 1"
                              margin={3}
                              errorMsg={locationReqMsg}
                            />
                          ) : (
                            <AdditionalLocationField key={index}>
                              <AdditionalLocationInputWrapper>
                                <InputField
                                  name={`locations.${index}`}
                                  label={`Name of location ${index + 1}`}
                                  margin={3}
                                  errorMsg={locationReqMsg}
                                />
                              </AdditionalLocationInputWrapper>
                              <CloseButton
                                type="button"
                                onClick={() => remove(index)}
                              >
                                <CloseIcon src={crossIcon} />
                              </CloseButton>
                            </AdditionalLocationField>
                          )
                        )}
                      <Button
                        type="button"
                        focusIndicator={false}
                        outlined
                        border={1}
                        width="130px"
                        fontSize={5}
                        margin={5}
                        onClick={() => push('')}
                      >
                        <Icon src={plusIcon} />
                        Add Location
                      </Button>
                    </>
                  )}
                />
                <Divider margin={0} size={1} />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  fontWeight="semiBold"
                  width="117px"
                  margin={{ top: '24px' }}
                >
                  <Icon src={arrowIcon} />
                  Continue
                </Button>
              </form>
            )}
          </Formik>
        )}
      </ContentWrapper>
    </>
  )
}

OrganizationDataForm.defaultProps = {
  initialValues: DEFAULT_VALUES,
  onSubmit: () => {},
}
