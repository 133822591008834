import styled from 'styled-components'

import { Title as BaseTitle } from '@/components/Title'

export const Title = styled(BaseTitle)`
  color: ${({ theme }) => theme.colors.greenButton};
  font-size: ${({ theme }) => theme.fontSizes[11]}px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-top: 58px;
`

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  margin-top: 14px;
  max-width: 371px;
  text-align: center;
`

export const SimpleText = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: ${({ theme }) => theme.fontSizes[5]}px;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  margin-top: 25px;
  text-align: center;
`

export const GreenText = styled.span`
  color: ${({ theme }) => theme.colors.greenButton};
  display: inline;
  font-size: ${({ theme }) => theme.fontSizes[5]}px;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  margin-top: 25px;
  text-align: center;
`
