import React from 'react'
import { Formik } from 'formik'
import { Text } from 'grommet'

import { Loader } from '@/components/Loader'
import { Button } from '@/components/Button'
import { DualFields } from '@/components/DualFields'
import { FormWrapper } from '@/components/FormWrapper'
import { SIGN_IN_ROUTE } from '@/constants/routes'
import { InputField } from '@/fields/Input'
import { MaskedInputField } from '@/fields/MaskedInput'

import { validationSchema, DEFAULT_VALUES } from './config'
import { SignInField, SignInLink, PasswordPrompt } from './styles'

export function SignUpForm({ initialValues, onSubmit, isLoading }) {
  return (
    <FormWrapper logo logoSize="regular" title="Create your admin account">
      {isLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <DualFields noWrap gap={5} margin={3}>
                <InputField
                  name="firstName"
                  label="FIRST NAME"
                  placeholder="First name"
                />
                <InputField
                  name="lastName"
                  label="LAST NAME"
                  placeholder="Last name"
                />
              </DualFields>
              <InputField
                name="email"
                label="WORK EMAIL"
                placeholder="test@soar.com"
                margin={3}
              />
              <MaskedInputField
                type="text"
                name="mobile"
                label="MOBILE PHONE NUMBER"
                placeholder="123-456-7890"
                margin={3}
              />
              <InputField
                type="password"
                name="password"
                label="PASSWORD"
                placeholder="xxxxxxxx"
                margin={3}
              />
              <PasswordPrompt>
                Must have at least 8 characters, 1 capital letter, and 1 number
              </PasswordPrompt>
              <Button type="submit" disabled={isSubmitting} margin={5}>
                Sign Up
              </Button>
              <SignInField>
                <Text size="14">Already have an account?</Text>
                <SignInLink to={SIGN_IN_ROUTE}>Sign In</SignInLink>
              </SignInField>
            </form>
          )}
        </Formik>
      )}
    </FormWrapper>
  )
}

SignUpForm.defaultProps = {
  initialValues: DEFAULT_VALUES,
  onSubmit: () => {},
}
