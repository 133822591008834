import axios from 'axios'
import { useMutation } from 'react-query'

import { createToast } from '@/helpers/createToast'
import { MANAGER_ENDPOINT } from '@/constants/endpoints'

export const useDeleteManagerMutation = () =>
  useMutation((payload) => axios.delete(`${MANAGER_ENDPOINT}/${payload.id}`), {
    onSuccess: () => {
      createToast('success', 'User has been deactivated successfully!')
    },
  })
