import React, { useMemo } from 'react'

import decreaseIcon from '@/assets/icons/decreaseTriangle.svg'
import increaseIcon from '@/assets/icons/increaseTriangle.svg'
import starIcon from '@/assets/icons/star.svg'
import { Icon } from '@/components/Icon'
import { Divider } from '@/components/Divider'
import { formatGoalsGraph } from '@/helpers/formatGraph'
import { formatGoalsGraphRange } from '@/helpers/formatGraphRange'

import {
  Wrapper,
  ItemWrapper,
  Step,
  StepTitle,
  StepValue,
  StepChange,
  DividerBlock,
  Arrow,
  ColumnTitle,
  PercentText,
  ItemContainer,
  ItemValue,
  ValueContainer,
  ItemName,
  WithIcon,
} from './styles'

export function GoalsGraph({ data, total, funnelData, range }) {
  const resultData = useMemo(() => {
    return data?.progressResult || []
  }, [data])

  const checkPositive = (result, goal) => {
    const value = result - goal
    switch (Math.sign(value)) {
      case 0:
        return 'white'
      case -1:
        return 'decrease'
      case 1:
        return 'increase'
      default:
        break
    }
  }

  const removeMinusSign = (value) => {
    try {
      const isPositive = Math.sign(value)

      if (isPositive === -1) {
        const checkValue = value.toString()
        return checkValue.toString().slice(1)
      }

      return value
    } catch (e) {
      console.log(e)
    }
  }

  const getIconWithValue = (value) => {
    switch (Math.sign(value)) {
      case 0:
        return starIcon
      case -1:
        return decreaseIcon
      case 1:
        return increaseIcon
      default:
        break
    }
  }

  return (
    <Wrapper style={{ marginTop: '10px', marginBottom: '-10px' }}>
      {resultData.map((item, index) => (
        <ItemContainer key={index}>
          <ItemName isChild={item.order.toString().includes(2)}>
            {item.name}
          </ItemName>

          <ValueContainer>
            <div>
              <ItemValue fontSize={28}>{parseFloat(item.goal)}</ItemValue>
              {index !== resultData.length - 1 && (
                <PercentText>
                  {parseInt(resultData[index + 1].goalPercent, 10)}%
                </PercentText>
              )}
            </div>
            <ItemValue
              color={checkPositive(item.result, item.goal)}
              fontSize={20}
            >
              {Math.sign(item.result - item.goal) === 0 ? (
                <Icon src={getIconWithValue(0)} size={20} margin={0} />
              ) : (
                <WithIcon fontSize={20}>
                  <Icon
                    src={getIconWithValue(item.result - item.goal)}
                    size={12}
                    margin={0}
                  />
                  {removeMinusSign(item.result - item.goal)}
                </WithIcon>
              )}
            </ItemValue>
            <div>
              <ItemValue
                color={checkPositive(item.result, item.goal)}
                fontSize={28}
              >
                {parseInt(item.result)}
              </ItemValue>
              {index !== resultData.length - 1 && (
                <PercentText>
                  {parseInt(resultData[index + 1].resultPercent)}%
                </PercentText>
              )}
            </div>
          </ValueContainer>
        </ItemContainer>
      ))}
    </Wrapper>
  )
}
