import axios from 'axios'
import { useMutation } from 'react-query'

import { FUNNEL_ENDPOINT } from '@/constants/endpoints'
import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'

export const useDeleteFunnelMutation = () =>
  useMutation((payload) =>
    axios.delete(`${FUNNEL_ENDPOINT}/${payload.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    })
  )
