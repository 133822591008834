import React from 'react'
import cls from 'classnames'

import { Button } from '@/components/Button'

export function AllUsersHeader({ openModal }) {
  const headerStyles = cls(
    'flex',
    'content-center',
    'bg-black',
    'w-full',
    'text-white',
    'font-bold',
    'h-24',
    'items-center',
    'justify-between',
    'pr-2',
    'pl-2'
  )
  return (
    <div className={headerStyles}>
      <div className="text-3xl ml-16">All Users</div>
      <Button
        type="submit"
        onClick={openModal}
        style={{ minWidth: '14rem', marginRight: '4rem' }}
        fontSize={5}
      >
        Add Employee
      </Button>
    </div>
  )
}
