import styled from 'styled-components'

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme }) => theme.spaces[5]}px;
`

export const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 160px;
  button {
    transition: 0.3s;
    &:disabled {
      background: rgb(75, 85, 99);
      color: rgb(156, 163, 175);
    }
  }
`

export const IconWrapper = styled.div`
  left: 120px;
  position: absolute;
  top: -1px;
`

export const WatchWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 30px;
  z-index: 2;
`

export const InputWrapper = styled.div`
  padding-bottom: 20px;
  position: relative;
  div {
    margin: 0;
  }
  .error {
    bottom: 3px;
    font-size: 12px;
    position: absolute;
  }
`
