import { useOrganizationImpersonate } from '@/hooks/useOrganizationImpersonate'
import React, { useContext, useEffect } from 'react'
import { useActor } from '@xstate/react'

import { SoarStateContext } from '@/global/soar.context'
import CoachingForm from '@/forms/Coaching'
import { MenuLayout } from '@/components/MenuLayout'
import backgroundImage from '@/assets/images/layout-background.png'
import { useOrgDataQuery } from '@/queries/get-org'
import { useCoacheeData } from '@/queries/get-coachee'
import { useGetSavedViewsQuery } from '@/queries/get-saved-views'

export default function Coaching() {
  const soarContext = useContext(SoarStateContext)
  const [state] = useActor(soarContext.dashboardService)

  const { orgId } = state.context
  const { organizationId } = useOrganizationImpersonate(orgId)

  const { data: orgData, refetch: refetchOrganization } =
    useOrgDataQuery(organizationId)
  const { data: savedViewData } = useGetSavedViewsQuery()
  const {
    mutate: fetchCoacheeData,
    data: coacheeData,
    isLoading: coacheeDataLoad,
  } = useCoacheeData(null, organizationId)

  useEffect(() => {
    fetchCoacheeData()
    refetchOrganization()
  }, [organizationId])

  return (
    <MenuLayout adminData={orgData} background={backgroundImage}>
      <CoachingForm
        orgData={orgData}
        coacheeData={coacheeData}
        savedViewData={savedViewData}
        coacheeDataLoad={coacheeDataLoad}
        fetchCoacheeData={fetchCoacheeData}
      />
    </MenuLayout>
  )
}
