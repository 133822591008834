/* eslint-disable no-nested-ternary */
import React, { useCallback, Fragment } from 'react'
import { useExpanded, useSortBy, useTable } from 'react-table'

import { Wrapper } from './styles'
import { theme } from '@/theme/baseTheme'

const voidHeaders = ['manager', 'average', 'leads', 'appointments', 'presentations', 'demos', 'write-ups', 'sales', 'usage', 'expertise'];

const defaultPropGetter = () => ({})

export function Table({
  columns,
  data,
  type,
  sort,
  sortingFunction,
  footer,
  allBlack,
  renderSubComponent,
  onClick,
  getRowProps = defaultPropGetter,
  getCeilProps = defaultPropGetter,
  ...props
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data
    },
    useSortBy,
    useExpanded,
  )

  const handleClick = useCallback(
    (event, item, index = '') => {
      if (onClick) {
        if (event.target.nodeName === 'BUTTON') return null
        return onClick(item, index)
      }
      return null
    },
    [onClick]
  )

  return (
    <Wrapper sort={sort} footer={footer} onClick={onClick} {...props}>
      <table {...getTableProps()}>
        {sort ? (
          <thead>
            <tr></tr>
            {headerGroups.map((headerGroup) => (
              <tr
                style={{
                  color: allBlack && theme.colors.backgroundActive,
                  backgroundColor: allBlack && '#111827',
                }}
                {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {voidHeaders.includes(column.Header) ? '' : column.render('Header')}
                      <div>
                        {column.isSorted ? (column.isSortedDesc ? '▾' : '▴') : ''}
                      </div>
                    </th>
                ))}
              </tr>
            ))}
          </thead>
        ) : (
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {voidHeaders.includes(column.Header) ? '' : column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <Fragment key={row.id + "_frag"}>
                <tr
                  style={{
                    color: sort ? 'rgb(209, 213, 219)' : 'rgb(156, 163, 175)',
                    backgroundColor: allBlack ? '#111827' : index % 2 === 0 ? '#111827' : '#1f2937',
                  }}
                  onClick={(event) => handleClick(event, row.original)}
                  {...row.getRowProps(getRowProps(row))}
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps(getCeilProps(cell))}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td></td>
                    <td colSpan={columns.length} style={{padding: 0}}>
                      <span>
                        {renderSubComponent(row.original)}
                      </span>
                    </td>
                  </tr>
                ) : null}
              </Fragment>
            )
          })}
        </tbody>
        {footer && (
          <tfoot>
            {footerGroups.map((group) => (
              <tr
                style={{
                  backgroundColor: allBlack && '#111827',
                }}
                {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </Wrapper>
  )
}
