import React from 'react'
import { Formik } from 'formik'
import { Text } from 'grommet'

import { Button } from '@/components/Button'
import { Loader } from '@/components/Loader'
import { DualFields } from '@/components/DualFields'
import { FormWrapper } from '@/components/FormWrapper'
import { FORGOT_PASSWORD_ROUTE, LANDING_ROUTE } from '@/constants/routes'
import { CheckboxField } from '@/fields/Checkbox'
import { InputField } from '@/fields/Input'

import { validationSchema, DEFAULT_VALUES } from './config'
import { SignUpField, StyledLink, SignUpLink, CheckboxWrapper } from './styles'

export function SignInForm({ initialValues, onSubmit, isLoading }) {
  return (
    <FormWrapper logo logoSize="regular" title="Sign in as Admin">
      {isLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <InputField
                name="email"
                label="WORK EMAIL"
                placeholder="test@soar.com"
                margin={3}
              />
              <InputField
                type="password"
                name="password"
                label="PASSWORD"
                placeholder="xxxxxxxx"
                margin={3}
              />
              <Button type="submit" margin={4}>
                Sign In
              </Button>
              <DualFields noWrap margin={5}>
                <CheckboxWrapper>
                  <CheckboxField name="rememberMe" label="Remember Me" noWrap />
                </CheckboxWrapper>
                <StyledLink to={FORGOT_PASSWORD_ROUTE}>
                  Forgot your password?
                </StyledLink>
              </DualFields>
              <SignUpField>
                <Text size="14">Don’t have an account?</Text>
                <SignUpLink to={LANDING_ROUTE}>Sign Up</SignUpLink>
              </SignUpField>
            </form>
          )}
        </Formik>
      )}
    </FormWrapper>
  )
}

SignInForm.defaultProps = {
  initialValues: DEFAULT_VALUES,
}
