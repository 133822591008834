import React, { useMemo } from 'react'

import { Title } from '@/components/Title'
import tableImage from '@/assets/images/billing-table.png'
import { Table } from '@/components/Table'
import { Loader } from '@/components/Loader'
import { If, Then, Else } from 'react-if'
import { ImageBlock } from '@/components/ImageBlock'
import { formatBillingInfo } from '@/helpers/formatData'

import {
  Wrapper,
  HeadingSection,
  ContentSection,
  TableBlock,
  TableImageBlockWrapper,
} from './styles'

export function BillingForm({ billingInfo, isFetching }) {
  const rows = isFetching ? [] : formatBillingInfo(billingInfo)

  const columns = useMemo(
    () => [
      {
        Header: 'Amount',
        accessor: 'amount_paid',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Date',
        accessor: 'created',
      },
      {
        Header: 'Invoice',
        accessor: 'invoice_pdf',
      },
    ],
    []
  )

  return (
    <>
      <Wrapper>
        <HeadingSection>
          <Title bold fontSize={9}>
            Billing
          </Title>
        </HeadingSection>
        <ContentSection>
          <TableBlock>
            <Title bold fontSize={7}>
              Billing History
            </Title>
            <If condition={isFetching}>
              <Then>
                <Loader />
              </Then>
              <Else>
                <If condition={rows.length > 0}>
                  <Then>
                    <Table sort data={rows} columns={columns} align="left" />
                  </Then>
                  <Else>
                    <TableImageBlockWrapper>
                      <ImageBlock
                        image={tableImage}
                        imageWidth="337px"
                        subtitle="No payments made yet!"
                        padding={2}
                      />
                    </TableImageBlockWrapper>
                  </Else>
                </If>
              </Else>
            </If>
          </TableBlock>
        </ContentSection>
      </Wrapper>
    </>
  )
}
