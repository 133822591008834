import { Text } from 'grommet'
import styled from 'styled-components'

export const SuccessRegistrationContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const QrContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10rem;
  margin: auto;
`
export const QrLink = styled.div`
  display: flex;
  flex-direction: column;
`

export const Subtitle = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes[7]}px;
  line-height: ${({ theme }) => theme.lineHeights[7]}px;
  margin: ${({ theme }) => theme.spaces[4]}px 0;
  text-align: center;
  ${({ theme }) => theme.below.tablet`
    font-size: ${() => theme.fontSizes[6]}px;
    line-height: ${() => theme.lineHeights[8]}px;
  `}
`

export const MobileStoreSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[7]}px;
  margin-top: ${({ theme }) => theme.spaces[7]}px;
`

export const MobileStoreLink = styled.a``

export const MobileStoreImage = styled.img`
  margin-top: 4rem;
  max-width: 22rem;
  width: 100%;
`
