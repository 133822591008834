export const addTotalToCoachee = (res) => {
  const result = [...res]
  result.map((coachee, index) => {
    let performance = 0
    let contactsFromCoach = 0
    let contactsFromCoachee = 0
    let sales = 0
    let overall = 0

    coachee.coachees.map((item) => {
      if (item && item.contacts) {
        performance += item.performance
        contactsFromCoach += item.contacts.received
        contactsFromCoachee += item.contacts.sent
        sales += item.sales
        overall += item.overall
        return item
      }
      return '-'
    })
    result[index].performance = performance
    result[index].contactsFromCoach = contactsFromCoach
    result[index].contactsFromCoachee = contactsFromCoachee
    result[index].sales = sales
    result[index].overall = coachee.coachees.length
      ? (overall * 100) / coachee.coachees.length
      : 0
    result[index].coachee = coachee.coachees.length
    return result
  })

  return result
}
