import React from 'react'

import peopleGroupImage from '@/assets/images/group-businesspeople.jpg'
import './index.css'
import { ChooseYourPlan } from '@/components/ChooseYourPlan'
import greenArrow from '@/assets/icons/greenArrow.svg'
import { Icon } from '@/components/Icon'

import { openCalendly } from 'utils/calendlyUtils'

export function PricingSection() {
  const GoToCalendly = (e) => {
    e.preventDefault()
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        "You're being redirected to our scheduling website. \nPlease setup an appointment for a sales consultation."
      )
    ) {
      openCalendly()
    }
  }
  return (
    <>
      <div className="elementor-row pricing-top-banner">
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element"
          data-element_type="column"
        >
          <div className="elementor-column-wrap elementor-element-populated">
            <div className="sales-banner elementor-widget-wrap">
              <div
                className="elementor-element elementor-element-722b3d7 elementor-widget elementor-widget-heading"
                data-id="722b3d7"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Start getting more from<strong> every lead.</strong>
                  </h2>
                </div>
              </div>
              <div className="elementor-element elementor-element-d5a8d32 elementor-icon-list--layout-inline elementor-align-center elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list">
                <div className="elementor-widget-container">
                  <ul className="elementor-icon-list-items elementor-inline-items">
                    <li className="elementor-icon-list-item elementor-inline-item">
                      <span className="elementor-icon-list-icon">
                        <Icon src={greenArrow} size={15} />
                      </span>
                      <span className="elementor-icon-list-text">
                        Free 30 Day Trial
                      </span>
                    </li>
                    <li className="elementor-icon-list-item elementor-inline-item">
                      <span className="elementor-icon-list-icon">
                        <Icon src={greenArrow} size={15} />
                      </span>
                      <span className="elementor-icon-list-text">
                        Unlimited Support
                      </span>
                    </li>
                    <li className="elementor-icon-list-item elementor-inline-item">
                      <span className="elementor-icon-list-icon">
                        <Icon src={greenArrow} size={15} />
                      </span>
                      <span className="elementor-icon-list-text">
                        Cancel Anytime
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="elementor-element elementor-element-4d55727 elementor-align-justify elementor-widget elementor-widget-button">
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper contact-sales-button">
                    <a
                      href="#"
                      onClick={GoToCalendly}
                      target="_blank"
                      className="elementor-button-link elementor-button elementor-size-sm external"
                      role="button"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-text">
                          Contact Sales
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element"
          data-element_type="column"
        >
          <div className="elementor-column-wrap elementor-element-populated">
            <div className="elementor-widget-wrap">
              <div className="elementor-widget-container">
                <img src={peopleGroupImage} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section
        className="pricing-section elementor-section elementor-top-section elementor-element elementor-element-316b9d7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="316b9d7"
        data-element_type="section"
      >
        <div id="pricing" className="elementor-menu-anchor" />
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <ChooseYourPlan />
          </div>
        </div>
      </section>
    </>
  )
}
