import { Else, If, Then } from 'react-if'
import React, { useEffect, useRef, useState } from 'react'
import { filter, orderBy } from 'lodash'

import { Button } from '@/components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loader } from '@/components/Loader'
import PropTypes from 'prop-types'
import { SearchAdminMenu } from '../SearchAdminMenu'
import { Table } from '@/components/Table'
import Toggle from 'react-toggle'
import activeStatus from '@/assets/icons/activeStatus.svg'
import cls from 'classnames'
import { formatOrgsData } from '@/helpers/formatData'
import inactiveStatus from '@/assets/icons/inactiveStatus.svg'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAllOrganizationsQuery } from './all_organizations_query'

function SortArrow({ sort, direction }) {
  const iconDir =
    direction === 'desc' ? solid('chevron-down') : solid('chevron-up')
  return (
    <FontAwesomeIcon
      onClick={() => sort()}
      icon={iconDir}
      className="ml-4 text-green-600"
    />
  )
}
export function AllOrganizations({
  openModal,
  handleOnChangeOrg,
  handleOnImpersonate,
  handleOnImpersonateByOrgId,
  handleEditOrg,
  handleMarkAsInactive,
  handleRestoreOrg,
}) {
  const [nameDir, setNameDir] = useState('desc')
  const [toggleChecked, setToggleChecked] = useState(false)
  const [totalSelected, setTotalSelected] = useState(0)
  const [billingDir, setBillingDir] = useState('desc')
  const [statusDir, setStatusDir] = useState('desc')
  const [rows, setRows] = useState([])
  const selectedOrgsRef = useRef(new Map())
  const orgsRef = useRef([])
  const [selectAllRows, setSelectAllRows] = useState(false)
  const { data: allOrgs, isLoading } = useAllOrganizationsQuery()
  const { orgs } = !allOrgs
    ? { orgs: [], inActiveTotal: 0, activeTotal: 0 }
    : allOrgs

  const handleImpersonate = (item) => {
    handleOnChangeOrg({
      value: item.id,
      label: item.name,
    })
    handleOnImpersonateByOrgId(item)
  }

  const handleToggleRecord = (org, checked) => {
    const orgsChecked = orgsRef.current.map((o) => {
      if (o.id === org.id) {
        return {
          ...o,
          checked,
        }
      }
      return { ...o }
    })

    orgsRef.current = orgsChecked
    if (!selectedOrgsRef.current.has(org.id)) {
      selectedOrgsRef.current.set(org.id, org)
    }

    if (!checked) {
      selectedOrgsRef.current.delete(org.id)
    }

    setTotalSelected(selectedOrgsRef.current.size)
    setRows(
      formatOrgsData(
        orgsChecked,
        handleEditOrg,
        handleImpersonate,
        handleToggleRecord
      )
    )

    return selectedOrgsRef
  }

  const handleToggleAllRecords = (e) => {
		if (!rows[0].id) return
    const selectAll = orgsRef.current.map((o) => ({
      ...o,
      checked: e.target.checked,
    }))
    orgsRef.current = selectAll
    selectedOrgsRef.current = new Map(
      selectAll.map((org) => {
        return [org.id, org]
      })
    )

    if (!e.target.checked) {
      selectedOrgsRef.current = new Map()
      setTotalSelected(0)
    } else {
      setTotalSelected(selectedOrgsRef.current.size)
    }
    setRows(
      formatOrgsData(
        selectAll,
        handleEditOrg,
        handleImpersonate,
        handleToggleRecord
      )
    )
    setSelectAllRows(!selectAllRows)
  }

  const headerStyles = cls(
    'flex',
    'content-center',
    'bg-black',
    'w-full',
    'text-white',
    'font-bold',
    'h-24',
    'items-center',
    'justify-between',
    'pr-2',
    'pl-2'
  )

  const markAsActiveStyles = cls(
    'flex',
    'border-solid',
    'w-60',
    'bg-slate-800',
    'border-slate-600',
    'hover:cursor-pointer',
    'border-2',
    'text-white',
    'justify-center',
    'items-center',
    'h-14',
    'rounded'
  )

  const markAsInactiveStyles = cls(
    'flex',
    'border-solid',
    'w-60',
    'bg-slate-800',
    'border-slate-600',
    'hover:cursor-pointer',
    'border-2',
    'text-white',
    'justify-center',
    'items-center',
    'h-14',
    'rounded'
  )

  const checkBoxStyle = cls(
    '!mt-7',
    'form-check-input',
    'appearance-none',
    'h-4',
    'w-4',
    'border',
    'border-gray-300',
    'rounded-sm',
    'bg-white',
    'checked:bg-green-600',
    'checked:border-green-600',
    'focus:outline-none',
    'transition',
    'duration-200',
    'mt-1',
    'align-top',
    'bg-no-repeat',
    'bg-center',
    'bg-contain',
    'float-left',
    'mr-2',
    'cursor-pointer'
  )

  const handleShowInActive = (e) => {
    setToggleChecked(e.target.checked)
		setSelectAllRows(false);
    if (!e.target.checked) {
      orgsRef.current = orgs
      return setRows(
        formatOrgsData(
          orgsRef.current,
          handleEditOrg,
          handleImpersonate,
          handleToggleRecord
        )
      )
    }

    const filteredRows = filter(
      orgsRef.current,
      (o) => o.status.toLowerCase() === 'inactive'
    )

    if (filteredRows.length <= 0) {
      return setRows([{}])
    }

    orgsRef.current = filteredRows
    return setRows(
      formatOrgsData(
        orgsRef.current,
        handleEditOrg,
        handleImpersonate,
        handleToggleRecord
      )
    )
  }

  const sortByName = (order) => {
		if (!rows[0].id) return
    const sortedOrgs = orderBy(orgsRef.current, ['name'], [order])
    orgsRef.current = sortedOrgs
    setRows(
      formatOrgsData(
        sortedOrgs,
        handleEditOrg,
        handleImpersonate,
        handleToggleRecord
      )
    )
  }

  const sortBillingPeriod = (order) => {
		if (!rows[0].id) return
    const sortedOrgs = orderBy(orgsRef.current, ['billingPeriod'], [order])
    orgsRef.current = sortedOrgs
    setRows(
      formatOrgsData(
        sortedOrgs,
        handleEditOrg,
        handleImpersonate,
        handleToggleRecord
      )
    )
  }

  const sortStatus = (order) => {
		if (!rows[0].id) return
    const sortedOrgs = orderBy(orgsRef.current, ['status'], [order])
    orgsRef.current = sortedOrgs
    setRows(
      formatOrgsData(
        sortedOrgs,
        handleEditOrg,
        handleImpersonate,
        handleToggleRecord
      )
    )
  }

  const markAsInactive = () => {
    const selectedOrgs = Array.from(selectedOrgsRef.current.values())
    selectedOrgsRef.current = new Map()
    setTotalSelected(0)
    handleMarkAsInactive(selectedOrgs)
  }

  const markAsActive = () => {
    const selectedOrgs = Array.from(selectedOrgsRef.current.values())
    selectedOrgsRef.current = new Map()
    setTotalSelected(0)
    handleRestoreOrg(selectedOrgs)
  }

  const filterOrganization = (value) => {
    setToggleChecked(false)
    if (!value) {
      orgsRef.current = [...orgs]
      return setRows(
        formatOrgsData(
          orgsRef.current,
          handleEditOrg,
          handleImpersonate,
          handleToggleRecord
        )
      )
    }
    const filteredRows = filter([...orgs], (o) => {
      return o?.name?.toLowerCase().includes(value.toLowerCase())
    })
    if (filteredRows.length <= 0) {
      return setRows([{}])
    }
    orgsRef.current = filteredRows
    return setRows(
      formatOrgsData(
        orgsRef.current,
        handleEditOrg,
        handleImpersonate,
        handleToggleRecord
      )
    )
  }

  useEffect(() => {
    orgsRef.current = orgs.map((o) => ({ ...o, checked: false }))
    setTotalSelected(0)
    setRows(
      formatOrgsData(
        orgsRef.current,
        handleEditOrg,
        handleImpersonate,
        handleToggleRecord
      )
    )
  }, [orgs])

  return (
    <>
      <div className={headerStyles}>
        <div className="text-3xl ml-16">All Organizations</div>
        <Button
          type="submit"
          onClick={openModal}
          style={{ minWidth: '14rem', marginRight: '4rem' }}
          fontSize={5}
        >
          Add Organization
        </Button>
      </div>
      <div className="flex gap-3 pt-8 pb-8">
        <div
          onClick={markAsActive}
          role="button"
          tabIndex={0}
          aria-hidden="true"
          className={markAsActiveStyles}
        >
          <span
            style={{
              backgroundImage: `url(${activeStatus})`,
              backgroundPosition: 'left',
              backgroundRepeat: 'no-repeat',
            }}
            className="w-8 h-6 align-middle display-inline-block"
          />{' '}
          Mark as Active ({totalSelected})
        </div>
        <div
          onClick={markAsInactive}
          role="button"
          tabIndex={0}
          aria-hidden="true"
          className={markAsInactiveStyles}
        >
          <span
            style={{
              backgroundImage: `url(${inactiveStatus})`,
              backgroundPosition: 'left',
              backgroundRepeat: 'no-repeat',
            }}
            className="w-8 h-6 align-middle display-inline-block"
          />{' '}
          Mark as Inactive ({totalSelected})
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-2/3">
          <SearchAdminMenu onSearch={filterOrganization} />
        </div>
        <label
          className="mt-2 form-check-label inline-block text-white mr-2"
          htmlFor="flexCheckDefault"
          style={{ width: '230px !important' }}
        >
          Show Inactive Organizations
        </label>
        <Toggle
          className="mt-2 ml-2"
          value=""
          checked={toggleChecked}
          onChange={handleShowInActive}
        />
      </div>
      <div className="text-white">
        <If condition={isLoading || !rows}>
          <Then>
            <Loader />
          </Then>
          <Else>
            <Table
              className="mt-8 border rounded-xl"
              style={{ borderColor: '#64748b' }}
              wide
              align="left"
              data={rows}
              columns={[
                {
                  id: 'checkbox',
                  accessor: 'checked',
                  Header: () => {
                    return (
                      <>
                        <input
                          type="checkbox"
                          className="checkbox"
                          checked={selectAllRows}
                          onChange={(e) => handleToggleAllRecords(e)}
                        />
                        <span className="ml-4">All</span>
                      </>
                    )
                  },
                },
                {
                  id: 'name',
                  Header: () => {
                    return (
                      <div className="flex items-center" style={{position: 'relative', top: 0, right: 0}}>
                        Organization Name
                        <SortArrow
                          sort={() => {
                            sortByName(nameDir)
                            if (nameDir === 'desc') return setNameDir('asc')
                            return setNameDir('desc')
                          }}
                          direction={nameDir}
                        />
                      </div>
                    )
                  },
                  accessor: 'name',
                },
                {
                  accessor: 'billingPeriod',
                  Header: () => {
                    return (
                      <div className="flex items-center" style={{position: 'relative', top: 0, right: 0}}>
                        Billing Period
                        <SortArrow
                          sort={() => {
                            sortBillingPeriod(billingDir)
                            if (billingDir === 'desc')
                              return setBillingDir('asc')
                            return setBillingDir('desc')
                          }}
                          direction={billingDir}
                        />
                      </div>
                    )
                  },
                },
                {
                  accessor: 'status',
                  Header: () => {
                    return (
                      <div className="flex items-center" style={{position: 'relative', top: 0, right: 0}}>
                        Status
                        <SortArrow
                          sort={() => {
                            sortStatus(statusDir)
                            if (statusDir === 'desc') return setStatusDir('asc')
                            return setStatusDir('desc')
                          }}
                          direction={statusDir}
                        />
                      </div>
                    )
                  },
                },
                {
                  Header: 'Actions',
                  accessor: 'actions',
                },
              ]}
            />
          </Else>
        </If>
      </div>
    </>
  )
}

AllOrganizations.propTypes = {
  openModal: PropTypes.func.isRequired,
}
