import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 163px;
  ${({ theme }) => theme.below.tablet`
    width: 130px;
  `}
`

export const ButtonWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes[8]}px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  justify-content: space-between;
  width: 163px;

  ${({ theme }) => theme.below.tablet`
    font-size: ${theme.fontSizes[6]}px;
    width: 130px;
  `}
`

export const QuestionBox = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundActive};
  border-radius: 18px;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes[9]}px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  height: 36px;
  justify-content: center;
  width: 36px;

  ${({ theme }) => theme.below.tablet`
    border-radius: 14px;
    font-size: ${theme.fontSizes[7]}px;
    height: 28px;
    width: 28px;
  `}
`
