import styled from 'styled-components'

export const BillingPeriodContainer = styled.label`
  align-items: center;
  display: inline-block;
`

export const BillingYearlyMsg = styled.span`
  background: ${({ darkStyle }) => (!darkStyle ? '#19ab27' : '#1f2937')};
  border-radius: 0 15px 15px 0;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamilies.default};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  left: ${({ darkStyle }) => (!darkStyle ? '-16px' : '-15px')};
  margin-left: 0.7rem;
  padding: ${({ darkStyle }) =>
    !darkStyle ? '3px 12px 3px 13px' : '3px 15px 3px 12px'};
  position: relative;
  top: -7px;
  z-index: ${({ darkStyle }) => (!darkStyle ? '0' : '')};
`

export const BillingMonthlyMsg = styled.span`
  background: ${({ darkStyle }) => (!darkStyle ? '#19ab27' : '#1f2937')};
  border-radius: 15px 0 0 15px;
  color: ${({ theme, darkStyle }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamilies.default};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-right: 0.7rem;
  padding: ${({ darkStyle }) =>
    !darkStyle ? '3px 13px 3px 12px' : '3px 12px 3px 15px'};
  position: relative;
  right: ${({ darkStyle }) => (!darkStyle ? '-16px' : '-15px')};
  top: -7px;
  z-index: ${({ darkStyle }) => (!darkStyle ? '0' : '')};
`
