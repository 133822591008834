import React, { useRef, useState } from 'react'

import { Icon } from '@/components/Icon'
import { Select } from '@/components/Select'
import cls from 'classnames'
import coachAndAdmin from '@/assets/icons/coachAndAdmin.svg'
import searchIcon from '@/assets/icons/search.svg'

export function SearchAdminMenu({
  onSearch,
  toggleInactive,
  sectionToShow,
  onChange,
  searchInputWidth,
}) {
  const [searchInput, setSearchInput] = useState('')
  const [fieldValue, setFieldValue] = useState({})
  const inputRef = useRef()

  const selectOptions = [
    { value: 'All Employees', label: 'All Employees', name: 'All Employees' },
    { value: 'Coach', label: 'Coach', name: 'Coach' },
    { value: 'Admin', label: 'Admin', name: 'Admin' },
    { value: 'Coach & Admin', label: 'Coach & Admin', name: 'Coach & Admin' },
  ]

  const searchAdminWrapper = cls('flex', 'items-center', 'w-full')

  const inputCls = cls(
    'border-none',
    'bg-transparent',
    'outline-none',
    'w-full',
    'ml-2',
    'mr-5'
  )
  const inputSearchCls = cls(
    'flex',
    'rounded-md',
    'border-gray-400',
    'border-solid',
    'border-2',
    'text-white',
    'h-14',
    'items-center',
    'pl-4',
    searchInputWidth || 'w-full',
    'mr-5',
    'bg-slate-800'
  )

  const btnCls = cls(
    '!border-green-600',
    'border-solid',
    'border-2',
    'h-14',
    'rounded-md',
    'w-40',
    'mr-5',
    'bg-green-600',
    'text-black',
    'font-bold'
  )

  const showInactiveStyles = cls(
    'display-inline-block',
    'border',
    'rounded-md',
    'text-white',
    'p-4',
    'w-60',
    'mr-5',
    'text-base',
    'leading-4',
    'border-solid',
    'border-slate-500',
    'cursor-pointer',
    'bg-slate-800'
  )

  return (
    <div className={searchAdminWrapper}>
      <div className={inputSearchCls}>
        <Icon src={searchIcon} size={20} />
        <input
          ref={inputRef}
          className={inputCls}
          style={{ border: 'none' }}
          type="text"
          onChange={(e) => {
            setSearchInput(e.target.value)
          }}
          value={searchInput}
          placeholder="Search"
        />
      </div>
      <button
        className={btnCls}
        type="submit"
        onClick={() => onSearch(searchInput)}
      >
        Search
      </button>

      {sectionToShow === 'usr' ? (
        <>
          <div className="w-72 mr-5">
            <Select
              options={selectOptions}
              icon={coachAndAdmin}
              setFieldValue={() => setFieldValue(fieldValue)}
              onChange={(value) => {
                onChange(value)
              }}
            />
          </div>
          <div className={showInactiveStyles}>
            <input
              onClick={toggleInactive}
              type="checkbox"
              className="align-middle"
            />{' '}
            Show Inactive Users
          </div>
        </>
      ) : null}
    </div>
  )
}
