import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import { SIGN_IN_ROUTE } from '@/constants/routes'
import { useAuth } from '@/contexts/auth'
import { Loader } from '@/components/Loader'

import { routeConfig } from './config'

export function Router() {
  const { authorized, isLoading } = useAuth()

  if (isLoading) {
    return <Loader />
  }

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>
          {routeConfig.map(({ isProtected, path, element }) => (
            <Route
              key={path}
              path={path}
              element={
                !isProtected || authorized ? (
                  element
                ) : (
                  <Navigate to={SIGN_IN_ROUTE} />
                )
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}
