import styled from 'styled-components'

export const Image = styled.img`
  display: block;
  width: ${({ imageWidth }) => imageWidth || '100%'};
`

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[5]}px;
  max-width: ${({ width }) => width || 'auto'};
  width: 100%;
`

export const ButtonWrapper = styled.div`
  max-width: 231px;
  width: 90%;
`
