import styled from 'styled-components'

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const Icon = styled.img`
  height: 10px;
  width: 10px;
`
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div`
  align-items: center;
  background: ${({ theme, checked }) =>
    checked ? theme.colors.backgroundActive : theme.colors.backgroundItems};
  border: 1px solid
    ${({ theme, checked }) =>
      checked ? theme.colors.backgroundActiveDark : theme.colors.border};
  border-radius: ${({ theme }) => theme.spaces[2]}px;
  cursor: pointer;
  display: flex;
  height: ${({ size }) => size || 16}px;
  justify-content: center;
  transition: all 0.1s;
  width: ${({ size }) => size || 16}px;

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`

export const Label = styled.span`
  align-items: center;
  color: ${({ theme, color }) =>
    theme.colors[color] || theme.colors.primaryText};
  display: flex;
  line-height: ${({ theme }) => theme.lineHeights[3]}px;
  margin-left: ${({ theme }) => theme.spaces[3]}px;
  ${({ noWrap }) => noWrap && 'white-space: nowrap;'}
`

export const Wrapper = styled.label`
  align-items: center;
  display: flex;
`
