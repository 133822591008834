export const REQUIRED_FIELD_ERROR_MESSAGE = 'Required field'
export const PHONE_ERROR_MESSAGE = 'Phone is format (XXX)XXX-XXXX'
export const VIEW_NAME_ERROR_MESSAGE = 'View name should not be more than 30 characters'
export const PASSWORD_LENGTH_ERROR_MESSAGE = 'Password should be 8 to 16 characters'
export const FIRSTNAME_LENGTH_ERROR_MESSAGE = 'Firstname should not be more than 30 characters'
export const LASTNAME_LENGTH_ERROR_MESSAGE = 'Lastname should not be more than 30 characters'
export const NAME_LENGTH_ERROR_MESSAGE = 'Name should not be more than 30 characters'
export const LOCATION_LENGTH_ERROR_MESSAGE = 'Location should not be more than 30 characters'

export const getMinLengthErrorMessage = (value) => `Minimum ${value} symbols`
export const getMaxLengthErrorMessage = (value) => `Maximum ${value} symbols`
