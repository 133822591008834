import { Text as BaseText } from 'grommet'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spaces[5]}px;
  max-width: 540px;
  width: 100%;

  ${({ theme }) => theme.below.laptop`
     margin-top: 50px;
  `}

  ${({ theme }) => theme.below.tablet`
     margin-top: 60px;
     width: 90%;
  `}
`

export const Text = styled(BaseText)`
  color: white;
  cursor: default;
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  line-height: ${({ theme }) => theme.lineHeights[4]}px;
  text-align: center;

  ${({ theme }) => theme.below.mobileL`
     font-size: ${() => theme.fontSizes[3]}px;
  `}
`

export const Item = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  font-weight: ${({ theme, active }) =>
    active ? theme.fontWeights.bold : theme.fontWeights.normal};
  position: relative;

  &:before {
    position: absolute;
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundInactive};
    width: 100%;
    top: ${({ theme }) => theme.spaces[5]}px;
    left: -50%;
    z-index: 2;
  }

  &:after {
    position: absolute;
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundInactive};
    width: 100%;
    top: ${({ theme }) => theme.spaces[5]}px;
    left: 50%;
    z-index: 2;
  }

  &:first-child::before {
    content: none;
  }

  &:last-child::after {
    content: none;
  }
`

export const Counter = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundInactive};
  border-radius: 50%;
  color: white;
  display: flex;
  height: ${({ theme }) => theme.spaces[7]}px;
  justify-content: center;
  margin-bottom: 6px;
  position: relative;
  width: ${({ theme }) => theme.spaces[7]}px;
  z-index: 5;
`

export const CompletedCounter = styled(Counter)`
  background-color: ${({ theme }) => theme.colors.backgroundActiveDark};
`

export const ActiveCounter = styled(Counter)`
  background-color: ${({ theme }) => theme.colors.backgroundActive};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`
