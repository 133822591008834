import axios from 'axios'
import { useMutation } from 'react-query'

import { USER_ENDPOINT } from '@/constants/endpoints'

export const useEditUserMutation = (options) =>
  useMutation(
    (payload) => axios.patch(`${USER_ENDPOINT}/${payload.id}`, payload),
    options
  )
