import React from 'react'

import { Icon } from '@/components/Icon'
import { Title } from '@/components/Title'
import { Button } from '@/components/Button'
import { Divider } from '@/components/Divider'
import { CloseButton } from '@/components/CloseButton'
import { ModalWindow } from '@/components/ModalWindow'
import deleteIcon from '@/assets/icons/delete.svg'
import pencilIcon from '@/assets/icons/pencilGreen.svg'
import { createToast } from '@/helpers/createToast'

import {
  TitleWrapper,
  ItemsWrapper,
  Item,
  ItemValue,
  ItemButtonsWrapper,
  ItemEditButton,
} from './styles'

export function SettingsLocationsModalForm({
  isOpen,
  closeModal,
  items,
  openAddAdditional,
  openEditAdditional,
  openDeleteAdditional,
}) {
  const deleteLocationCheck = (item) => {
    if (items.length === 1) {
      createToast(
        'info',
        `${item} is the only location left for this organization, the option to delete this location is disabled.`
      )
    } else {
      openDeleteAdditional(item)
    }
  }

  return (
    <ModalWindow
      isOpen={isOpen}
      handleClose={closeModal}
      width={450}
      padding={24}
    >
      <CloseButton onClick={closeModal} />
      <TitleWrapper>
        <Title bold fontSize={8}>
          Edit Locations
        </Title>
        <Button
          fontSize={5}
          height="26px"
          width="60px"
          onClick={openAddAdditional}
        >
          Add
        </Button>
      </TitleWrapper>
      <Divider size={1} margin={4} />
      <ItemsWrapper>
        {items.map((item) => (
          <Item key={item}>
            <ItemValue>
              <Title fontSize={5}>{item}</Title>
            </ItemValue>
            <ItemButtonsWrapper>
              <ItemEditButton onClick={() => openEditAdditional(item)}>
                <Icon margin={0} src={pencilIcon} size={14} />
                Edit
              </ItemEditButton>
              <ItemEditButton gray onClick={() => deleteLocationCheck(item)}>
                <Icon margin={0} src={deleteIcon} size={14} />
                Delete
              </ItemEditButton>
            </ItemButtonsWrapper>
          </Item>
        ))}
      </ItemsWrapper>
    </ModalWindow>
  )
}
