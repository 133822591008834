import styled from 'styled-components'

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme }) => theme.spaces[5]}px;
`

export const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 160px;
`
