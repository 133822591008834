import axios from 'axios'
import { useMutation, useQuery } from 'react-query'

import { DASHBOARD_TABLE_DATA_ENDPOINT } from '@/constants/endpoints'

export const useTableDataMutation = (options) => {
  return useMutation(
    (
      payload = {
        date: '2022-04',
        skip: 0,
        limit: 1000000,
        search: '',
      }
    ) => {
      const { orgId } = options
      return axios.post(`${DASHBOARD_TABLE_DATA_ENDPOINT}/${orgId}`, {
        ...payload,
        limit: 1000000,
      })
    }
  )
}

export const useTableDataQuery = (orgId) => {
  const body = {
    date: '2022-10-1',
    skip: 0,
    limit: 1000000,
    search: '',
  }

  return useQuery({
    queryKey: ['employeesTable', orgId],
    queryFn: () =>
      axios.post(`${DASHBOARD_TABLE_DATA_ENDPOINT}/${orgId}`, {
        ...body,
        limit: 1000000,
      }),
    enabled: !!orgId,
  })
}
