import styled from 'styled-components'

export const TitleContainer = styled.p`
  color: ${({ theme, color }) => {
    if (color) {
      return theme.colors[color] || color
    }

    return theme.colors.primaryText
  }};
  position: ${({ position }) => position};
  left: ${({ left }) => left};
  font-family: ${({ theme }) => theme.fontFamilies.default};
  font-size: ${({ theme, fontSize }) =>
    fontSize ? theme.fontSizes[fontSize] : theme.fontSizes[10]}px;
  font-style: ${({ theme, italic }) =>
    italic ? theme.fontStyles.italic : theme.fontStyles.normal};
  font-weight: ${({ theme, medium, semiBold, bold }) => {
    if (medium) {
      return theme.fontWeights.medium
    }

    if (semiBold) {
      return theme.fontWeights.semiBold
    }

    if (bold) {
      return theme.fontWeights.bold
    }

    return theme.fontWeights.normal
  }};

  line-height: ${({ theme, fontSize }) =>
    fontSize ? theme.lineHeights[fontSize] : theme.lineHeights[10]}px;
  margin: ${({ theme, margin }) => theme.spaces[margin]}px 0;
  margin-bottom: ${({ theme, marginBottom }) => theme.spaces[marginBottom]}px;

  text-transform: ${({ upper, capitalize }) => {
    if (upper) {
      return 'uppercase'
    }

    if (capitalize) {
      return 'capitalize'
    }

    return 'initial'
  }}
  }

  & > span {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
`
