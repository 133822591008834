import React from 'react'

import {
  DualScreenWrapper,
  DualScreenDecor,
  DualScreenDecorImage,
  DualScreenContent,
} from './styles'

export function DualScreenLayout({ image, imageWidth, background, children }) {
  return (
    <DualScreenWrapper>
      <DualScreenDecor background={background}>
        <DualScreenDecorImage src={image} imageWidth={imageWidth} />
      </DualScreenDecor>
      <DualScreenContent>{children}</DualScreenContent>
    </DualScreenWrapper>
  )
}
