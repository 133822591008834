import { createMachine, assign } from 'xstate'

export const subscriptionMachine = createMachine({
  id: 'subscriptionPlan',
  initial: 'selected',
  context: {
    planDetails: {
      name: '',
      users: 0,
      price: 0,
      frequency: '',
      band: '',
    },
  },
  states: {
    selected: {
      on: {
        SELECTED_PLAN_DETAILS: {
          actions: assign({
            planDetails: (context, event) => {
              return {
                ...event.planDetails,
              }
            },
          }),
        },
        CLEAR_PLAN_DETAILS: {
          actions: assign({
            planDetails: {
              name: '',
              totalUsers: 0,
              price: 0,
              frequency: '',
              band: '',
            },
          }),
        },
      },
    },
    end: {},
  },
})
