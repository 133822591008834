import axios from 'axios'
import { useMutation } from 'react-query'
import { signInWithEmailLink } from 'firebase/auth'

import { auth } from '@/services/firebase'
import { UPDATE_PASSWORD_ENDPOINT } from '@/constants/endpoints'

export const useUpdatePasswordMutation = (options) =>
  useMutation(async (payload) => {
    const formatPayload = payload
    delete formatPayload.rePassword

    const { _tokenResponse: token } = await signInWithEmailLink(
      auth,
      formatPayload.email,
      window.location.href
    )

    return axios.post(UPDATE_PASSWORD_ENDPOINT, formatPayload, {
      headers: {
        Authorization: `Bearer ${token.idToken}`,
      },
    })
  }, options)
