import { useOrganizationImpersonate } from '@/hooks/useOrganizationImpersonate'
import { useMeQuery } from '@/queries/get-me'
import { useOrgDataQuery } from '@/queries/get-org'
import { useUpdateOrgDataMutation } from '@/queries/update-org-data'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Grid } from 'grommet'

import { Icon } from '@/components/Icon'
import { Title } from '@/components/Title'
import { Loader } from '@/components/Loader'
import { Button } from '@/components/Button'
import { ArrowLink } from '@/components/ArrowLink'
import { ProgressBar } from '@/components/ProgressBar'
import { ModalWindow } from '@/components/ModalWindow'
import { DASHBOARD_ROUTE, SEND_INVITES_ROUTE } from '@/constants/routes'
import arrowIcon from '@/assets/icons/rightArrow.svg'
import { SalesFunnelOptions } from './enum'

import {
  ModalContent,
  ArrowLinkContainer,
  ContentBlock,
  Subtitle,
  InputBlock,
  FieldWrapper,
  Input,
  SalesFunnelOptionItem,
} from './styles'

export function ManageReportingForm({
  isLoading,
  deleteLoading,
  data,
  deleteItem,
}) {
  const navigate = useNavigate()
  const [modal, setModal] = useState('')
  const [selectedValues, setSelectedValues] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ])
  const [selectedFunnelOption, setSelectedFunnelOption] = useState('0')
  const { data: meData, isLoading: myDataLoading } = useMeQuery({
    staleTime: Infinity,
  })

  const { organizationId } = useOrganizationImpersonate(meData?.organization)

  const { data: orgData, isLoading: orgLoading } = useOrgDataQuery(
    organizationId || '',
    {
      enabled: !!organizationId,
    }
  )
  const { mutate: updateOrgData } = useUpdateOrgDataMutation()

  const closeModal = useCallback(() => setModal(''), [])

  const selectHandler = useCallback(
    (id) => {
      setSelectedValues((prevValues) =>
        prevValues.map((value, index) => (index === id ? !value : value))
      )
    },
    [selectedValues]
  )

  const submitFunnel = useCallback(() => {
    data.map(
      (item, index) =>
        !item.isSales && !selectedValues[index] && deleteItem(item)
    )
  }, [selectedValues, data])

  const submitForm = useCallback(() => {
    // if (
    //   selectedValues.reduce(
    //     (prev, item) => (item === true ? prev + 1 : prev),
    //     0
    //   ) > 1
    // ) {
    //   setModal('success')
    //   submitFunnel()
    // } else setModal('error')

    setModal('success')

    updateOrgData({
      id: orgData.id,
      name: orgData.orgName,
      locations: orgData.locations,
      conversionMeasurementOption: parseInt(selectedFunnelOption, 10),
    })
    submitFunnel()
  }, [selectedValues, submitFunnel, orgData, selectedFunnelOption])

  const handleSalesFunnelOptionChange = (event) => {
    setSelectedFunnelOption(event.target.value)
  }

  return (
    <>
      <ModalWindow
        maxWidth={470}
        isOpen={modal === 'success'}
        closeModal={closeModal}
      >
        {deleteLoading ? (
          <Loader />
        ) : (
          <ModalContent>
            <Title bold>Your setup is now complete!</Title>
            <Title fontSize={6} marginBottom={0}>
              Great work! The next screen is your Dashboard, which you'll see
              each day you log into the SOAR Portal
            </Title>
            <Button onClick={() => navigate(DASHBOARD_ROUTE)} width="230px">
              Go to Dashboard
            </Button>
          </ModalContent>
        )}
      </ModalWindow>
      <ModalWindow
        maxWidth={470}
        isOpen={modal === 'error'}
        closeModal={closeModal}
      >
        <ModalContent>
          <Title bold fontSize={9}>
            Please select at least one additional step you’d like to track
          </Title>
          <Button onClick={closeModal} width="230px">
            Update Funnel
          </Button>
        </ModalContent>
      </ModalWindow>
      <ProgressBar active={5} />
      <ArrowLinkContainer>
        <ArrowLink to={SEND_INVITES_ROUTE}>Back</ArrowLink>
      </ArrowLinkContainer>
      <ContentBlock>
        {isLoading || orgLoading || myDataLoading ? (
          <Loader />
        ) : (
          <>
            <Title bold fontSize={9} marginBottom={5}>
              Choose your Sales Funnel Option
            </Title>

            <Box className="w-full">
              <Grid
                className="text-center border-l-0 border-r-0 border-solid border-2 border-[#4B5563]"
                columns={['1/2', '1/2']}
                gap="medium"
              >
                <Box
                  border={{ color: '#4B5563', side: 'right', size: 'small' }}
                >
                  <Title className="text-[18px] font-bold">
                    Sales Conversion Measurement Options
                  </Title>
                </Box>

                <Box>
                  <Title className="text-[18px] font-bold">
                    Sales Conversion Funnel
                  </Title>
                </Box>
              </Grid>
              <Grid columns={['1/2', '1/2']} gap="medium" className="mb-[60px]">
                <Box
                  className="pt-8"
                  border={{ color: '#4B5563', side: 'right', size: 'small' }}
                >
                  <p
                    className="text-white font-bold"
                    style={{ fontFamily: 'Oswald, sans-serif' }}
                  >
                    Select One:
                  </p>
                  {SalesFunnelOptions.getList().map((item) => (
                    <Box align="start" className="pl-5">
                      <SalesFunnelOptionItem
                        htmlFor={item.id}
                        className="text-white my-2"
                      >
                        <input
                          type="radio"
                          name="funnel-option"
                          className="relative float-left -ml-[1.5rem] mr-2 mt-1.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-success checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-success checked:after:bg-success checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-success checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#5CAE6D] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-success dark:checked:after:border-success dark:checked:after:bg-success dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-success dark:checked:focus:before:shadow-[0px_0px_0px_13px_#5CAE6D]"
                          key={item.id}
                          value={item.id}
                          id={item.id}
                          checked={
                            parseInt(selectedFunnelOption, 10) === item.id
                          }
                          onChange={handleSalesFunnelOptionChange}
                        />
                        {item.label}
                      </SalesFunnelOptionItem>
                    </Box>
                  ))}
                </Box>
                <InputBlock className="pt-8">
                  {data.map((item, index) => (
                    <FieldWrapper width={390 - index * 36} key={item.id}>
                      <Box focusIndicator={false} width="100%">
                        {item.order === 2 ? (
                          <Grid columns={['1/2', '1/2']} gap="small">
                            <Input disabled value={item.name} />
                            <Input disabled value="In-Store Visits" />
                          </Grid>
                        ) : (
                          <Input disabled value={item.order === 2.1 ? 'Total Store Visits' : item.name} />
                        )}
                      </Box>
                    </FieldWrapper>
                  ))}
                </InputBlock>
              </Grid>
            </Box>

            <Button
              fontSize={5}
              width="180px"
              margin={{ top: '16px' }}
              onClick={submitForm}
            >
              <Icon src={arrowIcon} />
              Save & Continue
            </Button>
          </>
        )}
      </ContentBlock>
    </>
  )
}
