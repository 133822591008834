/* eslint-disable no-console */
import axios from 'axios'
import { useMutation } from 'react-query'

import { GET_COACHEE_DATA } from '@/constants/endpoints'

export const useCoacheeData = (options, orgId) =>
  useMutation(
    (
      payload = {
        startDate: '',
        endDate: '',
        date: '',
      }
    ) => {
      // default response in case call fails
      let data = {
        list: [],
        total: 0,
      }

      data = axios
        .post(`${GET_COACHEE_DATA}/${orgId}`, payload) // todo: add timeout on calls
        .catch((error) =>
          error.response.status === 400 ? [] : console.log(error)
        )
      return data
    },
    options
  )
