import React from 'react'
import { Field } from 'formik'
import { Box } from 'grommet'

import { ErrorMessage } from '@/components/ErrorMessage'
import { Select } from '@/components/Select'

export function SelectField({ name, options, ...otherProps }) {
  return (
    <Field name={name}>
      {({ field, meta, form }) => (
        <Box>
          <Select
            error={meta.touched && meta.error}
            options={options}
            setFieldValue={form.setFieldValue}
            {...field}
            {...otherProps}
          />
          {meta.touched && meta.error && (
            <ErrorMessage>{meta.error}</ErrorMessage>
          )}
        </Box>
      )}
    </Field>
  )
}
