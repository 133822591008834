import React from 'react'

import { Button } from '@/components/Button'
import Layout from '@/components/Layout'
import { Logo } from '@/components/Logo'
import CircleLegend from '@/components/CircleLegend'
import { Link } from '@/components/Link'
import { ImageBlock } from '@/components/ImageBlock'
import { SIGN_IN_ROUTE, SIGN_UP_ROUTE } from '@/constants/routes'
import signupImage from '@/assets/images/sign-up.png'
import doneIcon from '@/assets/icons/done-circle.svg'

import { Title, Subtitle, SimpleText } from './styles'

export default function HomePage() {
  return (
    <Layout hideLogo>
      <Logo size="regular" />
      <Title>Get started for free today</Title>
      <Subtitle>
        We won't charge for the first month &amp; you can cancel online anytime.
      </Subtitle>
      <SimpleText>Set up your admin account with 3 simple steps</SimpleText>
      <div style={{ textAlign: 'left', width: '100%', 'max-width': '310px' }}>
        <CircleLegend iconSrc={doneIcon}>
          Create your admin account
        </CircleLegend>
        <CircleLegend iconSrc={doneIcon}>Choose your plan</CircleLegend>
        <CircleLegend iconSrc={doneIcon}>Add payment method</CircleLegend>
      </div>
      <Link to={SIGN_UP_ROUTE}>
        <Button width="334px" style={{ marginTop: '20px' }}>
          Let's Go
        </Button>
      </Link>
      <div style={{ display: 'inline', marginTop: '24px' }}>
        <SimpleText style={{ display: 'inline' }}>
          Already have an account?
        </SimpleText>{' '}
        <Link to={SIGN_IN_ROUTE}>Sign In</Link>
      </div>
      <ImageBlock image={signupImage} imageWidth={334} />
    </Layout>
  )
}
