import * as Yup from 'yup'

import {
  PHONE_ERROR_MESSAGE,
  REQUIRED_FIELD_ERROR_MESSAGE,
} from '@/constants/error-messages'
import { PHONE_REGEXP } from '@/constants/forms'

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  email: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE).email(),
  phone: Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .matches(PHONE_REGEXP, PHONE_ERROR_MESSAGE),
  location: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
})

export const validationEmployeeSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  email: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE).email(),
  phone: Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .matches(PHONE_REGEXP, PHONE_ERROR_MESSAGE),
  location: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  manager: Yup.mixed().required(REQUIRED_FIELD_ERROR_MESSAGE),
})

export const DEFAULT_VALUES = {
  name: '',
  phone: '',
  email: '',
  location: '',
  certifiedCoach: false,
  expertise: [],
}
