import axios from 'axios'
import { useMutation } from 'react-query'

import { GET_DASHBOARD_GRAPH_TRENDS_ENDPOINT } from '@/constants/endpoints'

export const useTrendsGraphDataMutation = (options, orgId) =>
  useMutation(
    (
      payload = {
        locations: [],
        search: '',
      }
    ) => {
      const dashboardsUrl = GET_DASHBOARD_GRAPH_TRENDS_ENDPOINT(orgId)
      return axios.post(dashboardsUrl, payload)
    },
    options
  )
