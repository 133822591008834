import React, { useState } from 'react'

import Layout from '@/components/Layout'
import { UploadSpreadsheetForm } from '@/forms/UploadSpreadsheet'
import backgroundImage from '@/assets/images/layout-background.png'
import { createToast } from '@/helpers/createToast'
import { useBatchUploadService } from '@/queries/upload-batch'

export default function UploadSpreadsheet() {
  const sendFile = async (file) => {
    let result = null
    try {
      result = await useBatchUploadService(null, file)
      if (result.data.message !== 'success') {
        createToast('error', data)
      }
    } catch (err) {
      createToast('error', err)
    }
    return result
  }

  return (
    <Layout logo justify="start" background={backgroundImage}>
      <UploadSpreadsheetForm submitFile={sendFile} />
    </Layout>
  )
}
