import React from 'react'

import { Header } from '@/components/Header'
import { useAuth } from '@/contexts/auth'
import { useLocation } from 'react-router-dom'

import { Wrapper, ContentWrapper } from './styles'

export function MenuLayout({
  adminData,
  background,
  children,
  page = '',
  viewFlag = '',
  setViewFlag = {},
}) {
  const { isSuperAdmin } = useAuth()
  const { search } = useLocation()

  const showImpersonate = new URLSearchParams(search).get('showImpersonate')
  const displayImpersonateBar = isSuperAdmin || Boolean(showImpersonate)

  return (
    <Wrapper background={background}>
      <Header
        showImpersonation={displayImpersonateBar}
        adminData={adminData}
        page={page}
        viewFlag={viewFlag}
        setViewFlag={setViewFlag}
      />
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  )
}
