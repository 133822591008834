import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${({ wide }) => (wide ? 1110 : 870)}px) {
    max-height: 550px;
    overflow: scroll;
  }

  table {
    background-color: ${({ theme }) => theme.colors.background};
    border: ${({ theme }) => `1px solid ${theme.colors.tableBorder}`};
    border-radius: 3px;
    border-spacing: 0;
    width: 100%;

    thead {
      background-color: ${({ theme }) => theme.colors.backgroundItems};
      border: none;

      tr:first-child {
        display: ${({ footer }) => footer && 'none'};
      }

      tr {
        th {
          text-align: center;
          border: 1px solid rgb(46 52 61);
          position: relative;

          div {
            top: 15px;
            right: 10px;
            position: absolute;
            font-style: normal;
            color: white;
          }
        }
        th:first-child {
          border-radius: ${({ noBorder }) => !noBorder && `6px 0 0 0`};
          padding-left: ${({ theme }) => theme.spaces[6]}px;
        }
        th:last-child {
          border-radius: ${({ noBorder }) => !noBorder && `0 6px 0 0`};
        }
      }
    }

    tbody {
      tr {
        cursor: ${({ onClick }) => onClick && 'pointer'};
        td {
          position: relative;
          border: 1px solid rgb(46 52 61);
          white-space: pre-line;
          text-align: center;
        }
        &:hover {
          background-color: ${({ theme, onClick }) =>
            onClick && theme.colors.background};
          filter: ${({ onClick }) => onClick && 'brightness(1.2)'};
        }
      }

      tr:nth-child(odd) > td,
      tr:nth-child(odd) > th {
        background-color: transparent;
      }
    }

    tfoot {
      background-color: ${({ theme }) => theme.colors.backgroundItems};
      border: none;
      font-weight: ${({ theme }) => theme.fontWeights.bold};

      tr {
        th {
          text-align: left;
          border: none;
        }
      }
    }

    th,
    td {
      border: 1px solid rgb(46 52 61);
      padding: ${({ theme }) => theme.spaces[4]}px;
      text-align: ${({ align }) => align || 'center'};
      white-space: pre-line;
      text-align: center;
      font-style: italic;
      color: ${({ theme }) => theme.colors.percentage} !important;
    }

    tr {
      td:first-child, td:nth-child(2) {
        padding-left: ${({ theme }) => theme.spaces[6]}px;
        text-align: left;
      }

      td::first-line, th::first-line {
        font-style: normal;
        color: white;
      }
    }
  }
`
export const OpenIconWrapper = styled.div`
  left: 0;
  position: absolute;
  top: 15px;
  transition: 0.3s;
  &.open {
    top: 19px;
    transform: rotate(90deg);
  }
`
