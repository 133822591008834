import React from 'react'
import { useInterpret } from '@xstate/react'

import { SoarStateContext } from './soar.context'
import { dashboardMachine } from './dashboard.machine'
import { coachingMachine } from './coaching.machine'
import { subscriptionMachine } from './subscription.machine'
import { settingsMachine } from './settings.machine'

export function SoarStateProvider({ children }) {
  const dashboardService = useInterpret(dashboardMachine)
  const coachingService = useInterpret(coachingMachine)
  const subscriptionService = useInterpret(subscriptionMachine)
  const settingsService = useInterpret(settingsMachine)

  return (
    <SoarStateContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        dashboardService,
        coachingService,
        settingsService,
        subscriptionService,
      }}
    >
      {children}
    </SoarStateContext.Provider>
  )
}
