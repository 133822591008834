import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  border: 1px solid ${({ theme }) => theme.colors.backgroundInactive};
  border-radius: ${({ theme }) => theme.spaces[3]}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 12px 12px 12px 12px;
  min-height: 545px;
  min-width: 306px;
  padding: 16px 0 27px;
  width: 306px;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
`
