import React from 'react'

export function HowItWorksSection() {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-3f7ea30 elementor-section-content-middle elementor-section-full_width elementor-section-height-default elementor-section-height-default"
      data-id="3f7ea30"
      data-element_type="section"
    >
      <div className="elementor-background-overlay" />
      <div className="elementor-container elementor-column-gap-no">
        <div className="elementor-row">
          <div
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-aaaa604 elementor-hidden-tablet elementor-hidden-mobile"
            data-id="aaaa604"
            data-element_type="column"
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-606ac79 elementor-widget elementor-widget-image"
                  data-id="606ac79"
                  data-element_type="widget"
                  data-widget_type="image.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image">
                      <img
                        width="432"
                        height="600"
                        src={`${process.env.REACT_APP_DOMAIN}/landing/Group-7871.cropped.png`}
                        className="attachment-large size-large"
                        alt=""
                        loading="lazy"
                        srcSet={`${process.env.REACT_APP_DOMAIN}/landing/Group-7871.cropped.png 432w, ${process.env.REACT_APP_DOMAIN}/landing/Group-7871.cropped-216x300.png 216w`}
                        sizes="(max-width: 432px) 100vw, 432px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7047579 no-pad"
            data-id="7047579"
            data-element_type="column"
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-2614a9b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="2614a9b"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-row">
                      <div
                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c2b63c6"
                        data-id="c2b63c6"
                        data-element_type="column"
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-3a7f269 elementor-widget elementor-widget-heading"
                              data-id="3a7f269"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default">
                                  How it works
                                </h2>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-1394366 elementor-widget elementor-widget-heading"
                              data-id="1394366"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h4 className="elementor-heading-title elementor-size-default">
                                  Less than 3 mins to get started!
                                </h4>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-1aab963 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                              data-id="1aab963"
                              data-element_type="widget"
                              data-widget_type="icon-list.default"
                            >
                              <div className="elementor-widget-container">
                                <ul className="elementor-icon-list-items">
                                  <li className="elementor-icon-list-item">
                                    <span className="elementor-icon-list-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        id="Layer_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 500 500"
                                        style={{
                                          enableBackground: 'new 0 0 500 500',
                                        }}
                                        xmlSpace="preserve"
                                      >
                                        <g>
                                          {' '}
                                          <g id="Group_7769">
                                            {' '}
                                            <g id="Path_7213">
                                              {' '}
                                              <path d="M462.01,45.67h-45.38c-8.36,0-15.14,6.78-15.14,15.14s6.78,15.14,15.14,15.14l0,0h30.28v53.12H53.11V75.96h30.31    c8.36,0,15.14-6.78,15.14-15.14s-6.78-15.14-15.14-15.14H37.97c-8.36,0-15.14,6.78-15.14,15.14v424.04    c0,8.36,6.78,15.14,15.14,15.14c0,0,0,0,0,0h424.04c8.36,0,15.14-6.78,15.14-15.14c0,0,0,0,0,0V60.81    C477.15,52.45,470.37,45.67,462.01,45.67L462.01,45.67 M53.11,159.27h393.81v310.45H53.11V159.27z" />{' '}
                                            </g>{' '}
                                            <g id="Path_7214">
                                              {' '}
                                              <path d="M191.42,45.67c-8.36,0-15.14,6.78-15.14,15.14s6.78,15.14,15.14,15.14l0,0h116.65c8.36,0,15.14-6.78,15.14-15.14    s-6.78-15.14-15.14-15.14H191.42z" />{' '}
                                            </g>{' '}
                                            <g id="Path_7215">
                                              {' '}
                                              <path d="M378.56,258.83H120.76c-8.36,0-15.14,6.78-15.14,15.14l0,0v68.15c0,8.36,6.78,15.14,15.14,15.14c0,0,0,0,0,0h257.81    c8.37,0,15.15-6.78,15.16-15.14v-68.15C393.72,265.6,386.93,258.83,378.56,258.83 M363.45,326.95H135.9v-37.84h227.55V326.95z" />{' '}
                                            </g>{' '}
                                            <g id="Path_7216">
                                              {' '}
                                              <path d="M121.12,15.37v75.75c0,8.36,6.78,15.14,15.14,15.14s15.14-6.78,15.14-15.14V15.37c0-8.36-6.78-15.14-15.14-15.14    S121.12,7.01,121.12,15.37" />{' '}
                                            </g>{' '}
                                            <g id="Path_7217">
                                              {' '}
                                              <path d="M347.22,15.14v75.75c0.01,8.36,6.79,15.13,15.16,15.13c8.35-0.01,15.12-6.78,15.13-15.13V15.14    c0.01-8.36-6.76-15.15-15.13-15.16c-8.36-0.01-15.15,6.76-15.16,15.13C347.22,15.12,347.22,15.13,347.22,15.14" />{' '}
                                            </g>{' '}
                                          </g>
                                        </g>
                                      </svg>
                                    </span>
                                    <span className="elementor-icon-list-text">
                                      Input your schedule in seconds
                                    </span>
                                  </li>
                                  <li className="elementor-icon-list-item">
                                    <span className="elementor-icon-list-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        id="Layer_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 500 500"
                                        style={{
                                          enableBackground: 'new 0 0 500 500',
                                        }}
                                        xmlSpace="preserve"
                                      >
                                        <g>
                                          <g id="Path_7218">
                                            <path d="M41.66,41.66h416.65v416.65H41.66V41.66z M0,500h500V0H0V500z" />
                                          </g>
                                          <g id="Path_7219">
                                            <path d="M355.73,145.67l47.38,47.54L213.38,383.01L95.2,264.88l46.82-46.59l70.68,70.6L355.73,145.67z" />
                                          </g>
                                        </g>
                                      </svg>
                                    </span>
                                    <span className="elementor-icon-list-text">
                                      Input your current results{' '}
                                    </span>
                                  </li>
                                  <li className="elementor-icon-list-item">
                                    <span className="elementor-icon-list-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        id="Layer_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 500 500"
                                        style={{
                                          enableBackground: 'new 0 0 500 500',
                                        }}
                                        xmlSpace="preserve"
                                      >
                                        <g
                                          id="Icon_3"
                                          transform="translate(0 0)"
                                        >
                                          <g>
                                            <g
                                              id="Group_7869"
                                              transform="translate(0 0)"
                                            >
                                              <g id="Path_7307">
                                                <path d="M212.11,500.07C94.96,499.71,0.29,404.46,0.65,287.31S96.26,75.5,213.4,75.86c31.02,0.09,61.64,6.99,89.7,20.21     c2.19,0.95,4.17,2.33,5.82,4.06c2.74,3.38,2.64,8.25-0.23,11.52c-2.71,3.46-7.51,4.5-11.4,2.46     c-9.97-3.95-19.75-8.51-29.99-11.56C164.93,72.06,57.23,130.34,26.75,232.72s27.8,210.07,130.18,240.55     S367,445.46,397.48,343.09c2.52-8.46,4.46-17.09,5.8-25.82c5.97-37.8,0.48-76.53-15.76-111.18c-0.52-1.16-1.16-2.28-1.64-3.47     c-2.24-4.68-0.25-10.29,4.43-12.53c0.04-0.02,0.09-0.04,0.13-0.06c4.63-2.2,10.17-0.23,12.37,4.4c0.05,0.11,0.1,0.23,0.15,0.34     c7.84,15.81,13.59,32.58,17.08,49.88c23.92,114.67-49.65,227.03-164.33,250.95C241.37,498.59,226.76,500.09,212.11,500.07" />
                                              </g>
                                              <g id="Path_7308">
                                                <path d="M212.93,433.04c-80.03,0.18-145.06-64.56-145.24-144.59c-0.16-70.94,51.05-131.56,121.02-143.26     c25.28-4.25,51.25-1.57,75.13,7.75c3.15,1.27,5.69,3.73,7.05,6.84c0.52,3.22-0.31,6.52-2.28,9.12     c-2.28,3.28-6.27,3.01-10.13,1.96c-9.72-3.08-19.63-5.58-29.65-7.48c-68.8-9.01-131.88,39.46-140.89,108.27     s39.46,131.88,108.27,140.89c68.73,9,131.76-39.36,140.87-108.08c2.72-20.93,0.22-42.21-7.28-61.94     c-1.93-3.67-1.32-8.15,1.51-11.18c2.28-2.94,6.3-3.89,9.65-2.28c2.65,1.37,4.78,3.56,6.07,6.25     c29.07,74.33-7.62,158.14-81.94,187.21c-8.18,3.2-16.63,5.64-25.25,7.31c-8.78,1.69-17.79,2.14-26.68,3.19" />
                                              </g>
                                              <g id="Path_7309">
                                                <path d="M429.69,140.99c-15.96-1.82-30.29-3.28-44.59-5.2c-4.19-0.89-8.53,0.52-11.4,3.69     c-50.18,50.46-100.48,100.84-150.91,151.12c-1.44,1.53-2.97,2.98-4.56,4.36c-3.93,3.35-9.83,2.88-13.18-1.05     c-2.98-3.5-2.98-8.64,0-12.13c1.37-1.59,2.83-3.12,4.36-4.56c50.46-50.4,100.95-100.81,151.46-151.21     c2.89-2.51,4.22-6.37,3.49-10.13c-1.89-14.3-3.6-28.62-4.86-42.97c-0.14-3.15,0.94-6.23,3.01-8.6     c20.21-20.6,40.58-41.04,61.12-61.31c3.26-3.84,9.02-4.32,12.86-1.06c1.66,1.41,2.76,3.36,3.11,5.51     c4.11,11.97,7.89,24.04,12.18,35.94c0.94,2.22,2.71,3.99,4.93,4.93c11.75,4.24,23.65,8.03,35.49,12.06     c5.14,0.81,8.65,5.62,7.85,10.76c-0.4,2.54-1.82,4.81-3.92,6.27c-19.77,19.89-39.61,39.73-59.53,59.53     c-2.08,1.67-4.38,3.03-6.84,4.06 M426.29,27.3c-1.55,1.37-2.62,2.28-3.6,3.19C409,44.18,395.32,58,381.49,71.55     c-2.16,1.91-3.17,4.81-2.65,7.64c1.4,11.3,2.69,22.6,3.88,33.91c0.27,2.71,1.3,3.92,4.06,4.22c11.63,1.21,23.24,2.74,34.89,3.79     c2.29,0.07,4.52-0.74,6.23-2.28c14.19-13.91,28.19-28.05,42.24-42.12c0.86-0.99,1.67-2.03,2.42-3.1     c-9.49-3.19-18.25-6.41-27.37-9.12c-4.7-1.15-8.39-4.8-9.58-9.49c-2.76-9.12-6.02-18.25-9.24-27.73" />
                                              </g>
                                              <g id="Path_7310">
                                                <path d="M135.31,287.83c-0.14-42.35,34.08-76.79,76.42-76.93c3.27-0.01,6.54,0.19,9.79,0.59c6.45,0.94,9.83,4.84,9.12,10.58     c-0.55,5.01-5.06,8.62-10.07,8.07c-0.35-0.04-0.7-0.1-1.04-0.18c-27.33-3.51-53.35,12.7-62.26,38.77     c-8.21,21.55-2.24,45.93,15.01,61.24c16.11,16.58,40.91,21.21,61.92,11.56c21.38-8.09,35.67-28.39,36.08-51.25     c0.08-3.52-0.05-7.05-0.39-10.56c-0.58-5.12,3.11-9.74,8.23-10.31c5.12-0.58,9.74,3.11,10.31,8.23     c0.01,0.09,0.02,0.17,0.03,0.26c5.46,42.12-24.26,80.69-66.38,86.16c-42.12,5.46-80.69-24.26-86.16-66.38     c-0.42-3.25-0.64-6.53-0.64-9.81" />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                    </span>
                                    <span className="elementor-icon-list-text">
                                      Input your goal
                                    </span>
                                  </li>
                                  <li className="elementor-icon-list-item">
                                    <span className="elementor-icon-list-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        id="Layer_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 500 500"
                                        style={{
                                          enableBackground: 'new 0 0 500 500',
                                        }}
                                        xmlSpace="preserve"
                                      >
                                        <g
                                          id="Icon_4"
                                          transform="translate(0 20.265) rotate(-90)"
                                        >
                                          <g>
                                            <g
                                              id="Group_7774_00000126297488738400744790000002016715287104004996_"
                                              transform="translate(0 0)"
                                            >
                                              <path
                                                id="Path_7233_00000013909044854410556020000004537665694227764146_"
                                                d="M-210.21,429.06c0.6,6.04,3.68,11.57,8.51,15.25    c11.35,10.49,23.74,12.48,47.25-11.92L20.24,249.81L-154.89,67.16c-23.83-24.13-36.17-21.91-47.22-11.08    c-4.61,3.7-7.5,9.13-7.99,15.03l0,0c0,9.87,10.14,20.77,20.53,31.01c25.46,25.36,50.6,51.05,77.23,78.24l32.32,33.09l14.8,15.08    l-395.26,0.99c-11.32,0.84-19.81,10.71-18.97,22.03c0.72,9.6,8.01,17.42,17.53,18.81l1.33,0.22h1.55l395.41,0.84l-15.08,15.12    c-11.94,11.97-23.41,23.54-34.54,34.74c-26.72,26.87-51.81,52.26-77.3,77.15c-9.75,9.55-19.74,20.63-19.74,30.3L-210.21,429.06z"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                    </span>
                                    <span className="elementor-icon-list-text">
                                      Watch your income SOAR. It’s that easy!
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7b52fd6 elementor-hidden-desktop"
                        data-id="7b52fd6"
                        data-element_type="column"
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-4f294ed elementor-widget elementor-widget-image"
                              data-id="4f294ed"
                              data-element_type="widget"
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-image">
                                  <img
                                    width="432"
                                    height="600"
                                    src={`${process.env.REACT_APP_DOMAIN}/landing/Group-7871.cropped.png`}
                                    className="attachment-large size-large"
                                    alt=""
                                    loading="lazy"
                                    srcSet={`${process.env.REACT_APP_DOMAIN}/landing/Group-7871.cropped.png 432w, ${process.env.REACT_APP_DOMAIN}/landing/Group-7871.cropped-216x300.png 216w`}
                                    sizes="(max-width: 432px) 100vw, 432px"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div
                  className="elementor-element elementor-element-dec3da0 elementor-widget elementor-widget-heading"
                  data-id="dec3da0"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      &nbsp;
                    </h4>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-6d37bbd elementor-widget__width-auto elementor-widget elementor-widget-image"
                  data-id="6d37bbd"
                  data-element_type="widget"
                  data-widget_type="image.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image">
                      <a
                        href="https://apps.apple.com/us/app/sales-with-soar/id1640065963"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          width="416"
                          height="144"
                          src={`${process.env.REACT_APP_DOMAIN}/landing/app-store.png`}
                          className="attachment-medium_large size-medium_large"
                          alt=""
                          loading="lazy"
                          srcSet={`${process.env.REACT_APP_DOMAIN}/landing/app-store.png 416w, ${process.env.REACT_APP_DOMAIN}/landing/app-store-300x104.png 300w`}
                          sizes="(max-width: 416px) 100vw, 416px"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-4409bd2 elementor-widget__width-auto elementor-widget elementor-widget-image"
                  data-id="4409bd2"
                  data-element_type="widget"
                  data-widget_type="image.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.dgs.soar"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          width="416"
                          height="144"
                          src={`${process.env.REACT_APP_DOMAIN}/landing/google-play.png`}
                          className="attachment-medium_large size-medium_large"
                          alt=""
                          loading="lazy"
                          srcSet={`${process.env.REACT_APP_DOMAIN}/landing/google-play.png 416w, ${process.env.REACT_APP_DOMAIN}/landing/google-play-300x104.png 300w`}
                          sizes="(max-width: 416px) 100vw, 416px"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-9d8bd09 elementor-widget__width-auto elementor-hidden-desktop elementor-hidden-tablet elementor-hidden-mobile elementor-widget elementor-widget-image"
                  data-id="9d8bd09"
                  data-element_type="widget"
                  data-widget_type="image.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image">
                      <img
                        width="143"
                        height="143"
                        src="./soar_files/app-qr-code.png"
                        className="attachment-medium_large size-medium_large"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
