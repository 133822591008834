import React from 'react'
import { Formik } from 'formik'

import { Loader } from '@/components/Loader'
import { Button } from '@/components/Button'
import { FormWrapper } from '@/components/FormWrapper'
import { InputField } from '@/fields/Input'

import { validationSchema, DEFAULT_VALUES } from './config'
import { PasswordPrompt } from './styles'

export function SignUpUserForm({ initialValues, onSubmit, isLoading }) {
  return (
    <FormWrapper
      logo
      logoSize="regular"
      title="Create a password for your account"
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <InputField
                name="email"
                label="WORK EMAIL"
                placeholder="test@soar.com"
                margin={3}
                text={initialValues.email}
              />
              <InputField
                type="password"
                name="password"
                label="PASSWORD"
                placeholder="xxxxxxxx"
                margin={5}
              />
              <PasswordPrompt>
                Must have at least 8 characters, 1 capital letter, and 1 number
              </PasswordPrompt>
              <InputField
                type="password"
                name="rePassword"
                label="RE-TYPE PASSWORD"
                placeholder="xxxxxxxx"
                margin={5}
              />
              <Button type="submit" disabled={isSubmitting} margin={5}>
                Sign Up
              </Button>
            </form>
          )}
        </Formik>
      )}
    </FormWrapper>
  )
}

SignUpUserForm.defaultProps = {
  initialValues: DEFAULT_VALUES,
  onSubmit: () => {},
}
