import styled from 'styled-components'

export const HelpButtonWrapper = styled.div`
  position: absolute;
  right: 42px;
  top: 37px;
`

export const CloseIcon = styled.img`
  height: 20px;
  width: 20px;
`

export const LocationBlock = styled.div`
  display: flex;
  flex-direction: column;
`

export const AdditionalLocationsBlock = styled.div``
export const AdditionalLocationField = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[5]}px;
`

export const AdditionalLocationInputWrapper = styled.div`
  width: 90%;
`

export const CloseButton = styled.button`
  align-items: center;
  align-self: flex-end;
  background-color: ${({ theme }) => theme.colors.backgroundItems};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 15px;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-bottom: 13.5px;
  min-width: 30px;

  &:hover {
    filter: brightness(0.9);
  }
`
