import styled from 'styled-components'

import contactIcon from '@/assets/icons/contact.svg'

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme, marginBottom }) =>
    marginBottom && theme.spaces[marginBottom]}px;
`

export const ContactIcon = styled.img.attrs({
  src: contactIcon,
})`
  margin-right: ${({ theme }) => theme.spaces[3]}px;
`

export const CheckboxWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
  height: 100%;
  margin: ${({ theme }) => theme.spaces[4]}px 0;
  padding-bottom: ${({ theme, modal }) => (modal ? 12 : theme.spaces[0])}px;
`

export const CheckIcon = styled.img`
  height: 18px;
  width: 18px;
`

export const CheckIconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`

export const IconButton = styled.button`
  background: none;
  height: 18px;
  margin-right: 5px;
  padding: 0;
  width: 18px;
`

export const TableSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const Tabs = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.backgroundItems};
  display: flex;
  gap: 52px;
  height: 30px;
  margin-bottom: ${({ theme }) => theme.spaces[5]}px;
  width: 100%;
`

export const Tab = styled.div`
  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.border};
  font-weight: ${({ theme, active }) =>
    active ? theme.fontWeights.bold : theme.fontWeights.normal};
  height: 28px;
  position: relative;
  width: 118px;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }
`

export const Underline = styled.div`
  border-bottom: 3px solid ${({ theme }) => theme.colors.backgroundActive};
  bottom: -1.5px;
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: absolute;
  width: 100%;
`

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[4]}px;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`
