import styled from 'styled-components'

export const Image = styled.img`
  display: inline;
  height: 20px;
  margin-right: 8px;
  width: 20px;
`

export const Message = styled.span`
  color: ${({ theme }) => theme.colors.greenButton};
  display: inline;
  font-size: 13px;
`
