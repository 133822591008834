import React, { useState } from 'react'

import backgroundImage from '@/assets/images/layout-background.png'
import Layout from '@/components/Layout'
import { useUsersMutation } from '@/queries/users'
import { useAddUserMutation } from '@/queries/add-user'
import { useEditUserMutation } from '@/queries/edit-user'
import { ReviewInfoForm } from '@/forms/ReviewInfo'
import { useOrgDataQuery } from '@/queries/get-org'
import { createToast } from '@/helpers/createToast'

export default function ReviewInfo() {
  const [error, setError] = useState(false)

  const { data: orgData, isLoading: orgDataLoad } = useOrgDataQuery()

  const { mutate: fetchData, data, isLoading: usersLoad } = useUsersMutation()
  const { mutate: addUser, isLoading: addUserLoad } = useAddUserMutation({
    onSuccess: () => {
      createToast('success', 'User added successfully!')
    },
    onError: (err) => {
      setError(err.response.data.message)
    },
  })
  const { mutate: editUser, isLoading: editUserLoad } = useEditUserMutation({
    onSuccess: () => {
      createToast('success', 'User data edited successfully!')
    },
    onError: (err) => {
      setError(err.response.data.message)
    },
  })
  return (
    <Layout logo justify="start" pt={28} background={backgroundImage}>
      <ReviewInfoForm
        onAddSubmit={addUser}
        onEditSubmit={editUser}
        fetchData={fetchData}
        data={data && data.data}
        locations={orgData && orgData.locations}
        isLoading={usersLoad || addUserLoad || editUserLoad || orgDataLoad}
        error={error}
        setError={setError}
      />
    </Layout>
  )
}
