/* eslint-disable no-unused-vars */

import { formatSearchUsers } from '@/helpers/formatData'
import React, { useCallback, useRef, useImperativeHandle, useMemo } from 'react'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import { useTheme } from 'styled-components'

import { Icon } from '@/components/Icon'

import { Label, Wrapper, IconWrapper } from './styles'

export const SelectSearch = React.forwardRef(
  (
    {
      label = '',
      placeholder = '',
      name,
      type,
      value,
      touched,
      children,
      options,
      form,
      setFieldValue,
      onBlur,
      onChange,
      icon,
      loadOptions,
      defaultInputValue,
      hideIndicator = false,
      menuPlacement = 'auto',
      setManagerName,
      isMulti,
      coach,
      data,
      ...otherProps
    },
    ref
  ) => {
    const theme = useTheme()
    const selectRef = useRef()

    const optionData = useMemo(() => {
      return formatSearchUsers(data?.data?.list || [])
    }, [data])

    useImperativeHandle(ref, () => ({
      clear: () => {
        selectRef.current.clearValue()
      },
    }))

    const handleChange = useCallback(
      (option) => {
        if (setManagerName) {
          setManagerName(option)
        }
        if (setFieldValue) return setFieldValue(name, option.value)
        return onChange(option, true)
      },
      [setManagerName, setFieldValue]
    )

    return (
      <Wrapper
        icon={icon}
        hideIndicator={hideIndicator}
        value={value}
        {...otherProps}
      >
        {label && <Label>{label}</Label>}
        {optionData.length ? (
          <Select
            ref={selectRef}
            classNamePrefix="react-select"
            placeholder={placeholder}
            name={name}
            onChange={handleChange}
            options={optionData}
            defaultOptions
						isClearable={otherProps.isClearable}
            menuPlacement={menuPlacement}
            value={coach && value}
            menuPortalTarget={document.body}
            isMulti={isMulti}
            components={{ LoadingIndicator: null }}
						isDisabled={otherProps.disabled}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: theme.colors.backgroundItems,
                color: 'white',
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor:
                  (state.isFocused || state.isSelected) && theme.colors.border,
                padding: 8,
              }),
            }}
          />
        ) : (
          <AsyncSelect
            ref={selectRef}
            classNamePrefix="react-select"
            placeholder={placeholder}
            name={name}
            onChange={handleChange}
            options={optionData}
            defaultOptions
						isDisabled={otherProps.disabled}
            loadOptions={loadOptions && loadOptions}
            menuPlacement={menuPlacement}
            value={coach && value}
            menuPortalTarget={document.body}
            isMulti={isMulti}
						isClearable={otherProps.isClearable}
            components={{ LoadingIndicator: null }}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: theme.colors.backgroundItems,
                color: 'white',
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor:
                  (state.isFocused || state.isSelected) && theme.colors.border,
                padding: 8,
              }),
            }}
          />
        )}
        {icon && (
          <IconWrapper>
            <Icon size={20} src={icon} />
          </IconWrapper>
        )}
      </Wrapper>
    )
  }
)