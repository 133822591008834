import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Icon } from '@/components/Icon'
import { Button } from '@/components/Button'
import { Subtitle } from '@/components/Subtitle'
import { ProgressBar } from '@/components/ProgressBar'
import { ContentWrapper } from '@/components/ContentWrapper'
import { DOWNLOAD_SPREADSHEET_LINK } from '@/constants/endpoints'
import {
  CREATE_ORGANIZATION_ROUTE,
  UPLOAD_SPREADSHEET_ROUTE,
} from '@/constants/routes'
import downloadIcon from '@/assets/icons/download.svg'

import { ButtonWrapper } from './styles'

export function DownloadSpreadsheetForm() {
  const navigate = useNavigate()

  const buttonHandler = useCallback(
    () => navigate(UPLOAD_SPREADSHEET_ROUTE),
    []
  )
  return (
    <>
      <ProgressBar active={1} />
      <ContentWrapper
        size="medium"
        title="Here’s the spreadsheet template"
        titleSize={9}
        titleMargin={5}
        arrowLink
        path={CREATE_ORGANIZATION_ROUTE}
        padding="48px"
      >
        <Subtitle>
          Please download this template and follow the instructions in the file.
        </Subtitle>
        <ButtonWrapper>
          <a href={DOWNLOAD_SPREADSHEET_LINK} download>
            <Button fontSize={5}>
              <Icon src={downloadIcon} />
              Download Spreadsheet
            </Button>
          </a>
        </ButtonWrapper>
        <Subtitle style={{ marginTop: '20px' }}>
          Once your file is ready, please continue to start the import process.
        </Subtitle>

        <ButtonWrapper style={{ marginTop: '20px' }}>
          <Button onClick={buttonHandler} fontSize={5}>
            Continue
          </Button>
        </ButtonWrapper>
      </ContentWrapper>
    </>
  )
}
