import styled from 'styled-components'

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme, marginBottom }) =>
    marginBottom && theme.spaces[marginBottom]}px;
`

export const DualItemWrapper = styled.div`
  flex-basis: 50%;
`

export const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 160px;
`

export const CheckboxWrapper = styled.div`
  align-items: flex-end;
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
  height: 100%;
  margin-top: ${({ theme, modal }) => (!modal ? 6 : theme.spaces[0])}px;
  padding-bottom: ${({ theme, modal }) => (modal ? 12 : theme.spaces[0])}px;
`
