import React, { useState } from 'react'

import { MenuLayout } from '@/components/MenuLayout'
import { createToast } from '@/helpers/createToast'
import { OrganizationsForm } from '@/forms/Organizations'
import backgroundImage from '@/assets/images/layout-background.png'
import { useAllOrgsMutation } from '@/queries/all-organizations'
import { useAddOrgMutation } from '@/queries/add-organization'
import { useEditOrgMutation } from '@/queries/edit-organization'
import { useDeleteOrgMutation } from '@/queries/delete-organization'

export default function OrganizationsPage() {
  const [error, setError] = useState(false)

  const {
    mutate: fetchOrgsData,
    data: orgsData,
    isLoading: orgsLoad,
  } = useAllOrgsMutation()

  const { mutate: addOrg, isLoading: addOrgLoad } = useAddOrgMutation({
    onSuccess: () => {
      createToast('success', 'Organization added successfully!')
    },
    onError: (err) => {
      setError(err.response.data.message)
    },
  })

  const { mutate: editOrg, isLoading: editOrgLoad } = useEditOrgMutation({
    onSuccess: () => {
      createToast('success', 'Organization edited successfully!')
    },
    onError: (err) => {
      setError(err.response.data.message)
    },
  })

  const { mutate: deleteOrg, isLoading: deleteOrgLoad } = useDeleteOrgMutation({
    onSuccess: () => {
      createToast('success', 'Organization deactivated successfully!')
    },
    onError: (err) => {
      setError(err.response.data.message)
    },
  })

  return (
    <MenuLayout adminData={orgsData} background={backgroundImage}>
      <OrganizationsForm
        orgs={orgsData}
        fetchOrgsData={fetchOrgsData}
        addOrg={addOrg}
        editOrg={editOrg}
        deleteOrg={deleteOrg}
        isOrgsLoading={orgsLoad || addOrgLoad || editOrgLoad || deleteOrgLoad}
        error={error}
        setError={setError}
      />
    </MenuLayout>
  )
}
