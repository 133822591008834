import { createMachine, assign } from 'xstate'

export const dashboardMachine = createMachine({
  id: 'dashboard',
  initial: 'dashboardsReady',
  context: {
    dateRange: {
      startDate: '',
      endDate: '',
    },
    orgId: '',
    orgName: '',
    isImpersonate: false,
  },
  states: {
    initDashboard: {
      on: {
        SELECTED_DATE_RANGE: {
          actions: assign({
            dateRange: (context, event) => {
              return {
                ...event.dateRange,
              }
            },
          }),
        },
        CLEAN_DATE_RANGE: {
          actions: assign({
            dateRange: {
              startDate: '',
              endDate: '',
            },
          }),
        },
      },
    },
    dashboardsReady: {
      on: {
        ORGANIZATION_SELECTED: {
          actions: assign({
            orgId: (context, event) => {
              return event.orgId
            },
            orgName: (context, event) => {
              return event.orgName
            },
            isImpersonate: (context, event) => {
              return event.isImpersonate
            },
          }),
        },
      },
    },
    end: {},
  },
})
