import React, { useState, useContext } from 'react'
import cls from 'classnames'

import { useActor } from '@xstate/react'
import officeIcon from '@/assets/icons/office_gray.svg'
import userGroup from '@/assets/icons/userGroup_gray.svg'
import { SelectOrganization } from '@/components/SelectOrganization'
import { Button } from '@/components/Button'
import { Icon } from '@/components/Icon'
import { SoarStateContext } from '@/global/soar.context'

export function SearchOptions({
  orgQuery,
  handleOnChangeOrg,
  handleOnImpersonate,
  onMenuSelection,
}) {
  const soarContext = useContext(SoarStateContext)
  const [state] = useActor(soarContext.dashboardService)
  const { orgId, orgName } = state.context
  const [sectionSelected, setSectionSelected] = useState('orgs')
  const classAllOrgs = cls(
    'flex',
    'mt-8',
    'pl-14',
    'text-slate-500',
    'hover:cursor-pointer',
    'text-2xl',
    'h-16',
    'items-center',
    {
      'bg-green-600/20': sectionSelected === 'orgs',
      'text-green-600': sectionSelected === 'orgs',
    }
  )

  const classAllUsr = cls(
    'flex',
    'mt-8',
    'pl-14',
    'text-slate-500',
    'text-2xl',
    'hover:cursor-pointer',
    'h-16',
    'items-center',
    {
      'bg-green-600/20': sectionSelected === 'usr',
      'text-green-600': sectionSelected === 'usr',
    }
  )

  const usrSelected = () => {
    setSectionSelected('usr')
    onMenuSelection('usr')
  }

  const orgsSelected = () => {
    setSectionSelected('orgs')
    onMenuSelection('orgs')
  }

  return (
    <div className="flex flex-col mr-8 bg-gray-900 basis-1/5 pt-8">
      <span className="text-white text-3xl ml-14">Search</span>
      <span className={classAllOrgs} onClick={orgsSelected}>
        <Icon src={officeIcon} size={24} />
        All Organizations
      </span>
      <div className={classAllUsr} onClick={usrSelected}>
        <Icon src={userGroup} size={24} />
        All Users
      </div>
      <span className="mt-8 ml-14 text-white text-3xl">Impersonate Admin</span>
      <div className="flex flex-col mt-8 ml-8">
        <SelectOrganization
          orgQuery={orgQuery}
          onChange={handleOnChangeOrg}
          orgId={orgId}
          orgName={orgName}
          style={{
            width: '22rem',
            backgroundColor: '#1E293B',
            color: 'white',
          }}
          inputStyle={{
            color: 'white',
          }}
        />
        <Button
          type="submit"
          style={{ width: '22.2rem', marginTop: '2rem' }}
          onClick={handleOnImpersonate}
          fontSize={5}
        >
          Impersonate Admin
        </Button>
      </div>
    </div>
  )
}
