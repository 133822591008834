import * as Yup from 'yup'

import { 
  FIRSTNAME_LENGTH_ERROR_MESSAGE,
  LASTNAME_LENGTH_ERROR_MESSAGE,
  REQUIRED_FIELD_ERROR_MESSAGE,
  PHONE_ERROR_MESSAGE,
} from '@/constants/error-messages'
import { MAX_FIRSTNAME_LENGTH, MAX_LASTNAME_LENGTH, PHONE_REGEXP } from '@/constants/forms'

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE).max(MAX_FIRSTNAME_LENGTH, FIRSTNAME_LENGTH_ERROR_MESSAGE),
  lastName: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE).max(MAX_LASTNAME_LENGTH, LASTNAME_LENGTH_ERROR_MESSAGE),
  email: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE).email(),
  mobile: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE).matches(PHONE_REGEXP, PHONE_ERROR_MESSAGE),
})

export const DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
}
