/* eslint-disable consistent-return */
import { useOrganizationImpersonate } from '@/hooks/useOrganizationImpersonate'
import { useAllUsersDataMutation } from '@/queries/all-users-data'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useActor } from '@xstate/react'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { SoarStateContext } from '@/global/soar.context'
import { DashboardForm } from '@/forms/Dashboard'
import { MenuLayout } from '@/components/MenuLayout'
import backgroundImage from '@/assets/images/layout-background.png'
import { useOrgDataQuery } from '@/queries/get-org'
import { useTableDataMutation } from '@/queries/dashboard-employees'
import { useTableDataRangeMutation } from '@/queries/dashboard-employeesRange'
import { useTableLocationDataMutation } from '@/queries/dashboard-locations'
import { useTableUserDataMutation } from '@/queries/dashboard-user'
import { useGraphDataMutation } from '@/queries/dashboard-graph'
import { useTrendsGraphDataMutation } from '@/queries/dashboard-trends-graph'
import { useGraphDataRangeMutation } from '@/queries/dashboard-graphRange'
import { useFunnelQuery } from '@/queries/get-funnel'
import { useGetSavedViewsQuery } from '@/queries/get-saved-views'
import { format } from 'date-fns'
import { VIEW_FLAG } from '@/forms/Dashboard/constants'

export default function Dashboard() {
  const soarContext = useContext(SoarStateContext)
  const [state] = useActor(soarContext.dashboardService)
  const [viewFlag, setViewFlag] = useState(VIEW_FLAG.CHARTS)

  const { orgId } = state.context

  const { organizationId } = useOrganizationImpersonate(orgId)

  const {
    data: orgData,
    isLoading: orgDataLoad,
    refetch: refetchOrg,
  } = useOrgDataQuery(organizationId, {
    onSuccess: (data) => {
      soarContext.dashboardService.send({
        type: 'ORGANIZATION_SELECTED',
        orgId: data.id,
        orgName: data.orgName,
      })
    },
    onError: (err) => {
      setError(err.response.data.message)
    },
  })

  const {
    data: funnelData,
    isLoading: funnelLoad,
    refetch: refetchFunnels,
  } = useFunnelQuery(organizationId)
  const {
    data: savedViewData,
    isLoading: savedViewDataLoad,
    refetch: refetchSavedViews,
  } = useGetSavedViewsQuery(organizationId)

  const {
    mutate: fetchGraphData,
    data: graphData,
    isLoading: graphDataLoad,
  } = useGraphDataMutation(null, organizationId)

  const { mutate: fetchGraphTrendsData, data: graphTrendsData } =
    useTrendsGraphDataMutation(null, organizationId)

  const {
    mutate: fetchGraphDataRange,
    data: graphDataRange,
    isLoading: graphDataLoadRange,
  } = useGraphDataRangeMutation()

  const {
    mutate: fetchTableAllData,
    data: tableAllData,
    isLoading: tableDataLoad,
  } = useTableDataMutation({ orgId: organizationId })

  const {
    mutate: fetchTableAllDataRange,
    data: tableAllDataRange,
    isLoading: tableDataLoadRange,
  } = useTableDataRangeMutation()

  const {
    mutate: fetchTableLocationData,
    data: tableLocationData,
    isLoading: tableLocationDataLoad,
  } = useTableLocationDataMutation(null, organizationId)

  const {
    mutate: fetchTableUserData,
    data: tableUserData,
    isLoading: tableUserDataLoad,
  } = useTableUserDataMutation()

  const {
    mutate: fetchUsersData,
    mutateAsync: fetchUsersDataAsync,
    data: users,
    isLoading: usersLoad,
  } = useAllUsersDataMutation(null, organizationId, 'onlyUsers')

  const getTableData = () => {
    if (!tableAllData?.data && !tableAllDataRange?.data) {
      return null
    }

    if (tableAllData?.data) {
      return tableAllData?.data
    }

    if (tableAllDataRange?.data) {
      return tableAllDataRange?.data
    }

    return null
  }

  const fetchTableOptions = {
    date: format(new Date(), 'yyyy-MM'),
    skip: 0,
    limit: 1000000,
    search: '',
  }

  useEffect(() => {
    refetchFunnels()
    refetchOrg()
    refetchSavedViews()
    fetchTableAllData(fetchTableOptions)
    fetchTableLocationData()
    fetchGraphTrendsData()
    fetchGraphData(fetchTableOptions)
    fetchUsersData()
  }, [organizationId])

  return (
    <MenuLayout
      adminData={orgData}
      background={backgroundImage}
      page={'Dashboard'}
      viewFlag={viewFlag}
      setViewFlag={setViewFlag}
    >
      <DashboardForm
        viewFlag={viewFlag}
        funnelData={funnelData}
        orgData={orgData}
        locationsData={orgData && orgData.locations}
        graphData={graphData && graphData.data}
        graphDataRange={graphDataRange && graphDataRange.data}
        allUsers={users}
        tableAllData={getTableData()}
        tableLocationData={tableLocationData && tableLocationData.data}
        tableUserData={tableUserData && tableUserData.data}
        graphTrendsData={graphTrendsData}
        savedViewData={savedViewData}
        fetchGraphData={fetchGraphData}
        fetchGraphDataRange={fetchGraphDataRange}
        fetchTableAllData={fetchTableAllData}
        fetchTableAllDataRange={(config) => {
          tableAllData.data = null
          fetchTableAllDataRange(config)
        }}
        fetchTableLocationData={fetchTableLocationData}
        fetchGraphTrendsData={fetchGraphTrendsData}
        fetchTableUserData={fetchTableUserData}
        isGraphLoading={graphDataLoad || graphDataLoadRange}
        isTableLoading={
          tableDataLoad ||
          tableLocationDataLoad ||
          tableUserDataLoad ||
          tableDataLoadRange
        }
        isOrgDataLoading={orgDataLoad || funnelLoad}
        isSavedViewDataLoad={savedViewDataLoad}
      />
    </MenuLayout>
  )
}
