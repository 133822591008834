import React from 'react'

import { Icon } from '@/components/Icon'
import infoCircleIcon from '@/assets/icons/infoCircle.svg'

import { Wrapper, Message } from './styles'

export function InfoCircle({ marginLeft, right, children }) {
  return (
    <Wrapper marginLeft={marginLeft}>
      <Icon pointer src={infoCircleIcon} size={20} />
      <Message right={right}>{children}</Message>
    </Wrapper>
  )
}
