import React, { useCallback } from 'react'
import { Formik } from 'formik'

import { ModalWindow } from '@/components/ModalWindow'
import { Button } from '@/components/Button'
import { Title } from '@/components/Title'
import { Divider } from '@/components/Divider'
import { InputField } from '@/fields/Input'
import { CloseButton } from '@/components/CloseButton'

import { validationSchema, DEFAULT_VALUES } from './config'
import { TitleWrapper, ModalButtonsWrapper } from './styles'

export function SettingsAdditionalEditModalForm({
  initialValues,
  isOpen,
  closeModal,
  onSubmit,
  locations,
  item,
}) {
  const onFormSubmit = useCallback(
    (data) => {
      const newLocations = locations
      newLocations.forEach((location, index) => {
        if (location === item) newLocations[index] = data.value
        return null
      })
      onSubmit(newLocations, item, data.value);
    },
    [locations, item]
  )

  return (
    <ModalWindow
      additional
      isOpen={isOpen}
      handleClose={closeModal}
      closeOnOverlay={false}
      width={350}
      padding={24}
    >
      <CloseButton onClick={closeModal} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <TitleWrapper marginBottom={3}>
              <Title bold fontSize={7}>
                Edit Location Details
              </Title>
            </TitleWrapper>
            <Title margin={5} fontSize={5} color="promptText">
              Previous Location: <span>{item}</span>
            </Title>
            <InputField name="value" label="New Location" margin={3} />
            <Divider size={1} margin={5} />
            <ModalButtonsWrapper>
              <Button type="submit" width="65px" fontSize={5}>
                Save
              </Button>
              <Button
                gray
                color="headerText"
                width="80px"
                fontSize={5}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </ModalButtonsWrapper>
          </form>
        )}
      </Formik>
    </ModalWindow>
  )
}

SettingsAdditionalEditModalForm.defaultProps = {
  initialValues: DEFAULT_VALUES,
}
