import React from 'react'
import { Text } from 'grommet'

import { Button } from '@/components/Button'
import { FormWrapper } from '@/components/FormWrapper'
import { Link } from '@/components/Link'
import { SIGN_IN_ROUTE } from '@/constants/routes'

import { Subtitle, ResendField, ResendButton } from './styles'

export function RecoveryLinkForm({ resend }) {
  return (
    <FormWrapper logo={false} title="Recover Your Account" titleSize={12}>
      <Subtitle>A recovery link has been sent to your email. </Subtitle>
      <Link to={SIGN_IN_ROUTE}>
        <Button type="submit" margin={4}>
          Go Back to Log In
        </Button>
      </Link>
      <ResendField>
        <Text size="14">Didn’t get a recovery link?</Text>
        <ResendButton onClick={resend}>Resend</ResendButton>
      </ResendField>
    </FormWrapper>
  )
}
