import React from 'react'
import { Field } from 'formik'
import { Box } from 'grommet'

import { ErrorMessage } from '@/components/ErrorMessage'
import { Checkbox } from '@/components/Checkbox'

export function CheckboxField({ name, size, ...otherProps }) {
  return (
    <Field name={name}>
      {({ field, meta, form }) => (
        <Box>
          <Checkbox
            checked={field.value}
            onChange={form.setFieldValue}
            size={size}
            {...field}
            {...otherProps}
          />
          {meta.touched && meta.error && (
            <ErrorMessage>{meta.error}</ErrorMessage>
          )}
        </Box>
      )}
    </Field>
  )
}
