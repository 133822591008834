import axios from 'axios'
import { useMutation } from 'react-query'

import { ORGANIZATION_ENDPOINT } from '@/constants/endpoints'

export const useEditOrgMutation = (options) =>
  useMutation((payload) => {
    const locations = payload?.locations.length > 0 ? payload.locations : []
    axios.patch(`${ORGANIZATION_ENDPOINT}/${payload.id}`, {
      ...payload,
      locations,
    })
  }, options)
