import axios from 'axios'
import { useMutation } from 'react-query'

import { DELETE_MANY_ORGANIZATIONS_ENDPOINT } from '@/constants/endpoints'

export const useDeleteOrgMutation = (options) =>
  useMutation(
    (payload) =>
      axios.delete(`${DELETE_MANY_ORGANIZATIONS_ENDPOINT}`, { data: payload }),
    options
  )
