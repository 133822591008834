import styled from 'styled-components'

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme, marginBottom }) =>
    marginBottom && theme.spaces[marginBottom]}px;
`

export const DualItemWrapper = styled.div`
		flex-basis: 50%;
		margin-bottom: ${({ theme }) => theme.spaces[3]}px;

		.css-1n6sfyn-MenuList {
			max-height: 200px;
			overflow-y: auto;
			position: relative;
			-webkit-overflow-scrolling: touch;
			padding-bottom: 4px;
			padding-top: 4px;
			box-sizing: border-box;
	}
`

export const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 160px;
`

export const CheckboxWrapper = styled.div`
  align-items: flex-end;
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
  height: 100%;
  margin-top: ${({ theme, modal }) => (!modal ? 6 : theme.spaces[0])}px;
  padding-bottom: ${({ theme, modal }) => (modal ? 12 : theme.spaces[0])}px;
`
