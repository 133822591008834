import React, { useCallback } from 'react'
import ReactSelect from 'react-select'

import { Icon } from '@/components/Icon'

import { Label, Wrapper, IconWrapper } from './styles'

export function Select({
  label = '',
  placeholder = '',
  error = '',
  name,
  type,
  value,
  touched,
  children,
  options,
  form,
  setFieldValue,
  onBlur,
  onChange,
  icon,
  isSearchable,
  disabled,
  ...otherProps
}) {
  const handleChange = useCallback(
    (option) => {
      setFieldValue(name, option.value)
      if (onChange) onChange(option.value)
    },
    [onChange]
  )

  return (
    <Wrapper error={error} value={value} icon={icon} disabled={disabled} {...otherProps}>
      {label && <Label>{label}</Label>}
      <ReactSelect
        classNamePrefix="react-select"
        options={options}
        hasError={error}
        placeholder={placeholder}
        name={name}
        value={options ? options.find((option) => option.value === value) : ''}
        onChange={handleChange}
        onBlur={onBlur}
        isSearchable={isSearchable}
        menuPlacement="auto"
        isDisabled={disabled}
      />
      {icon && (
        <IconWrapper>
          <Icon size={20} src={icon} />
        </IconWrapper>
      )}
    </Wrapper>
  )
}
