import React from 'react'

import { Wrapper, ImageWrapper, Image, Subtitle } from './styles'

export function ImageBlock({
  image,
  imageWidth,
  subtitle,
  fontSize,
  bold,
  padding,
  bg,
  dark,
  border,
  children,
}) {
  return (
    <Wrapper border={border} padding={padding}>
      <ImageWrapper dark={dark} bg={bg} padding={padding}>
        <Image src={image} imageWidth={imageWidth} />
        {dark && (
          <Subtitle bold={bold} fontSize={fontSize}>
            {subtitle}
          </Subtitle>
        )}
      </ImageWrapper>
      {!dark && subtitle && (
        <Subtitle bold={bold} fontSize={fontSize}>
          {subtitle}
        </Subtitle>
      )}
      {children}
    </Wrapper>
  )
}
