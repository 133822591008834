import React from 'react'

import { ItemWrapper } from '../styles'

export default function CoacheeRows({ item }) {
  const renderCoachee = () => {
    const output = []

    item.coachees.map((coachee) => {
      output.push(
        <ItemWrapper
          key={coachee?.fbUid}
          className={item.open ? 'opened' : 'collapsed'}
        >
          <div>{`${coachee?.firstName} ${coachee?.lastName}`}</div>
          <div>{coachee?.performance ? coachee?.performance : 0}</div>
          <div>{coachee?.contacts?.received}</div>
          <div>{coachee?.contacts?.sent}</div>
          <div>{coachee?.sales}</div>
          <div>
            {coachee.overall ? (coachee.overall * 100).toFixed(2) : 0} %
          </div>
        </ItemWrapper>
      )
      return output
    })

    output.push(
      <ItemWrapper key="total-item" className="total">
        <div>{item.open ? 'Total' : item.coachees.length}</div>
        <div>{item.performance}</div>
        <div>{item.contactsFromCoach}</div>
        <div>{item.contactsFromCoachee}</div>
        <div>{item.sales}</div>
        <div>{item.overall ? item.overall.toFixed(2) : 0} %</div>
      </ItemWrapper>
    )

    return output
  }

  return renderCoachee()
}
