import * as Yup from 'yup'

import {
  getMaxLengthErrorMessage,
  getMinLengthErrorMessage,
  REQUIRED_FIELD_ERROR_MESSAGE,
  PHONE_ERROR_MESSAGE,
  FIRSTNAME_LENGTH_ERROR_MESSAGE,
  LASTNAME_LENGTH_ERROR_MESSAGE,
} from '@/constants/error-messages'
import {
  MAX_FIRSTNAME_LENGTH,
  MAX_LASTNAME_LENGTH,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  PHONE_REGEXP,
} from '@/constants/forms'

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE).max(MAX_FIRSTNAME_LENGTH, FIRSTNAME_LENGTH_ERROR_MESSAGE),
  lastName: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE).max(MAX_LASTNAME_LENGTH, LASTNAME_LENGTH_ERROR_MESSAGE),
  email: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE).email(),
  password: Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .min(MIN_PASSWORD_LENGTH, getMinLengthErrorMessage(MIN_PASSWORD_LENGTH))
    .matches(/^(?=.*[a-z])/, 'Must must contain at least one lowercase character')
    .matches(/^(?=.*[A-Z])/, 'Must must contain at least one uppercase character')
    .matches(/^(?=.*[0-9])/, 'Must must contain at least one numeric character')
    .max(MAX_PASSWORD_LENGTH, getMaxLengthErrorMessage(MAX_PASSWORD_LENGTH)),
  mobile: Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .matches(PHONE_REGEXP, PHONE_ERROR_MESSAGE),
})

export const DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  mobile: '',
}
