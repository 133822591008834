import React from 'react'

export function CoachingSection() {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-58fbd95 elementor-section-full_width no-pad elementor-section-height-default elementor-section-height-default"
      data-id="58fbd95"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div className="elementor-row">
          <div
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3d7cb55"
            data-id="3d7cb55"
            data-element_type="column"
          >
            <div className="elementor-column-wrap elementor-element-populated coaching">
              <div
                className="elementor-widget-wrap"
                style={{
                  backgroundImage: `url("${process.env.REACT_APP_DOMAIN}/landing/coaching.jpeg")`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
              >
                <div
                  className="elementor-element elementor-element-249dd6a elementor-widget elementor-widget-spacer"
                  data-id="249dd6a"
                  data-element_type="widget"
                  data-widget_type="spacer.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-spacer">
                      <div className="elementor-spacer-inner" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="coaching"
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5138754"
            data-id="5138754"
            data-element_type="column"
          >
            <div id="anchor" className="elementor-menu-anchor" />
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-9f27408 elementor-widget elementor-widget-heading"
                  data-id="9f27408"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Coaching
                    </h4>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-d5c4c79 elementor-widget elementor-widget-heading"
                  data-id="d5c4c79"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h2 className="elementor-heading-title elementor-size-default">
                      Great things happen when
                      <strong> talent &amp; opportunity</strong> meet.
                    </h2>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-4b0d583 elementor-widget elementor-widget-heading"
                  data-id="4b0d583"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      &nbsp;
                    </h4>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-8728245 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                  data-id="8728245"
                  data-element_type="widget"
                  data-widget_type="icon-list.default"
                >
                  <div className="elementor-widget-container">
                    <ul className="elementor-icon-list-items">
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 500 500"
                            style={{ enableBackground: 'new 0 0 500 500' }}
                            xmlSpace="preserve"
                          >
                            <g>
                              <g id="Group_7774">
                                <g id="Path_7233">
                                  <path d="M269.53,429.06c0.6,6.04,3.68,11.57,8.51,15.25c11.35,10.49,23.74,12.48,47.25-11.92l174.69-182.58L324.85,67.16    c-23.83-24.13-36.17-21.91-47.22-11.08c-4.61,3.7-7.5,9.13-7.99,15.03l0,0c0,9.87,10.14,20.77,20.53,31.01    c25.46,25.36,50.6,51.05,77.23,78.24l32.32,33.09l14.8,15.08l-395.26,0.99c-11.32,0.84-19.81,10.71-18.97,22.03    c0.72,9.6,8.01,17.42,17.53,18.81l1.33,0.22h1.55l395.41,0.84l-15.08,15.12c-11.94,11.97-23.41,23.54-34.54,34.74    c-26.72,26.87-51.81,52.26-77.3,77.15c-9.75,9.55-19.74,20.63-19.74,30.3L269.53,429.06z" />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span className="elementor-icon-list-text">
                          SOAR transforms salespeople into trainers by allowing
                          them to engage with other sales associates one-on-one.{' '}
                        </span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 500 500"
                            style={{ enableBackground: 'new 0 0 500 500' }}
                            xmlSpace="preserve"
                          >
                            <g>
                              <g id="Group_7774">
                                <g id="Path_7233">
                                  <path d="M269.53,429.06c0.6,6.04,3.68,11.57,8.51,15.25c11.35,10.49,23.74,12.48,47.25-11.92l174.69-182.58L324.85,67.16    c-23.83-24.13-36.17-21.91-47.22-11.08c-4.61,3.7-7.5,9.13-7.99,15.03l0,0c0,9.87,10.14,20.77,20.53,31.01    c25.46,25.36,50.6,51.05,77.23,78.24l32.32,33.09l14.8,15.08l-395.26,0.99c-11.32,0.84-19.81,10.71-18.97,22.03    c0.72,9.6,8.01,17.42,17.53,18.81l1.33,0.22h1.55l395.41,0.84l-15.08,15.12c-11.94,11.97-23.41,23.54-34.54,34.74    c-26.72,26.87-51.81,52.26-77.3,77.15c-9.75,9.55-19.74,20.63-19.74,30.3L269.53,429.06z" />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span className="elementor-icon-list-text">
                          Most companies today have little to no money or time
                          to spend on training their staff. Start leveraging
                          your star performers by transforming them into
                          trainers.{' '}
                        </span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 500 500"
                            style={{ enableBackground: 'new 0 0 500 500' }}
                            xmlSpace="preserve"
                          >
                            <g>
                              <g id="Group_7774">
                                <g id="Path_7233">
                                  <path d="M269.53,429.06c0.6,6.04,3.68,11.57,8.51,15.25c11.35,10.49,23.74,12.48,47.25-11.92l174.69-182.58L324.85,67.16    c-23.83-24.13-36.17-21.91-47.22-11.08c-4.61,3.7-7.5,9.13-7.99,15.03l0,0c0,9.87,10.14,20.77,20.53,31.01    c25.46,25.36,50.6,51.05,77.23,78.24l32.32,33.09l14.8,15.08l-395.26,0.99c-11.32,0.84-19.81,10.71-18.97,22.03    c0.72,9.6,8.01,17.42,17.53,18.81l1.33,0.22h1.55l395.41,0.84l-15.08,15.12c-11.94,11.97-23.41,23.54-34.54,34.74    c-26.72,26.87-51.81,52.26-77.3,77.15c-9.75,9.55-19.74,20.63-19.74,30.3L269.53,429.06z" />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span className="elementor-icon-list-text">
                          Build your bench by elevating high-potential sales
                          people into future managers.
                        </span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 500 500"
                            style={{ enableBackground: 'new 0 0 500 500' }}
                            xmlSpace="preserve"
                          >
                            <g>
                              <g id="Group_7774">
                                <g id="Path_7233">
                                  <path d="M269.53,429.06c0.6,6.04,3.68,11.57,8.51,15.25c11.35,10.49,23.74,12.48,47.25-11.92l174.69-182.58L324.85,67.16    c-23.83-24.13-36.17-21.91-47.22-11.08c-4.61,3.7-7.5,9.13-7.99,15.03l0,0c0,9.87,10.14,20.77,20.53,31.01    c25.46,25.36,50.6,51.05,77.23,78.24l32.32,33.09l14.8,15.08l-395.26,0.99c-11.32,0.84-19.81,10.71-18.97,22.03    c0.72,9.6,8.01,17.42,17.53,18.81l1.33,0.22h1.55l395.41,0.84l-15.08,15.12c-11.94,11.97-23.41,23.54-34.54,34.74    c-26.72,26.87-51.81,52.26-77.3,77.15c-9.75,9.55-19.74,20.63-19.74,30.3L269.53,429.06z" />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span className="elementor-icon-list-text">
                          Partner with industry leaders for next-level coaching.
                        </span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 500 500"
                            style={{ enableBackground: 'new 0 0 500 500' }}
                            xmlSpace="preserve"
                          >
                            <g>
                              <g id="Group_7774">
                                <g id="Path_7233">
                                  <path d="M269.53,429.06c0.6,6.04,3.68,11.57,8.51,15.25c11.35,10.49,23.74,12.48,47.25-11.92l174.69-182.58L324.85,67.16    c-23.83-24.13-36.17-21.91-47.22-11.08c-4.61,3.7-7.5,9.13-7.99,15.03l0,0c0,9.87,10.14,20.77,20.53,31.01    c25.46,25.36,50.6,51.05,77.23,78.24l32.32,33.09l14.8,15.08l-395.26,0.99c-11.32,0.84-19.81,10.71-18.97,22.03    c0.72,9.6,8.01,17.42,17.53,18.81l1.33,0.22h1.55l395.41,0.84l-15.08,15.12c-11.94,11.97-23.41,23.54-34.54,34.74    c-26.72,26.87-51.81,52.26-77.3,77.15c-9.75,9.55-19.74,20.63-19.74,30.3L269.53,429.06z" />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span className="elementor-icon-list-text">
                          Improve employee retention through coaching and
                          training.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
