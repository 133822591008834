import axios from 'axios'
import { useMutation } from 'react-query'

import { SEND_INVITES_ENDPOINT } from '@/constants/endpoints'
import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'
import { createToast } from '@/helpers/createToast'

export const useInvitesMutation = (options) =>
  useMutation(
    SEND_INVITES_ENDPOINT,
    async (ids) => {
      const { data } = await axios.post(SEND_INVITES_ENDPOINT, ids, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            AUTH_TOKEN_STORAGE_KEY
          )}`,
        },
      })
      return data
    },
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      options,
    }
  )
