/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable dot-notation */

import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  useContext,
} from 'react'
import { useActor } from '@xstate/react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useTheme } from 'styled-components'
import axios from 'axios'
import { format } from 'date-fns'
import { DateRangePicker } from 'rsuite'

import { SoarStateContext } from '@/global/soar.context'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import closeIcon from '@/assets/icons/closeIcon.svg'
import managersIcon from '@/assets/icons/managers.svg'
import employeesIcon from '@/assets/icons/employees.svg'
import {
  ALL_USERS_DATA_ENDPOINT,
  GET_COACH_MANAGER_ENDPOINT,
} from '@/constants/endpoints'
import {
  fetchAllDashboardConfig,
  fetchGraphConfig,
} from '@/constants/fetch-data-config'
import searchIcon from '@/assets/icons/search.svg'
import officeIcon from '@/assets/icons/office.svg'
import calendarIcon from '@/assets/icons/calendar.svg'
import { FunnelGraph } from '@/components/FunnelGraph'
import { Icon } from '@/components/Icon'
import { Title } from '@/components/Title'
import { Table } from '@/components/Table'
import { Loader } from '@/components/Loader'
import { Button } from '@/components/Button'
import { GoalsGraph } from '@/components/GoalsGraph'
import { LabelButton } from '@/components/LabelButton'
import { TrendsGraph } from '@/components/TrendsGraph'
import { SelectSearch } from '@/components/SelectSearch'
import { GraphWrapper } from '@/components/GraphWrapper'
import { CustomReport } from '@/components/CustomReport'
import { SelectDashboard } from '@/components/SelectDashboard'
import { formatGraph } from '@/helpers/formatGraph'
import { formatGraphRange } from '@/helpers/formatGraphRange'
import {
  formatColumns,
  formatLocationColumns,
  formatUserColumns,
  formatManagerColumns,
} from '@/helpers/formatColumns'
import {
  formatDashboardData,
  formatManagerDashboardData,
  formatSearchUsers,
} from '@/helpers/formatData'

import { getLastMonthDate } from './Dashboard.utils'
import {
  CloseButton,
  CustomDateRange,
  HeadingSection,
  GraphFiltersBlock,
  GraphsSection,
  RangeTitle,
  TableSection,
  TableFiltersBlock,
  TableLoaderWrapper,
  TopWrapper,
  UserTableWrapper,
} from './styles'
import { VIEW_FLAG } from '@/forms/Dashboard/constants'
import { Box } from 'grommet'
import { employeeColumns } from '@/constants/table-columns'
import { Divider } from '@/components/Divider'

export function DashboardForm({
  viewFlag,
  funnelData,
  graphData,
  graphDataRange,
  tableAllData,
  tableLocationData,
  tableUserData,
  orgData,
  locationsData,
  savedViewData,
  graphTrendsData,
  fetchGraphData,
  fetchGraphDataRange,
  fetchTableAllData,
  fetchTableAllDataRange,
  fetchGraphTrendsData,
  fetchTableLocationData,
  isGraphLoading,
  isOrgDataLoading,
  isTableLoading,
  allUsers,
}) {
  const { afterToday } = DateRangePicker
  const theme = useTheme()
  const [isPageLoading, setLoading] = useState(true)
  const [isTableSectionLoading, setTableLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [managerRows, setManagerRows] = useState([])
  const [graphs, setGraphs] = useState([])
  const [trendsGraphs, setTrendsGraphs] = useState([])
  const [locations, setLocations] = useState(false)
  const [employees, setEmployees] = useState(false)
  const [managers, setManagers] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState('All Locations')
  const [selectedUser, setSelectedUser] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState('All Employees')
  const [selectedManager, setSelectedManager] = useState('All Managers')
  const [hasViewSelected, setHasViewSelected] = useState(false)
  const [hasSelectedDate, setHasSelectedDate] = useState(false)
  const [, setEditUser] = useState(false)
  const [footerData, setFooterData] = useState([])
  const [managerFooterData, setManagerFooterData] = useState([])
  const [table, setTable] = useState('employees')
  const [range, setRange] = useState('thisMonth')
  const [userList, setUserList] = useState([])

  const formatMonth = (current) => {
    current = parseInt(current) === 0 ? 12 : current
    return parseInt(current) < 10 ? `0${current}` : current
  }

  const [date, setDate] = useState(
    `${new Date().getFullYear()}-${formatMonth(new Date().getMonth() + 1)}`
  )
  const [config, setConfig] = useState(fetchAllDashboardConfig)
  const [graphConfig, setGraphConfig] = useState({
    ...fetchGraphConfig,
    orgId: orgData?.id || null,
  })
  const [pages, setPages] = useState(1)
  const [currentPage, setPage] = useState(0)
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const [dateRange, setDateRange] = useState([])
  const isDesktop = useMediaQuery('(min-width: 770px)')

  let searchTimeout
  const employeeListRef = useRef()
  const customReportRef = useRef()
  const soarContext = useContext(SoarStateContext)
  const [state] = useActor(soarContext.dashboardService)

  const columns = useMemo(
    () =>
      footerData &&
      funnelData &&
      formatColumns(
        footerData,
        funnelData,
        orgData?.conversionMeasurementOption
      ),
    [funnelData, footerData]
  )

  const managerColumns = useMemo(
    () =>
      managerFooterData &&
      funnelData &&
      formatManagerColumns(
        managerFooterData,
        funnelData,
        orgData?.conversionMeasurementOption
      ),
    [funnelData, managerFooterData]
  )

  const locationColumns = useMemo(
    () =>
      footerData && funnelData && formatLocationColumns(footerData, funnelData),
    [footerData]
  )

  const userColumns = useMemo(
    () => footerData && funnelData && formatUserColumns(funnelData),
    [footerData]
  )

  const isLoading = useMemo(() => {
    return isGraphLoading || isOrgDataLoading || isTableLoading
  }, [isGraphLoading, isOrgDataLoading, isTableLoading])

  const changeRange = useCallback((event) => {
    const now = new Date()
    soarContext.dashboardService.send('CLEAN_DATE_RANGE')
    if (event.value === 'thisMonth') {
      setIsDatePickerOpen(false)
      setRange('thisMonth')
      setDateRange([])
      return setDate(
        `${now.getFullYear()}-${formatMonth(now.getMonth() + 1)}-01`
      )
    }

    if (event.value === 'lastMonth') {
      setIsDatePickerOpen(false)
      setRange('lastMonth')
      setDateRange([])
      return setDate(`${now.getFullYear()}-${formatMonth(now.getMonth())}-01`)
    }

    if (event.value === 'range') {
      setRange('range')
      setIsDatePickerOpen(true)
    }

    return null
  }, [])

  const setRangeOfDates = (items) => {
    setDateRange({
      startDate: format(items[0], 'yyyy-MM-dd'),
      endDate: format(items[1], 'yyyy-MM-dd'),
    })
  }

  const setEmployeesTable = useCallback(() => {
    setTableLoading(true)
    setTable('employees')
    return setTableLoading(false)
  }, [])

  const setLocationsTable = useCallback(() => {
    setTableLoading(true)
    setTable('locations')
    return setTableLoading(false)
  }, [])

  const clearFilters = useCallback(() => {
    employeeListRef.current.clear()
    customReportRef.current.clear()
    setHasViewSelected(false)
    setHasSelectedDate(false)
    setSelectedUser(false)
    setSelectedEmployee('All Employees')
    setSelectedManager('All Managers')
    setSelectedLocation('All Locations')
    setTable('employees')
    // setRange('thisMonth')
    changeRange({
      value: 'thisMonth',
      label: 'This Month',
    })
    soarContext.dashboardService.send('CLEAN_DATE_RANGE')
  }, [])

  const formatLocations = useCallback(() => {
    if (locationsData) {
      const arr = locationsData.map((item) => {
        return { value: item, label: item, key: item }
      })
      arr.unshift({ label: 'All Locations', value: 'All Locations' })
      return setLocations(arr)
    }
    return setLocations(false)
  }, [locationsData])

  const formatEmployees = useCallback(() => {
    if (rows) {
      const arr = rows.map((item) => {
        return { value: item.name, label: item.name, key: item.fbUid, ...item }
      })
      arr.unshift({ label: 'All Employees', value: 'All Employees' })
      return setEmployees(arr)
    }
    return setEmployees(false)
  }, [rows])

  const formatManagers = useCallback(() => {
    if (managerRows) {
      const arr = managerRows.map((item) => {
        const subs = item.subordinates.map((item) => {
          return {
            value: item.name,
            label: item.name,
            key: item.fbUid,
            ...item,
          }
        })
        subs.unshift({ label: 'All Employees', value: 'All Employees' })
        return {
          value: item.name,
          label: item.name,
          key: item.fbUid,
          salespersons: subs,
          ...item,
        }
      })
      arr.unshift({ label: 'All Managers', value: 'All Managers' })
      return setManagers(arr)
    }
    return setManagers(false)
  }, [managerRows])

  const handlePagination = useCallback((page) => {
    setPage(page)
  }, [])

  useEffect(() => {
    if (dateRange.startDate) {
      setHasSelectedDate(true)
      let location = locationsData
      let searchConfig = ''
      let searchGraph = ''

      if (
        (selectedUser ||
          selectedEmployee !== 'All Employees' ||
          selectedManager !== 'All Managers') &&
        locationsData
      ) {
        searchGraph =
          selectedUser.fbUid || selectedEmployee.fbUid || selectedManager.fbUid
      } else if (selectedLocation !== 'All Locations') {
        searchConfig = selectedLocation
        location = [selectedLocation]
      }

      setGraphConfig({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        locations: location,
        search: searchGraph,
        orgId: orgData.id,
      })

      setConfig({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        skip: 0,
        limit: 1000000,
        search: searchConfig,
      })
    } else if (date) {
      if (range !== 'thisMonth') {
        setHasSelectedDate(true)
      }
      if (
        (selectedUser ||
          selectedEmployee !== 'All Employees' ||
          selectedManager !== 'All Managers') &&
        locationsData
      ) {
        setGraphConfig({
          date,
          locations: locationsData,
          search:
            selectedUser.fbUid ||
            selectedEmployee.fbUid ||
            selectedManager.fbUid,
          orgId: orgData.id,
        })
      } else if (selectedLocation !== 'All Locations') {
        setConfig({
          date,
          // skip: currentPage * 10,
          // limit: 10,
          skip: 0,
          limit: 1000000,
          search: selectedLocation,
        })
        setGraphConfig({
          date,
          locations: [selectedLocation],
          search: '',
          orgId: orgData.id,
        })
      } else {
        setConfig({
          date,
          // skip: currentPage * 10,
          // limit: 10,
          skip: 0,
          limit: 1000000,
          search: '',
        })
        if (locationsData)
          setGraphConfig({
            date,
            locations: locationsData,
            search: '',
            orgId: orgData.id,
          })
      }
    }
    return null
  }, [
    date,
    dateRange,
    selectedUser,
    selectedEmployee,
    selectedManager,
    selectedLocation,
    locationsData,
    currentPage,
  ])

  useEffect(() => {
    if (graphTrendsData && graphTrendsData.data) {
      const result = []

      const hasLeads = graphTrendsData.data.trendsProgress.some((x) =>
        Object.keys(x).indexOf('Leads & Opportunities')
      )

      const hasAppointments = graphTrendsData.data.trendsProgress.some((x) =>
        Object.keys(x).indexOf('Appointments')
      )

      const hasShows = graphTrendsData.data.trendsProgress.some((x) =>
        Object.keys(x).indexOf('Appointments Shows')
      )

      const hasOtherVisits = graphTrendsData.data.trendsProgress.some((x) =>
        Object.keys(x).indexOf('In-Store Visits')
      )

      const hasPresentations = graphTrendsData.data.trendsProgress.some((x) =>
        Object.keys(x).indexOf('Presentations')
      )

      const hasDemos = graphTrendsData.data.trendsProgress.some((x) =>
        Object.keys(x).indexOf('Demo Drives')
      )

      const hasWriteUps = graphTrendsData.data.trendsProgress.some((x) =>
        Object.keys(x).indexOf('Write-Ups')
      )

      const hasSales = graphTrendsData.data.trendsProgress.some((x) =>
        Object.keys(x).indexOf('Sales')
      )

      for (let i = 0; i < graphTrendsData.data.trendsProgress.length; i++) {
        const month = graphTrendsData.data.trendsProgress[i]

        const item = {
          name: `${month.date.split('-')[0]}-${+month.date.split('-')[1]}`,
          Income: month.Income,
        }
        if (hasLeads) {
          item['Leads'] = month['Leads & Opportunities'] ?? 0
        }

        if (hasAppointments) {
          item['Appointments'] = month['Appointments'] ?? 0
        }

        if (hasPresentations) {
          item['Presentations'] = month['Presentations'] ?? 0
        }

        if (hasShows) {
          item['Shows'] = month['Appointment Shows'] ?? 0
        }

        if (hasOtherVisits) {
          item['Other Visits'] = month['In-Store Visits'] ?? 0
        }

        if (hasDemos) {
          item['Demo Drives'] = month['Demo Drives'] ?? 0
        }

        if (hasWriteUps) {
          item['Write-Ups'] = month['Write-Ups'] ?? 0
        }

        if (hasSales) {
          item['Sales'] = month['Sales'] ?? 0
        }

        result.push(item)
      }

      setTrendsGraphs(result)
    }
  }, [graphTrendsData])

  useEffect(() => {
    fetchGraphTrendsData(graphConfig)
    if (
      selectedUser ||
      selectedEmployee !== 'All Employees' ||
      selectedManager !== 'All Managers'
    ) {
      if (dateRange.startDate) fetchGraphDataRange(graphConfig)
      else fetchGraphData(graphConfig)
    }
    if (
      (!selectedUser ||
        selectedEmployee === 'All Employees' ||
        selectedManager === 'All Managers') &&
      selectedLocation !== 'All Locations'
    ) {
      if (dateRange.startDate) fetchGraphDataRange(graphConfig)
      else fetchGraphData(graphConfig)
    }
    if (
      (!selectedUser ||
        selectedEmployee === 'All Employees' ||
        selectedManager === 'All Managers') &&
      selectedLocation === 'All Locations' &&
      graphConfig?.locations?.length
    ) {
      if (dateRange.startDate) fetchGraphDataRange(graphConfig)
      else fetchGraphData(graphConfig)
    }
    return null
  }, [
    graphConfig,
    selectedUser,
    selectedEmployee,
    selectedManager,
    selectedLocation,
  ])

  useEffect(() => {
    if (table === 'employees' && !config.startDate) {
      return fetchTableAllData(config)
    }

    if (table === 'employees' && graphConfig.startDate) {
      return fetchTableAllDataRange(graphConfig)
    }

    if (table === 'locations') fetchTableLocationData(config)
    return null
  }, [table, config?.date, config?.skip, config?.search, config?.startDate])

  const selectUser = useCallback((item) => {
    setEditUser(item)
  }, [])

  const selectItem = useCallback((item) => {
    if (item === null) {
      setTable('locations')
    } else if (item.fbUid) {
      setSelectedUser(item)
      setSelectedEmployee('All Employees')
      setSelectedManager('All Managers')
    } else if (item.employees) {
      setSelectedLocation(item.name, false)
    }
  }, [])

  const selectEmployee = useCallback((item) => {
    setIsDatePickerOpen(false)
    employeeListRef.current.clear()
    customReportRef.current.clear()
    if (item === null) {
      setTable('locations')
    } else if (item.fbUid) {
      setSelectedUser(false)
      setSelectedEmployee(item)
    } else if (item.employees) {
      setSelectedLocation(item.name, false)
    } else {
      setSelectedEmployee(item.label || item.name)
    }
  }, [])

  const selectManager = useCallback((item) => {
    setSelectedEmployee('All Employees')
    setIsDatePickerOpen(false)
    employeeListRef.current.clear()
    customReportRef.current.clear()
    if (item === null) {
      setTable('locations')
    } else if (item.fbUid) {
      setSelectedUser(false)
      setSelectedManager(item)
    } else if (item.employees) {
      setSelectedLocation(item.name, false)
    } else {
      setSelectedManager(item.label || item.name)
    }
  }, [])

  const selectLocation = useCallback((data) => {
    setIsDatePickerOpen(false)
    employeeListRef.current.clear()
    customReportRef.current.clear()

    if (data.label || data.name) {
      setSelectedUser(false)
      setSelectedEmployee('All Employees')
      setSelectedManager('All Managers')
      setSelectedLocation(data.label || data.name)
      setEmployeesTable()
    }
  }, [])

  const selectedView = useCallback(
    (location, selectedDateRange, user, savedRange) => {
      clearFilters()
      setHasViewSelected(true)
      selectLocation(location, false)
      if (savedRange === 'range') {
        setDateRange(selectedDateRange[0])
        setRange(savedRange)
      } else if (savedRange === 'thisMonth') {
        setRange(savedRange)
        changeRange({
          value: 'thisMonth',
          label: 'This Month',
        })
      } else if (savedRange === 'lastMonth') {
        setRange(savedRange)
        changeRange({
          value: 'lastMonth',
          label: 'Last Month',
        })
      }

      if (user) {
        selectItem(user)
      } else {
        setSelectedUser(false)
      }
    },
    []
  )

  useEffect(() => {
    setRows(
      (tableAllData &&
        funnelData &&
        formatDashboardData(
          tableAllData.users,
          selectUser,
          funnelData,
          orgData?.conversionMeasurementOption
        )) ||
        []
    )

    setManagersAllRows(
      (tableAllData &&
        funnelData &&
        formatManagerDashboardData(
          tableAllData.managers,
          selectUser,
          funnelData,
          orgData?.conversionMeasurementOption
        )) ||
        []
    )

    setPages(tableAllData && tableAllData.total)
  }, [
    table,
    tableLocationData,
    tableAllData,
    tableUserData,
    selectedUser,
    selectedEmployee,
    selectedManager,
    funnelData,
  ])

  const setManagersAllRows = (managers) => {
    setManagerRows(managers)
  }

  useEffect(() => {
    if (dateRange.startDate) {
      setGraphs(
        graphDataRange &&
          pages &&
          funnelData &&
          formatGraphRange(graphDataRange, pages, funnelData)
      )
    } else
      setGraphs(
        graphData &&
          pages &&
          funnelData &&
          formatGraph(graphData, pages, funnelData)
      )
  }, [graphData, graphDataRange, tableAllData, pages, funnelData])

  useEffect(() => {
    formatEmployees()

    const footer = {
      sales: { total: 0, avg: 0 },
      shows: { total: 0, avg: 0 },
      instore: { total: 0, avg: 0 },
      totVisits: { total: 0, avg: 0 },
      writeUps: { total: 0, avg: 0 },
      leads: { total: 0, avg: 0 },
      demos: { total: 0, avg: 0 },
      presentations: { total: 0, avg: 0 },
      appointments: { total: 0, avg: 0 },
      employees: { total: 0, avg: 0 },
      usage: { total: 0, avg: 0 },
    }

    rows.forEach((row) => {
      footer.leads.total += parseFloat(row['Leads & Opportunities'], 10) || 0
      footer.appointments.total += parseFloat(row.Appointments, 10) || 0
      footer.shows.total += parseFloat(row['Appointment Shows'], 10) || 0
      footer.instore.total += parseFloat(row['In-Store Visits'], 10) || 0
      footer.totVisits.total = footer.shows.total + footer.instore.total
      footer.sales.total += parseFloat(row.Sales, 10) || 0
      footer.writeUps.total += parseFloat(row['Write-Ups'], 10) || 0
      footer.presentations.total += parseFloat(row.Presentations, 10) || 0
      footer.demos.total += parseFloat(row['Demo Drives'], 10) || 0
      footer.employees.total += parseFloat(row.Employees, 10) || 0
      footer.usage.total += parseFloat(row.Usage, 10) || 0
    })

    const keys = Object.keys(footer)

    keys.forEach((key) => {
      footer[key].avg = Math.trunc((footer[key].total / rows.length) * 10) / 10
    })

    const newKeys = []

    if (footer.leads) newKeys.push('leads')
    if (footer.appointments) newKeys.push('appointments')
    if (footer.shows) newKeys.push('shows')
    if (footer.instore) newKeys.push('instore')
    if (footer.presentations) newKeys.push('presentations')
    if (footer.demos) newKeys.push('demos')
    if (footer.writeUps) newKeys.push('writeUps')
    if (footer.sales) newKeys.push('sales')

    let conversionMeasurementOption = orgData?.conversionMeasurementOption

    if (newKeys.length > 0) {
      newKeys.forEach((item, index) => {
        if (item !== 'leads' && index > 0) {
          let percent = 0
          if (!conversionMeasurementOption) {
            percent = parseInt(
              (parseFloat(footer[item].total, 10) /
                parseFloat(footer[newKeys[index - 1]].total, 10)) *
                100,
              10
            )
          } else if (conversionMeasurementOption === 1) {
            percent = parseInt(
              (parseFloat(footer[item].total, 10) /
                parseFloat(footer[newKeys[0]].total, 10)) *
                100,
              10
            )
          } else {
            if (index < 3) {
              percent = parseInt(
                (parseFloat(footer[item].total, 10) /
                  parseFloat(footer[newKeys[0]].total, 10)) *
                  100,
                10
              )
            } else if (index === 3) {
              percent = parseInt(
                (parseFloat(footer[item].total, 10) /
                  parseFloat(footer[newKeys[1]].total, 10)) *
                  100,
                10
              )
            } else {
              percent = parseInt(
                (parseFloat(footer[item].total, 10) /
                  parseFloat(footer.totVisits.total, 10)) *
                  100,
                10
              )
            }
          }
          footer[item].total = `${footer[item].total}\n${
            isNaN(percent) ? 0 : percent
          }%`
        }
      })
    }
    setFooterData(footer)
  }, [rows])

  useEffect(() => {
    formatManagers()

    const footer = {
      sales: { total: 0, avg: 0 },
      shows: { total: 0, avg: 0 },
      instore: { total: 0, avg: 0 },
      totVisits: { total: 0, avg: 0 },
      writeUps: { total: 0, avg: 0 },
      leads: { total: 0, avg: 0 },
      demos: { total: 0, avg: 0 },
      presentations: { total: 0, avg: 0 },
      appointments: { total: 0, avg: 0 },
      employees: { total: 0, avg: 0 },
      usage: { total: 0, avg: 0 },
    }

    managerRows.forEach((row) => {
      footer.leads.total += parseFloat(row['Leads & Opportunities'], 10) || 0
      footer.appointments.total += parseFloat(row.Appointments, 10) || 0
      footer.shows.total += parseFloat(row['Appointment Shows'], 10) || 0
      footer.instore.total += parseFloat(row['In-Store Visits'], 10) || 0
      footer.totVisits.total = footer.shows.total + footer.instore.total
      footer.sales.total += parseFloat(row.Sales, 10) || 0
      footer.writeUps.total += parseFloat(row['Write-Ups'], 10) || 0
      footer.presentations.total += parseFloat(row.Presentations, 10) || 0
      footer.demos.total += parseFloat(row['Demo Drives'], 10) || 0
      footer.employees.total += parseFloat(row.Employees, 10) || 0
      footer.usage.total += parseFloat(row.Usage, 10) || 0
    })

    const keys = Object.keys(footer)

    keys.forEach((key) => {
      footer[key].avg =
        Math.trunc((footer[key].total / managerRows.length) * 10) / 10
    })

    const newKeys = []

    if (footer.leads) newKeys.push('leads')
    if (footer.appointments) newKeys.push('appointments')
    if (footer.shows) newKeys.push('shows')
    if (footer.instore) newKeys.push('instore')
    if (footer.presentations) newKeys.push('presentations')
    if (footer.demos) newKeys.push('demos')
    if (footer.writeUps) newKeys.push('writeUps')
    if (footer.sales) newKeys.push('sales')

    let conversionMeasurementOption = orgData?.conversionMeasurementOption

    if (newKeys.length > 0) {
      newKeys.forEach((item, index) => {
        if (item !== 'leads' && index > 0) {
          let percent = 0
          if (!conversionMeasurementOption) {
            percent = parseInt(
              (parseFloat(footer[item].total, 10) /
                parseFloat(footer[newKeys[index - 1]].total, 10)) *
                100,
              10
            )
          } else if (conversionMeasurementOption === 1) {
            percent = parseInt(
              (parseFloat(footer[item].total, 10) /
                parseFloat(footer[newKeys[0]].total, 10)) *
                100,
              10
            )
          } else {
            if (index < 3) {
              percent = parseInt(
                (parseFloat(footer[item].total, 10) /
                  parseFloat(footer[newKeys[0]].total, 10)) *
                  100,
                10
              )
            } else if (index === 3) {
              percent = parseInt(
                (parseFloat(footer[item].total, 10) /
                  parseFloat(footer[newKeys[1]].total, 10)) *
                  100,
                10
              )
            } else {
              percent = parseInt(
                (parseFloat(footer[item].total, 10) /
                  parseFloat(footer.totVisits.total, 10)) *
                  100,
                10
              )
            }
          }
          footer[item].total = `${footer[item].total}\n${
            isNaN(percent) ? 0 : percent
          }%`
        }
      })
    }
    setManagerFooterData(footer)
  }, [managerRows])

  useEffect(() => {
    formatLocations()
  }, [locationsData])

  useEffect(() => {
    if (!isOrgDataLoading) {
      setTimeout(() => setLoading(false), 1500)
    }
  }, [isOrgDataLoading])

  useEffect(() => {
    if (isTableLoading) setTableLoading(true)
    if (!isTableLoading) setTimeout(() => setTableLoading(false), 1000)
  }, [isTableLoading])

  useEffect(() => {
    setIsDatePickerOpen(false)
  }, [viewFlag])

  const styleCeils = useCallback(
    (cell) => {
      let footerKey = ''

      switch (cell.column.Header) {
        case 'Sales':
          footerKey = 'sales'
          break
        case 'Shows':
          footerKey = 'shows'
          break
        case 'Write-ups':
          footerKey = 'writeUps'
          break
        case 'Leads':
          footerKey = 'leads'
          break
        case 'Demos':
          footerKey = 'demos'
          break
        case 'Presentations':
          footerKey = 'presentations'
          break
        case 'Appointments':
          footerKey = 'appointments'
          break
        case 'Usage':
          footerKey = 'usage'
          break
        default:
          footerKey = ''
      }

      return footerData &&
        footerKey &&
        cell.value &&
        parseInt(cell.value, 10) <
          // eslint-disable-next-line no-unsafe-optional-chaining
          parseInt(footerData[footerKey]?.avg * 0.9, 10)
        ? {
            style: {
              color: theme.colors.error,
            },
          }
        : {
            style: {},
          }
    },
    [footerData]
  )

  const handleOk = (value) => {
    soarContext.dashboardService.send({
      type: 'SELECTED_DATE_RANGE',
      dateRange: {
        startDate: value[0],
        endDate: value[1],
      },
    })
    setIsDatePickerOpen(false)
  }

  const subTable = useCallback(
    (row) => {
      let columns = formatColumns(
        row.footer,
        funnelData,
        orgData?.conversionMeasurementOption
      )
      if (row.subordinates.length)
        return (
          <Table
            sort
            footer
            allBlack
            type={'employee'}
            data={row.subordinates}
            columns={columns}
            align="left"
            getCeilProps={styleCeils}
          />
        )
      else return 'No Data'
    },
    [columns]
  )

  let dashboardTitle =
    selectedLocation !== 'All Locations' || selectedUser
      ? `${selectedUser?.name || selectedLocation}`
      : `${state.context.orgName} Dashboard`

  const { startDate, endDate } = state.context.dateRange
  const strStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : ''
  const strEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : ''

  if (strStartDate) {
    dashboardTitle = `${dashboardTitle} (${strStartDate} to ${strEndDate})`
  }

  const getDateRangeValue = (range) => {
    if (!Array.isArray(range)) {
      return [new Date(range.startDate), new Date(range.endDate)]
    }

    return range
  }

  return isPageLoading ? (
    <Loader />
  ) : (
    <>
      <CustomReport
        ref={customReportRef}
        type="DASHBOARD"
        range={range}
        userList={userList}
        location={selectedLocation}
        endDate={dateRange.endDate}
        startDate={dateRange.startDate}
        manager={selectedUser.managerName}
        employee={selectedUser.name}
        search={selectedUser.label}
        savedViewData={savedViewData}
        selectedView={selectedView}
        title={dashboardTitle}
      />
      <Divider margin={1} />
      <HeadingSection>
        {isPageLoading ? (
          <GraphFiltersBlock>
            <Loader size="50" />
          </GraphFiltersBlock>
        ) : (
          <GraphFiltersBlock>
            {(selectedLocation !== 'All Locations' ||
              selectedUser ||
              selectedEmployee !== 'All Employees' ||
              selectedManager !== 'All Managers' ||
              hasSelectedDate ||
              hasViewSelected) && (
              <>
                <Button onClick={clearFilters} margin="none" width="130px">
                  Clear Filters
                </Button>
              </>
            )}
            <SelectSearch
              ref={employeeListRef}
              color="primaryText"
              placeholder="Search"
              icon={searchIcon}
              onChange={selectItem}
              hideIndicator
              value={selectedUser}
              data={allUsers}
              marginLeft={
                selectedLocation !== 'All Locations' ||
                selectedUser ||
                selectedEmployee !== 'All Employees' ||
                selectedManager !== 'All Managers' ||
                hasSelectedDate ||
                hasViewSelected
                  ? '5%'
                  : '0px'
              }
            />
            <SelectDashboard
              color="primaryText"
              icon={officeIcon}
              isSearchable={false}
              placeholder="All Locations"
              width="200px"
              options={locations}
              onChange={selectLocation}
              value={selectedLocation}
            />
            <SelectDashboard
              color="primaryText"
              icon={managersIcon}
              isSearchable={false}
              placeholder="All Managers"
              width="200px"
              options={managers}
              onChange={selectManager}
              value={selectedManager}
            />
            <SelectDashboard
              color="primaryText"
              icon={employeesIcon}
              isSearchable={false}
              placeholder="All Employees"
              width="200px"
              options={
                selectedManager === 'All Managers'
                  ? employees
                  : selectedManager.salespersons
              }
              onChange={selectEmployee}
              value={selectedEmployee}
            />
            <SelectDashboard
              color="primaryText"
              icon={calendarIcon}
              isSearchable={false}
              placeholder="This Month"
              width="181px"
              options={[
                { value: 'thisMonth', label: 'This Month' },
                { value: 'lastMonth', label: 'Last Month' },
                { value: 'range', label: 'Select Date Range' },
              ]}
              value={range}
              onChange={changeRange}
            />
            {isDatePickerOpen && (
              <CustomDateRange>
                <TopWrapper>
                  <RangeTitle>Select Custom Date Range</RangeTitle>
                  <CloseButton onClick={() => setIsDatePickerOpen(false)}>
                    <Icon src={closeIcon} />
                  </CloseButton>
                </TopWrapper>
                <DateRangePicker
                  open={isDatePickerOpen}
                  block
                  character=" - "
                  preventOverflow
                  format="dd-MM-yyyy"
                  ranges={[]}
                  value={getDateRangeValue(dateRange)}
                  onChange={setRangeOfDates}
                  showOneCalendar={!isDesktop}
                  placeholder="Select Range"
                  placement="autoVerticalEnd"
                  disabledDate={afterToday()}
                  onOk={handleOk}
                />
              </CustomDateRange>
            )}
          </GraphFiltersBlock>
        )}
      </HeadingSection>
      {viewFlag === VIEW_FLAG.CHARTS ? (
        <ScrollContainer>
          {isPageLoading && <Loader />}
          <GraphsSection>
            <GraphWrapper isLoading={isLoading} title="Goals vs Results">
              {!isGraphLoading && graphData && funnelData && (
                <GoalsGraph
                  data={dateRange.startDate ? graphDataRange : graphData}
                  total={selectedUser ? 1 : pages || 1}
                  funnelData={funnelData}
                  range={range}
                />
              )}
            </GraphWrapper>
            {graphs?.length &&
            Object.keys(graphs[0]).length > 2 &&
            Object.keys(graphs[1]).length > 2 ? (
              <GraphWrapper
                isLoading={isLoading}
                title="Trends"
                style={{ width: '620px' }}
              >
                {!isGraphLoading && graphData && (
                  <TrendsGraph data={trendsGraphs} />
                )}
              </GraphWrapper>
            ) : (
              <GraphWrapper
                isLoading={isLoading}
                title="Trends"
                style={{ width: '620px' }}
              >
                <TrendsGraph />
              </GraphWrapper>
            )}
            {funnelData?.length &&
              funnelData.map(
                ({ name: item, _id }) =>
                  item !== 'name' &&
                  item !== 'month' && (
                    <GraphWrapper isLoading={isLoading} title={item} key={_id}>
                      {!isGraphLoading && graphData && (
                        <FunnelGraph
                          data={
                            dateRange.startDate ? graphDataRange : graphData
                          }
                          field={item}
                          total={1}
                          isRange={!!dateRange.startDate}
                        />
                      )}
                    </GraphWrapper>
                  )
              )}
          </GraphsSection>
        </ScrollContainer>
      ) : (
        <TableSection>
          {(isTableSectionLoading || isPageLoading) && (
            <TableLoaderWrapper>
              <Loader />
            </TableLoaderWrapper>
          )}
          {!isTableSectionLoading && !isPageLoading && (
            <>
              <Title bold fontSize={8}>
                Salespeople
              </Title>
              <Box style={{ marginTop: 10 }}>
                <Table
                  sort
                  footer={
                    selectedUser.role === 'Sales Person' ||
                    selectedEmployee !== 'All Employees'
                      ? false
                      : true
                  }
                  type={'employee'}
                  data={
                    selectedUser.role === 'Sales Person' ||
                    selectedEmployee !== 'All Employees'
                      ? rows.filter((item) =>
                          selectedUser.role === 'Sales Person'
                            ? item.fbUid === selectedUser.fbUid
                            : item.fbUid === selectedEmployee.fbUid
                        )
                      : rows
                  }
                  columns={columns}
                  align="left"
                  onClick={table === 'employees' ? selectItem : null}
                  getCeilProps={styleCeils}
                />
              </Box>
              <Title bold fontSize={7} style={{ marginTop: 30 }}>
                Managers
              </Title>
              <Box style={{ marginTop: 10 }}>
                <Table
                  sort
                  footer={
                    selectedUser.role === 'Sales Manager' ||
                    selectedManager !== 'All Managers'
                      ? false
                      : true
                  }
                  type={'manager'}
                  data={
                    selectedUser.role === 'Sales Manager' ||
                    selectedManager !== 'All Managers'
                      ? managerRows.filter((item) =>
                          selectedUser.role === 'Sales Manager'
                            ? item.fbUid === selectedUser.fbUid
                            : item.fbUid === selectedManager.fbUid
                        )
                      : managerRows
                  }
                  columns={managerColumns}
                  align="left"
                  renderSubComponent={subTable}
                  getCeilProps={styleCeils}
                />
              </Box>
            </>
          )}
        </TableSection>
      )}
    </>
  )
}
