import React from 'react'

import { Wrapper, Line } from './styles'

export function Divider({ width, margin, color, size }) {
  return (
    <Wrapper width={width} margin={margin}>
      <Line color={color} size={size} />
    </Wrapper>
  )
}
