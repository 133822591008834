import styled from 'styled-components'

export const LinkContainer = styled.div`
  a {
    color: ${({ theme }) => theme.colors.headerText};
    font-size: ${({ theme }) => theme.fontSizes[6]}px;
    text-decoration: none;
    text-transform: capitalize;

    &:hover {
      color: ${({ theme }) => theme.colors.primaryText};
    }
  }

  a.active {
    color: ${({ theme }) => theme.colors.primaryText};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.backgroundActive};
    text-underline-offset: 7px;
  }

  ${({ theme }) => theme.below.tablet`
    a {
      font-size: 14px;
    }
  `}
`
