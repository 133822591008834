export const MIN_PASSWORD_LENGTH = 8
export const MAX_PASSWORD_LENGTH = 16
export const MAX_FIRSTNAME_LENGTH = 30
export const MAX_LASTNAME_LENGTH = 30
export const MAX_NAME_LENGTH = 30
export const MAX_LOCATION_LENGTH = 30
export const MAX_VIEW_NAME_LENGTH = 30

export const PHONE_REGEXP = /^(?:\(\d{3}\)|\d{3}-)\d{3}-\d{4}$/
export const EMAIL_REGEXP = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
