import axios from 'axios'
import { useMutation } from 'react-query'

import { DASHBOARD_GRAPH_DATA_RANGE_ENDPOINT } from '@/constants/endpoints'

export const useGraphDataRangeMutation = (options) =>
  useMutation(
    (
      payload = {
        startDate: '2022-01-01',
        endDate: '2022-01-01',
        locations: [],
        search: '',
      }
    ) => {
      if (!payload?.locations?.length) return null
      return axios.post(DASHBOARD_GRAPH_DATA_RANGE_ENDPOINT, payload)
    },
    options
  )
