import React from 'react'

import { Button } from '@/components/Button'
import { FormWrapper } from '@/components/FormWrapper'
import { Logo } from '@/components/Logo'
import { Link } from '@/components/Link'
import { CREATE_ORGANIZATION_ROUTE } from '@/constants/routes'

import { Subtitle, LogoContainer } from './styles'

export function WelcomeForm() {
  return (
    <>
      <LogoContainer>
        <Logo size="small" />
      </LogoContainer>
      <FormWrapper logo={false} title="Welcome!" titleSize={12}>
        <Subtitle>Let’s set up your organization</Subtitle>
        <Link to={CREATE_ORGANIZATION_ROUTE}>
          <Button margin={4}>Start Setup</Button>
        </Link>
      </FormWrapper>
    </>
  )
}
