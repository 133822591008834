import axios from 'axios'
import { useMutation } from 'react-query'

import { ORGANIZATION_ENDPOINT } from '@/constants/endpoints'
import { createToast } from '@/helpers/createToast'

export const useAddOrgMutation = () =>
  useMutation((payload) => axios.post(ORGANIZATION_ENDPOINT, payload), {
    onSuccess: (data) => {
      if (data.status < 300)
        return createToast('success', 'Organization added successfully!')
      return createToast('error', data.statusText)
    },
  })
