import axios from 'axios'
import { useQuery } from 'react-query'

import { UPDATE_USERS_ENDPOINT } from '@/constants/endpoints'
import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'

export const useUpdateUsersQuery = async (
  action = null,
  ids = null,
  options = null
) => {
  switch (action) {
    case 'Active':
      action = 'active'
      break
    case 'Inactive':
      action = 'inactive'
      break
    case 'Setup Complete':
      action = 'setup'
      break
    case 'Training Complete':
      action = 'training'
      break
  }

  const result = await axios.patch(UPDATE_USERS_ENDPOINT, {
    action,
    userIds: ids,
  })
  return result
}
