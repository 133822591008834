import React from 'react'

import { ArrowLink } from '@/components/ArrowLink'
import { Title } from '@/components/Title'
import { Subtitle } from '@/components/Subtitle'
import { Link } from '@/components/Link'
import { Button } from '@/components/Button'
import { Icon } from '@/components/Icon'
import arrowIcon from '@/assets/icons/rightArrow.svg'

import {
  Wrapper,
  ArrowLinkContainer,
  TitleBlock,
  ContentBlock,
  FormWrapper,
  TableWrapper,
  ContinueButtonContainer,
} from './styles'

export function DualSection({
  tabs,
  size,
  title,
  subtitle,
  arrowLinkPath,
  continuePath,
  padding,
  data,
  children,
}) {
  return (
    <>
      {arrowLinkPath && (
        <ArrowLinkContainer size={size}>
          <ArrowLink to={arrowLinkPath}>Back</ArrowLink>
        </ArrowLinkContainer>
      )}
      <Wrapper size={size} padding={padding}>
        <TitleBlock>
          {title && (
            <Title semiBold as="h2" fontSize={9}>
              {title}
            </Title>
          )}
          {subtitle && <Subtitle fontSize={6}>{subtitle}</Subtitle>}
        </TitleBlock>
        <ContentBlock>
          <FormWrapper>{children[0]}</FormWrapper>
          <TableWrapper tabs={tabs} data={data}>
            {children[1]}
          </TableWrapper>
        </ContentBlock>
      </Wrapper>
      {continuePath && data && (
        <ContinueButtonContainer size={size}>
          <Link to={continuePath}>
            <Button width="180px">
              <Icon src={arrowIcon} />
              Save & Continue
            </Button>
          </Link>
        </ContinueButtonContainer>
      )}
    </>
  )
}
