import React, { useState, useCallback, useEffect } from 'react'
import { Area, AreaChart, CartesianGrid, Legend, XAxis, YAxis } from 'recharts'
import _ from 'lodash'

import {
  Wrapper,
  LegendWrapper,
  LegendRow,
  LegendItem,
  LegendCircle,
  IncomeLine,
  NoDataDiv,
} from './styles'

export function TrendsGraph({ data }) {
  const [newData, setNewData] = useState({})

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const res = [...data]
      Object.keys(res[0]).map((item) => {
        if (res[0][item] === 0) {
          res[0][item] = 0.00001
        }
        return null
      })
      setNewData(res)
    }
  }, [data])

  const renderLegend = useCallback(
    ({ payload }) => (
      <LegendWrapper>
        {payload.length > 4 ? (
          <>
            <LegendRow>
              {payload.map((entry, index) => {
                if (index < 5) {
                  return (
                    <LegendItem key={`item-${entry.name}-${entry.value}`}>
                      {entry.dataKey !== 'Income' && (
                        <LegendCircle color={entry.color} />
                      )}
                      {entry.value}
                    </LegendItem>
                  )
                }
                return null
              })}
            </LegendRow>
            <LegendRow>
              {payload.map((entry, index) => {
                if (index >= 5 && index < 10) {
                  return (
                    <LegendItem key={`item-${entry.name}-${entry.value}`}>
                      {entry.value !== 'Income' ? (
                        <LegendCircle color={entry.color} />
                      ) : (
                        <IncomeLine />
                      )}
                      {entry.value}
                    </LegendItem>
                  )
                }
                return null
              })}
            </LegendRow>
            <LegendRow>
              {payload.map((entry, index) => {
                if (index >= 10) {
                  return (
                    <LegendItem key={`item-${entry.name}-${entry.value}`}>
                      {entry.value !== 'Income' ? (
                        <LegendCircle color={entry.color} />
                      ) : (
                        <IncomeLine />
                      )}
                      {entry.value}
                    </LegendItem>
                  )
                }
                return null
              })}
            </LegendRow>
          </>
        ) : (
          <LegendRow singleLine>
            {payload.map((entry) => (
              <LegendItem singleLine key={`item-${entry.name}-${entry.value}`}>
                {entry.name !== 'Income' && (
                  <LegendCircle color={entry.color} />
                )}
                {entry.value}
              </LegendItem>
            ))}
          </LegendRow>
        )}
      </LegendWrapper>
    ),
    []
  )

  const formatTick = useCallback(
    (value) => (value ? `$${value / 1000}k` : '0'),
    []
  )

  const monthFormatter = useCallback((value) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    return months[+value.split('-')[1] - 1]
  }, [])

  const funnelFormatter = useCallback((value) => {
    return (+value).toLocaleString('en-US')
  }, [])

  const getMaxKey = () => {
    const res = []
    newData.map((item) => {
      let ret = {}
      let current = 0
      let currentKey = ''
      Object.keys(item).map((e) => {
        if (e && e !== 'name' && e !== 'Income') {
          if (item[e] >= current) {
            ret = { [e]: item[e] }
            current = item[e]
            currentKey = e
          } else ret = { [currentKey]: current }
        }
        return current
      })
      if (!_.isEmpty(ret)) res.push(ret)
      return res
    })

    res.sort((a, b) => {
      return Object.values(b) - Object.values(a)
    })

    return Object.keys(res[0])[0]
  }

  return (
    <Wrapper>
      {newData.length > 0 ? (
        <AreaChart
          width={650}
          height={470}
          margin={{
            top: 35,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          data={newData}
        >
          <defs>
            <linearGradient id="colorLeads" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="rgb(43, 202, 96)"
                stopOpacity={0.4}
              />
              <stop offset="95%" stopColor="rgb(43, 202, 96)" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorAppointments" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="rgb(0, 147, 199)"
                stopOpacity={0.4}
              />
              <stop offset="95%" stopColor="rgb(0, 147, 199)" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorDemoDrives" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="rgb(127, 92, 209)"
                stopOpacity={0.4}
              />
              <stop
                offset="95%"
                stopColor="rgb(127, 92, 209)"
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorWriteUps" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="rgb(223, 45, 25)"
                stopOpacity={0.4}
              />
              <stop offset="95%" stopColor="rgb(223, 45, 25)" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorSales" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="rgb(248, 203, 69)"
                stopOpacity={0.4}
              />
              <stop
                offset="95%"
                stopColor="rgb(248, 203, 69)"
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorPresentations" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="rgb(235, 123, 54)"
                stopOpacity={0.4}
              />
              <stop
                offset="95%"
                stopColor="rgb(235, 123, 54)"
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorShows" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="rgb(229, 88, 149)"
                stopOpacity={0.4}
              />
              <stop
                offset="95%"
                stopColor="rgb(229, 88, 149)"
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorOtherVisits" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="rgb(3, 255, 242)"
                stopOpacity={0.4}
              />
              <stop
                offset="95%"
                stopColor="rgb(3, 255, 242)"
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="transparent" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="rgb(248, 203, 69)" stopOpacity={0} />
              <stop
                offset="100%"
                stopColor="rgb(248, 203, 69)"
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient
              id="line-gradient"
              x1="60"
              y1="20"
              x2="245"
              y2="20"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0%" stopColor="rgb(93, 99, 107)" stopOpacity={0} />
              <stop offset="50%" stopColor="rgb(93, 99, 107)" stopOpacity={1} />
              <stop
                offset="100%"
                stopColor="rgb(93, 99, 107)"
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis
            axisLine={false}
            tickLine={false}
            dataKey="name"
            tickSize={8}
            tickFormatter={monthFormatter}
            height={20}
          />
          <YAxis
            label={{
              value: 'Activities',
              position: 'top',
              dx: 10,
              dy: -15,
            }}
            type="number"
            domain={
              newData?.length && newData[newData.length - 1].empty && [0, 1000]
            }
            axisLine={false}
            tickLine={false}
            tickFormatter={funnelFormatter}
            dataKey={getMaxKey()}
            tickCount={
              newData?.length && newData[newData.length - 1].empty ? 11 : 10
            }
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            label={{
              value: 'Income',
              position: 'top',
              dx: -12,
              dy: -15,
            }}
            axisLine={false}
            tickLine={false}
            type="number"
            tickCount={10}
            dataKey="Income"
            tickFormatter={formatTick}
            padding={{ bottom: 10 }}
          />
          <CartesianGrid strokeWidth={1} vertical={false} />
          <Legend verticalAlign="bottom" content={renderLegend} />
          {newData?.length && newData[0].Leads && (
            <Area
              type="monotone"
              dataKey="Leads"
              stroke="rgb(43, 202, 96)"
              strokeWidth={1.5}
              fillOpacity={1}
              fill="url(#colorLeads)"
            />
          )}
          {newData?.length && newData[0].Appointments && (
            <Area
              type="monotone"
              dataKey="Appointments"
              stroke="rgb(0, 147, 199)"
              strokeWidth={1.5}
              fillOpacity={1}
              fill="url(#colorAppointments)"
            />
          )}
          {newData?.length && newData[0]['Demo Drives'] && (
            <Area
              type="monotone"
              dataKey="Demo Drives"
              stroke="rgb(127, 92, 209)"
              strokeWidth={1.5}
              fillOpacity={1}
              fill="url(#colorDemoDrives)"
            />
          )}
          {newData?.length && newData[0]['Write-Ups'] && (
            <Area
              type="monotone"
              dataKey="Write-Ups"
              stroke="rgb(223, 45, 25)"
              strokeWidth={1.5}
              fillOpacity={1}
              fill="url(#colorWriteUps)"
            />
          )}
          {newData?.length && newData[0].Sales && (
            <Area
              type="monotone"
              dataKey="Sales"
              stroke="rgb(248, 203, 69)"
              strokeWidth={1.5}
              fillOpacity={1}
              fill="url(#colorSales)"
            />
          )}
          {newData?.length && newData[0].Shows && (
            <Area
              type="monotone"
              dataKey="Shows"
              stroke="rgb(229, 88, 149)"
              strokeWidth={1.5}
              fillOpacity={1}
              fill="url(#colorShows)"
            />
          )}
          {newData?.length && newData[0]['Other Visits'] && (
            <Area
              type="monotone"
              dataKey="Other Visits"
              stroke="rgb(3, 255, 242)"
              strokeWidth={1.5}
              fillOpacity={1}
              fill="url(#colorOtherVisits)"
            />
          )}
          {newData?.length && newData[0].Presentations && (
            <Area
              type="monotone"
              dataKey="Presentations"
              stroke="rgb(235, 123, 54)"
              strokeWidth={1.5}
              fillOpacity={1}
              fill="url(#colorPresentations)"
            />
          )}
          {newData?.length && newData[0].Income && (
            <Area
              type="monotone"
              dataKey="Income"
              stroke="rgb(255, 255, 255)"
              strokeWidth={1.5}
              fill="url(#transparent)"
              strokeDasharray="4 4"
              yAxisId="right"
            />
          )}
        </AreaChart>
      ) : (
        <NoDataDiv>No Data</NoDataDiv>
      )}
    </Wrapper>
  )
}
