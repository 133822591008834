import styled, { css } from 'styled-components'

import React from 'react'

export const SubscriptionBox = styled.div`
  background: ${({ background }) => (background ? `${background}` : 'none')};
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  margin: 2rem 0 2rem 0;
  padding-bottom: 3rem;
  width: 350px;

  @font-face {
    font-family: 'Inter';
    src: url('Inter.ttf') format('truetype');
  }

  .contact-sales-button {
    text-align: center;
  }

  .contact-sales-button a {
    width: auto;
  }

  .pricing-group-box {
    border: 1px solid #1f2937;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 35px;
  }

  .pricing-group-box-highlighted {
    background: #1f2937;
  }

  .subscription-box-title {
    color: #fff;
    font-size: 32px !important;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 5px;
    position: relative;
    text-align: center;
    .plan-offer {
      background: #5cae6d;
      border-radius: 3px;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      height: 30px;
      line-height: 30px;
      padding-left: 5px;
      padding-right: 5px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
    }
  }

  .subscription-box-users {
    color: #7a7a7a;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 10px;
    text-align: center;
  }

  .subscription-box-price-row {
    color: #fff;
    margin-bottom: 10px;
    text-align: center;
  }

  .subscription-box-dollar {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    position: relative;
    top: 8px;
    vertical-align: top;
  }
  .subscription-box-price {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
  }

  .subscription-box-frequency {
    color: #9ca3af;
  }

  .subscription-box-billing {
    margin-bottom: 10px;
  }

  .subscription-box-billing {
    color: #7a7a7a;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
  }

  .subscription-box-price-annual {
    color: #478755;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 10px;
  }

  .subscription-box-get-started {
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-top: 30px;
    text-align: center;

    .elementor-button {
      background: #5cae6d;
      border-radius: 4px;
      border-radius: 4px;
      color: #000;
      display: block;
      padding: 12px 16px;
    }
    .disabled-button {
      background: #4b5563;
      color: #9ca3af;
    }
  }

  .subscription-box-features {
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin: 0 auto;
    padding: 0 35px !important;
    font-size: 16px !important;
  }

  .subscription-box-features {
    .elementor-icon-list-item {
      background: url('../../assets/icons/check-white.png') center center
        no-repeat;
      margin-bottom: 0.7rem;
    }
  }

  .subscription-box-features .feature-text {
    padding-left: 5px;
  }
  .subscription-box-features-li {
    background-image: url('./check-sign.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  .subscription-box-bottom-box {
    color: #7a7a7a;
    font-weight: bold;
  }

  .subscription-box-bottom-box {
    color: #7a7a7a;
    font-weight: bold;
    margin-bottom: 0 !important;
  }

  .subscription-pricing-details table {
    width: 100%;
  }

  .subscription-pricing-details table th {
    border: none;
    color: #fff;
    font-weight: 600;
    padding: 0;
  }

  .subscription-pricing-details table td {
    background: none !important;
    border: none !important;
    color: #f3f4f6;
    font-size: 12px;
    line-height: inherit;
    padding: 0px;
  }

  .subscription-box-price-before {
    color: #9ca3af;
    font-family: arial;
    padding-right: 10px;
    text-decoration-line: line-through;
  }

  .subscription-box-price-after {
    color: #5cae6d;
    font-family: arial;
  }

  .you-save {
    color: #5cae6d;
    margin: 10px auto;
    text-align: center;
  }

  .subscription-box-wrapper {
    margin: 10px auto;
  }

  .display-inline-block {
    display: inline-block !important;
  }

  .text-bold {
    font-weight: bold;
  }
`

export const Icon = styled.img`
  display: inline-block;
  height: 15px;
  margin-right: 5px;
  width: 15px;
`
