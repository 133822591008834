import axios from 'axios'
import { useMutation } from 'react-query'
import { signInWithEmailAndPassword } from 'firebase/auth'

import { auth } from '@/services/firebase'
import { GET_INFO_ENDPOINT } from '@/constants/endpoints'
import { HTTP_STATUS_UNAUTHORIZED } from '@/constants/http-status'
import { AUTH_TOKEN_STORAGE_KEY } from '@/constants/storage-keys'
import { isSuperAdmin } from '@/utils/rolesUtils'
import {
  ADD_MANAGERS_ROUTE,
  CONFIRM_YOUR_PLAN_ROUTE,
  DASHBOARD_ROUTE,
  CREATE_ORGANIZATION_ROUTE,
  SUPER_ADMIN_MENU,
} from '@/constants/routes'

export const useSignInMutation = (options) =>
  useMutation(async ({ email, password }) => {
    const { user } = await signInWithEmailAndPassword(auth, email, password)

    const { data } = await axios
      .get(GET_INFO_ENDPOINT, {
        headers: { Authorization: `Bearer ${user.accessToken}` },
      })
      .catch((error) => {
        if (
          error.status === HTTP_STATUS_UNAUTHORIZED ||
          error.response.status === HTTP_STATUS_UNAUTHORIZED
        ) {
          localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, '')
        }
      })

    console.log(
      'data.isReg ',
      data.isReg,
      ' data.organization ',
      data.organization,
      ' data.isPaid ',
      data.isPaid
    )

    const { roles } = data
    const usrIsSuperAdmin = isSuperAdmin(roles)

    console.log('usrIsSuperAdmin', usrIsSuperAdmin)

    if(data.role === 'Sales Manager') {
      return { user, route: DASHBOARD_ROUTE, usrIsSuperAdmin }
    }

    if (usrIsSuperAdmin) {
      return { user, route: SUPER_ADMIN_MENU, usrIsSuperAdmin }
    }
    if (!data.isReg && data.organization) {
      return { user, route: ADD_MANAGERS_ROUTE, usrIsSuperAdmin }
    }
    if (!data.isReg && !data.isPaid) {
      return { user, route: CONFIRM_YOUR_PLAN_ROUTE, usrIsSuperAdmin }
    }
    if (!data.isReg && data.isPaid) {
      return { user, route: CREATE_ORGANIZATION_ROUTE, usrIsSuperAdmin }
    }
    return { user, route: DASHBOARD_ROUTE, usrIsSuperAdmin }
  }, options)
