import React from 'react'
import { Box } from 'grommet'

import { Wrapper, Label } from './styles'

export function LabelButton({ width, padding, type, label, value, ...props }) {
  return (
    <Box {...props}>
      <Wrapper width={width} padding={padding} type={type} {...props}>
        {value}
        <Label>{label}</Label>
      </Wrapper>
    </Box>
  )
}
