import React from 'react'

import checkIcon from '@/assets/icons/checkboxCheck.svg'

import {
  Wrapper,
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Label,
  Icon,
} from './styles'

export function Checkbox({
  label,
  checked = false,
  size,
  value,
  noWrap,
  color,
  onChange,
  ...props
}) {
  return (
    <Wrapper>
      <CheckboxContainer>
        <HiddenCheckbox
          checked={checked}
          onChange={onChange}
          value={value}
          {...props}
        />
        <StyledCheckbox checked={checked} size={size}>
          <Icon src={checkIcon} size={10} />
        </StyledCheckbox>
      </CheckboxContainer>
      {label && (
        <Label size={size} noWrap={noWrap} color={color}>
          {label}
        </Label>
      )}
    </Wrapper>
  )
}
