import axios from 'axios'
import { useMutation } from 'react-query'

import { ENABLE_MANY_ORGANIZATIONS_ENDPOINT } from '@/constants/endpoints'

export const useRestoreOrgMutation = (options) =>
  useMutation(
    (payload) => axios.post(`${ENABLE_MANY_ORGANIZATIONS_ENDPOINT}`, payload),
    options
  )
