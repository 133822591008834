import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { DualScreenLayout } from '@/components/DualScreenLayout'
import { SignUpUserForm } from '@/forms/SignUpUser'
import backgroundImage from '@/assets/images/signUp-password-background.svg'
import signUpPasswordImage from '@/assets/images/signUp-password-image.png'
import { useUpdatePasswordMutation } from '@/queries/update-password'
import { SUCCESS_REGISTRATION_ROUTE } from '@/constants/routes'
import { createToast } from '@/helpers/createToast'

export default function SignUpUser() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const email = new URLSearchParams(search).get('email')
  const { mutate: updatePassword, isLoading } = useUpdatePasswordMutation({
    onSuccess: () => {
      createToast('success', 'Success registration! You can close this window.')
      navigate(SUCCESS_REGISTRATION_ROUTE)
    },
  })

  return (
    <DualScreenLayout
      image={signUpPasswordImage}
      imageWidth="90%"
      background={backgroundImage}
    >
      <SignUpUserForm
        initialValues={{ email }}
        isLoading={isLoading}
        onSubmit={updatePassword}
      />
    </DualScreenLayout>
  )
}
