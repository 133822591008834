import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { Area, AreaChart, CartesianGrid, Legend, XAxis, YAxis } from 'recharts'
import { format } from 'date-fns'

import { months } from '@/constants/months'
import { formatFunnelGraph } from '@/helpers/formatGraph'
import { formatFunnelGraphRange } from '@/helpers/formatGraphRange'

import {
  Wrapper,
  LegendWrapper,
  LegendRow,
  LegendItem,
  LegendCircle,
  IncomeLine,
} from './styles'

function CustomizedDot({ cx, cy, index, isRange }) {
  if (index !== 1 || isRange) return null
  return (
    <svg viewBox="0 0 325 430">
      <circle cx={cx} cy={cy} r="4" fill="white" />
      <line x1={cx} y1={cy} x2={cx} y2={370} stroke="white" strokeWidth={2} />
      <text x={cx - 10} y={387}>
        Today
      </text>
    </svg>
  )
}

export function FunnelGraph({ data, field, total, isRange }) {
  let fieldName = field
  if (field === 'Leads') fieldName = 'Leads & Opportunities'
  if (field === 'Shows') fieldName = 'Appointment Shows'

  const [yTicks, setyTicks] = useState([])
  const [yMaxDomain, setyMaxDomain] = useState(110)

  function setTicks(input) {
    if (input) {
      const max = Math.max(
        Math.max(...input.map((x) => x.Actual)),
        Math.max(...input.map((x) => x.Goal)),
        Math.max(...input.map((x) => x['Last Month'] ?? x['Last Period']))
      )

      let next = 120
      const ticks = []
      let multiple = 10

      if (max <= 10) {
        multiple = 1
      } else if (max <= 20) {
        multiple = 2
      } else if (max <= 30) {
        multiple = 3
      } else if (max <= 40) {
        multiple = 4
      } else if (max <= 50) {
        multiple = 5
      } else if (max <= 100) {
        multiple = 10
      } else if (max > 120 && max <= 200) {
        multiple = 20
      } else if (max > 200 && max <= 250) {
        multiple = 25
      } else if (max > 250) {
        multiple = 300
      }

      next = Math.ceil(max / multiple) * multiple

      for (let i = 0; i <= next; i += multiple) {
        ticks.push(i)
      }

      setyMaxDomain(next)
      setyTicks(ticks)
    }
  }

  const graphData = useMemo(() => {
    let fieldData = []
    if (data && fieldName) {
      if (data.startDate) {
        fieldData = formatFunnelGraphRange(data, fieldName, total)
      } else {
        fieldData = formatFunnelGraph(data, fieldName, total)
      }

      return fieldData
    }
    return data
  }, [data, fieldName])

  useEffect(() => {
    setTicks(graphData)
  }, [graphData])

  const renderLegend = useCallback(
    ({ payload }) => (
      <LegendWrapper>
        {payload.length > 3 ? (
          <>
            <LegendRow style={{ marginTop: '30px' }}>
              {payload.map((entry, index) => {
                if (index < 3) {
                  return (
                    <LegendItem key={`item-${entry.name}-${entry.value}`}>
                      {entry.dataKey !== 'Income' && (
                        <LegendCircle color={entry.color} />
                      )}
                      {entry.value}
                    </LegendItem>
                  )
                }
                return null
              })}
            </LegendRow>
            <LegendRow>
              {payload.map((entry, index) => {
                if (index >= 3 && index < 6) {
                  return (
                    <LegendItem key={`item-${entry.name}-${entry.value}`}>
                      {entry.value !== 'Income' ? (
                        <LegendCircle color={entry.color} />
                      ) : (
                        <IncomeLine />
                      )}
                      {entry.value}
                    </LegendItem>
                  )
                }
                return null
              })}
            </LegendRow>
            <LegendRow>
              {payload.map((entry, index) => {
                if (index >= 6) {
                  return (
                    <LegendItem key={`item-${entry.name}-${entry.value}`}>
                      {entry.value !== 'Income' ? (
                        <LegendCircle color={entry.color} />
                      ) : (
                        <IncomeLine />
                      )}
                      {entry.value}
                    </LegendItem>
                  )
                }
                return null
              })}
            </LegendRow>
          </>
        ) : (
          <LegendRow singleLine style={{ marginTop: '30px' }}>
            {payload.map((entry) => (
              <LegendItem singleLine key={`item-${entry.name}-${entry.value}`}>
                {entry.name !== 'Income' && (
                  <LegendCircle color={entry.color} />
                )}
                {entry.value}
              </LegendItem>
            ))}
          </LegendRow>
        )}
      </LegendWrapper>
    ),
    []
  )

  const formatDate = useCallback((value) => {
    const date = new Date(value)
    const today = new Date()

    if (data.startDate) {
      return format(date, 'yyyy-MM-dd')
    }
    return today.getDate() === date.getDate()
      ? ''
      : `${months[date.getMonth()]} ${date.getDate()}`
  }, [])

  const domain = useMemo(
    () =>
      graphData?.length && [
        graphData[0].name,
        graphData[graphData.length - 1].name,
      ],
    [graphData]
  )

  const ticks = useMemo(
    () =>
      graphData?.length &&
      (new Date(graphData[0].name).getMonth() === new Date().getMonth()
        ? [
            graphData[0].name,
            graphData[1].name,
            graphData[graphData.length - 1].name,
          ]
        : [graphData[0].name, graphData[graphData.length - 1].name]),
    [graphData]
  )

  return (
    <Wrapper>
      <AreaChart
        width={325}
        height={430}
        margin={{
          top: 20,
          right: 40,
          left: 0,
          bottom: 0,
        }}
        data={graphData}
      >
        <defs>
          <linearGradient id="colorGoal" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="rgb(43, 202, 96)" stopOpacity={0.4} />
            <stop offset="95%" stopColor="rgb(43, 202, 96)" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorActual" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="rgb(0, 147, 199)" stopOpacity={0.4} />
            <stop offset="95%" stopColor="rgb(0, 147, 199)" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorLastMonth" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="rgb(127, 92, 209)" stopOpacity={0.4} />
            <stop offset="95%" stopColor="rgb(127, 92, 209)" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="line-gradient">
            <stop offset="0%" stopColor="rgb(93, 99, 107)" stopOpacity={0} />
            <stop offset="50%" stopColor="rgb(93, 99, 107)" stopOpacity={1} />
            <stop offset="100%" stopColor="rgb(93, 99, 107)" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          axisLine={false}
          tickLine={false}
          dataKey="name"
          scale="time"
          type="number"
          tickSize={8}
          tickFormatter={formatDate}
          domain={domain}
          ticks={ticks}
          dy={10}
        />
        <YAxis
          type="number"
          axisLine={false}
          tickLine={false}
          tickSize={10}
          minTickGap={0}
          domain={[0, yMaxDomain]}
          ticks={yTicks}
        />
        <CartesianGrid strokeWidth={1} vertical={false} stroke="#656B69" />
        <Legend verticalAlign="bottom" content={renderLegend} />
        <Area
          type="linear"
          dataKey="Goal"
          stroke="rgb(43, 202, 96)"
          strokeWidth={1.5}
          fillOpacity={1}
          fill="url(#colorGoal)"
        />
        <Area
          type="linear"
          dataKey="Actual"
          stroke="rgb(0, 147, 199)"
          strokeWidth={1.5}
          fillOpacity={1}
          fill="url(#colorActual)"
          dot={ticks?.length > 2 && <CustomizedDot isRange={isRange} />}
        />
        <Area
          type="linear"
          dataKey={data && data.startDate ? 'Last Period' : 'Last Month'}
          stroke="rgb(127, 92, 209)"
          strokeWidth={1.5}
          fillOpacity={1}
          fill="url(#colorLastMonth)"
        />
      </AreaChart>
    </Wrapper>
  )
}
